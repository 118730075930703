import { trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

export function useCreateOrder(callbackFunction: () => void) {
  const {
    data,
    mutate: createOrder,
    isLoading,
  } = trpc.company.order.useMutation({
    onSuccess: () => callbackFunction(),
    onError(error) {
      dispatchToast({
        type: 'error',
        content: error.message,
      });
    },
  });

  return {
    adminInfo: data?.adminInfo,
    createOrder,
    externalId: data?.data._id,
    isLoading,
  };
}
