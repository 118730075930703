import Typography from '@frontend/components/Typography';
import styled from 'styled-components';

type Props = {
  /** Progress percentage. Should be between 0 and 100 */
  progress: number;
};

export default function ProgressBar(props: Props) {
  const progress = Math.min(100, Math.max(0, Math.floor(props.progress)));
  return (
    <StyledContainer>
      <StyledBarContainer>
        <StyledBar progress={progress} />
      </StyledBarContainer>
      <Typography.Body2>{`${progress}%`}</Typography.Body2>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 12px;
`;

const StyledBarContainer = styled.div`
  flex: 1;
  height: 8px;
  background-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.pill};
`;

const StyledBar = styled.div<{ progress: number }>`
  width: ${({ progress }) => `${progress}%`};
  height: 8px;
  background-color: ${({ theme, progress }) =>
    progress === 100
      ? theme.colors.feedback.success[40]
      : theme.colors.secondary[50]};
  border-radius: ${({ theme }) => theme.borders.radius.pill};
`;
