import DefaultModal from '@frontend/components/modals/DefaultModal';
import Icon from '@frontend/components/Icon';
import Spacer from '@frontend/components/Spacer';
import TableClient from '@frontend/components/TableClient';
import Typography from '@frontend/components/Typography';
import ModalService from '@frontend/services/ModalService';
import { cpfMask } from '@frontend/utils/masks';

type Props = {
  deposits: Array<{
    name: string;
    documentNumber: string;
  }>;
};

function DepositsByEmployeeModal(props: Props) {
  const controller = ModalService.useModalController();

  return (
    <DefaultModal
      isOpen={controller.visible}
      onClose={controller.remove}
      header={{
        title: 'Ver depósito por pessoa',
        subtitle:
          'Confira as pessoas selecionadas para a solicitação em andamento',
      }}
      buttons={{
        confirm: {
          text: 'Fechar',
          onClick: controller.remove,
        },
      }}
    >
      <Typography.Headline7>Pessoas na solicitação</Typography.Headline7>
      <Spacer y={'xs'} />
      <TableClient
        emptyState={{
          emptyText: '',
          filteredEmptyText: '',
        }}
        loading={false}
        columns={columns}
        data={props.deposits}
      />
    </DefaultModal>
  );
}

export default ModalService.create(DepositsByEmployeeModal);

type Row = Props['deposits'][number];
type Columns = React.ComponentProps<typeof TableClient<Row>>['columns'];

const columns = [
  {
    accessorKey: 'name',
    header: 'Pessoa',
    cell: (context) => {
      return (
        <>
          <Typography.Body3 color="neutral_40">
            {context.row.original.name}
          </Typography.Body3>
          <div style={{ display: 'flex', gap: '4px' }}>
            <Icon size={16} name="IconId" color="neutral_70" />
            <Typography.Body4 color="neutral_50">
              {cpfMask(context.row.original.documentNumber)}
            </Typography.Body4>
          </div>
        </>
      );
    },
  },
] satisfies Columns;
