import { Route } from 'react-router-dom';
import FlashCash from './FlashCash';
import BasePath from '../../../routes/BasePath';
import RouterService from '@frontend/services/RouterService';

export const ROUTE_FlashCash = RouterService.create(
  `${BasePath.relativePath}/transactions/flashcash`,
);

export const RouteComponent_FlashCash = (
  <Route path={ROUTE_FlashCash.path} element={<FlashCash />} />
);
