import { Checkbox } from '@flash-tecnologia/hros-web-ui-v2';
import TableClient from '@frontend/components/TableClient';
import Typography from '@frontend/components/Typography';
import Flex from '@frontend/components/frames/Flex';
import InputErrorMessage from '../_primitives/InputErrorMessage';

type Props<TOption extends string> = {
  header: string;
  options: Readonly<
    Array<{
      title: string;
      description?: string;
      value: TOption;
    }>
  >;
  value: Set<TOption>;
  onChange: (newValue: Set<TOption>) => void;
  error?: string;
};

export default function SelectTable<TOption extends string>(
  props: Props<TOption>,
) {
  function handleToggleAll() {
    if (props.options.length === props.value.size) {
      props.onChange(new Set());
    } else {
      props.onChange(new Set(props.options.map((option) => option.value)));
    }
  }

  function handleToggle(selectedValue: TOption) {
    const setCopy = new Set(props.value);
    if (setCopy.has(selectedValue)) {
      setCopy.delete(selectedValue);
    } else {
      setCopy.add(selectedValue);
    }
    props.onChange(setCopy);
  }

  return (
    <Flex direction="column">
      <TableClient
        data={props.options}
        emptyState={{
          emptyText: 'Nenhum item disponível',
          filteredEmptyText: 'Nenhum item encontrado',
        }}
        loading={false}
        columns={[
          {
            id: 'Checkbox',
            header: () => (
              <Checkbox
                checked={props.options.length === props.value.size}
                onChange={handleToggleAll}
              />
            ),
            cell: ({ row }) => (
              <Checkbox
                checked={props.value.has(row.original.value)}
                onChange={() => handleToggle(row.original.value)}
              />
            ),
          },
          {
            accessorKey: 'title',
            header: props.header,
            size: Number.MAX_SAFE_INTEGER,
            cell: ({ row }) => (
              <>
                <Typography.Body3>{row.original.title}</Typography.Body3>
                <Typography.Caption>
                  {row.original.description}
                </Typography.Caption>
              </>
            ),
          },
        ]}
      />
      <InputErrorMessage error={props.error} />
    </Flex>
  );
}
