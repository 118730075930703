import {
  Button,
  Icons,
  LinkButton,
  PDFViewer,
  Spinner,
} from '@flash-tecnologia/hros-web-ui-v2';
import { ModuleContainer } from '@frontend/components/FocusedFlow/styled';
import { OrderlyList } from '@frontend/components/OrderlyList';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import ModalService from '@frontend/services/ModalService';
import { capitalizeWords, formatDate, toCurrency } from '@frontend/utils/masks';
import { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { z } from 'zod';
import { CreateDepositFormSchema } from '../data/useCreateDeposit';
import { FlexColumn } from './styled';

type Props = {
  adminInfo?: {
    email?: string;
    name: string;
  };
  form: UseFormReturn<z.infer<typeof CreateDepositFormSchema>>;
  isLoading: boolean;
  pixMetadata?: {
    debitNote: string;
    pdfLink: string;
    pixCode?: string;
  };
};

export const PixDepositFeedback = ({
  adminInfo,
  form,
  isLoading = true,
  pixMetadata,
}: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const modalController = ModalService.useModalController();

  const { amount, expirationDate } = form.getValues();

  const { pdfLink, pixCode, debitNote } = pixMetadata || {};
  const { name, email } = adminInfo || {};

  const leftSideActions = [
    ...(!!debitNote
      ? [
          {
            label: 'Baixar comprovante da solicitação',
            onClick: () => {
              const win = window.open(debitNote);
              if (win) {
                win.focus();
              }
            },
          },
        ]
      : []),
    {
      label: 'Ir para pagamentos e cobranças',
      onClick: () => {
        modalController.remove();
        navigate('/corporateCard/transactions');
      },
    },
  ];

  return (
    <ModuleContainer gap="24px">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Icons
          name="IconCircleCheck"
          color={theme.colors.feedback.success[40]}
          fill={theme.colors.feedback.success[90]}
          size={48}
        />
        <div style={{ display: 'flex', gap: '16px' }}>
          <Tag variant="neutral" size="md">
            {
              <div
                style={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                }}
              >
                <Icons name="Pix" color="none" size={24} />
                Pix
              </div>
            }
          </Tag>
          <Tag variant="error" size="md" dot={true}>
            {'Aguardando pagamento'}
          </Tag>
        </div>
      </div>
      <FlexColumn gap="8px">
        <Typography.Headline7 color="neutral_20">
          Depósito solicitado
        </Typography.Headline7>
        {email ? (
          <Typography.Body3 color="neutral_30">
            O código Pix também será enviado para o e-mail{' '}
            <strong>{email}</strong>
          </Typography.Body3>
        ) : (
          <Typography.Body3 color="neutral_30">
            O código Pix também será enviado por e-mail{' '}
          </Typography.Body3>
        )}
      </FlexColumn>
      <div style={{ display: 'flex', gap: '48px' }}>
        <FlexColumn gap="8px">
          <Typography.Body3 color="neutral_30">
            Valor de depósito
          </Typography.Body3>
          <Typography.Headline8 color="neutral_30">
            {toCurrency(amount)}
          </Typography.Headline8>
        </FlexColumn>
        <FlexColumn gap="8px">
          <Typography.Body3 color="neutral_30">
            Vencimento do código Pix
          </Typography.Body3>
          <Typography.Headline8 color="neutral_30">
            {formatDate(expirationDate)}
          </Typography.Headline8>
        </FlexColumn>
        <FlexColumn gap="8px">
          <Typography.Body3 color="neutral_30">Solicitado por</Typography.Body3>
          <Typography.Headline8 color="neutral_30">
            {capitalizeWords(name || '')}
          </Typography.Headline8>
        </FlexColumn>
      </div>
      <FlexColumn gap="40px">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          {isLoading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '24px',
              }}
            >
              <Spinner variant="primary" size={64} />
            </div>
          ) : !pdfLink ? (
            <div
              style={{
                padding: '40px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '24px',
              }}
            >
              <Typography.Headline8 color="neutral_30">
                Erro ao buscar os dados de pagamento
              </Typography.Headline8>
              <Typography.Body3 color="neutral_30">
                Acesse a página de Faturas para obter as informações de
                pagamento.
              </Typography.Body3>
            </div>
          ) : (
            <div style={{ display: 'flex', width: '100%', height: '642px' }}>
              <PDFViewer
                src={pdfLink}
                options={{
                  fit: 'height',
                  showToolbar: true,
                }}
                width={'100%'}
                height={'100%'}
              />
            </div>
          )}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '24px',
              width: '100%',
            }}
          >
            <OrderlyList
              title={'Passo-a-passo para o pagamento via QR-CODE'}
              listItems={[
                'No app do banco ou instituição financeira entre na área Pix',
                'Escolha a opção pagar com QR Code e escaneie o código',
                'Confirme as informações e finalize o pagamento',
              ]}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
              }}
            >
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
              >
                <Typography.Body3 color="neutral_20" weight={700}>
                  Você pode pagar usando o código “Pix copia e cola”
                </Typography.Body3>
                {isLoading ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '24px',
                    }}
                  >
                    <Spinner variant="primary" size={48} />
                  </div>
                ) : (
                  <Typography.Body4 color="neutral_30" weight={600}>
                    {pixCode}
                  </Typography.Body4>
                )}
              </div>
              {!pixCode ? (
                <></>
              ) : (
                <Button
                  size="small"
                  onClick={() => navigator.clipboard.writeText(pixCode)}
                  buttonType={'secondary'}
                  variant={'secondary'}
                  variantType="default"
                >
                  <Icons name="IconCopy" fill="none" /> Copiar código Pix
                </Button>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            height: '1px',
            background: theme.colors.neutral[90],
          }}
        />
      </FlexColumn>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '24px' }}>
          {leftSideActions.map((action) => (
            <LinkButton
              variant={'neutral'}
              onClick={() => action.onClick()}
              style={{ alignSelf: 'center' }}
              key={action.label}
            >
              <Typography.Body3 color="neutral_30" weight={700}>
                {action.label}
              </Typography.Body3>
            </LinkButton>
          ))}
        </div>
      </div>
    </ModuleContainer>
  );
};
