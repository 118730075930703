import ModalService from '../../../services/ModalService';
import { toCurrency } from '../../../utils/masks';
import ReclassificationError from '../modals/reclassification/ReclassificationError';
import ReclassificationPreviewModal from '../modals/reclassification/ReclassificationPreviewModal';
import { trpc } from '../../../utils/trpc';

type Input = {
  transactionId: string;
};

export default function useReclassifyTransactionPreview(input: Input) {
  const reclassificationPreview =
    trpc.employee.corporateCard.statement.update.preview.useQuery(input, {
      onError: (error) => {
        ModalService.hide(ReclassificationPreviewModal);
        ModalService.show(ReclassificationError, { message: error.message });
      },
    });
  if (!reclassificationPreview.data) return { loading: true } as const;

  const currentIsCorporate =
    reclassificationPreview.data.currentDebitData[0].isCorporate;
  const previewIsCorporate =
    reclassificationPreview.data.previewDebitData[0].isCorporate;

  const formattedData = {
    current: {
      icon: currentIsCorporate ? 'IconBriefcase' : 'IconWallet',
      title: currentIsCorporate ? 'Corporativo' : 'Benefício',
      body: reclassificationPreview.data.currentDebitData
        .map((item) => `${item.name} ${toCurrency(item.amount)}`)
        .join('\n'),
    },
    preview: {
      icon: previewIsCorporate ? 'IconBriefcase' : 'IconWallet',
      title: previewIsCorporate ? 'Corporativo' : 'Benefício',
      body: reclassificationPreview.data.previewDebitData
        .map((item) => `${item.name} ${toCurrency(item.amount)}`)
        .join('\n'),
    },
  } as const;

  return {
    data: formattedData,
    loading: reclassificationPreview.isLoading,
  } as const;
}
