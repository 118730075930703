import ToggleField from '@frontend/components/inputs/ToggleField';
import { Controller } from 'react-hook-form';
import { UseFormReturn } from '../../../forms/useForm';

type Props = {
  form: UseFormReturn;
};

export default function WithdrawToggle(props: Props) {
  return (
    <Controller
      control={props.form.control}
      name="withdrawEnabled"
      render={({ field }) => (
        <ToggleField
          value={field.value}
          onChange={field.onChange}
          title="Habilitar saque no cartão físico"
          description="Defina se o colaborador poderá sacar o saldo corporativo usando o cartão físico da Flash. Limite de 2 saques por dia para o colaborador e limite de R$ 20.000,00 por dia para toda a empresa. Para cada saque, haverá uma taxa de operação de R$ 5,99."
        />
      )}
    />
  );
}
