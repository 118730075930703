import styled from 'styled-components';

export const OrderlyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const OrderlyListUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const OrderlyListLi = styled.li`
  list-style: none;
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const OrderlyListItemNumber = styled.div`
  border-radius: 24px;
  background: ${({ theme }) => theme.colors.secondary[95]};
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;
