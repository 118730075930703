import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import ModalService from '@frontend/services/ModalService';
import OnboardingCoupledVirtualCardTutorialModal from './OnboardingCoupledVirtualCardTutorialModal';

function OnboardingCoupledVirtualCardStartModal() {
  const modalController = ModalService.useModalController();

  function handleDismiss() {
    modalController.remove();
  }

  function handleAccept() {
    ModalService.show(OnboardingCoupledVirtualCardTutorialModal, {});
    modalController.remove();
  }

  return (
    <ConfirmationModal
      isOpen={modalController.visible}
      onClose={handleDismiss}
      variant="default"
      text={{
        highlightedText: 'Novidade!',
        title: 'Cartão virtual corporativo',
        body: (
          <>
            Agora as pessoas de sua equipe poderão gerar um cartão virtual
            específico de saldo corporativo através do Flash App.
            <br />
            <br />
            Quer entender os benefícios e como o fluxo funciona? Vamos te
            mostrar!
          </>
        ),
      }}
      buttons={{
        cancel: {
          onClick: handleDismiss,
          text: 'Não, obrigado',
        },
        confirm: {
          onClick: handleAccept,
          text: 'Conferir',
        },
      }}
    />
  );
}

export default ModalService.create(OnboardingCoupledVirtualCardStartModal);
