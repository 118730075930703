import { Route } from 'react-router-dom';
import Deposits from './Deposits';
import BasePath from '../../../routes/BasePath';
import RouterService from '@frontend/services/RouterService';

export const ROUTE_Deposit = RouterService.create(
  `${BasePath.relativePath}/transactions/deposits`,
);

export const RouteComponent_Deposit = (
  <Route path={ROUTE_Deposit.path} element={<Deposits />} />
);
