import errors from '@frontend/utils/commonTexts/errors';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

function toastFormSchemaError() {
  dispatchToast({
    type: 'error',
    content: 'Falha ao validar formulário de criação da política de uso',
    description: errors.actions.toastDescription,
  });
}

export const toasts = {
  toastFormSchemaError,
};
