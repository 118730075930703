import styled from 'styled-components';

export const CenteredBox = styled.div`
  display: flex;
  justify-content: center;
`;

export const MenuItem = styled.div`
  display: flex;
  gap: 16px;
`;
