import { ModuleContainer } from '@frontend/components/FocusedFlow/styled';
import Typography from '@frontend/components/Typography';
import { useEffect } from 'react';
import { UseFormReturn } from '../form/useForm';
import { UserCard } from './SelectPerson';

type Props = {
  name: string;
  email?: string;
  nanoId: string;
  form: UseFormReturn;
};

export const PersonInfoCard = ({ name, email, nanoId, form }: Props) => {
  useEffect(() => {
    form.setValue('employee', {
      id: nanoId,
      name,
    });
  }, [nanoId]);

  return (
    <ModuleContainer
      padding="32px"
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      }}
    >
      <Typography.Headline8
        style={{ fontWeight: 700, fontSize: '20px' }}
        color="neutral_30"
      >
        Titular da conta
      </Typography.Headline8>
      <UserCard companyRole={null} email={email} name={name} reportTo={null} />
    </ModuleContainer>
  );
};
