import { GetStatementOutput } from '../../Statement/FlashCard/data/useTableData';

type TransactionType = GetStatementOutput['items'][number]['type'];

type TransactionInfo = {
  description: string;
  transactionType: string;
};

export function useTransactionInfo(
  transactionType: TransactionType,
): TransactionInfo {
  const translatedInfo = {
    description: '',
    transactionType: '',
  };
  switch (transactionType) {
    case 'VOUCHER_PURCHASE':
      translatedInfo.description = 'Compra de Voucher';
      translatedInfo.transactionType = 'Compra';
      break;
    case 'OPEN_LOOP_PAYMENT':
      translatedInfo.description = 'Compra no Cartão';
      translatedInfo.transactionType = 'Compra';
      break;
    case 'BILLET_PAYMENT':
      translatedInfo.description = 'Pagamento de boleto';
      translatedInfo.transactionType = 'Compra';
      break;
    case 'REVERSAL':
      translatedInfo.description = 'Pagamento de boleto';
      translatedInfo.transactionType = 'Compra';
      break;
    case 'DEPOSIT':
      translatedInfo.description = 'Depósito para colaborador';
      translatedInfo.transactionType = 'Depósito';
      break;
    case 'TED_CASH_OUT':
      translatedInfo.description = 'Transferência TED para outra conta';
      translatedInfo.transactionType = 'Transferência';
      break;
    case 'PIX_CASH_OUT':
      translatedInfo.description = 'Transferência PIX para outra conta';
      translatedInfo.transactionType = 'Transferência';
      break;
    case 'PIX_CASH_IN':
      translatedInfo.description = 'Transferência PIX para conta';
      translatedInfo.transactionType = 'Transferência';
      break;
    case 'OTHER':
      translatedInfo.description = 'Outro';
      translatedInfo.transactionType = 'Outro';
      break;
  }
  return translatedInfo;
}
