import Typography from '@frontend/components/Typography';
import Flex from '@frontend/components/frames/Flex';
import ModalService from '@frontend/services/ModalService';
import ReportCard from './components/ReportCard';

import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import Spacer from '@frontend/components/Spacer';
import Tag from '@frontend/components/TagV2';
import BorderedSection from '@frontend/components/frames/BorderedSection';
import {
  REPORTS_CORPORATE_CARD_STATEMENT,
  REPORTS_CORPORATE_CARD_USERS,
  REPORTS_FLASH_BALANCE,
  REPORTS_FLASH_CASH_STATEMENT,
  REPORTS_MANUAL_URL,
} from './consts';
import CorporateCardStatement from './reports/CorporateCardStatement';
import CorporateCardUsers from './reports/CorporateCardUsers';
import FlashBalance from './reports/FlashBalance';
import FlashCashStatement from './reports/FlashCashStatement';

export function ReportSection() {
  return (
    <Flex direction="column" gap="xs">
      <BorderedSection backgroundColor="neutral" padding="xs3">
        <Flex direction="row" align="center">
          <Spacer x="xs2" />
          <Icons name="IconBulb" />
          <Spacer x="xs3" />
          <Typography.Body4 weight={600} color="neutral_50">
            Quer entender sobre como utilizar os relatórios de cartão
            corporativo e extrair o melhor dos dados? Saiba mais no nosso{' '}
            <Typography.Body4
              color="primary"
              as="a"
              href={REPORTS_MANUAL_URL}
              target="_blank"
              weight={700}
            >
              manual de uso de Reports
            </Typography.Body4>
          </Typography.Body4>
        </Flex>
      </BorderedSection>
      <ReportCard
        title={REPORTS_FLASH_BALANCE}
        template="expense-balance-flash"
        description="Visão do saldo disponível na Flash, com possibilidade de consulta por data e abertura de valores por Flash Cash e Cartões Corporativos Flash"
        onClick={() => ModalService.show(FlashBalance, {})}
        leftIcon="IconCoin"
        retryReportCreation={(input) => {
          if (
            input.action === 'create' &&
            input.template === 'expense-balance-flash'
          ) {
            ModalService.show(FlashBalance, { initialValue: input });
          } else {
            ModalService.show(FlashBalance, { step: 2 });
          }
        }}
      />
      <ReportCard
        title={REPORTS_FLASH_CASH_STATEMENT}
        template="expense-company-statement"
        description="Visão detalhada das movimentações realizadas em Flash Cash, apresentando os débitos, créditos e saldo disponível por data pesquisada."
        onClick={() => ModalService.show(FlashCashStatement, {})}
        leftIcon="IconListSearch"
        retryReportCreation={(input) => {
          if (
            input.action === 'create' &&
            input.template === 'expense-company-statement'
          ) {
            ModalService.show(FlashCashStatement, { initialValue: input });
          } else {
            ModalService.show(FlashCashStatement, { step: 2 });
          }
        }}
      />
      <ReportCard
        title={REPORTS_CORPORATE_CARD_STATEMENT}
        template="expense-employees-statement"
        description="Visão detalhada das movimentações realizadas nos Cartões Corporativos Flash, apresentando os débitos e créditos de cada pessoa, bem como seu saldo diário."
        onClick={() => ModalService.show(CorporateCardStatement, {})}
        leftIcon="IconCreditCard"
        component={
          <Tag size="xs" variant="neutral">
            <Flex direction="row" align="center">
              <Icons name="IconSparkles" />
              <Spacer x="xs5" />
              <Typography.Caption
                color="neutral_10"
                weight={600}
                style={{ lineHeight: '18px' }}
              >
                Modelo atualizado: Inclusão do status da transação
              </Typography.Caption>
            </Flex>
          </Tag>
        }
        retryReportCreation={(input) => {
          if (
            input.action === 'create' &&
            input.template === 'expense-employees-statement'
          ) {
            ModalService.show(CorporateCardStatement, {
              initialValue: input,
            });
          } else {
            ModalService.show(CorporateCardStatement, { step: 2 });
          }
        }}
      />
      <ReportCard
        title={REPORTS_CORPORATE_CARD_USERS}
        template="expense-employees"
        description="Relação de pessoas incluídas para uso do Cartão Corporativo Flash, apresentando seu status atual e o saldo conforme data pesquisada."
        onClick={() => ModalService.show(CorporateCardUsers, {})}
        leftIcon="IconUsers"
        retryReportCreation={(input) => {
          if (
            input.action === 'create' &&
            input.template === 'expense-employees'
          ) {
            ModalService.show(CorporateCardUsers, { initialValue: input });
          } else {
            ModalService.show(CorporateCardUsers, { step: 2 });
          }
        }}
      />
    </Flex>
  );
}

export function ReportSectionTabLabel() {
  return (
    <Flex direction="row" gap="xs5" align="center">
      Cartão corporativo Flash
    </Flex>
  );
}
