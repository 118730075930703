import { dispatchToast } from '../../../../utils/dispatchEvents';
import { trpc } from '@frontend/trpc';

export const useCancelDeposit = () => {
  const { mutate: cancelDepositMutation, isLoading: loading } =
    trpc.company.deposits.cancel.useMutation();

  const cancelDeposit = (employeeDepositId: string, callback?: () => void) => {
    return cancelDepositMutation(
      {
        employeeDepositId,
      },
      {
        onSuccess() {
          callback?.();
          dispatchToast({
            type: 'success',
            content: 'Depósito cancelado com sucesso!',
          });
        },
        onError() {
          dispatchToast({
            type: 'error',
            content: 'Não foi possível cancelar deposito.',
            description:
              'Tente novamente e se persistir, entre em contato com o suporte.',
          });
        },
      },
    );
  };

  return {
    cancelDeposit,
    loading,
  };
};
