import Typography from '../Typography';
import {
  OrderlyListContainer,
  OrderlyListUl,
  OrderlyListLi,
  OrderlyListItemNumber,
} from './styled';

type Props = {
  title: string;
  listItems: string[];
};

export const OrderlyList = ({ title, listItems }: Props) => {
  return (
    <OrderlyListContainer>
      <Typography.Body3 color="neutral_20" weight={700}>
        {title}
      </Typography.Body3>
      <OrderlyListUl>
        {listItems.map((item, i) => (
          <OrderlyListLi key={item}>
            <OrderlyListItemNumber>
              <Typography.Caption color="secondary_50">
                {i + 1}
              </Typography.Caption>
            </OrderlyListItemNumber>
            {item}
          </OrderlyListLi>
        ))}
      </OrderlyListUl>
    </OrderlyListContainer>
  );
};
