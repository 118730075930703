import { Route } from 'react-router-dom';
import ErrorMonitor from './ErrorMonitor';
import BasePath from '../../../routes/BasePath';
import RouterService from '@frontend/services/RouterService';

export const ROUTE_ErrorMonitor = RouterService.create(
  `${BasePath.relativePath}/playground/error-monitor`,
);

export const RouteComponent_ErrorMonitor = (
  <Route path={ROUTE_ErrorMonitor.path} element={<ErrorMonitor />}></Route>
);
