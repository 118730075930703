import Callout from '@frontend/components/frames/Callout';
import Icon from '@frontend/components/Icon';
import CheckboxGroup from '@frontend/components/inputs/CheckboxGroup';
import {
  sortedWeekdays,
  translatedWeekdays,
} from '@frontend/utils/dataFormatters/weekdays.dataFormatter';
import { Controller } from 'react-hook-form';
import { UseFormReturn } from '../../../forms/useForm';

type Props = {
  form: UseFormReturn;
};

export default function WeekdaysToggleList(props: Props) {
  return (
    <Callout
      title="Dias de uso"
      description="Dias da semana em que transações com esse cartão estão permitidas."
      alignItems="start"
      leftAdornment={
        <Icon
          name="IconCalendar"
          size={48}
          color="secondary_50"
          background="default"
        />
      }
    >
      <Controller
        control={props.form.control}
        name="weekdaysEnabled"
        render={({ field, fieldState }) => (
          <CheckboxGroup
            options={sortedWeekdays.map((weekday) => ({
              label: translatedWeekdays[weekday],
              value: weekday,
            }))}
            value={field.value}
            onChange={field.onChange}
            error={fieldState.error?.message}
          />
        )}
      />
    </Callout>
  );
}
