import { checkAuth } from '@frontend/utils/auth/checkAuth';
import { Navigate, Outlet } from 'react-router-dom';

export default function CheckPermissions() {
  const isAuthorized = checkAuth();
  if (!isAuthorized) {
    return <Navigate to={'/home'} />;
  }
  return <Outlet />;
}
