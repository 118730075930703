import RequestBalanceSection from './RequestBalanceSection';
import usePendencyCounter from './data/usePendencyCounter';

function RequestBalanceTab() {
  const pendencyCounter = usePendencyCounter();
  const totalPendencies = pendencyCounter.totalPendencies || 0;

  return {
    component: <RequestBalanceSection />,
    label: `Pedidos de saldo (${totalPendencies})`,
    shouldShow,
  };
}

function shouldShow() {
  return true;
}

export { RequestBalanceTab, shouldShow };
