import { Tab } from '@flash-tecnologia/hros-web-ui-v2';
import Policies from '@frontend/pages/Policies/Policies';
import PermissionsService from '@frontend/services/Permissions';

export function CustomizationsTab() {
  const permissions = PermissionsService.usePermissions();
  const show =
    permissions?.includes('*') ||
    permissions?.includes('expense_manage_corporate_cards');

  return {
    show,
    label: 'Cartão corporativo',
    component: (
      <Tab
        variant="secondary"
        tabItens={[
          {
            label: 'Políticas',
            component: <Policies />,
          },
        ]}
      />
    ),
  };
}
