import { trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import useForm, { FormOutput } from './forms/externalCardForm';

export const useCreateExternalCorporateCardUser = (
  onSuccessCallback: () => void,
) => {
  const context = trpc.useContext();

  const createCorporateCardUser = trpc.card.createCorporateCardUser.useMutation(
    {
      onSuccess: () => {
        onSuccessCallback();
      },
      onError: (error) => {
        dispatchToast({
          type: 'error',
          content: error.message,
        });
      },
    },
  );

  function isCreditCard(cardNumber: string) {
    return cardNumber.length === 16;
  }

  async function handleSubmit({
    accountabilityStartDate,
    cardNumber,
    cardOwnerName,
    employee,
  }: FormOutput) {
    const createCorporateCardUserInput = {
      employeeId: employee.id,
      cardType: isCreditCard(cardNumber) ? 'CREDIT' : 'PREPAID',
      cardNumber,
      accountId: cardNumber,
      cardOwnerName,
      accountabilityStartDate: new Date(accountabilityStartDate),
    } as const;

    await createCorporateCardUser.mutateAsync(createCorporateCardUserInput);
    await context.company.externalCards.getAll.invalidate();
  }

  const externalCardForm = useForm({}, () => ({
    mutateAsync: handleSubmit,
    data: createCorporateCardUser.data,
  }));

  return {
    externalCardForm,
    createExternalCardUser: externalCardForm.onSubmit,
    isLoading: createCorporateCardUser.isLoading,
  };
};
