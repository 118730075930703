import errors from '@frontend/utils/commonTexts/errors';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

function toastFormSchemaError() {
  dispatchToast({
    type: 'error',
    content: 'Falha ao validar formulário de edição da política de uso',
    description: errors.actions.toastDescription,
  });
}

function toastSuccess() {
  dispatchToast({
    type: 'success',
    content: 'Política atualizado com sucesso',
  });
}

function toastError() {
  dispatchToast({
    type: 'error',
    content: 'Não foi possível salvar as alterações',
    description: errors.actions.toastDescription,
  });
}

export const toasts = {
  toastFormSchemaError,
  toastSuccess,
  toastError,
};
