import TagFilterBase, { ExposedProps } from './components/TagFilterBase';
import { TagFilterRef } from './components/shared';
import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import styled, { useTheme } from 'styled-components';
import {
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  TextField,
} from '@mui/material';
import { Checkbox } from '@flash-tecnologia/hros-web-ui-v2';
import React from 'react';

type Props<TOption extends React.Key> = ExposedProps & {
  initialValue?: Array<TOption>;
  /** List of selectable options */
  options: Readonly<
    Array<{
      /** Readable label that will be presented to the user */
      label: string;
      /** Unique React.Key used to identify the selectable option */
      value: TOption;
      /** Custom label rendering */
      renderedLabel?: React.ReactNode;
    }>
  >;
  /** Called when the filter is applied */
  onApply?: (_selectedValues: Set<TOption>) => void;
};

function TagFilterCheckbox<TOption extends React.Key>(
  props: Props<TOption>,
  ref: React.Ref<TagFilterRef<Set<TOption>>>,
) {
  const theme = useTheme();
  const [searchField, setSearchField] = React.useState<string | null>(null);
  const [temporarilySelectedOptions, setTemporarilySelectedOptions] =
    React.useState<Set<TOption>>(new Set(props.initialValue));
  const [selectedOptions, setSelectedOptions] = React.useState<Set<TOption>>(
    new Set(props.initialValue),
  );

  /** Toggles the temporary-selection state of a value */
  function handleToggleSelect(value: TOption) {
    setTemporarilySelectedOptions((prev) => {
      if (prev.has(value)) prev.delete(value);
      else prev.add(value);
      return new Set(prev); // Creates a new set to change the state's ref
    });
  }

  /** Toggles the temporary-selection state of all values */
  function handleToggleAll() {
    setTemporarilySelectedOptions((prev) => {
      let newSelectedOptions: Set<TOption>;
      if (prev.size === props.options.length) newSelectedOptions = new Set();
      else
        newSelectedOptions = new Set(
          props.options.map((option) => option.value),
        );
      return newSelectedOptions;
    });
  }

  /** Labels filtered by the search-input value */
  const filteredOptions = searchField
    ? props.options.filter((option) =>
        option.label.toLowerCase().includes(searchField),
      )
    : props.options;

  /* ------------------------------------- Exposed handlers ------------------------------------- */
  /** Applies the temporary filter. Returns the new filter */
  function handleOnApply() {
    setSelectedOptions(temporarilySelectedOptions);
    props.onApply?.(temporarilySelectedOptions);
    return temporarilySelectedOptions;
  }

  /** Clears the filter and temporary filter */
  function handleOnClear() {
    setSelectedOptions(new Set());
    setTemporarilySelectedOptions(new Set());
    props.onApply?.(new Set());
    return new Set<TOption>();
  }

  /** Discards the temporary selection when closing */
  function handleOnClose() {
    setTemporarilySelectedOptions(new Set(selectedOptions));
  }

  // Exposes the triggers for usage with Drawer
  React.useImperativeHandle(
    ref,
    () => ({
      triggerApply: handleOnApply,
      triggerClear: handleOnClear,
      triggerClose: handleOnClose,
    }),
    [handleOnApply, handleOnClear, handleOnClose],
  );

  return (
    <TagFilterBase
      asDrawerItem={props.asDrawerItem}
      disabled={props.disabled}
      label={props.label}
      leftIcon={props.leftIcon}
      onApply={handleOnApply}
      onClear={handleOnClear}
      onClose={handleOnClose}
      status={selectedOptions.size ? 'active' : 'neutral'}
    >
      <StyledContainer>
        <StyledSection>
          <TextField
            onChange={(e) => setSearchField(e.target.value.toLowerCase())}
            style={{
              width: '100%',
            }}
            InputProps={{
              startAdornment: (
                <Icons
                  name="IconSearch"
                  fill={'transparent'}
                  color={theme.colors.neutral[50]}
                />
              ),
              style: {
                borderRadius: '30px',
                color: theme.colors.neutral[40],
                height: '60px',
              },
              placeholder: 'Buscar',
            }}
            inputProps={{
              style: {
                marginLeft: '16px',
              },
            }}
          />
        </StyledSection>
        <Divider style={{ paddingTop: '12px' }} />
        <MenuList>
          {/* 'Select all' button */}
          <StyledSection>
            <StyledMenuItem
              selected={
                temporarilySelectedOptions.size === props.options.length
              }
              key="select-all"
              onClick={handleToggleAll}
            >
              <ListItemIcon>
                <Checkbox
                  checked={
                    temporarilySelectedOptions.size === props.options.length
                  }
                  color="primary"
                />
              </ListItemIcon>
              <ListItemText>Selecionar todos</ListItemText>
            </StyledMenuItem>
          </StyledSection>
          <Divider style={{ marginBottom: '10px' }} />
          <StyledSection>
            {filteredOptions.map((item) => {
              const selected = temporarilySelectedOptions.has(item.value);
              return (
                <StyledMenuItem
                  selected={selected}
                  key={item.value}
                  onClick={() => handleToggleSelect(item.value)}
                >
                  <ListItemIcon>
                    <Checkbox checked={selected} />
                  </ListItemIcon>
                  <ListItemText>
                    {item.renderedLabel ?? item.label}
                  </ListItemText>
                </StyledMenuItem>
              );
            })}
          </StyledSection>
        </MenuList>
      </StyledContainer>
    </TagFilterBase>
  );
}

const StyledMenuItem = styled(MenuItem)<{ selected: boolean }>`
  border-radius: 8px;
  margin-bottom: 4px;
  background-color: ${(p) =>
    p.selected ? p.theme.colors.secondary[95] : 'transparent'};
`;

const StyledContainer = styled.div`
  padding-top: 12px;
`;

const StyledSection = styled.section`
  padding: 0px 12px;
`;

// Cast is necessary due to forwardRef not accepting generic props
export default React.forwardRef(TagFilterCheckbox) as <
  TOption extends React.Key,
>(
  _props: Props<TOption> & {
    ref?: React.ForwardedRef<TagFilterRef<Set<TOption>>>;
  },
) => ReturnType<typeof TagFilterCheckbox>;
