import { FocusedFlow } from '@frontend/components/FocusedFlow';
import useController, { Steps } from './data/useController';
import BasicInfo from './steps/01-BasicInfo';
import Rules from './steps/02-Rules';
import Confirmation from './steps/03-Confirmation';
import ModalService from '@frontend/services/ModalService';

function CreatePolicy() {
  const modalController = ModalService.useModalController();
  const controller = useController({ close: modalController.remove });

  const Content = () => {
    switch (controller.step) {
      case Steps.BASIC_INFO:
        return <BasicInfo form={controller.form} />;
      case Steps.RULES:
        return <Rules form={controller.form} />;
      case Steps.CONFIRMATION:
        return <Confirmation form={controller.form} />;
    }
  };

  return (
    <FocusedFlow
      headerMetadata={{
        breadcrumbs: [
          { text: 'Gerenciar empresa' },
          { text: 'Políticas' },
          { text: 'Adicionar política' },
        ],
        stepper: {
          disableClick: true,
          activeStep: controller.step - 1,
          steps: ['Informações básicas', 'Regras de aplicação', 'Resumo'],
        },
      }}
      content={Content()}
      footer={{
        leftActions: controller.cancelButton,
        rightActions: controller.rightButtons,
      }}
    />
  );
}

export default ModalService.create(CreatePolicy);
