import { Menu, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import TableClient from '@frontend/components/TableClient';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import ModalService from '@frontend/services/ModalService';
import { formatDate, toCurrency } from '@frontend/utils/masks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UseGetStatementOutput } from '../data/useGetStatement';
import DeleteTransactionModal from '../modals/DeleteTransactionModal';
import mappings from './mappings';

type Row = UseGetStatementOutput['items'][number];
type Columns = React.ComponentProps<typeof TableClient<Row>>['columns'];

export default [
  {
    accessorKey: 'date',
    header: 'Data',
    filterFn: 'customInDateRange',
    enableGlobalFilter: false,
    cell: (context) => (
      <Typography.Body4 noWrap color="neutral_30">
        {formatDate(context.row.original.date)}
      </Typography.Body4>
    ),
  },
  {
    accessorKey: 'userCardId',
    filterFn: 'customInSet',
    enableGlobalFilter: false,
    isHidden: true,
  },
  {
    accessorKey: 'source',
    header: 'Tipo de cartão',
    filterFn: 'customInSet',
    enableGlobalFilter: false,
    cell: (context) => {
      const { label, variant } = mappings.source[context.row.original.source];
      return (
        <Tag size="sm" variant={variant}>
          {label}
        </Tag>
      );
    },
  },
  {
    accessorKey: 'merchantName',
    header: 'Descrição',
    cell: (context) => (
      <Typography.Body4 noWrap color="neutral_30">
        {context.row.original.merchantName}
      </Typography.Body4>
    ),
  },
  {
    accessorKey: 'amount',
    header: 'Valor',
    filterFn: 'inNumberRange',
    enableGlobalFilter: false,
    cell: (context) => (
      <Typography.Body4 noWrap color="neutral_30">
        {toCurrency(context.row.original.amount)}
      </Typography.Body4>
    ),
  },
  // > Add back when mapped to readable label
  // {
  //   accessorKey: 'type',
  //   header: 'Transação',
  //   filterFn: 'customInSet',
  //   enableGlobalFilter: false,
  //   cell: (context) => (
  //     <Typography.Body4 noWrap color="neutral_30">
  //       {context.row.original.type}
  //     </Typography.Body4>
  //   ),
  // },
  {
    accessorKey: 'accountabilityStatus',
    header: 'Status do Relatório',
    filterFn: 'customInSet',
    enableGlobalFilter: false,
    cell: (context) => {
      const { label, variant } =
        mappings.accountabilityStatus[
          context.row.original.accountabilityStatus
        ];
      return (
        <Tag size="sm" variant={variant} dot>
          {label}
        </Tag>
      );
    },
  },
  {
    id: 'actions',
    header: 'Ações',
    enableGlobalFilter: false,
    isSticky: true,
    cell: (context) => {
      const navigate = useNavigate();
      const row = context.row.original;

      const options: React.ComponentProps<typeof Menu>['options'] = [
        // {
        //   label: 'Ver mais detalhes',
        //   onClick: () => alert('Não implementado'),
        // },
      ];
      if (row.importedBy === 'USER')
        options.push({
          label: 'Excluir transação',
          onClick: () =>
            ModalService.show(DeleteTransactionModal, {
              transactionId: row._id,
            }),
        });
      if (row.expenseId)
        options.push({
          label: 'Prestar Conta',
          onClick: () => {
            navigate(`/expenseManagement/expenses/details/${row.expenseId}`);
          },
        });

      if (!options.length) return null;
      return (
        <Menu type="default" locked options={options}>
          <PillButton size="medium" variant="default" icon="IconDotsVertical" />
        </Menu>
      );
    },
  },
] satisfies Columns;
