import errors from '@frontend/utils/commonTexts/errors';
import { DateTime } from 'luxon-business-days';
import React from 'react';
import { UseFormReturn } from '../../../forms/useForm';

type Input = {
  form: UseFormReturn;
  flashCashBalance: {
    balance: number | undefined;
    isLoading: boolean;
  };
};

export default function useFormData(input: Input) {
  const formData = input.form.watch();
  const { flashCashBalance } = input;

  /** Readable amount of deposits on the list */
  const depositsCount =
    (formData.deposits?.length ?? 0) === 1
      ? '1 pessoa'
      : `${formData.deposits?.length} pessoas`;

  /** Total amount in cents */
  const totalAmount = formData.value * formData.deposits?.length ?? 0;

  /** Initial allowed date for the input */
  const creditDateFrom =
    formData.paymentMethod === 'BILLET'
      ? DateTime.now().plusBusiness({ days: 3 }).startOf('day').toJSDate()
      : DateTime.now().startOf('day').toJSDate();

  /** Initial allowed date for the input */
  const expirationDateFrom = DateTime.fromJSDate(
    formData.creditDate ?? creditDateFrom,
  )
    .plus({ days: 1 })
    .toJSDate();

  React.useEffect(() => {
    if (
      formData.paymentMethod === 'BILLET' ||
      formData.paymentMethod === 'PIX'
    ) {
      // Only flash-cash can be used for automatic deposits
      if (formData.depositType === 'AUTOMATIC') {
        input.form.resetField('depositType');
      }
    }

    if (
      formData.paymentMethod === 'FLASH_CASH' &&
      formData.depositType === 'SINGLE' &&
      !flashCashBalance.isLoading &&
      formData.value >= (flashCashBalance.balance || 0)
    ) {
      input.form.resetField('value');
    }
  }, [formData.paymentMethod, flashCashBalance.isLoading]);

  React.useEffect(() => {
    if (
      formData.paymentMethod === 'FLASH_CASH' &&
      formData.depositType === 'SINGLE' &&
      !flashCashBalance.isLoading
    ) {
      if (formData.value > (flashCashBalance.balance || 0)) {
        input.form.setError('paymentMethod', {
          message:
            'Não há saldo suficiente em Flash Cash para realizar os depósitos por esse método.',
        });
      } else {
        input.form.clearErrors('paymentMethod');
      }
    }
  }, [formData.value, flashCashBalance.isLoading]);

  React.useEffect(() => {
    // Updating the deposit type will reset the `complementary` and `expires` fields
    input.form.resetField('complementary');
    input.form.resetField('expirationDate');
    input.form.resetField('expires');
    if (
      formData.paymentMethod === 'FLASH_CASH' &&
      formData.depositType === 'SINGLE' &&
      !flashCashBalance.isLoading &&
      formData.value > (flashCashBalance.balance || 0)
    ) {
      input.form.setError('paymentMethod', {
        message:
          'Não há saldo suficiente em Flash Cash para realizar os depósitos por esse método.',
      });
    }
  }, [formData.depositType, flashCashBalance.isLoading]);

  /* Due to the react-hook-form behavior, rules that depend on other fields can
   * only be defined on the schema by using `refine`, and are only processed
   * when all field-specific rules are satisfied. For that reason, it's
   * necessary to define them here manually, to present the error messages ASAP.
   */
  React.useEffect(() => {
    if (
      formData.paymentMethod === 'BILLET' &&
      formData.creditDate < creditDateFrom
    ) {
      input.form.setError('creditDate', {
        message:
          'Para depósitos por Boleto, a data de crédito deve ser pelo menos 3 dias úteis a partir da data atual',
      });
    } else if (formData.creditDate && formData.paymentMethod) {
      input.form.trigger('creditDate');
    }
  }, [formData.creditDate, formData.paymentMethod]);

  React.useEffect(() => {
    if (!formData.expires) {
      // `expires` was toggled off when `expirationDate` was invalid, the error must be cleared
      if (!!input.form.getFieldState('expirationDate').error) {
        input.form.resetField('expirationDate');
      }
      return;
    }
    // If `expires` is toggled on, `expirationDate` is required
    if (!formData.expirationDate) {
      input.form.setError('expirationDate', {
        message: errors.forms.generic.required,
      });
      return;
    }
    // If `creditDate` is empty, `expirationDate` may stop being invalid
    if (!formData.creditDate) {
      input.form.trigger('expirationDate');
      return;
    }

    // `expirationDate` must be after `creditDate`
    if (formData.expirationDate <= formData.creditDate) {
      input.form.setError('expirationDate', {
        message: 'A data de expiração deve ser posterior à data de crédito',
      });
    } else {
      input.form.trigger('expirationDate');
    }
  }, [formData.expires, formData.expirationDate, formData.creditDate]);

  return {
    ...formData,
    metadata: {
      creditDateFrom,
      depositsCount,
      expirationDateFrom,
      totalAmount,
    },
  };
}
