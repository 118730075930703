import { MonitoringErrorBoundary } from '@flash-tecnologia/hros-web-utility';
import EnvService from '@frontend/services/EnvService';
import React from 'react';
import { AppProps } from 'single-spa';

export class ErrorBoundary extends MonitoringErrorBoundary {
  project = EnvService.SENTRY_PROJECT_URL;

  constructor(props: React.PropsWithChildren<AppProps>) {
    super(props);
  }
}
