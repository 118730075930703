import styled from 'styled-components';

type CardContainerProps = {
  backgroundColor: string;
  borderColor: string;
};

export const CardContainer = styled.div<CardContainerProps>`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(p) => p.borderColor};
  border-radius: 12px 32px 12px 12px;
  background-color: ${(p) => p.backgroundColor};
  width: 311px;
  padding: 32px;
  height: 100%;
`;

type CardIconContainerProps = {
  backgroundColor: string;
};

export const CardIconContainer = styled.div<CardIconContainerProps>`
  width: 48px;
  height: 48px;
  border-radius: 40px;
  background: ${(p) => p.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;
