import TableClient from '@frontend/components/TableClient';
import { UseGetCardsReturn } from '../../../data/useGetCards';
import Typography from '@frontend/components/Typography';
import Tag from '@frontend/components/TagV2';
import { Button } from '@flash-tecnologia/hros-web-ui-v2';
import UnlinkPolicyModal from '../modals/UnlinkPolicyModal';
import RouterService from '@frontend/services/RouterService';
import { ROUTE_PolicyDetails } from '../../..';
import ModalService from '@frontend/services/ModalService';

type Row = NonNullable<UseGetCardsReturn['data']>[number];
type Columns = React.ComponentProps<typeof TableClient<Row>>['columns'];

export default [
  {
    accessorKey: 'lastFourDigits',
    header: 'Nº do cartão',
    cell: (context) => (
      <Typography.Body3 weight={600} color="neutral_40">
        —— {context.row.original.lastFourDigits}
      </Typography.Body3>
    ),
  },
  {
    accessorKey: 'cardName',
    header: 'Apelido',
    cell: (context) => (
      <Typography.Body3 weight={600} color="neutral_40">
        {context.row.original.cardName}
      </Typography.Body3>
    ),
  },
  {
    accessorKey: 'ownerName',
    header: 'Titular do cartão',
    cell: (context) => (
      <Typography.Body3 weight={600} color="neutral_40">
        {context.row.original.ownerName}
      </Typography.Body3>
    ),
  },
  {
    accessorKey: 'type',
    header: 'Tipo de cartão',
    cell: (context) => {
      switch (context.row.original.type) {
        case 'PLASTIC':
          return (
            <Tag size="sm" variant="neutral">
              Físico
            </Tag>
          );
        case 'VIRTUAL':
          return (
            <Tag size="sm" variant="neutral">
              Virtual
            </Tag>
          );
      }
    },
  },
  {
    id: 'actions',
    header: 'Ações',
    cell: (context) => {
      const policyId = RouterService.useParams(ROUTE_PolicyDetails).policyId;
      return (
        <Button
          size="medium"
          variant="secondary"
          onClick={() =>
            ModalService.show(UnlinkPolicyModal, {
              cardId: context.row.original.id,
              policyId,
            })
          }
        >
          Desvincular cartão
        </Button>
      );
    },
  },
] satisfies Columns;
