export const TEMPLATE_COLUMNS = {
  CARD_NUMBER: 'Número do cartão',
  CARD_HOLDER: 'Nome do titular',
  CARD_FLAG: 'Bandeira',
  BILLING_DATE: 'Data da fatura',
  TRANSACTION_DATE: 'Data da transação',
  TRANSACTION_CURRENCY: 'Moeda da transação',
  TRANSACTION_VALUE: 'Valor da transação',
  CONVERTED_TRANSACTION_CURRENCY: 'Moeda valor convertido',
  CONVERTED_TRANSACTION_VALUE: 'Valor convertido',
  TRANSACTION_TYPE: 'Tipo de transação',
  MCC: 'Código MCC',
  ESTABLISHMENT_NAME: 'Nome do estabelecimento',
} as const;

export enum TEMPLATE_FIELDS {
  CARD_NUMBER,
  CARD_HOLDER,
  CARD_FLAG,
  BILLING_DATE,
  TRANSACTION_DATE,
  TRANSACTION_CURRENCY,
  TRANSACTION_VALUE,
  CONVERTED_TRANSACTION_CURRENCY,
  CONVERTED_TRANSACTION_VALUE,
  TRANSACTION_TYPE,
  MCC,
  ESTABLISHMENT_NAME,
}
