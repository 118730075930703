import { Route } from 'react-router-dom';
import FeatureFlags from './FeatureFlags';
import BasePath from '../../../routes/BasePath';
import RouterService from '@frontend/services/RouterService';

export const ROUTE_FeatureFlags = RouterService.create(
  `${BasePath.relativePath}/playground/feature-flags`,
);

export const RouteComponent_FeatureFlags = (
  <Route path={ROUTE_FeatureFlags.path} element={<FeatureFlags />}></Route>
);
