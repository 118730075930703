import InfoModal from '../../../../components/InfoModal';
import Spacer from '../../../../components/Spacer';
import Tag from '../../../../components/TagV2';
import Typography from '../../../../components/Typography';
import ModalService from '../../../../services/ModalService';

function NextCreditStatusModal() {
  const modalController = ModalService.useModalController();

  return (
    <InfoModal
      isOpen={modalController.visible}
      onClose={modalController.remove}
      header={{
        noWrap: true,
        title: 'Status da previsão do próximo depósito',
        subtitle: 'Saiba mais sobre quais são os status das recargas',
      }}
      children={
        <>
          <Tag size="md" variant="error">
            A confirmar
          </Tag>
          <Spacer y="xs3" />
          <Typography.Body3>
            O seu saldo já foi definido por sua empresa, mas o pagamento ou
            compensação bancária ainda está pendente. Mostramos uma prévia do
            que está definido no momento, mas o valor e a data podem se alterar.
          </Typography.Body3>
          <Spacer y="m" />
          <Tag size="md" variant="success">
            Confirmado
          </Tag>
          <Spacer y="xs3" />
          <Typography.Body3>
            O seu benefício já foi confirmado por sua empresa. Mostramos o valor
            e data definitivos.
          </Typography.Body3>
        </>
      }
    />
  );
}

export default ModalService.create(NextCreditStatusModal);
