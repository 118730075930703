import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import Root from './root.component';
import { ErrorBoundary } from './utils/ErrorBoundary';
import { dispatchToast } from './utils/dispatchEvents';
import errors from './utils/commonTexts/errors';
import * as external from '../src/external';

const lifeCycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary() {
    dispatchToast({ type: 'error', content: errors.genericError });
    return <></>;
  },
  errorBoundaryClass: ErrorBoundary,
});

export const { bootstrap, mount, unmount } = lifeCycles;

export { external };
