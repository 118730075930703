import { useCheckIsCorporateCard } from '@frontend/pages/Statement/data/useCheckIsCorporateCard';
import { Navigate, Outlet } from 'react-router-dom';

export default function CheckIsCorporateCard() {
  const { isCorporateCardUser, isLoading, hasError } =
    useCheckIsCorporateCard();

  if ((!isCorporateCardUser && !isLoading) || hasError) {
    return <Navigate to={'/home'} />;
  }
  return <Outlet />;
}
