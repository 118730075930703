import { TextArea as UITextArea } from '@flash-tecnologia/hros-web-ui-v2';
import Icon from '@frontend/components/Icon';
import Typography from '@frontend/components/Typography';
import styled from 'styled-components';

type Props = {
  // Required
  /** Input's label */
  label: string;
  /** Input's value */
  value: string;
  /** Triggered when the input value changes */
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  /** Maximum number of characters allowed */
  maxLength: number;

  // Optional
  /** Readable error message displayed under the input container */
  error?: string;
  /** Whether the input occupies the full available width */
  fullWidth?: boolean;
};

export default function TextArea(props: Props) {
  return (
    <>
      <UITextArea
        placeholder={props.label}
        value={props.value}
        onChange={props.onChange}
        width={props.fullWidth ? '100%' : undefined}
        maxLength={props.maxLength}
      />
      {props.error && (
        <ErrorMessageArea>
          <Icon color={'error_50'} name="IconInfoCircle" size={18} />
          <Typography.Caption color="neutral_50" weight={600}>
            {props.error}
          </Typography.Caption>
        </ErrorMessageArea>
      )}
    </>
  );
}

export const ErrorMessageArea = styled.div`
  margin: ${({ theme }) => theme.spacings.xs5} 0 0 16px;

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs5};
  fill: transparent;
`;
