export const sortedMccGroups = [
  'GROCERY',
  'MEAL',
  'MOBILITY',
  // 'ACCOMMODATION',
  'EDUCATION',
  'CULTURE',
  'HEALTH',
  'CONVENIENCE',
] as const;

export const translatedMccGroups = {
  GROCERY: {
    title: 'Alimentação',
    description: 'Aceito em atacadistas, supermercados, açougues e padarias.',
  },
  MEAL: {
    title: 'Refeição',
    description:
      'Aceito em restaurantes, lanchonetes, cafés e aplicativos de delivery.',
  },
  MOBILITY: {
    title: 'Mobilidade',
    description:
      'Aceito em postos de combustível, estacionamentos, aluguel de carros e aplicativos de corrida.',
  },
  // ACCOMMODATION:{
  //   title: 'Hospedagem',
  //   description:
  //     'Aceito em hotéis, pousadas, aplicativos de locação por temporada e outras categorias do segmento.',
  // },
  EDUCATION: {
    title: 'Educação',
    description: 'Aceito em faculdades, cursos técnicos e cursos de idiomas.',
  },
  CULTURE: {
    title: 'Cultura',
    description:
      'Aceito em cinemas, livrarias, teatros, shows e aplicativos de streaming.',
  },
  HEALTH: {
    title: 'Saúde',
    description:
      'Aceito em laboratórios, consultas e outras categorias do segmento.',
  },
  CONVENIENCE: {
    title: 'Conveniência',
    description:
      'Aceito em lojas de serviços e bens de consumo físicas ou virtuais',
  },
  WITHDRAW: {
    title: 'Saque',
    description: '',
  },
} as const satisfies Record<
  (typeof sortedMccGroups)[number] | 'WITHDRAW',
  { title: string; description: string }
>;
