import ModalService from '@frontend/services/ModalService';
import useForm from '../form/useForm';

export function useCreateVirtualCard() {
  const modalController = ModalService.useModalController();
  const virtualCardForm = useForm({
    onSuccess: modalController.remove,
  });

  return {
    isLoading: virtualCardForm.isLoading,
    virtualCardForm,
    virtualCardFormSubmit: virtualCardForm.onSubmit,
  };
}
