import StepModal from '@frontend/components/modals/StepModal';
import ModalService from '@frontend/services/ModalService';
import OnboardingFlashCashEndModal from './OnboardingFlashCashEndModal';

function OnboardingFlashCashTutorialModal() {
  const modalController = ModalService.useModalController();

  function handleDismiss() {
    modalController.remove();
  }

  function handleShowEnd() {
    modalController.remove();
    ModalService.show(OnboardingFlashCashEndModal, {});
  }

  return (
    <StepModal
      isOpen={modalController.visible}
      onClose={handleDismiss}
      onEnd={handleShowEnd}
      steps={[
        {
          title: 'Preencha as informações',
          subtitle:
            'Gere um boleto para adicionar saldo no Flash Cash Corporativo.',
          content: (
            <img
              src={
                'https://images.flashapp.com.br/expense/corporate-card/onboarding-flash-cash-corp-1.gif'
              }
            ></img>
          ),
        },
        {
          title: 'Agora é só pagar o boleto!',
          subtitle:
            'Pague o boleto e o saldo cairá na sua conta para você fazer transações instantaneamente entre o seus colaboradores utilizando o Flash Cash Corporativo.',
          content: (
            <img
              src={
                'https://images.flashapp.com.br/expense/corporate-card/onboarding-flash-cash-corp-2.gif'
              }
            ></img>
          ),
        },
      ]}
    />
  );
}

export default ModalService.create(OnboardingFlashCashTutorialModal);
