import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import ModalService from '@frontend/services/ModalService';

type Props = {
  edit: () => Promise<void>;
};

function EditConfirmationModal(props: Props) {
  const modalController = ModalService.useModalController();
  function handleEdit() {
    void props.edit().then(() => {
      modalController.remove();
    });
  }

  return (
    <ConfirmationModal
      isOpen={modalController.visible}
      onClose={modalController.remove}
      variant="default"
      text={{
        title: 'Confirma as alterações na política?',
        body: 'Todas as alterações serão imediatamente aplicadas aos cartões vinculados a esta política',
      }}
      buttons={{
        cancel: {
          text: 'Cancelar',
          onClick: modalController.remove,
        },
        confirm: {
          text: 'Confirmar alterações',
          onClick: handleEdit,
        },
      }}
    ></ConfirmationModal>
  );
}

export default ModalService.create(EditConfirmationModal);
