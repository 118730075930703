import {
  PageContainer,
  PageHeader,
  Tab,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { CorporateCard } from './CorporateCard';

export default function Wallet() {
  return (
    <>
      <PageHeader>
        <Typography variant="headline6">Minha Carteira</Typography>
      </PageHeader>
      <PageContainer>
        <Tab
          tabItens={[
            {
              label: 'Corporativo',
              component: <CorporateCard />,
            },
          ]}
        />
      </PageContainer>
    </>
  );
}
