import { fromCurrency, toCurrency } from '@frontend/utils/masks';
import TextField from '../TextField';
import { Container } from './styled';

type Props = {
  // Required
  /** Input's label */
  label: string;
  /** Input's value */
  value: number | undefined;
  /** Triggered when the input value changes */
  onChange: (newValue: number) => void;

  // Optional
  /** Whether the input is disabled */
  disabled?: boolean;
  /** Readable error message displayed under the input container */
  error?: string;
  /** Whether the input occupies the full available width */
  fullWidth?: boolean;
};

export default function BalanceField(props: Props) {
  return (
    <Container>
      <TextField
        {...props}
        value={props.value ? toCurrency(props.value) : ''}
        onChange={({ target }) => {
          const amountInCents = fromCurrency(target.value);
          props.onChange(amountInCents);
        }}
      />
    </Container>
  );
}
