import { Divider } from '@flash-tecnologia/hros-web-ui-v2';

import BorderedSection from '@frontend/components/frames/BorderedSection';
import Flex from '@frontend/components/frames/Flex';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import {
  formatDate,
  formatDatePeriod,
  formatDateTime,
} from '@frontend/utils/masks';
import { ReportColumn } from './ReportColumn';
import * as SC from './styled';

type ReportStatusProps = {
  reportType: string;
  startDate: Date;
  endDate?: Date;
  fileType: string;
};

export function ReportStatus({
  reportType,
  startDate,
  endDate,
  fileType,
}: ReportStatusProps) {
  return (
    <BorderedSection>
      <Flex justify="space-between" align="center" padding={['xs3', 'xs2']}>
        <Tag size="xs" variant="info" dot>
          Em processamento
        </Tag>

        <Typography.Caption color="neutral_40">
          Relatório solicitado em {formatDateTime(new Date())}
        </Typography.Caption>
      </Flex>

      <Divider orientation="horizontal" />

      <SC.ContentContainer>
        <ReportColumn title="Tipo de relatório">{reportType}</ReportColumn>

        {endDate ? (
          <ReportColumn title="Período">
            {formatDatePeriod(startDate, endDate)}
          </ReportColumn>
        ) : (
          <ReportColumn title="Data">{formatDate(startDate)}</ReportColumn>
        )}
        <ReportColumn title="Formato">{fileType}</ReportColumn>
      </SC.ContentContainer>
    </BorderedSection>
  );
}
