import { trpc } from '@frontend/trpc';

export default function useSetSeenFlashCashOnboarding() {
  const setSeenFlashCashOnboarding = trpc.notices.setSeen.useMutation();
  const trpcContext = trpc.useContext();

  return function () {
    setSeenFlashCashOnboarding.mutate({ template: 'onboarding_flash-cash' });
    trpcContext.notices.get.invalidate({ template: 'onboarding_flash-cash' });
  };
}
