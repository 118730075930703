import { RouterInputs, RouterOutputs, trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import { formatDate, toCurrency } from '@frontend/utils/masks';

type QueryInput = RouterInputs['company']['billing']['search'];
type QueryOutput = RouterOutputs['company']['billing']['search'];

type Input = {
  filter: Omit<QueryInput['filter'], 'companyId'>;
  pagination: QueryInput['pagination'];
};

export type OutputData = ReturnType<typeof formatData>;

export default function useSearchBillets(input: Input) {
  const { data, isLoading: loading } = trpc.company.billing.search.useQuery(
    {
      ...input,
    },
    { onError: errorToast },
  );

  if (!data) return { loading };

  return {
    data: formatData(data),
    loading,
  };
}

function formatData(data: QueryOutput) {
  return {
    ...data,
    data: data.data.map((item) => ({
      ...item,
      billetLink: item.billetLink,
      createdAt: formatDate(new Date(item.createdAt)),
      debitLink: item.debitLink,
      destination:
        item.destination === 'employee' ? 'Colaborador' : 'Flash Cash',
      dueDate: item.dueDate ? formatDate(new Date(item.dueDate)) : '-',
      totalAmount: toCurrency(item.totalAmount),
    })),
  };
}

function errorToast(error?: { message: string }) {
  const formattedError = error?.message;
  if (formattedError) {
    dispatchToast({
      type: 'error',
      content: formattedError,
    });
  } else {
    dispatchToast({
      type: 'error',
      content: 'Não foi possível carregar a lista de boletos.',
      description:
        'Tente novamente. Se o problema persistir, entre em contato com o suporte',
    });
  }
}
