import { Dispatch, SetStateAction } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ZodSchema, ZodTypeDef } from 'zod';
import encodeQueryParams, { ComplexTypes } from '../encodeQueryParams';

export function useParamsState<
  TOutput extends Record<string, ComplexTypes>,
  TInput = unknown,
>(
  initialParams: TOutput,
  schema: ZodSchema<TOutput, ZodTypeDef, TInput>,
): [TOutput, Dispatch<SetStateAction<TOutput>>] {
  const [params, setParams] = useSearchParams(encodeQueryParams(initialParams));

  const state = Array.from(params.entries()).reduce<TOutput>(
    (object, [key, value]) => ({ ...object, [key]: decodeURI(value) }),
    {} as TOutput,
  );

  function setState(input: SetStateAction<TOutput>) {
    const result = typeof input === 'function' ? input(state) : input;
    setParams(encodeURI(encodeQueryParams(result)));
  }

  return [schema.parse(state), setState];
}
