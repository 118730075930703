import { Slider } from '@mui/material';
import TagFilterBase, { type ExposedProps } from './components/TagFilterBase';
import React from 'react';
import BarsSVG from '../../assets/tagFilterRange/tag_filter_range_bars.svg';
import BarsSVGDrawer from '../../assets/tagFilterRange/tag_filter_range_bars_drawer.svg';
import styled from 'styled-components';
import { TextField } from '@flash-tecnologia/hros-web-ui-v2';
import { TagFilterRef } from './components/shared';

type Range = {
  /** Lower bound of the range */
  min: number;
  /** Upper bound of the range */
  max: number;
};

type Props = ExposedProps & {
  /** Initial value for tag filter */
  initialValue?: Range;
  /** Upper limit */
  max: number;
  /** Lower limit */
  min: number;
  /** Called when the filter is applied */
  onApply?: (_newValue: Range) => void;
  /** Mask for the input value */
  mask?: (_value: number) => string;
  /** Translates back the input to a number value */
  fromMask?: (_value: string) => number;
};

function TagFilterRange(props: Props, ref: React.Ref<TagFilterRef<Range>>) {
  const [appliedFilter, setAppliedFilter] = React.useState({
    min: props.initialValue?.min ?? props.min,
    max: props.initialValue?.max ?? props.max,
  });
  const [temporaryFilter, setTemporaryFilter] = React.useState({
    min: props.initialValue?.min ?? props.min,
    max: props.initialValue?.max ?? props.max,
  });
  const [isCustomized, setIsCustomized] = React.useState(!!props.initialValue);

  React.useEffect(() => {
    // Mirrors the updated min/max values if the range has not been set by the user
    if (!isCustomized) {
      setTemporaryFilter({ min: props.min, max: props.max });
      setAppliedFilter({ min: props.min, max: props.max });
    }
  }, [props.min, props.max]);

  /** Triggered by every step of the range input */
  function handleInternalOnChange(_event: Event, newValue: number[]) {
    setTemporaryFilter({
      min: newValue[0] ?? props.min,
      max: newValue[1] ?? props.max,
    });
  }

  /** Triggered by changing the text input */
  function handleOnChangeTextInput(input: 'min' | 'max', newValue: string) {
    setTemporaryFilter((prev) => ({
      ...prev,
      [input]: fromMask(newValue),
    }));
  }

  /** Transforms the filter's number value to a string that will be presented on the text-input */
  function maskValue(value: number) {
    return props.mask?.(value) ?? value.toString();
  }

  /** Transforms the input string into a number that will override the filter */
  function fromMask(value: string) {
    let number = props.fromMask?.(value) ?? parseInt(value);
    if (Number.isNaN(number)) number = props.min;

    // Clips the number to the range
    if (number < props.min) number = props.min;
    if (number > props.max) number = props.max;

    return number;
  }

  /* ------------------------------------- Exposed handlers ------------------------------------- */

  /** Applies the temporary filter, overriding the current one */
  function handleOnApply() {
    // Necessary to sort due to the text input
    const sortedValues = {
      min: Math.min(temporaryFilter.min, temporaryFilter.max),
      max: Math.max(temporaryFilter.min, temporaryFilter.max),
    } as const;
    setTemporaryFilter(sortedValues);
    setAppliedFilter(sortedValues);
    setIsCustomized(true);
    props.onApply?.(sortedValues);
    return sortedValues;
  }

  /** Clears both applied and temporary filters, selecting the whole range */
  function handleOnClear() {
    const cleanFilter = {
      min: props.min,
      max: props.max,
    } as const;
    setTemporaryFilter(cleanFilter);
    setAppliedFilter(cleanFilter);
    setIsCustomized(false);
    props.onApply?.(cleanFilter);
    return cleanFilter;
  }

  /** Resets the temporary filter to the applied range */
  function handleOnClose() {
    setTemporaryFilter(appliedFilter);
  }

  // Exposes the triggers for usage with Drawer
  React.useImperativeHandle(
    ref,
    () => ({
      triggerApply: handleOnApply,
      triggerClear: handleOnClear,
      triggerClose: handleOnClose,
    }),
    [handleOnApply, handleOnClear, handleOnClose],
  );

  return (
    <TagFilterBase
      asDrawerItem={props.asDrawerItem}
      disabled={props.disabled}
      label={props.label}
      status={isCustomized ? 'active' : 'neutral'}
      leftIcon={props.leftIcon}
      onApply={handleOnApply}
      onClear={handleOnClear}
      onClose={handleOnClose}
    >
      <StyledContainer>
        {props.asDrawerItem ? (
          <BarsSVGDrawer style={{ translate: '0 13px' }} />
        ) : (
          <BarsSVG style={{ translate: '0 13px' }} />
        )}
        <Slider
          min={props.min}
          max={props.max}
          value={[temporaryFilter.min, temporaryFilter.max]}
          onChange={handleInternalOnChange}
        />
        <StyledInputArea>
          <TextField
            label="Mínimo"
            value={maskValue(temporaryFilter.min)}
            onChange={(event) => {
              handleOnChangeTextInput('min', event.target.value);
            }}
          />
          <TextField
            label="Máximo"
            value={maskValue(temporaryFilter.max)}
            onChange={(event) => {
              handleOnChangeTextInput('max', event.target.value);
            }}
          />
        </StyledInputArea>
      </StyledContainer>
    </TagFilterBase>
  );
}

const StyledContainer = styled.div`
  padding: 20px;
`;

const StyledInputArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export default React.forwardRef(TagFilterRange);
