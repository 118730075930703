import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(p) => p.theme.spacings.xs};
  align-items: center;
`;

export const SummaryArea = styled.div`
  display: flex;
  gap: ${(p) => p.theme.spacings.xs3};
`;

export const SummaryItem = styled.div`
  flex: 1;
`;

export const PixInstructionsArea = styled.div`
  display: grid;
  grid-template-columns: 1fr 50%;
  gap: 24px;
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const LoaderArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 0;
`;
