import { Button } from '@flash-tecnologia/hros-web-ui-v2';
import Icon from '@frontend/components/Icon';
import Spacer from '@frontend/components/Spacer';
import TableClient from '@frontend/components/TableClient';
import Typography from '@frontend/components/Typography';
import Flex from '@frontend/components/frames/Flex';
import ModalService from '@frontend/services/ModalService';
import useSearchPolicies from './data/useSearchPolicies';
import CreatePolicy from './focusedFlows/CreatePolicy';
import columns from './table/columns';
import useSearchFilter from './table/useSearchFilter';

export default function Policies() {
  const policies = useSearchPolicies();
  const searchFilter = useSearchFilter({ disable: false });

  return (
    <Flex direction="column" style={{ minWidth: 0, width: '100%' }}>
      <section>
        <Spacer y="s" />
        <Flex justify="space-between">
          <div>
            <Typography.Headline6>
              Políticas do cartão corporativo
            </Typography.Headline6>
            <Typography.Body3 color="neutral_50">
              Cadastre e configure as políticas da sua empresa.
            </Typography.Body3>
          </div>
          <Button
            size="large"
            onClick={() => ModalService.show(CreatePolicy, {})}
            variant="primary"
          >
            Adicionar política
            <Icon name="IconPlus" color="neutral_100" />
          </Button>
        </Flex>
      </section>
      <TableClient
        data={policies.data ?? []}
        columns={columns}
        loading={policies.isLoading}
        emptyState={{
          emptyText:
            'Nenhuma política de uso do cartão corporativo foi adicionada ainda.',
          filteredEmptyText: 'Nenhum resultado encontrado.',
        }}
        TableSearchFilter={searchFilter.SearchFilterComponent}
        searchFilter={searchFilter.appliedFilter}
      />
    </Flex>
  );
}
