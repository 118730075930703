import { Template } from '@frontend/components/FocusedFlow/Template';
import { UseFormReturn } from '../../forms/useForm';
import { Controller } from 'react-hook-form';
import BorderedSection from '@frontend/components/frames/BorderedSection';
import Spacer from '@frontend/components/Spacer';
import TextField from '@frontend/components/inputs/TextField';
import TextArea from '@frontend/components/inputs/TextArea';

type Props = {
  form: UseFormReturn;
};

export default function BasicInfo(props: Props) {
  return (
    <Template
      pageInfo={{
        title: 'Adicionar política de uso do cartão corporativo',
      }}
      contentInfo={{
        title: 'Informações básicas',
        description:
          'Dê um nome e descreva a finalidade da política que está sendo criada.',
      }}
      Modules={
        <BorderedSection padding="m">
          <Controller
            control={props.form.control}
            name="title"
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                label="Nome da política"
                value={field.value}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            )}
          />
          <Spacer y={'xs'} />
          <Controller
            control={props.form.control}
            name="description"
            render={({ field, fieldState }) => (
              <TextArea
                fullWidth
                maxLength={255}
                label="Descrição"
                value={field.value}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            )}
          />
        </BorderedSection>
      }
    />
  );
}
