import { PDFViewer as PDFViewerUI } from '@flash-tecnologia/hros-web-ui-v2';
type PDFViewerUIProps = React.ComponentProps<typeof PDFViewerUI>;

type Props = {
  options: PDFViewerUIProps['options'];
  url: string;
  width?: string;
  height?: string;
};

export default function PDFViewer(props: Props) {
  return (
    <PDFViewerUI
      options={props.options}
      src={props.url}
      width={props.width}
      height={props.height}
    />
  );
}
