import FormService from '@frontend/services/FormService';
import ModalService from '@frontend/services/ModalService';
import { trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import { z } from 'zod';
import Feedback from '../modules/Feedback';
import ErrorMonitorService from '@frontend/services/MonitorService';
import { DateTime } from 'luxon';
import errors from '@frontend/utils/commonTexts/errors';

const FormSchema = z.object({
  employee: z.object(
    {
      id: z.string(),
      name: z.string(),
    },
    {
      required_error: 'Selecione pelo menos uma pessoa da lista',
    },
  ),
  cards: z.array(
    z.object({
      nickname: z.string({
        required_error: 'Informar apelido do cartão',
      }),
      expiration: z.object(
        {
          expirationRule: z.enum(['DAYS', 'MONTHS', 'CUSTOM'], {
            required_error: 'Informar regra de expiração',
          }),
          expirationMetadata: z.string({
            required_error: 'Informar expiração',
          }),
        },
        {
          required_error: 'Informar regra de expiração',
        },
      ),
    }),
  ),
  /** Optional policy to be added to the card */
  policyId: z.string().optional(),
});

type Input = {
  /** Callback for the submit success. Closes the focusedFlow */
  onSuccess: () => void;
};

export default function useForm(input: Input) {
  /* ------------------------------ Form setup ------------------------------ */
  const form = FormService.useCreateForm(FormSchema, {
    mode: 'onChange',
    defaultValues: {
      cards: [{}],
    },
  });

  const createVirtualCard = trpc.company.virtualCard.create.useMutation();
  const linkPolicy = trpc.company.policies.cardLinks.patch.useMutation();

  const onSubmit = form.handleSubmit(
    (values) => {
      /** Formatted input data */
      const createVirtualCardInput = values.cards.map((card) => {
        return {
          name: card.nickname,
          employeeNanoId: values.employee.id,
          expirationDate: getExpirationDate(card.expiration).toISODate(),
        };
      });

      createVirtualCard.mutate(createVirtualCardInput, {
        onSuccess: (data) => {
          if (values.policyId) {
            // If a policy was defined, link it to the card
            const linkCardIds = data.map((card) => card.financeCardId);
            linkPolicy.mutate(
              {
                policyId: values.policyId,
                linkCardIds,
              },
              {
                onError: () => {
                  dispatchToast({
                    type: 'error',
                    content:
                      'Não foi possível vincular o cartão à Política de uso',
                    description: errors.actions.toastDescription,
                  });
                  ErrorMonitorService.error({
                    severity: 'fatal',
                    message:
                      "Virtual card created, but couldn't link to policy",
                    extras: {
                      linkCardIds: linkCardIds.join(', '),
                      policyId: values.policyId ?? '',
                    },
                  });
                },
              },
            );
          }
          ModalService.show(Feedback, {
            employeeName: values.employee.name,
          });
          input.onSuccess();
        },
        onError: () => {
          dispatchToast({
            type: 'error',
            content: 'Não foi possível criar o cartão virtual',
            description: errors.actions.toastDescription,
          });
        },
      });
    },
    (error) => {
      // Schema validation
      ErrorMonitorService.error({
        message: 'Error while validating virtual-card form schema',
        severity: 'fatal',
        extras: {
          zodErrors: JSON.stringify(error),
        },
      });
      dispatchToast({
        type: 'error',
        content: 'Falha ao validar formulário de criação do cartão virtual',
        description: errors.actions.toastDescription,
      });
    },
  );

  /* -------------------------------- Return -------------------------------- */
  return {
    ...form,
    isLoading: createVirtualCard.isLoading,
    onSubmit,
  };
}

export const getExpirationDate = (expiration: {
  expirationRule: 'DAYS' | 'MONTHS' | 'CUSTOM';
  expirationMetadata: string;
}) => {
  switch (expiration.expirationRule) {
    case 'DAYS':
      return DateTime.now()
        .plus({ days: parseInt(expiration.expirationMetadata) })
        .endOf('day');

    case 'MONTHS':
      const expirationDate = DateTime.fromObject({
        month: Number(expiration.expirationMetadata),
      }).endOf('month');

      return expirationDate.diff(DateTime.now()).as('days') <= 0
        ? expirationDate.plus({ year: 1 })
        : expirationDate;

    case 'CUSTOM':
      return DateTime.fromISO(expiration.expirationMetadata).endOf('day');
  }
};

export type UseFormReturn = ReturnType<typeof useForm>;
