import { cpfMask, formatDate, toCurrency } from '../../../../utils/masks';
import { MenuActions } from './../MenuActions';
import { capitalizeWords } from '../../../../utils/masks/capitalizeWords';
import Typography from '../../../../components/Typography';
import { TableProps } from '@frontend/components/TableV2';
import { RouterOutputs } from '@frontend/trpc';
import Tag from '@frontend/components/TagV2';
import mappings from './mappings';

type DepositsOutput = RouterOutputs['company']['deposits']['search'];

type Rows = DepositsOutput['deposits'][number];
type Columns = TableProps<Rows>['columns'];

export const tableEmptyState = {
  isFiltered: false,
  emptyText: 'Você ainda não fez depósito',
  filteredEmptyText: 'Não encontramos o Depósito',
};

export function useGetDepositColumns(): Columns {
  return [
    {
      accessorKey: 'name',
      header: 'Colaborador',
      accessorFn: ({ name }) => capitalizeWords(name),
      cell: (context) => {
        return (
          <Typography.Body4 weight={700} color="neutral_30">
            {context.row.original.name}
          </Typography.Body4>
        );
      },
    },
    {
      accessorKey: 'cpf',
      header: 'CPF',
      cell: ({ cell }) => {
        const maskedCpf = cpfMask(cell.row.original.documentNumber);
        return (
          <Typography.Body4 color="neutral_40">{maskedCpf}</Typography.Body4>
        );
      },
    },
    {
      accessorKey: 'creditDate',
      header: 'Disponível em',
      cell: ({ cell }) => {
        return (
          <Typography.Body4 color="neutral_40">
            {formatDate(cell.row.original.creditDate)}
          </Typography.Body4>
        );
      },
    },
    {
      accessorKey: 'amount',
      header: 'Valor',
      cell: ({ cell }) => {
        return (
          <Typography.Body4 color="neutral_40">
            {toCurrency(cell.getValue<number>())}
          </Typography.Body4>
        );
      },
    },
    {
      accessorKey: 'creditType',
      header: 'Tipo',
      cell: () => {
        return (
          <Typography.Body4 color="neutral_40">Corporativo</Typography.Body4>
        );
      },
    },
    {
      accessorKey: 'paid',
      header: 'Status',
      cell: ({ cell }) => {
        const { paid, transferred, cancelled } = cell.row.original;
        let status: {
          label: string;
          variant:
            | 'error'
            | 'primary'
            | 'success'
            | 'info'
            | 'neutral'
            | 'secondary';
        } = mappings.status.WAITING_FOR_PAYMENT;
        if (cancelled) {
          status = mappings.status.CANCELLED;
        } else if (transferred) {
          status = mappings.status.AVAILABLE;
        } else if (paid) {
          status = mappings.status.WAITING_FOR_AVAILABILITY;
        }
        return (
          <Tag variant={status.variant} size="sm">
            {status.label}
          </Tag>
        );
      },
    },
    {
      accessorKey: '_id',
      header: 'Ações',
      cell: ({ cell }) => {
        return <MenuActions deposit={cell.row.original} />;
      },
    },
  ];
}
