import { Navigate, Route } from 'react-router-dom';

import Management from './Management';
import FailedCredit from './tabs/FlashCard/FailedCredit/FailedCredit';
import FlashCash from './tabs/FlashCard/FlashCard';
import NextCredit from './tabs/FlashCard/NextCredit/NextCredit';
import RequestBalanceSection from './tabs/RequestBalanceSection/RequestBalanceSection';

export const RouteComponent_Management = (
  <>
    <Route path="/corporateCard/management" element={<Management />}>
      <Route
        element={
          <Navigate to="/corporateCard/management/deposits/nextCredit" />
        }
      />
      <Route path="/corporateCard/management/deposits" element={<FlashCash />}>
        <Route
          path="/corporateCard/management/deposits/nextCredit"
          element={<NextCredit />}
        />
        <Route
          path="/corporateCard/management/deposits/failedCredit"
          element={<FailedCredit />}
        />
        <Route
          index
          path="/corporateCard/management/deposits/*"
          element={
            <Navigate to="/corporateCard/management/deposits/nextCredit" />
          }
        />
      </Route>
      <Route
        path="/corporateCard/management/balanceRequests"
        element={<RequestBalanceSection />}
      />
    </Route>
    <Route
      index
      path="/corporateCard/management/*"
      element={<Navigate to="/corporateCard/management/deposits" />}
    />
  </>
);
