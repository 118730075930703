import { trpc } from '@frontend/trpc';

type Input = {
  cardId: string;
};

export default function useGetStatement(input: Input) {
  const statement = trpc.company.externalCards.statement.getAll.useQuery({
    cardId: input.cardId,
  });

  return {
    items: statement.data?.items ?? [],
    metadata: statement.data?.metadata,
    isLoading: statement.isLoading,
  };
}

export type UseGetStatementOutput = ReturnType<typeof useGetStatement>;
