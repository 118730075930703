import { Radio } from '@flash-tecnologia/hros-web-ui-v2';
import Icon from '@frontend/components/Icon';
import Typography from '@frontend/components/Typography';
import BorderedSection from '@frontend/components/frames/BorderedSection';
import Flex from '@frontend/components/frames/Flex';
import InputErrorMessage from '@frontend/components/inputs/_primitives/InputErrorMessage';
import {
  fileFormatsMap,
  sortedFileFormats,
} from '@frontend/utils/dataFormatters/fileFormat.dataFormatter';
import { includes } from '@corporate-card/ts-utils/helper-functions/includes';

type Props<T extends keyof typeof fileFormatsMap> = {
  formats: Readonly<Array<T>>;
  selected: T | undefined;
  onChange: (format: T) => void;
  error?: string;
};

export function FileFormatGroup<T extends keyof typeof fileFormatsMap>(
  props: Props<T>,
) {
  return (
    <div>
      <Flex direction="row" gap="xs2" style={{ width: '100%' }} wrap="wrap">
        {sortedFileFormats.map((format) => {
          // Filters out formats that are not in props.formats
          if (!includes(props.formats, format)) return null;

          const isSelected = props.selected === format;
          const fileFormat = fileFormatsMap[format];

          return (
            <div
              style={{ minWidth: '250px', display: 'flex', flex: 1 }}
              onClick={() => props.onChange(format)}
            >
              <BorderedSection
                padding={['xs2', 'xs']}
                key={format}
                style={{ width: '100%' }}
                backgroundColor={isSelected ? 'secondary' : undefined}
                variant={isSelected ? 'secondary' : undefined}
              >
                <Flex direction="row" align="center" justify="space-between">
                  <Flex direction="row" align="center" gap="xs">
                    <Radio checked={isSelected} />
                    <Typography.Body3 color="neutral_30">
                      {format}
                    </Typography.Body3>
                  </Flex>
                  <Icon
                    name={fileFormat.icon}
                    size={48}
                    color="secondary_50"
                    background="default"
                  />
                </Flex>
              </BorderedSection>
            </div>
          );
        })}
      </Flex>
      <InputErrorMessage error={props.error} />
    </div>
  );
}
