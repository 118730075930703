import LazyComponent from '@frontend/components/LazyComponent';
import RouterService from '@frontend/services/RouterService';
import { Route } from 'react-router-dom';
import BasePath from '../../routes/BasePath';

export const ROUTE_Cards = RouterService.create(
  `${BasePath.relativePath}/cards`,
);

const Cards = () => <LazyComponent factory={() => import('./Cards')} />;

export const RouteComponent_Cards = (
  <Route path={ROUTE_Cards.path} element={<Cards />} />
);
