import { Menu, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import Icon from '@frontend/components/Icon';
import TableClient from '@frontend/components/TableClient';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import Avatar from '@frontend/components/display/Avatar';
import Flex from '@frontend/components/frames/Flex';
import Toggle from '@frontend/components/inputs/Toggle';
import { formatDateTime } from '@frontend/utils/masks';
import React from 'react';
import styled from 'styled-components';
import usePatchActive from '../data/usePatchActive';
import type { UseSearchPoliciesOutput } from '../data/useSearchPolicies';
import useMenuActions from './useMenuActions';

type Row = NonNullable<UseSearchPoliciesOutput['data']>[number];
type Columns = React.ComponentProps<typeof TableClient<Row>>['columns'];

export default [
  {
    accessorKey: 'title',
    header: 'Nome da política',
    enableGlobalFilter: true,
    cell: (context) => <ClampedCell>{context.row.original.title}</ClampedCell>,
  },
  {
    accessorKey: 'description',
    header: 'Descrição',
    enableGlobalFilter: true,
    cell: (context) => (
      <ClampedCell>{context.row.original.description}</ClampedCell>
    ),
  },
  {
    accessorFn: (original) => original.cardIds.length,
    header: 'Cartões vinculados',
    enableGlobalFilter: false,
    cell: (context) => (
      <Tag variant="neutral" size="sm">
        <Flex align="center" gap="xs5">
          <Icon name="IconCreditCard" color="neutral_10" size={16} />
          {context.row.original.cardIds?.length}{' '}
          {context.row.original.cardIds?.length === 1 ? 'Cartão' : 'Cartões'}
        </Flex>
      </Tag>
    ),
  },
  {
    accessorKey: 'active',
    header: 'Ativar/Desativar',
    enableGlobalFilter: false,
    cell: (context) => {
      const patchActive = usePatchActive();
      return (
        <Toggle
          value={context.row.original.active}
          onChange={(newValue) => {
            patchActive.mutateAsync({
              id: context.row.original._id,
              cardIds: context.row.original.cardIds,
              newActiveValue: newValue,
            });
          }}
        />
      );
    },
  },
  {
    id: 'lastEditedBy',
    header: 'Última atualização',
    enableGlobalFilter: false,
    cell: (context) => {
      const admin = context.row.original.lastEditedBy;
      if (!admin) return null;
      return (
        <Flex align="center" gap="xs4">
          <Avatar alt={admin.name} />
          <div>
            <Typography.Body3 noWrap color="neutral_40" weight={600}>
              {context.row.original.lastEditedBy?.name}
            </Typography.Body3>
            <Typography.Body4 noWrap color="neutral_40" weight={600}>
              {formatDateTime(context.row.original.updatedAt)}
            </Typography.Body4>
          </div>
        </Flex>
      );
    },
  },
  {
    id: 'actions',
    header: 'Ações',
    enableGlobalFilter: false,
    isSticky: true,
    cell: (context) => {
      const actions = useMenuActions(context.row.original);
      return (
        <Menu type="default" locked options={actions}>
          <PillButton size="medium" variant="default" icon="IconDotsVertical" />
        </Menu>
      );
    },
  },
] satisfies Columns;

/** Clamps a Typography.Body4 to 2 lines, with ellipsis */
const ClampedCell = styled(Typography.Body4)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
