import { Menu, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import Spacer from '../../../components/Spacer';
import { TableProps } from '../../../components/TableV2';
import { formatDateTime, toCurrency } from '../../../utils/masks';
import { CompanyStatementOutput } from './data/useTableData';
import * as styles from './styled';

type Rows = CompanyStatementOutput['statement'][number];
type Columns = TableProps<Rows>['columns'];

export const flashCashTableColumns: Columns = [
  {
    accessorKey: 'description',
    header: 'Informação',
    cell: ({ cell }) => {
      const value = cell.row.original.description;
      return <div style={{ width: '400px' }}>{value}</div>;
    },
  },
  {
    accessorKey: 'transactionDate',
    header: 'Disponível em',
    accessorFn: ({ transactionDate }) => formatDateTime(transactionDate),
  },
  {
    accessorKey: 'value',
    header: 'Valor',
    cell: ({ cell }) => {
      const value = cell.getValue<number>();
      return (
        <styles.CurrencyWrapper isNegative={value < 0}>
          {toCurrency(value)}
        </styles.CurrencyWrapper>
      );
    },
  },
  {
    accessorKey: 'link',
    header: 'Ações',
    cell: ({ cell }) => {
      const value = cell.getValue<string | undefined>();
      return value ? (
        <Menu
          type="default"
          locked
          options={[
            {
              label: 'Baixar relatório',
              onClick: () => {
                const win = window.open(value);
                if (win) {
                  win.focus();
                }
              },
            },
          ]}
        >
          <PillButton size="medium" variant="default" icon="IconDotsVertical" />
        </Menu>
      ) : (
        <div>
          <Spacer y="m" />
          <Spacer y="xs3" />
        </div>
      );
    },
  },
];
