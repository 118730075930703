import { FocusedFlow } from '@frontend/components/FocusedFlow';
import ModalService from '@frontend/services/ModalService';
import { useCreateVirtualCorporateCardController } from './data/useCreateVirtualCorporateCardController';

type Props = {
  selectedEmployee?: {
    name: string;
    email?: string;
    nanoId: string;
  };
};

const CreateVirtualCorporateCard = (props: Props) => {
  const controller = useCreateVirtualCorporateCardController(
    props.selectedEmployee,
  );

  return (
    <FocusedFlow
      headerMetadata={{
        breadcrumbs: [
          {
            link: '/corporateCard/home',
            text: 'Contas corporativas',
          },
          {
            text: 'Novo cartão virtual corporativo',
          },
        ],
      }}
      content={controller.content}
      footer={controller.footer}
    />
  );
};

export default ModalService.create(CreateVirtualCorporateCard);
