import { trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

export default function useDeleteTransaction() {
  const context = trpc.useContext();

  const deleteItem =
    trpc.company.externalCards.statement.deleteItem.useMutation({
      onMutate: startToast,
      onSuccess: () => {
        context.company.externalCards.statement.getAll.invalidate();
        successToast();
      },
      onError: errorToast,
    });

  return {
    mutateAsync: deleteItem.mutateAsync,
    isLoading: deleteItem.isLoading,
  };
}

function startToast() {
  dispatchToast({
    type: 'warning',
    content: 'Excluindo transação...',
    description: 'Isso pode demorar alguns segundos.',
  });
}

function successToast() {
  dispatchToast({
    type: 'success',
    content: 'Transação excluída com sucesso!',
  });
}

function errorToast() {
  dispatchToast({
    type: 'error',
    content: 'Não foi possível excluir a transação.',
    description:
      'Tente novamente. Se o problema persistir, entre em contato com o suporte',
  });
}
