import { trpc } from '@frontend/trpc';

export default function useSearchPolicies() {
  const policies = trpc.company.policies.search.useQuery({});

  return {
    data: policies.data,
    isLoading: policies.isLoading,
    isError: policies.isError,
  };
}

export type UseSearchPoliciesOutput = ReturnType<typeof useSearchPolicies>;
