import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import ModalService from '@frontend/services/ModalService';

type Props = {
  policy: {
    id: string;
    cardIds: string[];
  };
  onConfirm: () => void;
};

function PatchActiveModal(props: Props) {
  const modalController = ModalService.useModalController();

  const cardCount = props.policy.cardIds.length;

  return (
    <ConfirmationModal
      buttons={{
        cancel: {
          text: 'Cancelar',
          onClick: () => {
            modalController.remove();
          },
        },
        confirm: {
          text: 'Concluir',
          onClick: () => {
            props.onConfirm();
            modalController.remove();
          },
        },
      }}
      isOpen={modalController.visible}
      onClose={modalController.remove}
      variant="error"
      text={{
        highlightedText: 'Atenção!',
        title: 'Tem certeza que deseja desativar essa política?',
        body:
          cardCount === 0 ? (
            <>
              A política não possui cartões vinculados, e poderá ser reativada
              quando desejar.
            </>
          ) : cardCount === 1 ? (
            <>
              Ao desativar a política, ela perderá o vínculo com{' '}
              <b>1 cartão corporativo</b>, que ficará sem regras de uso até que
              seja vinculado a uma outra política.
              <br />A política poderá ser reativada quando desejar, mas os
              cartões deverão ser vinculados a ela novamente.
            </>
          ) : (
            <>
              Ao desativar a política, ela perderá o vínculo com{' '}
              <b>{cardCount} cartões corporativos</b>, que ficarão sem regras de
              uso até que sejam vinculado a uma outra política.
              <br />A política poderá ser reativada quando desejar, mas os
              cartões deverão ser vinculados a ela novamente.
            </>
          ),
      }}
    />
  );
}

export default ModalService.create(PatchActiveModal);
