import { ErrorMessage } from '@corporate-card/ts-utils/helper-types';
import { MonitoringManager } from '@flash-tecnologia/hros-web-utility';
import { ZodError, fromZodError } from 'zod-validation-error';
import EnvService from './EnvService';

type ForbiddenExtraKeys = 'message' | 'type';
type ForbiddenExtraKeysMessage = 'Forbidden keys: `message` | `type`';

type Extras<TExtras extends object> = {
  [TKey in keyof TExtras]: TKey extends ForbiddenExtraKeys
    ? ErrorMessage<ForbiddenExtraKeysMessage>
    : string;
};

type ErrorInput<TExtras extends object> = {
  /** Message attached to the error report */
  message: string;
  /** Error object. Provide it whenever possible */
  error?: Error;
  /** Error severity:
   * - Error: Non-crashing exception;
   * - Fatal: Crashing exception.
   */
  severity: 'error' | 'fatal';
  /** Object with additional info */
  extras?: Extras<TExtras>;
};

/** Logs an error to the Monitor (Sentry) */
function error<TExtras extends object>(input: ErrorInput<TExtras>) {
  MonitoringManager.captureException(
    EnvService.SENTRY_PROJECT_URL,
    input.error ||
      new Error(
        'No error object was provided. Only message and extra keys are available',
      ),
    input.severity,
    {
      message: input.message,
      ...input.extras,
    },
  );
}

type MessageInput<TExtras extends object> = {
  /** Message attached to the log report */
  message: string;
  /** Message level:
   * - Debug: Detailed messages for debugging;
   * - Info: For info logs.
   */
  severity: 'debug' | 'info';
  /** Object with additional info */
  extras?: Extras<TExtras>;
};

/** Logs a message to the Monitor (Sentry) */
function message<TExtras extends object>(input: MessageInput<TExtras>) {
  MonitoringManager.captureMessage(
    EnvService.SENTRY_PROJECT_URL,
    input.message,
    input.severity,
    input.extras,
  );
}

type ZodErrorInput<TExtras extends object> = {
  /** ZodError object */
  error: ZodError;
  /** Object with additional info */
  extras?: Extras<TExtras>;
};

/** Logs a Zod Error to the Monitor (Sentry) */
function zodError<TExtras extends object>(input: ZodErrorInput<TExtras>) {
  error({
    message: fromZodError(input.error).message,
    severity: 'fatal',
    error: input.error,
    extras: input.extras,
  });
}

const ErrorMonitorService = {
  error,
  message,
  zodError,
};
export default ErrorMonitorService;
