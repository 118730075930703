import { FocusedFlow } from '@frontend/components/FocusedFlow';
import ModalService from '@frontend/services/ModalService';
import conditionalList from '@frontend/utils/conditionalList';
import React from 'react';
import useForm from '../forms/useForm';
import EditConfirmationModal from '../modals/EditConfirmationModal';

type Input = {
  /** Closes the `edit policy` focused flow */
  close: () => void;
  /** Policy ID */
  policyId: string;
};

export default function useController(input: Input) {
  const [step, setStep] = React.useState<Steps>(Steps.BASIC_INFO);
  const form = useForm({ onSuccess: input.close, policyId: input.policyId });

  /* ------------------------- Confirm button setup ------------------------- */
  let confirmEnabled: boolean;
  let confirmLabel: string;
  switch (step) {
    case Steps.BASIC_INFO:
      // Checks if `title` and `description` are filled and valid
      confirmEnabled =
        !form.formState.errors.title && !form.formState.errors.description;
      confirmLabel = 'Continuar';
      break;
    case Steps.RULES:
      // Checks if the entire form is valid
      confirmEnabled = form.validSchema;
      confirmLabel = 'Continuar';
      break;
    case Steps.CONFIRMATION:
      confirmEnabled = true;
      confirmLabel = 'Salvar política';
  }

  function onConfirm() {
    if (step < Steps.CONFIRMATION) {
      // Just step forward
      setStep(step + 1);
    } else {
      ModalService.show(EditConfirmationModal, { edit: form.onSubmit });
    }
  }

  /* -------------------------- Cancel button setup ------------------------- */
  function onCancel() {
    input.close();
  }

  /* -------------------------------- Return -------------------------------- */
  return {
    form,
    step,
    rightButtons: conditionalList<RightButton>([
      [
        step > Steps.BASIC_INFO,
        {
          isDisabled: form.isSubmitting,
          isLoading: false,
          label: 'Voltar',
          onClick: () => setStep(step - 1),
          variant: 'secondary',
        },
      ],
      [
        true,
        {
          isDisabled: !confirmEnabled,
          isLoading: form.isSubmitting,
          label: confirmLabel,
          onClick: onConfirm,
          variant: 'primary',
        },
      ],
    ]),
    cancelButton: {
      isDisabled: form.isSubmitting,
      label: 'Cancelar',
      onClick: onCancel,
    } satisfies LeftButton,
  };
}

/** Identifies the Focused Flow page steps */
export enum Steps {
  /** Prompts the user for basic information about the policy (title and description) */
  BASIC_INFO = 1,
  /** Prompts the user for the usage rules of the policy (usage limits, withdraw permission etc.) */
  RULES = 2,
  /** Confirmation page, presents a summary of the policy before updating it */
  CONFIRMATION = 3,
}

type LeftButton = React.ComponentProps<
  typeof FocusedFlow
>['footer']['leftActions'];
type RightButton = React.ComponentProps<
  typeof FocusedFlow
>['footer']['rightActions'][number];
