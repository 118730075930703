import ConfirmationModal from '../../../../../../components/modals/ConfirmationModal';
import ModalService from '../../../../../../services/ModalService';

type Props = {
  employeeName: string;
};

function FeedbackSuccessModal(props: Props) {
  const modalController = ModalService.useModalController();

  return (
    <ConfirmationModal
      isOpen={modalController.visible}
      onClose={modalController.remove}
      variant="default"
      text={{
        title: 'Cartão corporativo virtual criado com sucesso!',
        body: `O cartão virtual de ${props.employeeName} foi criado com sucesso!`,
      }}
    />
  );
}

export default ModalService.create(FeedbackSuccessModal);
