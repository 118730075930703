import { Filter } from '../data/useDepositsData';
import TagFilters from '@frontend/components/TagFilters';

type Props = {
  filter: Filter;
  setFilter: (_: Filter) => void;
};

export default function TableFilter({ filter, setFilter }: Props) {
  return (
    <>
      <TagFilters.DateRange
        label="Disponível"
        initialRange={{
          from: filter.startDate,
          to: filter.endDate,
        }}
        onApply={(value) => {
          const filterCopy = { ...filter };
          if (value?.from && value?.to) {
            filterCopy.startDate = value?.from;
            filterCopy.endDate = value?.to;
          } else {
            delete filterCopy.startDate;
            delete filterCopy.endDate;
          }
          setFilter(filterCopy);
        }}
      />
      <TagFilters.Checkbox
        label="Status"
        initialValue={filter.status}
        options={[
          {
            label: 'Disponibilizado',
            value: 'transferred',
          },
          {
            label: 'Aguardando disponibilização',
            value: 'paid',
          },
          {
            label: 'Cancelado',
            value: 'cancelled',
          },
          {
            label: 'Aguardando pagamento',
            value: 'waiting',
          },
        ]}
        onApply={(value) => {
          const status = [...value];
          if (
            status.length !== (filter.status || []).length ||
            status.some((val, index) => val !== filter.status?.[index])
          ) {
            setFilter({
              ...filter,
              status: status.length ? status : undefined,
            });
          }
        }}
      />
    </>
  );
}
