import { dispatchToast } from '../../../../utils/dispatchEvents';
import { z } from 'zod';
import { useState } from 'react';
import { type RouterOutputs, trpc } from '@frontend/utils/trpc';
import { useParamsState } from '@frontend/utils/hooks/searchParams';

const searchParamsSchema = z
  .object({
    currentPage: z.coerce.number().nonnegative().default(0).catch(0),
    pageSize: z.coerce.number().positive().default(10).catch(10),
    endDate: z.coerce.date().optional().catch(undefined),
    startDate: z.coerce.date().optional().catch(undefined),
    amountMax: z.coerce.number().optional(),
    amountMin: z.coerce.number().optional(),
  })
  .strip();

type SearchParams = z.infer<typeof searchParamsSchema>;

export type GetStatementOutput = RouterOutputs['person']['getStatement'];

export const useTableData = () => {
  const [amountFilters, setAmountFilters] = useState<{
    max: number;
    min: number;
  }>();
  const [filters, setFilters] = useParamsState<SearchParams>(
    { currentPage: 0, pageSize: 10 },
    searchParamsSchema,
  );
  const { pageSize, currentPage, ...otherFilters } = filters;
  const { amountMax, amountMin, ...keys } = otherFilters;

  const filter = {
    ...(!!keys && {
      ...keys,
      amount: amountFilters,
      ...(amountMin !== undefined &&
        amountMax !== undefined && {
          amount: {
            min: amountMin,
            max: amountMax,
          },
        }),
    }),
  };

  const pagination = {
    currentPage,
    pageSize,
  };
  const { data, isLoading } = trpc.person.getStatement.useQuery(
    {
      pagination,
      filter,
    },
    {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      notifyOnChangeProps: 'all',
      onError({ message }) {
        dispatchToast({
          type: 'error',
          content: message,
        });
      },
      onSuccess(result) {
        const { amount } = result.filter;
        if (amount?.max !== undefined && amount?.min !== undefined) {
          setAmountFilters(amount);
        }
      },
    },
  );

  return {
    amount: amountFilters,
    getStatement: data,
    filters,
    setFilters: (data: Omit<SearchParams, 'pageSize' | 'currentPage'>) => {
      setFilters({ ...filters, ...data, currentPage: 0 });
    },
    setPagination: (pagination: { currentPage: number; pageSize: number }) => {
      setFilters({ ...filters, ...pagination });
    },
    pagination,
    isFiltered: Object.keys(filter).length > 0,
    getStatementLoading: isLoading,
  };
};
