import { RouterOutputs, trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import debounce from 'lodash.debounce';
import { useEffect, useMemo, useRef, useState } from 'react';

export type BackboneUsers = RouterOutputs['company']['cards']['findToAdd'];

export default function useListUsers() {
  const [search, setSearch] = useState<string>('');

  const debouncedSearch = useMemo(() => {
    return debounce(setSearch, 1000, { maxWait: 5000 });
  }, []);

  const {
    data: EMfindUsersToAdd,
    isLoading,
    refetch,
  } = trpc.company.virtualCard.findToAdd.useQuery(
    { search },
    {
      retry: false,
      keepPreviousData: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      notifyOnChangeProps: 'all',
      enabled: false,
      onError: (error) => {
        dispatchToast({
          type: 'error',
          content: error.message,
        });
      },
    },
  );

  const ref = useRef('');
  useEffect(() => {
    if (ref.current !== search) {
      ref.current = search;
      void refetch();
    }
  }, [search]);

  return {
    findUsers: (input: { stringSearch: string }) => {
      debouncedSearch(input.stringSearch);
    },
    loading: !!search.length && isLoading,
    options: EMfindUsersToAdd?.users || [],
  };
}
