import { Button } from '@flash-tecnologia/hros-web-ui-v2';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import ErrorMonitorService from '@frontend/services/MonitorService';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

export default function ErrorMonitor() {
  return (
    <>
      <Typography.Headline1>Error Monitor</Typography.Headline1>
      <Spacer y={'l'} />
      <Button
        size="medium"
        variant="primary"
        onClick={() => {
          ErrorMonitorService.message({
            message: 'Test: Error-monitor message',
            severity: 'info',
            extras: {
              extraKey: 'extraValue',
            },
          });
          dispatchToast({ content: 'Log enviado', type: 'success' });
        }}
      >
        Gerar mensagem de log
      </Button>
      <Button
        size="medium"
        variant="primary"
        onClick={() => {
          ErrorMonitorService.error({
            message: 'Test: Error-monitor error',
            severity: 'error',
            error: new Error('Test: Error-monitor error'),
            extras: {
              extraKey: 'extraValue',
            },
          });
          dispatchToast({ content: 'Erro enviado', type: 'success' });
        }}
      >
        Gerar mensagem de erro
      </Button>
      <Button
        size="medium"
        variant="primary"
        onClick={() => {
          dispatchToast({ content: 'Erro será lançado', type: 'success' });
          throw new Error('Test: Error-monitor error boundary');
        }}
      >
        Lançar erro
      </Button>
    </>
  );
}
