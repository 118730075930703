import { toCurrency } from '@frontend/utils/masks';
import Spacer from '../../../components/Spacer';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import ModalService from '../../../services/ModalService';
import AddBalance from '../tabs/FlashCard/focusedFlows/AddBalance/AddBalance';
import * as styles from '../tabs/FlashCard/styled';

type Props = {
  value?: number;
};

function ShowSensitiveInfoModal({ value }: Props) {
  const modalController = ModalService.useModalController();

  return (
    <ConfirmationModal
      buttons={{
        confirm: {
          text: 'Adicionar Flash Cash Corporativo',
          onClick: () => {
            modalController.remove();
            ModalService.show(AddBalance, { amount: value });
          },
        },
        cancel: {
          text: 'Cancelar',
          onClick: () => modalController.remove(),
        },
      }}
      isOpen={modalController.visible}
      onClose={() => modalController.remove()}
      variant="error"
      text={{
        title: 'Você não tem saldo suficiente no Flash Cash Corporativo',
        body: (
          <>
            <styles.CenterText>
              {`Você precisa adicionar ${
                value ? toCurrency(value) : 'saldo'
              } no Flash Cash Corporativo para realizar a Recarga.`}
            </styles.CenterText>
            <Spacer y="s" />
          </>
        ),
      }}
    />
  );
}

export default ModalService.create(ShowSensitiveInfoModal);
