import { Template } from '@frontend/components/FocusedFlow/Template';
import { UseFormReturn } from '../../forms/useForm';
import Callout from '@frontend/components/frames/Callout';
import Typography from '@frontend/components/Typography';
import Spacer from '@frontend/components/Spacer';
import Icon from '@frontend/components/Icon';
import Flex from '@frontend/components/frames/Flex';
import { translatedPeriodTypes } from '../../../../shared/mappers';
import Tag from '@frontend/components/TagV2';
import { toCurrency } from '@frontend/utils/masks';
import {
  sortedWeekdays,
  translatedWeekdays,
} from '@frontend/utils/dataFormatters/weekdays.dataFormatter';
import {
  sortedMccGroups,
  translatedMccGroups,
} from '@frontend/utils/dataFormatters/mccGroups.dataFormatter';
import { Divider } from '@flash-tecnologia/hros-web-ui-v2';

type Props = {
  form: UseFormReturn;
};

export default function Confirmation(props: Props) {
  const policy = props.form.getValues();
  return (
    <Template
      pageInfo={{
        title: 'Editar política de uso do cartão corporativo',
      }}
      contentInfo={{
        title: 'Resumo',
        description:
          'Revise as informações de sua política. Só prossiga depois de confirmar as definições. Caso necessário, retorne para a edição.',
      }}
      Modules={
        <>
          <Callout title="Informações básicas">
            <Typography.Caption>Nome da política</Typography.Caption>
            <Typography.Body3>{policy.title}</Typography.Body3>
            <Spacer y={'xs1'} />
            <Typography.Caption>Descrição</Typography.Caption>
            <Typography.Body3>{policy.description}</Typography.Body3>
          </Callout>
          <Spacer y="xs" />
          {policy.limitEnabled && policy.limit ? (
            <Callout
              title="Limite de gastos habilitado"
              description="Valor máximo que um cartão pode utilizar em um período"
              leftAdornment={
                <Icon
                  name="IconCoin"
                  color="success_40"
                  background="success"
                  size={48}
                />
              }
              alignItems="start"
            >
              <Flex gap="xs">
                <Flex direction="column" align="start">
                  <Typography.Caption>Limite</Typography.Caption>
                  <Typography.Body3>
                    {toCurrency(policy.limit.amount)}
                  </Typography.Body3>
                </Flex>
                <Flex direction="column" align="start">
                  <Typography.Caption>Período</Typography.Caption>
                  <Typography.Body3>
                    {translatedPeriodTypes[policy.limit.period]}
                  </Typography.Body3>
                </Flex>
              </Flex>
            </Callout>
          ) : (
            <Callout
              title="Limite de gastos desabilitado"
              description="Os titulares dos cartões poderão utilizar a qualquer momento todo o saldo corporativo disponibilizado."
              leftAdornment={
                <Icon
                  name="IconCoinOff"
                  color="error_40"
                  background="error"
                  size={48}
                />
              }
            />
          )}
          <Spacer y="xs" />
          {policy.withdrawEnabled ? (
            <Callout
              title="Saque no cartão físico habilitado"
              description="Limite de 2 saques por dia para o colaborador e limite de R$ 20.000,00 por dia para toda a empresa. Para cada saque, haverá uma taxa de operação de R$ 5,99."
              leftAdornment={
                <Icon
                  name="IconCash"
                  color="success_40"
                  background="success"
                  size={48}
                />
              }
            />
          ) : (
            <Callout
              title="Saque no cartão físico desabilitado"
              description="Os titulares dos cartões não poderão realizar saques do saldo corporativo em caixas eletrônicos."
              leftAdornment={
                <Icon
                  name="IconCashOff"
                  color="error_40"
                  background="error"
                  size={48}
                />
              }
            />
          )}
          <Spacer y="xs" />
          <Callout
            title="Dias de uso"
            description="Dias da semana em que transações com esse cartão estão permitidas."
            leftAdornment={
              <Icon
                name="IconCalendar"
                size={48}
                color="secondary_50"
                background="default"
              />
            }
          >
            <Flex wrap="wrap" gap="xs4">
              {sortedWeekdays
                .filter((weekday) => policy.weekdaysEnabled[weekday])
                .map((weekday) => (
                  <Tag size="sm" variant="neutral" key={weekday}>
                    {translatedWeekdays[weekday]}
                  </Tag>
                ))}
            </Flex>
          </Callout>
          <Spacer y="xs" />
          <Callout
            title="Tipos de estabelecimento"
            description="Categorias nas quais transações feitas com esse cartão serão aceitas."
            leftAdornment={
              <Icon
                name="IconBuildingStore"
                size={48}
                color="secondary_50"
                background="default"
              />
            }
          >
            <Flex wrap="wrap" direction="column">
              {sortedMccGroups
                .filter((mccGroup) => policy.mccGroups[mccGroup])
                .map((mccGroup, index) => (
                  <>
                    {index !== 0 && (
                      <div style={{ width: '100%' }}>
                        <Spacer y="xs3" />
                        <Divider orientation="horizontal" />
                        <Spacer y="xs3" />
                      </div>
                    )}
                    <Typography.Body3 color="neutral_30" weight={600}>
                      {translatedMccGroups[mccGroup].title}
                    </Typography.Body3>
                    <Typography.Body4 color="neutral_40">
                      {translatedMccGroups[mccGroup].description}
                    </Typography.Body4>
                  </>
                ))}
            </Flex>
          </Callout>
          <Spacer y="s" />
        </>
      }
    />
  );
}
