import Spacer from '../../../components/Spacer';
import Table from '../../../components/TableV2';
import { useTableData, tableEmptyState } from './data/useDepositsData';
import { useGetDepositColumns } from './table/setup';
import TableFilter from './table/TableFilter';

export default function Deposits() {
  const { data, loading, pagination, setPagination, filters, setFilters } =
    useTableData();

  const columns = useGetDepositColumns();
  return (
    <>
      <Spacer y="s" />
      <Table
        pagination={{
          pageIndex: pagination.currentPage,
          pageSize: pagination.pageSize,
        }}
        emptyState={tableEmptyState}
        columns={columns}
        data={data.deposits}
        loading={loading}
        onPaginationChange={setPagination}
        TableFilters={<TableFilter filter={filters} setFilter={setFilters} />}
        pageCount={data.pagination.totalPages}
      />
      <Spacer y="s" />
    </>
  );
}
