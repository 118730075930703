import { capitalizeWords } from '@frontend/utils/masks/capitalizeWords';
import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import ModalService from '../../../../services/ModalService';
import { formatDate, toCurrency } from '../../../../utils/masks';
import { useCancelDeposit } from './dataSource';

type Props = {
  employeeDepositId: string;
  employeeName: string;
  amount: number;
  date: string | Date;
  onCancel?: () => void;
};

function CancelDepositModal({
  employeeDepositId,
  employeeName,
  amount,
  date,
  onCancel,
}: Props) {
  const { cancelDeposit, loading } = useCancelDeposit();

  const modalController = ModalService.useModalController();

  return (
    <ConfirmationModal
      buttons={{
        confirm: {
          text: 'Confirmar cancelamento',
          onClick: () =>
            cancelDeposit(employeeDepositId, () => {
              onCancel?.();
              modalController.remove();
            }),
        },
        cancel: {
          text: 'Cancelar',
          onClick: modalController.remove,
        },
      }}
      isOpen={modalController.visible}
      onClose={modalController.remove}
      variant="error"
      text={{
        highlightedText: 'Atenção!',
        title: 'Tem certeza que deseja cancelar deposito?',
        body: (
          <>
            Se você cancelar, <strong>{capitalizeWords(employeeName)}</strong>{' '}
            não irá mais receber o deposito de{' '}
            <strong>{toCurrency(amount)}</strong> previsto para o dia{' '}
            <strong>{formatDate(new Date(date as string))}</strong>
          </>
        ),
      }}
      loading={loading}
    />
  );
}

export default ModalService.create(CancelDepositModal);
