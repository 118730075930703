import React from 'react';
import ModalService from '../../../services/ModalService';
import { trpc } from '../../../utils/trpc';
import AddEmployeeOnboardingStartModal from '../modals/onboardingAddEmployee/OnboardingAddEmployeeStartModal';

export default function useShowAddEmployeeOnboarding() {
  const { data } = trpc.notices.get.useQuery(
    {
      template: 'onboarding_add-employee',
    },
    {
      cacheTime: 10000000000,
      staleTime: 10000000000,
      refetchOnWindowFocus: false,
    },
  );
  React.useEffect(() => {
    if (data?.show) ModalService.show(AddEmployeeOnboardingStartModal, {});
  }, [data?.show]);
}
