import TagFilterBase, { type ExposedProps } from './components/TagFilterBase';
import React from 'react';
import { TagFilterRef } from './components/shared';
import {
  DayPicker,
  DateFormatter,
  type DateRange,
  type SelectRangeEventHandler,
} from 'react-day-picker';
import ptbr from 'date-fns/locale/pt-BR';
import * as DateFns from 'date-fns';
import styled, { useTheme } from 'styled-components';
import 'react-day-picker/dist/style.css';
import Typography from '../Typography';

const MONTHS = [
  'JAN',
  'FEV',
  'MAR',
  'ABR',
  'MAI',
  'JUN',
  'JUL',
  'AGO',
  'SET',
  'OUT',
  'NOV',
  'DEZ',
] as const;

const WEEKDAYS = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'] as const;

/** Formats the displayed month and year */
const formatCaption: DateFormatter = (date) => (
  <Typography.Body4 color="neutral_60" weight={600}>
    {`${MONTHS[date.getMonth()]} ${date.getFullYear()}`}
  </Typography.Body4>
);

/** Formats the head-row of weekdays */
const formatWeekdayName: DateFormatter = (date) => (
  <Typography.Body3
    color="neutral_50"
    weight={400}
    style={{ textAlign: 'center' }}
  >
    {WEEKDAYS[date.getDay()]}
  </Typography.Body3>
);

/** Formats the text of each day-cell */
const formatDay: DateFormatter = (date) => (
  <Typography.Body3
    color={[0, 6].includes(date.getDay()) ? 'neutral_70' : 'neutral_30'}
    weight={700}
  >
    {date.getDate()}
  </Typography.Body3>
);

type Props = ExposedProps & {
  /** Called when the filter is applied */
  onApply?: (_newValue?: DateRange) => void;

  initialRange?: DateRange;
};

function TagFilterRange(props: Props, ref: React.Ref<TagFilterRef<DateRange>>) {
  const theme = useTheme();
  const [appliedFilter, setAppliedFilter] = React.useState<DateRange>(
    props.initialRange ?? {
      from: undefined,
      to: undefined,
    },
  );
  /** Calendar's selected interval */
  const [temporarilySelectedRange, setTemporarilySelectedRange] =
    React.useState<DateRange>(
      props.initialRange ?? {
        from: undefined,
        to: undefined,
      },
    );
  /** Start-date text input ref */
  const startDateInputRef = React.useRef<HTMLInputElement>(null);
  /** End-date text input ref */
  const endDateInputRef = React.useRef<HTMLInputElement>(null);

  /** Controls the start-date text input */
  const handleStartDateBlur = () => {
    const inputValue = startDateInputRef.current?.value;

    // Should only update the selected range when the typed date is valid
    const date = DateFns.parse(inputValue ?? '', 'y-MM-dd', new Date());
    if (!DateFns.isValid(date)) {
      if (startDateInputRef.current) startDateInputRef.current.value = '';
      if (endDateInputRef.current) endDateInputRef.current.value = '';
      return setTemporarilySelectedRange({ from: undefined, to: undefined });
    }

    if (
      temporarilySelectedRange?.to &&
      DateFns.isAfter(date, temporarilySelectedRange.to)
    ) {
      setTemporarilySelectedRange({
        from: temporarilySelectedRange.to,
        to: date,
      });
    } else {
      setTemporarilySelectedRange({
        from: date,
        to: temporarilySelectedRange?.to,
      });
    }
  };

  /** Controls the end-date text input */
  const handleEndDateBlur = () => {
    const inputValue = endDateInputRef.current?.value;

    // Should only update the selected range when the typed date is valid
    const date = DateFns.parse(inputValue ?? '', 'y-MM-dd', new Date());
    if (!DateFns.isValid(date)) {
      if (startDateInputRef.current) startDateInputRef.current.value = '';
      if (endDateInputRef.current) endDateInputRef.current.value = '';
      return setTemporarilySelectedRange({
        from: temporarilySelectedRange?.from,
        to: undefined,
      });
    }

    if (
      temporarilySelectedRange?.from &&
      DateFns.isBefore(date, temporarilySelectedRange.from)
    ) {
      setTemporarilySelectedRange({
        from: date,
        to: temporarilySelectedRange.from,
      });
    } else {
      setTemporarilySelectedRange({
        from: temporarilySelectedRange?.from,
        to: date,
      });
    }
  };

  /** Controls the calendar selection */
  const handleSelectionChange: SelectRangeEventHandler = (range) => {
    const selectedRange = range ?? { from: undefined, to: undefined };
    setTemporarilySelectedRange(selectedRange);
    if (startDateInputRef.current)
      startDateInputRef.current.value = selectedRange.from
        ? DateFns.format(selectedRange.from, 'yyyy-MM-dd')
        : '';
    if (endDateInputRef.current)
      endDateInputRef.current.value = selectedRange.to
        ? DateFns.format(selectedRange.to, 'yyyy-MM-dd')
        : '';
  };

  /** Applies the temporary filter, overriding the current one */
  function handleOnApply() {
    const newFilter = { ...temporarilySelectedRange };
    if (newFilter.from && !newFilter.to) {
      // Only selected the starting date. Using it as start and end date
      newFilter.to = newFilter.from;
      setTemporarilySelectedRange(newFilter);
      if (endDateInputRef.current)
        endDateInputRef.current.value = DateFns.format(
          newFilter.from,
          'yyyy-MM-dd',
        );
    }
    setAppliedFilter(newFilter);
    props.onApply?.(newFilter);
    return newFilter;
  }

  /** Clears both applied and temporary filters, selecting the whole range */
  function handleOnClear() {
    if (startDateInputRef.current) startDateInputRef.current.value = '';
    if (endDateInputRef.current) endDateInputRef.current.value = '';
    const emptyRange = {
      from: undefined,
      to: undefined,
    };
    setTemporarilySelectedRange(emptyRange);
    setAppliedFilter(emptyRange);
    props.onApply?.(emptyRange);
    return emptyRange;
  }

  /** Resets the temporary filter to the applied range */
  function handleOnClose() {
    setTemporarilySelectedRange(appliedFilter);
  }

  // Exposes the triggers for usage with Drawer
  React.useImperativeHandle(
    ref,
    () => ({
      triggerApply: handleOnApply,
      triggerClear: handleOnClear,
      triggerClose: handleOnClose,
    }),
    [handleOnApply, handleOnClear, handleOnClose],
  );

  return (
    <TagFilterBase
      asDrawerItem={props.asDrawerItem}
      disabled={props.disabled}
      label={props.label}
      status={appliedFilter.from ? 'active' : 'neutral'}
      leftIcon={props.leftIcon}
      onApply={handleOnApply}
      onClear={handleOnClear}
      onClose={handleOnClose}
    >
      <StyledTextInputSection>
        <Typography.Body4 color="neutral_50">
          Selecione ou digite
        </Typography.Body4>
        <StyledTextInputRow>
          <StyledTextInput
            defaultValue={
              props.initialRange?.from
                ? DateFns.format(props.initialRange.from, 'yyyy-MM-dd')
                : undefined
            }
            placeholder="De"
            type="date"
            ref={startDateInputRef}
            onBlur={handleStartDateBlur}
          />
          <Typography.Body4>–</Typography.Body4>
          <StyledTextInput
            defaultValue={
              props.initialRange?.to
                ? DateFns.format(props.initialRange.to, 'yyyy-MM-dd')
                : undefined
            }
            placeholder="Até"
            type="date"
            ref={endDateInputRef}
            onBlur={handleEndDateBlur}
          />
        </StyledTextInputRow>
      </StyledTextInputSection>
      <StyledDayPickerContainer>
        <DayPicker
          selected={temporarilySelectedRange}
          onSelect={handleSelectionChange}
          locale={ptbr}
          mode="range"
          formatters={{
            formatCaption,
            formatWeekdayName,
            formatDay,
          }}
          modifiersStyles={{
            range_start: {
              backgroundColor: theme.colors.secondary[50],
            },
            range_middle: {
              backgroundColor: theme.colors.secondary[95],
            },
            range_end: {
              backgroundColor: theme.colors.secondary[50],
            },
          }}
        />
      </StyledDayPickerContainer>
    </TagFilterBase>
  );
}

export default React.forwardRef(TagFilterRange);

const StyledTextInputSection = styled.div`
  margin: 5px;
  padding: 20px 20px 0;
`;

const StyledTextInputRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const StyledTextInput = styled.input`
  // Removes the calendar icon from the text-input
  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  width: 6rem;
  &:focus {
    outline: none;
  }
  color: ${(p) => p.theme.colors.neutral[30]};
  border-bottom: 2px solid ${(p) => p.theme.colors.neutral[30]};
`;

const StyledDayPickerContainer = styled.div`
  .rdp {
    margin: 0;
    width: 100%;
  }
  .rdp-month {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .rdp-caption {
      width: 100%;
      padding: 20px 20px 0;
    }
  }
  // Color of the start and end of selection range
  .rdp-day_range_start,
  .rdp-day_range_end {
    & > p {
      color: white !important;
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 0 10px;
  }
  // Adds shadow and spacing between the weekday label header and the table body
  .rdp-head {
    box-shadow: 0px 5px 4px -2px #0000001a;
  }
  // Styling of the selected interval
  .rdp:not([dir='rtl']) {
    .rdp-cell:has(.rdp-day_range_start:not(.rdp-day_range_end)) {
      background-color: ${(p) => p.theme.colors.secondary[95]} !important;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      padding: 3px;

      .rdp-day_range_start {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }

    .rdp-cell:has(.rdp-day_range_end:not(.rdp-day_range_start)) {
      background-color: ${(p) => p.theme.colors.secondary[95]} !important;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
      padding: 3px;

      .rdp-day_range_end {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }
    .rdp-day_range_middle > p {
      color: var(--color-secondary-dark2);
    }
  }
`;
