import Icon from '@frontend/components/Icon';

export const sortedFileFormats = ['CSV', 'PDF', 'XLSX'] as const;

export const fileFormatsMap = {
  CSV: {
    icon: 'IconFileTypeCsv',
    extension: 'csv',
    name: 'CSV',
  },
  PDF: {
    icon: 'IconFileTypePdf',
    extension: 'pdf',
    name: 'PDF',
  },
  XLSX: {
    icon: 'IconFileTypeXls',
    extension: 'xlsx',
    name: 'XLSx',
  },
} as const satisfies Record<
  (typeof sortedFileFormats)[number],
  {
    icon: React.ComponentProps<typeof Icon>['name'];
    extension: string;
    name: string;
  }
>;
