import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import CreateDeposits from '@frontend/pages/Home/focusedFlows/CreateDeposits';
import ModalService from '@frontend/services/ModalService';
import useApproveRequest from '../data/useApproveRequest';

type Props = {
  order: {
    _id: string;
    creditDate: Date;
    employees: Array<{
      id: string;
      documentNumber: string;
      name: string;
    }>;
    value: number;
  };
};

function ApproveRequestModal(props: Props) {
  const modalController = ModalService.useModalController();

  return (
    <ConfirmationModal
      buttons={{
        confirm: {
          text: 'Ir para pagamento',
          onClick: () => {
            modalController.remove();
            ModalService.show(CreateDeposits, {
              initialValues: {
                _id: props.order._id,
                creditDate: props.order.creditDate,
                employees: props.order.employees,
                value: props.order.value,
              },
              useApiHook: useApproveRequest,
            });
          },
        },
        cancel: {
          text: 'Decidir mais tarde',
          onClick: () => {
            modalController.remove();
          },
        },
      }}
      variant="default"
      text={{
        title: 'Aprovar pedido de saldo',
        body: (
          <>
            Você está prestes a aprovar o pedido de saldo, mas precisa finalizar
            a etapa de pagamento para concluir o depósito.
          </>
        ),
      }}
      isOpen={modalController.visible}
      onClose={modalController.remove}
    />
  );
}

export default ModalService.create(ApproveRequestModal);
