import { Icons, IconTypes, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useTheme } from 'styled-components';
import Spacer from '../../Spacer';
import Typography from '../../Typography';
import * as SC from './styles';

type Props = {
  active?: boolean;
  icon: IconTypes;
  subtitle: React.ReactNode;
  title: React.ReactNode;
  variant: 'default' | 'primary';
  linkButton?: {
    label: string;
    onClick: () => void;
  };
};

export default function Card(props: Props) {
  const theme = useTheme();
  const palette = (
    {
      default: {
        // TODO: use theme
        iconColor: '#83727D',
        iconFill: '#B9ACB4',
        iconBackground: '#B9ACB4',
        background: '#f8f6f8',
        borderColor: '#83727d',
        titleColor: 'neutral_50',
        bodyColor: 'neutral_50',
      },
      primary: {
        iconColor: theme.colors.primary,
        iconFill: theme.colors.secondary[95],
        iconBackground: theme.colors.secondary[95],
        background: props.active
          ? theme.colors.secondary[99]
          : theme.colors.neutral[100],
        borderColor: props.active
          ? theme.colors.secondary[80]
          : theme.colors.neutral[80],
        titleColor: props.active ? 'primary' : 'neutral_30',
        bodyColor: props.active ? 'neutral_50' : 'neutral_30',
      },
    } as const
  )[props.variant];

  return (
    <SC.CardContainer
      backgroundColor={palette.background}
      borderColor={palette.borderColor}
    >
      <SC.CardIconContainer backgroundColor={palette.iconBackground}>
        <Icons
          color={palette.iconColor}
          fill={palette.iconFill}
          name={props.icon}
        />
      </SC.CardIconContainer>
      <Spacer y="xs2" />
      <Typography.Body3 color={palette.titleColor} weight={700}>
        {props.title}
      </Typography.Body3>
      <Spacer y="xs4" />
      <Typography.Body4 color={palette.bodyColor} weight={400}>
        {props.subtitle}
      </Typography.Body4>
      <Spacer y="xs4" />
      {props.linkButton && (
        <LinkButton variant="default" onClick={props.linkButton.onClick}>
          {props.linkButton.label}
        </LinkButton>
      )}
    </SC.CardContainer>
  );
}
