import { MultiSelect } from '@flash-tecnologia/hros-web-ui-v2';
import Modal from '../../../../components/modals/DefaultModal';
import Spacer from '../../../../components/Spacer';
import Typography from '../../../../components/Typography';
import ModalService from '../../../../services/ModalService';
import { useFindBackboneUsers } from '../../data/useAddUsers';
import useFindUsers, { BackboneUsers } from '../../data/useFindUsers';

function AddUserModal() {
  const modalController = ModalService.useModalController();
  const {
    selectedUsers,
    setSelectedUsers,
    disabledConfirm,
    inputShowHelperText,
    addUsersLoading,
    onConfirm,
  } = useFindBackboneUsers();
  const { findBackBoneUsers, loading, options } = useFindUsers(selectedUsers);

  return (
    <Modal
      loading={addUsersLoading}
      buttons={{
        confirm: {
          disabled: disabledConfirm,
          text: 'Confirmar',
          onClick: () =>
            onConfirm(() => {
              modalController.remove();
            }),
        },
        cancel: {
          text: 'Cancelar',
          onClick: modalController.remove,
        },
      }}
      isOpen={modalController.visible}
      onClose={modalController.remove}
      header={{
        title: 'Vincular uso corporativo',
        subtitle:
          'Ativar uso do cartão corporativo para uma pessoa cadastrada na plataforma.',
      }}
      children={
        <>
          <Typography.Body3 weight={700}>Informe os dados</Typography.Body3>
          <Spacer y="xs" />
          <MultiSelect
            renderInput={() => <div />} // TODO: remove this prop from web-ui
            options={[...options, ...selectedUsers]}
            fullWidth
            limitTags={2}
            error={inputShowHelperText}
            helperText={
              inputShowHelperText
                ? 'Colaborador selecionado na lista já possui Cartão Corporativo'
                : undefined
            }
            noOptionsText="Digite algo para buscar"
            filterOptions={(option) => option}
            getOptionDisabled={(option) => option.alreadyActive}
            loading={loading}
            loadingText="Carregando..."
            onChange={({ target }) => {
              const newValue = target.value;
              if (!!newValue.length) {
                findBackBoneUsers({ stringSearch: newValue });
              }
            }}
            onSelectChange={(_, selected: BackboneUsers | null) => {
              setSelectedUsers(!!selected ? selected : []);
            }}
            label="Nome ou CPF da Pessoa"
          />
        </>
      }
    />
  );
}

export default ModalService.create(AddUserModal);
