import { dispatchToast } from '@frontend/utils/dispatchEvents';
import { formatDate, toCurrency } from '@frontend/utils/masks';
import { RouterOutputs, trpc } from '@frontend/utils/trpc';

export function useEmployeeInfo() {
  const { data, isLoading, isError } =
    trpc.employee.corporateCard.getWallet.useQuery(undefined, {
      keepPreviousData: true,
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      onError: ({ message }) => {
        dispatchToast({
          type: 'error',
          content: message,
        });
      },
    });
  return {
    employeeInfo: formatData(data),
    employeeInfoLoading: isLoading,
    employeeInfoError: isError,
  };
}

function formatData(
  data: RouterOutputs['employee']['corporateCard']['getWallet'] | undefined,
) {
  return {
    balance: data?.balance ? toCurrency(data.balance) : '-',
    hasCard: data?.hasCard ?? true,
    nextMonthCreditAmount: data?.nextMonthCreditAmount
      ? toCurrency(data.nextMonthCreditAmount)
      : '-',
    nextCreditInfo: data?.nextCreditInfo.map((info) => ({
      amount: toCurrency(info.amount),
      availableDate: formatDate(info.availableDate),
      expirationDate: info.expirationDate
        ? formatDate(info.expirationDate)
        : undefined,
      status: statusMap[info.status],
      statusVariant: statusVariantMap[info.status],
      type: typeMap[info.type],
    })),
  };
}

const typeMap = {
  normal: 'Recarga Única',
  fixedDate: 'Recarga Automática',
  fixedValue: 'Recarga Automática Complementar',
} as const;

const statusMap = {
  confirmed: 'Confirmado',
  toConfirm: 'A confirmar',
} as const;

const statusVariantMap = {
  confirmed: 'success',
  toConfirm: 'error',
} as const;
