import { MenuOptionType } from '@flash-tecnologia/hros-web-ui-v2';
import ModalService from '@frontend/services/ModalService';
import DeletePolicyModal from '../modals/DeletePolicyModal';
import { UseSearchPoliciesOutput } from '../data/useSearchPolicies';
import RouterService from '@frontend/services/RouterService';
import { ROUTE_PolicyDetails } from '../PolicyDetails';
import EditPolicy from '../focusedFlows/EditPolicy';

type Policy = NonNullable<UseSearchPoliciesOutput['data']>[number];

export default function useMenuActions(policy: Policy) {
  const navigate = RouterService.useNavigate();

  return [
    {
      label: 'Ver política',
      onClick: () => {
        navigate({
          route: ROUTE_PolicyDetails,
          params: { policyId: policy._id },
          searchParams: {},
          state: {},
        });
      },
    },
    {
      label: 'Editar política',
      onClick: () => {
        ModalService.show(EditPolicy, {
          policyId: policy._id,
        });
      },
    },
    {
      label: 'Excluir política',
      onClick: () => {
        ModalService.show(DeletePolicyModal, {
          policyId: policy._id,
        });
      },
    },
  ] satisfies MenuOptionType[];
}
