import { BoxAdornment } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledBoxAdornment = styled(BoxAdornment)`
  border: unset !important;
  white-space: nowrap;
`;

export const StyledIcon = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 50%;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  fill: transparent;
  align-items: center;
`;

export interface ICell {
  width: number;
}

export const Cell = styled.div`
  white-space: nowrap;
  display: flex;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TableAction = styled.div`
  position: sticky;
  display: flex;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  height: 68px;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  z-index: 5;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 8px;
`;
