import {
  LinkButton,
  Breadcrumbs as UIBreadcrumbs,
} from '@flash-tecnologia/hros-web-ui-v2';
import Icon from '@frontend/components/Icon';
import Typography from '@frontend/components/Typography';

type Props = {
  breadcrumbs: Readonly<
    Array<{
      label: string;
      onClick?: () => void;
    }>
  >;
  separator?: React.ReactNode;
};

export default function Breadcrumbs(props: Props) {
  return (
    <UIBreadcrumbs
      breadcrumbs={props.breadcrumbs.map((breadcrumb) =>
        Boolean(breadcrumb.onClick) ? (
          <LinkButton variant="neutral" onClick={breadcrumb.onClick}>
            {breadcrumb.label}
          </LinkButton>
        ) : (
          <Typography.Body4 color="neutral_50">
            {breadcrumb.label}
          </Typography.Body4>
        ),
      )}
      separator={
        props.separator ?? <Icon name="IconChevronRight" color="neutral_50" />
      }
    />
  );
}
