import { Button, Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import Spacer from '@frontend/components/Spacer';
import TagButton from '@frontend/components/TagButton';
import Typography from '@frontend/components/Typography';
import { Popover } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

/** Props that should be exposed by implemented Filters */
export type ExposedProps = {
  /** Renders as a drawer item instead of a tag popup */
  asDrawerItem?: boolean;
  /** Filter's label */
  label: string;
  /** Icon on the left of the filter's name */
  leftIcon?: React.ComponentProps<typeof Icons>['name'];
  /** Disables the input, overwriting the status */
  disabled?: boolean;
};

/** Props that should be handled by the filter implementation */
type InternalProps = React.PropsWithChildren<{
  /** Changes the aspect of the tag */
  status: 'active' | 'neutral';
  /** Called when the `Filter` button is clicked */
  onApply: () => void;
  /** Called when the `Clear` button is clicked */
  onClear: () => void;
  /** Called when the popup closes */
  onClose?: () => void;
}>;

type Props = ExposedProps & InternalProps;

export default function (props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  function handleOnClose() {
    setAnchorEl(null);
    props.onClose?.();
  }

  if (props.asDrawerItem)
    return (
      <StyledFrame>
        <Typography.Headline8 color="neutral_30">
          {props.label}
        </Typography.Headline8>
        <Spacer y="s" />
        {props.children}
      </StyledFrame>
    );

  return (
    <>
      <TagButton
        label={props.label}
        status={props.disabled ? 'disabled' : props.status}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        leftIcon={props.leftIcon}
        rightIcon="IconChevronDown"
      />
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleOnClose}
        PaperProps={{
          style: {
            marginTop: '8px',
            width: '320px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {props.children}
        <StyledDrawerButtonArea>
          <LinkButton
            variant="default"
            onClick={props.onClear}
            style={{ alignSelf: 'center' }}
          >
            Limpar
          </LinkButton>
          <Button
            variant="primary"
            size="medium"
            onClick={props.onApply}
            style={{ width: '50%' }}
          >
            Filtrar
          </Button>
        </StyledDrawerButtonArea>
      </Popover>
    </>
  );
}

const StyledFrame = styled.div`
  width: 540px;
  padding: 24px 40px;
  border: 1px solid ${(p) => p.theme.colors.neutral[90]};
  border-radius: 12px;
`;

const StyledDrawerButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 32px;
  border-top: 1px solid ${(p) => p.theme.colors.neutral[90]};
  align-items: center;
  justify-content: space-between;
`;
