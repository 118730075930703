import { FormOutput } from '@frontend/pages/Home/focusedFlows/CreateDeposits/forms/useForm';
import { trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

export default function useApproveRequest() {
  const context = trpc.useContext();

  const approval = trpc.company.requestBalance.approval.useMutation({
    onSuccess: () => {
      context.company.requestBalance.search.invalidate();
    },
    onError: (error) => {
      dispatchToast({
        type: 'error',
        content: error.message,
      });
    },
  });

  return {
    mutateAsync: async (input: FormOutput) => {
      const data = await approval.mutateAsync({
        id: input._id,
        data: {
          creditDate: input.creditDate,
          employees: input.deposits.map((deposit) => {
            return {
              ...deposit,
              value: input.value,
              ...automaticMap(input),
            };
          }),
          paymentMethod: paymentMethodMap(input.paymentMethod),
        },
      });

      return {
        _id: data._id,
        value: data.value,
        creditDate: data.creditDate,
        paymentMethod: data.paymentMethod,
      };
    },
    data: approval.data && {
      _id: approval.data._id,
      value: approval.data.value,
      creditDate: approval.data.creditDate,
      paymentMethod: approval.data.paymentMethod,
    },
  };
}

function automaticMap(input: {
  depositType: 'SINGLE' | 'AUTOMATIC';
  expires?: boolean;
  expirationDate?: Date;
  complementary?: boolean;
}):
  | {
      creditType: 'normal' | 'automaticCredit' | 'automaticCreditFixedValue';
    }
  | {
      creditType: 'automaticDebit';
      debitDate: Date;
    } {
  if (input.depositType === 'SINGLE') {
    if (!input.expires || !input.expirationDate)
      return { creditType: 'normal' };
    return {
      creditType: 'automaticDebit',
      debitDate: input.expirationDate,
    } as const;
  } else {
    if (!input.complementary) return { creditType: 'automaticCredit' } as const;
    return {
      creditType: 'automaticCreditFixedValue',
    } as const;
  }
}

function paymentMethodMap(input: 'BILLET' | 'PIX' | 'FLASH_CASH') {
  switch (input) {
    case 'BILLET':
      return 'billet';
    case 'PIX':
      return 'pix';
    default:
      return 'balance';
  }
}

export type UseCreateDepositsReturn = ReturnType<typeof useApproveRequest>;
