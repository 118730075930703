import { DateTime } from 'luxon';
import { UseFormReturn } from '../../../forms/useForm';

type Input = {
  form: UseFormReturn;
};

export default function useFormData(input: Input) {
  const formData = input.form.getValues();

  const creditDate =
    formData.creditDate &&
    DateTime.fromJSDate(formData.creditDate, {
      zone: 'America/Sao_Paulo',
    }).startOf('day');

  const creditDateDescription =
    creditDate &&
    (formData.depositType === 'AUTOMATIC'
      ? `Todo dia ${creditDate.toFormat(
          'dd',
        )}, a partir de ${creditDate.toFormat('dd/MM/yyyy')}`
      : creditDate.toFormat('dd/MM/yyyy'));

  const description = `Depósito ${depositTypeMap[formData.depositType]} ${
    formData.complementary ? 'complementar ' : ''
  }${formData.expires ? 'com expiração de saldo ' : ''}pago com ${
    paymentMethodMap[formData.paymentMethod]
  } agendado com sucesso!`;

  /** Flash Cash doesn't need additional order info */
  const needsOrderInfo = formData.paymentMethod !== 'FLASH_CASH';

  const result = input.form.result;

  const totalAmount = formData.value * formData.deposits?.length ?? 0;

  return {
    ...formData,
    metadata: {
      creditDateDescription,
      description,
      needsOrderInfo,
      result,
      totalAmount,
    },
  };
}

const depositTypeMap = {
  AUTOMATIC: 'automático',
  SINGLE: 'único',
} as const;

const paymentMethodMap = {
  FLASH_CASH: 'Flash Cash corporativo',
  BILLET: 'Boleto',
  PIX: 'Pix',
};
