import Spacer from '@frontend/components/Spacer';
import Table from '@frontend/components/TableV2';
import TagFilters from '@frontend/components/TagFilters';
import SearchField from '@frontend/components/inputs/SearchField';
import { useSearchRequests } from './data/useSearchRequests';
import { useSheetTable } from './data/useSheetTable';
import * as styled from './styled';
import { useTableColumns } from './table/setup';

export default function RequestBalanceSection() {
  const {
    data,
    filters,
    error,
    loading,
    isFiltered,
    setFilters,
    pagination,
    setPagination,
    setStringSearch,
  } = useSearchRequests();

  const sheetTable = useSheetTable();

  const tableColumns = useTableColumns();

  return (
    <styled.BottomWrapper>
      <Spacer y="xs" />
      <SearchField
        label="Buscar pedido de carga por solicitante"
        size="lg"
        initialValue={filters.stringInput}
        disabled={loading}
        onChange={({ target }) => {
          setStringSearch(target.value);
        }}
      />
      <Spacer y="s" />
      <Table
        emptyState={{
          isFiltered,
          emptyText: error
            ? 'Houve um erro ao retornar lista de Pedidos de carga.'
            : 'Você não possui nenhum Pedido de carga',
          filteredEmptyText: 'Não encontramos nenhum Pedido de carga',
        }}
        columns={tableColumns}
        data={data.entries}
        loading={loading}
        pagination={{
          pageIndex: pagination.currentPage,
          pageSize: pagination.pageSize,
        }}
        pageCount={data.pagination.totalPages}
        onPaginationChange={({ pageIndex, pageSize }) => {
          setPagination({
            currentPage: pageIndex,
            pageSize,
          });
        }}
        TableFilters={
          <>
            <TagFilters.Checkbox
              label="Status"
              disabled={loading}
              initialValue={filters.status}
              options={[
                {
                  label: 'Pendente',
                  value: 'preview',
                },
                {
                  label: 'Aguardando pagamento',
                  value: 'waiting',
                },
                {
                  label: 'Pago',
                  value: 'paid',
                },
                {
                  label: 'Reprovado',
                  value: 'reproved',
                },
                {
                  label: 'Expirado',
                  value: 'expired',
                },
              ]}
              onApply={(value) => {
                const status = [...value];
                if (
                  status.length !== (filters.status || []).length ||
                  status.some((val, index) => val !== filters.status?.[index])
                ) {
                  setFilters({
                    ...filters,
                    status: status.length ? status : undefined,
                  });
                }
              }}
            />
            <TagFilters.DateRange
              label="Data do pedido"
              disabled={loading}
              initialRange={{
                from: filters.createdAtStartDate,
                to: filters.createdAtEndDate,
              }}
              onApply={(value) => {
                const filterCopy = { ...filters };
                if (value?.from && value?.to) {
                  filterCopy.createdAtStartDate = value?.from;
                  filterCopy.createdAtEndDate = value?.to;
                } else {
                  delete filterCopy.createdAtStartDate;
                  delete filterCopy.createdAtEndDate;
                }
                setFilters(filterCopy);
              }}
            />
            <styled.ButtonWrapper>
              <styled.TagButton
                status={
                  loading || error || sheetTable.loading ? 'disabled' : 'active'
                }
                label="Exportar para Excel"
                onClick={() => sheetTable.dispatch()}
              />
            </styled.ButtonWrapper>
          </>
        }
      />
    </styled.BottomWrapper>
  );
}
