import { toCurrency } from '@frontend/utils/masks';
import { trpc } from '@frontend/utils/trpc';

export function useManagementInfo() {
  const {
    data: toPayCountData,
    isError: toPayCountError,
    isLoading: toPayCountLoading,
    isRefetching: toPayCountRefetching,
  } = trpc.company.billing.toPayCount.useQuery(undefined, {
    retry: false,
    retryOnMount: true,
    cacheTime: 60 * 1000,
    staleTime: 30 * 1000,
  });

  const {
    data: nextMonthTotalAmountData,
    isError: nextMonthTotalAmountError,
    isLoading: nextMonthTotalAmountLoading,
    isRefetching: nextMonthTotalAmountRefetching,
  } = trpc.company.deposits.nextMonthTotalAmount.useQuery(undefined, {
    retry: false,
    retryOnMount: true,
    cacheTime: 60 * 1000,
    staleTime: 30 * 1000,
  });

  return {
    billetToPayCount: {
      count: toPayCountData !== undefined ? toPayCountData.toString() : '-',
      error: toPayCountError,
      loading: toPayCountLoading || toPayCountRefetching,
    },
    nextMonthTotalAmount: {
      amount:
        nextMonthTotalAmountData !== undefined
          ? toCurrency(-nextMonthTotalAmountData)
          : '-',
      error: nextMonthTotalAmountError,
      loading: nextMonthTotalAmountLoading || nextMonthTotalAmountRefetching,
    },
  };
}
