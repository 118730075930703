import { dispatchToast } from '../../../../utils/dispatchEvents';
import { RouterInputs } from '@frontend/trpc';
import { trpc } from '@frontend/trpc';

export type Input = RouterInputs['company']['employees']['refundBalance'];

export function useRefundUserBalance() {
  const context = trpc.useContext();
  const { isLoading: loading, mutate } =
    trpc.company.employees.refundBalance.useMutation({
      onSuccess: () => {
        dispatchToast({
          type: 'success',
          content: 'Ação realizada com sucesso!',
        });
      },
      onError: (error) => {
        dispatchToast({
          type: 'error',
          content: error.message,
        });
      },
    });

  return {
    refundUserBalance: (input: Input, callback?: () => void) => {
      mutate(input, {
        onSuccess: () => {
          context.company.cards.search.refetch(undefined);
          callback?.();
        },
      });
    },
    loading,
  };
}
