import { PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import React from 'react';
import Typography from '../Typography';
import * as SC from './styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  loading?: boolean;
  header: {
    noWrap?: boolean;
    title: React.ReactNode;
    subtitle: React.ReactNode;
  };
  OverHeader?: React.ReactNode;
  children: React.ReactNode;
};

export default function InfoModal(props: Props) {
  return (
    <SC.Modal
      open={props.isOpen}
      onClose={props.onClose}
      header={
        <SC.Header>
          <SC.CloseButtonWrapper>
            <PillButton
              size="medium"
              variant="default"
              icon="IconX"
              onClick={props.onClose}
            />
          </SC.CloseButtonWrapper>
          {props.OverHeader || (
            <SC.TitleWrapper noWrap={props.header.noWrap}>
              <Typography.Headline6 color="neutral_20">
                {props.header.title}
              </Typography.Headline6>
              <Typography.Body3 color="neutral_50">
                {props.header.subtitle}
              </Typography.Body3>
            </SC.TitleWrapper>
          )}
        </SC.Header>
      }
      children={<SC.Body>{props.children}</SC.Body>}
    />
  );
}
