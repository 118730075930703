import ModalService from '../../../../../../services/ModalService';
import { dispatchToast } from '../../../../../../utils/dispatchEvents';
import { trpc } from '@frontend/trpc';

export const useCancelDeposit = () => {
  const modalController = ModalService.useModalController();

  const { mutate: updateDepositMutation, isLoading: loading } =
    trpc.company.automaticCredit.cancel.useMutation();

  function cancelDeposit(
    employeeDepositIdList: string[],
    onCancel?: () => void,
  ) {
    return updateDepositMutation(
      {
        employeeDepositIdList,
        cancelled: true,
      },
      {
        onSuccess() {
          onCancel?.();
          modalController.remove();
          dispatchToast({
            type: 'success',
            content: 'A Recarga Automática foi cancelada com sucesso!',
          });
        },
        onError() {
          modalController.remove();
          dispatchToast({
            type: 'error',
            content:
              'Erro ao cancelar a Recarga Automática! Tente novamente mais tarde.',
          });
        },
      },
    );
  }

  return {
    cancelDeposit,
    loading: loading,
  };
};
