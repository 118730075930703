import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { ZodSchema, z } from 'zod';

/** Creates a react-hook-form based on a zod schema */
function useCreateForm<FormSchemaType extends ZodSchema>(
  FormSchema: FormSchemaType,
  formOptions?: Parameters<typeof useForm<z.infer<FormSchemaType>>>[0],
) {
  return useForm<z.infer<FormSchemaType>>({
    ...formOptions,
    resolver: zodResolver(FormSchema),
  });
}

const FormService = {
  useCreateForm,
};

export default FormService;
