import ModalService from '@frontend/services/ModalService';
import { trpc } from '@frontend/trpc';
import React from 'react';
import OnboardingFlashCashStartModal from '../modals/onboardingFlashCash/OnboardingFlashCashStartModal';

export default function useShowFlashCashOnboarding() {
  const { data } = trpc.notices.get.useQuery(
    {
      template: 'onboarding_flash-cash',
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );
  React.useEffect(() => {
    if (data?.show) ModalService.show(OnboardingFlashCashStartModal, {});
  }, [data?.show]);
}
