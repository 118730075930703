import { trpc } from '@frontend/trpc';

type Input = {
  policyId: string;
};

export function useGetCards(input: Input) {
  const cards = trpc.company.policies.cardLinks.getCards.useQuery({
    policyId: input.policyId,
  });

  return {
    data: cards.data ?? [],
    isLoading: cards.isLoading,
  };
}

export type UseGetCardsReturn = ReturnType<typeof useGetCards>;
