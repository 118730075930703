import {
  TagDateFilter,
  TagSliderRangeFilter,
} from '@flash-tecnologia/hros-web-ui-v2';
import { INITIAL_DATE, LIMIT_DATE, useCreditInfo } from './data/useCreditInfo';
import Table from '@frontend/components/TableV2';
import { managementColumns, pageSizeOptions } from './tableSetup';
import { toCurrency } from '@frontend/utils/masks';
import TagFilters from '@frontend/components/TagFilters';

export default function FailedCredit() {
  const {
    isFiltered,
    creditInfoData,
    loading,
    pagination,
    value,
    setPagination,
    filter,
    setFilters,
  } = useCreditInfo();

  return (
    <>
      <Table
        emptyState={{
          isFiltered,
          emptyText: 'Nenhum lançamento que falhou',
          filteredEmptyText: 'Não encontramos nenhum lançamento que falhou',
        }}
        pageSizeOptions={pageSizeOptions}
        columns={managementColumns}
        data={creditInfoData.credits}
        loading={loading}
        initialState={{
          pagination: {
            pageIndex: pagination.currentPage,
            pageSize: pagination.pageSize,
          },
        }}
        pageCount={creditInfoData.pagination.totalPages}
        onPaginationChange={({ pageIndex, pageSize }) => {
          setPagination({
            currentPage: pageIndex,
            pageSize,
          });
        }}
        TableFilters={
          <>
            <TagFilters.Checkbox
              label="Tipo de recarga"
              options={[
                {
                  label: 'Recarga única',
                  value: 'normal',
                },
                {
                  label: 'Recarga automática',
                  value: 'fixedDate',
                },
                {
                  label: 'Recarga automática complementar',
                  value: 'fixedValue',
                },
              ]}
              onApply={(value) => {
                setFilters((prevFilter) => {
                  return { ...prevFilter, type: Array.from(value) };
                });
              }}
            />
            <TagDateFilter
              variant="tertiary"
              filterLabel="Data de recarga"
              hasLeftIcon={false}
              options={[]}
              disabledDate={(date) => date.toISOString() > LIMIT_DATE.toISO()}
              onSubmit={(dates) => {
                const { from, to } = dates;
                setFilters({
                  date: {
                    from: from?.toISOString() || INITIAL_DATE.toISO(),
                    to: to?.toISOString() || LIMIT_DATE.toISO(),
                  },
                });
              }}
            />
            <TagSliderRangeFilter
              variant={filter?.value ? 'primary' : 'tertiary'}
              filterLabel="Valor de recarga"
              max={value?.max}
              min={value?.min}
              step={100}
              fieldMask={toCurrency}
              onApply={(values) => {
                const [min, max] = values;
                setFilters({
                  value: {
                    max: max !== undefined ? Number(max) : value?.max || 0,
                    min: min !== undefined ? Number(min) : value?.min || 0,
                  },
                });
              }}
            />
          </>
        }
      />
    </>
  );
}
