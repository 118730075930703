import FormService from '@frontend/services/FormService';
import ErrorMonitorService from '@frontend/services/MonitorService';
import { RouterOutputs } from 'backend';
import _isEmpty from 'lodash/isEmpty';
import React from 'react';
import { z } from 'zod';
import useCreatePolicy from '../data/useCreatePolicy';
import { FormSchema, PolicyLimit } from './schema';
import { toasts } from './toasts';

export type FormSchemaOutput = z.output<typeof FormSchema>;
export type LimitSchema = z.output<typeof PolicyLimit>;

type Input = {
  /** Callback for the submit success */
  onSuccess: (policy: RouterOutputs['company']['policies']['create']) => void;
};

export default function useForm(input: Input) {
  /* ------------------------------ Form setup ------------------------------ */
  const form = FormService.useCreateForm(FormSchema, {
    mode: 'onChange',
    defaultValues: {
      limitEnabled: false,
      mccGroups: {
        CONVENIENCE: true,
        CULTURE: true,
        EDUCATION: true,
        GROCERY: true,
        HEALTH: true,
        MEAL: true,
        MOBILITY: true,
      },
      weekdaysEnabled: {
        SUNDAY: true,
        MONDAY: true,
        TUESDAY: true,
        WEDNESDAY: true,
        THURSDAY: true,
        FRIDAY: true,
        SATURDAY: true,
      },
      withdrawEnabled: false,
    },
  });

  /* --------------------- Additional schema validations -------------------- */
  const mccGroups = form.watch('mccGroups');
  const mccLimits = form.watch('mccLimits') || {};
  const withdrawEnabled = form.watch('withdrawEnabled');
  React.useEffect(() => {
    Object.keys(mccLimits)
      .filter((mcc) => !mccGroups[mcc])
      .map((mcc: keyof typeof mccGroups) => {
        form.setValue(`mccLimits.${mcc}`, undefined);
      });

    if (!withdrawEnabled) {
      form.setValue('mccLimits.WITHDRAW', undefined);
    }
  }, [mccGroups, withdrawEnabled]);

  const limitType = form.watch('limitType');
  React.useEffect(() => {
    if (limitType === 'GENERAL') {
      form.setValue('limitEnabled', true);
      form.resetField('mccLimits');
    } else {
      form.resetField('limit.amount');
      form.resetField('limit.period');
      form.resetField('limit');
    }
  }, [limitType]);

  /* ------------------------ Schema validation check ----------------------- */
  const validSchema = form.formState.isValid && _isEmpty(form.formState.errors);

  /* ------------------------------ Form submit ----------------------------- */
  const createPolicy = useCreatePolicy();

  const onSubmit = form.handleSubmit(
    (values) => {
      void createPolicy.mutateAsync(values).then((result) => {
        if (result) {
          input.onSuccess(result);
        }
      });
    },
    (errors) => {
      // Schema validation
      ErrorMonitorService.error({
        message: 'Error while validating policy form schema',
        severity: 'fatal',
        extras: {
          zodErrors: JSON.stringify(errors),
        },
      });
      toasts.toastFormSchemaError();
    },
  );

  /* -------------------------------- Return -------------------------------- */
  return {
    ...form,
    isSubmitting: form.formState.isSubmitting || createPolicy.isLoading,
    onSubmit,
    result: createPolicy.data,
    validSchema,
  };
}

export type UseFormReturn = ReturnType<typeof useForm>;
