import Tag from '@frontend/components/TagV2';
import type { UseGetStatementOutput } from '../data/useGetStatement';

type TagProps<T extends React.Key> = Record<
  T,
  {
    label: string;
    variant: React.ComponentProps<typeof Tag>['variant'];
  }
>;

type Row = UseGetStatementOutput['items'][number];

const accountability = {
  PENDING: {
    label: 'Pendente',
    variant: 'info',
  },
  OPEN: {
    label: 'Em aberto',
    variant: 'info',
  },
  PROCESSING: {
    label: 'Aguardando processamento',
    variant: 'info',
  },
  SUBMITTED: {
    label: 'Submetido',
    variant: 'info',
  },
  APPROVED: {
    label: 'Aprovado',
    variant: 'success',
  },
  REFUNDED: {
    label: 'Reembolsado',
    variant: 'success',
  },
  DISAPPROVED: {
    label: 'Reprovado',
    variant: 'error',
  },
  NA: {
    label: 'N/A',
    variant: 'neutral',
  },
} as const satisfies TagProps<Row['accountability']>;

const movement = {
  VOUCHER_PURCHASE: {
    label: 'Compra em parceiros',
    variant: 'info',
  },
  OPEN_LOOP_PAYMENT: {
    label: 'Compra no cartão',
    variant: 'info',
  },
  BILLET_PAYMENT: {
    label: 'Pagamento de boleto',
    variant: 'info',
  },
  REVERSAL: {
    label: 'Débito empresa',
    variant: 'error',
  },
  DEPOSIT: {
    label: 'Depósito empresa',
    variant: 'success',
  },
  TED_CASH_OUT: {
    label: 'Transferência via TED',
    variant: 'primary',
  },
  PIX_CASH_OUT: {
    label: 'Transferência via PIX',
    variant: 'primary',
  },
  PIX_CASH_IN: {
    label: 'Depósito via PIX',
    variant: 'success',
  },
  OTHER: {
    label: 'Outros',
    variant: 'neutral',
  },
} as const satisfies TagProps<Row['movement']>;

const status = {
  CANCELED: { label: 'Cancelada', variant: 'neutral' },
  COMPLETED: { label: 'Completa', variant: 'success' },
  CONFIRMED: { label: 'Confirmada', variant: 'success' },
  CREATED: { label: 'Criada', variant: 'info' },
  DECLINED: { label: 'Recusada', variant: 'primary' },
  FAILED: { label: 'Falha', variant: 'error' },
  PARTIALLY_CANCELED: { label: 'Parcialmente Cancelada', variant: 'neutral' },
  PROCESSING: { label: 'Processando', variant: 'info' },
  SETTLED: { label: 'Liquidada', variant: 'success' },
  REVERTED: { label: 'Estornada', variant: 'neutral' },
} as const satisfies TagProps<Row['status']>;

export default {
  accountability,
  movement,
  status,
};
