import { Menu, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import useToggleActive from '../../../data/useToggleActive';

type Props = {
  cardStatus: 'active' | 'disabled';
  cardId: string;
};

export default function CardMenu(props: Props) {
  const toggleActive = useToggleActive();
  const options =
    props.cardStatus === 'active'
      ? [
          {
            label: 'Desativar Cartão Corporativo',
            onClick: () => toggleActive.mutate(props.cardId, false),
          },
        ]
      : [
          {
            label: 'Ativar Cartão Corporativo',
            onClick: () => toggleActive.mutate(props.cardId, true),
          },
        ];
  return (
    <Menu type="default" locked options={options}>
      <PillButton size="medium" variant="default" icon="IconDotsVertical" />
    </Menu>
  );
}
