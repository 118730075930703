import React from 'react';
import Spacer from '../../../components/Spacer';
import Table from '../../../components/TableV2';
import useSearchBillets from './data/searchBillets';
import columns from './table/columns';
import { paginationSetup } from './table';
import TableFilter from './table/TableFilter';
import { useParamsState } from '@frontend/utils/hooks/searchParams';
import { z } from 'zod';

const statusSchema = z.enum(['waiting', 'generating', 'paid', 'cancelled']);

export const paramsSchema = z.object({
  status: z
    .string()
    .transform((data) => z.array(statusSchema).catch([]).parse(data.split(',')))
    .optional(),
  startDate: z.coerce.date().optional().catch(undefined),
  endDate: z.coerce.date().optional().catch(undefined),
  pageIndex: z.coerce.number().nonnegative().default(0).catch(0),
  pageSize: z.coerce.number().positive().default(10).catch(10),
});

type Filter = z.infer<typeof paramsSchema>;

export default function Billet() {
  const [filters, setFilters] = useParamsState<Filter>(
    {
      ...paginationSetup.initial,
    },
    paramsSchema,
  );

  const { pageIndex, pageSize, ...filter } = filters;
  function handleUpdateFilter(newFilter: Filter) {
    setFilters({
      ...newFilter,
      pageIndex: 0,
      pageSize,
    });
  }

  const billets = useSearchBillets({
    filter: {
      ...filter,
      ...(filter.startDate &&
        filter.endDate && {
          createdAt: {
            from: filter.startDate,
            to: filter.endDate,
          },
        }),
    },
    pagination: {
      pageIndex,
      pageSize,
    },
  });

  return (
    <>
      <Spacer y="s" />
      <Table
        TableFilters={
          <TableFilter
            disabled={billets.loading}
            filter={filter}
            setFilter={handleUpdateFilter}
          />
        }
        columns={columns}
        data={billets.data?.data ?? []}
        emptyState={{
          isFiltered: !!Object.keys(filter).length,
          emptyText: 'Você ainda não criou faturas',
          filteredEmptyText: 'Não encontramos nenhuma fatura',
        }}
        pagination={{
          pageIndex,
          pageSize,
        }}
        onPaginationChange={(pagination) =>
          setFilters({ ...filters, ...pagination })
        }
        loading={billets.loading}
        pageCount={billets.data?.pagination.totalPages ?? 0}
        pageSizeOptions={paginationSetup.options}
      />
      <Spacer useMargin y="m" />
      <Spacer useMargin y="l" />
    </>
  );
}
