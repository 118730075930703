import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import Icon from '@frontend/components/Icon';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import ProgressBar from '@frontend/components/display/ProgressBar';
import React, { DragEventHandler } from 'react';
import mimeTypes, { type FileExtension } from './mimeTypes';
import * as SC from './styles';

type Props = {
  /** List of accepted file extensions */
  acceptedExtensions: FileExtension[];
  /** Callback fired when files are dropped inside the component. Returns a status that indicates if the upload was successful and valid */
  onUpload: (
    /** The file that was uploaded */
    _file: File,
    /** Function for updating the validation progress. Between 0 and 100 */
    _setProgress: (_progress: number) => void,
  ) => Promise<UploadStatus>;
  /** Callback fired when the component is reset */
  onReset?: () => void;
};

export type UploadStatus =
  | {
      success: true;
    }
  | {
      success: false;
      customError?: React.ReactNode;
    };

export default function FileUpload(props: Props) {
  /** Name of the currently uploaded file */
  const [fileName, setFileName] = React.useState<string>('');
  /** Whether the user is dragging a file over the component */
  const [isDraggingOver, setIsDraggingOver] = React.useState(false);
  /** Current upload progress. Between 0 and 100 (undefined if hasn't started) */
  const [progress, setProgress] = React.useState<number>();
  /** Current upload status (if it failed, has the error message) */
  const [uploadStatus, setUploadStatus] = React.useState<UploadStatus>();

  /** Callback fired when files are dropped inside the component */
  const onDrop: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    const uploadedFiles = Object.values(event.dataTransfer.files);
    if (!uploadedFiles.length) return;

    // Accepts only one file, by using the first one
    handleUpload(uploadedFiles[0]);
  };

  /** Opens the file input dialog */
  const openFileInputDialog = () => {
    document.getElementById('file-input')?.click();
  };

  /** Callback fired when a file is chosen from the file input dialog */
  const onChooseFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = Object.values(event.target.files || {});
    if (!uploadedFiles.length) return;

    // Accepts only one file, by using the first one
    handleUpload(uploadedFiles[0]);
  };

  /** Checks if the file is valid and calls the `onUpload` callback */
  const handleUpload = (uploadedFile: File) => {
    setProgress(0); // Starts the progress bar
    setFileName(uploadedFile.name);
    const validatedFile = validateFile({
      file: uploadedFile,
      acceptedExtensions: props.acceptedExtensions,
    });

    if (!validatedFile.success) handleUpdateStatus(validatedFile);
    else {
      props
        .onUpload(uploadedFile, setProgress)
        .then((result) => handleUpdateStatus(result))
        .catch(() =>
          handleUpdateStatus({
            success: false,
            customError: 'Não foi possível validar o arquivo.',
          }),
        );
    }
  };

  /** Updates the upload status and resets the progress bar */
  const handleUpdateStatus = (status: UploadStatus | undefined) => {
    setUploadStatus(status);
    setProgress(undefined);
  };

  const handleReset = () => {
    setFileName('');
    handleUpdateStatus(undefined);
    props.onReset?.();
  };

  if (!uploadStatus && progress === undefined) {
    /* ---------------- Default state (waiting for an upload) --------------- */
    return (
      <SC.Container
        status={isDraggingOver ? 'ACTIVE' : 'DEFAULT'}
        onDrop={onDrop}
        onDragOver={(e) => e.preventDefault()}
        onDragEnter={() => setIsDraggingOver(true)}
        onDragLeave={() => setIsDraggingOver(false)}
      >
        <Icon
          name="IconCloudUpload"
          size={64}
          color="secondary_50"
          background="default"
        />
        <Spacer y={'xs3'} />
        <Typography.Body3 color="secondary_50" weight={700}>
          Envie seu arquivo
        </Typography.Body3>
        <Typography.Body3 color="neutral_30" weight={600}>
          Formatos permitidos: {props.acceptedExtensions.join(', ')}
        </Typography.Body3>
        <Spacer y={'xs'} />
        <Typography.Body3 color="neutral_30" weight={400}>
          Arraste e solte aqui, ou
        </Typography.Body3>
        <Spacer y={'xs4'} />
        <LinkButton
          style={{ alignSelf: 'center' }}
          variant="default"
          onClick={openFileInputDialog}
        >
          <input
            id="file-input"
            type="file"
            style={{ display: 'none' }}
            onChange={onChooseFile}
          />
          <Icon name="IconFileUpload" size={24} color="secondary_50" />
          Selecione seu arquivo
        </LinkButton>
      </SC.Container>
    );
  } else if (!uploadStatus && progress !== undefined) {
    /* ---------------------------- Uploading file ---------------------------- */
    return (
      <SC.Container status="DEFAULT">
        <Icon
          name="IconFileDescription"
          size={64}
          color="secondary_50"
          background="default"
        />
        <Spacer y={'xs3'} />
        <Typography.Body3 color="neutral_30" weight={600}>
          {fileName}
        </Typography.Body3>
        <Spacer y={'xs'} />
        <Typography.Body3 color="neutral_30" weight={400}>
          Enviando o arquivo...
        </Typography.Body3>
        <Spacer y={'xs'} />
        <ProgressBar progress={progress} />
      </SC.Container>
    );
  } else if (uploadStatus?.success) {
    /* ---------------------------- File uploaded ---------------------------- */
    return (
      <SC.Container status="SUCCESS">
        <Icon
          name="IconCheck"
          size={64}
          color="success_40"
          background="success"
        />
        <Spacer y={'xs3'} />
        <Typography.Body3 color="neutral_30" weight={600}>
          {fileName}
        </Typography.Body3>
        <Spacer y={'xs'} />
        <Typography.Body3 color="neutral_30" weight={400}>
          Arquivo enviado com sucesso!
        </Typography.Body3>
        <Spacer y={'xs'} />
        <ProgressBar progress={100} />
      </SC.Container>
    );
  }
  /* ----------------------------- Upload failed ---------------------------- */
  return (
    <SC.Container status="ERROR">
      <Icon name="IconX" size={64} color="error_40" background="error" />
      <Spacer y={'xs3'} />
      <Typography.Body3 color="neutral_30" weight={600}>
        {fileName}
      </Typography.Body3>
      <Spacer y={'xs'} />
      <Typography.Body3 color="neutral_30" weight={400} center>
        {uploadStatus?.customError ?? 'Erro no envio do seu arquivo'}
      </Typography.Body3>
      <Spacer y={'xs'} />
      <LinkButton
        style={{ alignSelf: 'center' }}
        variant="error"
        onClick={handleReset}
      >
        Tentar novamente
      </LinkButton>
    </SC.Container>
  );
}

function validateFile(input: {
  file: File;
  acceptedExtensions: FileExtension[];
}): UploadStatus {
  /** Accepted MIME types, based on the `acceptedExtensions` prop */
  const acceptedMimeTypes = input.acceptedExtensions.map(
    (ext) => mimeTypes[ext],
  );

  const fileMimeType = input.file.type;
  if (!fileMimeType || !acceptedMimeTypes.includes(fileMimeType))
    return {
      success: false,
      customError: 'Tipo de arquivo inválido',
    } as const;
  return { success: true } as const;
}
