import { useTheme } from 'styled-components';

/** Provides a flat access to the color palette, making it easier to use as props */
export default function useColors() {
  const theme = useTheme();
  return {
    // Error
    error_10: theme.colors.feedback.error[10],
    error_40: theme.colors.feedback.error[40],
    error_50: theme.colors.feedback.error[50],
    error_70: theme.colors.feedback.error[70],
    error_90: theme.colors.feedback.error[90],
    // Info
    info_10: theme.colors.feedback.info[10],
    info_40: theme.colors.feedback.info[40],
    info_50: theme.colors.feedback.info[50],
    info_70: theme.colors.feedback.info[70],
    info_90: theme.colors.feedback.info[90],
    // Neutral
    neutral_0: theme.colors.neutral[0],
    neutral_10: theme.colors.neutral[10],
    neutral_20: theme.colors.neutral[20],
    neutral_30: theme.colors.neutral[30],
    neutral_40: theme.colors.neutral[40],
    neutral_50: theme.colors.neutral[50],
    neutral_60: theme.colors.neutral[60],
    neutral_70: theme.colors.neutral[70],
    neutral_80: theme.colors.neutral[80],
    neutral_90: theme.colors.neutral[90],
    neutral_95: theme.colors.neutral[95],
    neutral_100: theme.colors.neutral[100],
    // Primary
    primary: theme.colors.primary,
    // Secondary
    secondary_10: theme.colors.secondary[10],
    secondary_20: theme.colors.secondary[20],
    secondary_30: theme.colors.secondary[30],
    secondary_40: theme.colors.secondary[40],
    secondary_50: theme.colors.secondary[50],
    secondary_70: theme.colors.secondary[70],
    secondary_80: theme.colors.secondary[80],
    secondary_90: theme.colors.secondary[90],
    secondary_95: theme.colors.secondary[95],
    secondary_99: theme.colors.secondary[99],
    success_10: theme.colors.feedback.success[10],
    success_40: theme.colors.feedback.success[40],
    success_50: theme.colors.feedback.success[50],
    success_70: theme.colors.feedback.success[70],
    success_90: theme.colors.feedback.success[90],
  } as const;
}

/** Useful for the storybook control settings */
export const ColorOptions = [
  'error_10',
  'error_40',
  'error_50',
  'error_70',
  'error_90',
  'info_10',
  'info_40',
  'info_50',
  'info_70',
  'info_90',
  'neutral_0',
  'neutral_10',
  'neutral_20',
  'neutral_30',
  'neutral_40',
  'neutral_50',
  'neutral_60',
  'neutral_70',
  'neutral_80',
  'neutral_90',
  'neutral_95',
  'neutral_100',
  'primary',
  'secondary_10',
  'secondary_20',
  'secondary_30',
  'secondary_40',
  'secondary_50',
  'secondary_70',
  'secondary_80',
  'secondary_90',
  'secondary_95',
  'secondary_99',
  'success_10',
  'success_40',
  'success_50',
  'success_70',
  'success_90',
] as const satisfies Readonly<Color[]>;

export type Color = keyof ReturnType<typeof useColors>;
