import {
  PageContainer,
  PillButton,
  Spinner,
  Tab,
  Tooltip,
} from '@flash-tecnologia/hros-web-ui-v2';
import Spacer from '@frontend/components/Spacer';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import Breadcrumbs from '@frontend/components/display/Breadcrumbs';
import Flex from '@frontend/components/frames/Flex';
import { ROUTE_CompanyCustomization } from '@frontend/routes/ExternalRoutes';
import ModalService from '@frontend/services/ModalService';
import RouterService from '@frontend/services/RouterService';
import errors from '@frontend/utils/commonTexts/errors';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import { ROUTE_PolicyDetails } from '.';
import usePatchActive from '../data/usePatchActive';
import EditPolicy from '../focusedFlows/EditPolicy';
import DeletePolicyModal from '../modals/DeletePolicyModal';
import useGetPolicy from './data/useGetPolicy';
import { LinkedCards } from './tabs/LinkedCards/LinkedCards';
import { PolicyData } from './tabs/PolicyData/PolicyData';

export default function PolicyDetails() {
  const navigate = RouterService.useNavigate();
  const policyId = RouterService.useParams(ROUTE_PolicyDetails).policyId;
  const policy = useGetPolicy({ policyId });
  const patchActive = usePatchActive();

  if (!policy.data) {
    if (!policy.isLoading) {
      // No data available
      dispatchToast({
        type: 'error',
        content: 'Dados da política não encontrados',
        description: errors.actions.toastDescription,
      });
      navigate(-1);
    }
    return (
      <Flex align="center" justify="center" style={{ height: '100%' }}>
        <Spinner size={64} variant="primary" />
      </Flex>
    );
  }

  return (
    <PageContainer>
      <Spacer y="s" />
      <Breadcrumbs
        breadcrumbs={[
          { label: 'Gerenciar empresa' },
          {
            label: 'Políticas',
            onClick: () =>
              navigate({
                route: ROUTE_CompanyCustomization,
                params: {},
                searchParams: { tab: 'corporate-card' },
                state: {},
              }),
          },
          { label: 'Detalhes da política' },
        ]}
      />
      <Spacer y="s" />
      <Flex align="center" justify="space-between">
        <div>
          <Flex gap="xs4" align="center">
            <Typography.Headline6>{policy.data.title}</Typography.Headline6>
            <Tag
              dot
              size="md"
              variant={policy.data.active ? 'success' : 'neutral'}
            >
              {policy.data.active ? 'Ativo' : 'Desativado'}
            </Tag>
          </Flex>
          <Typography.Body4 color="neutral_40" weight={600}>
            {policy.data.description}
          </Typography.Body4>
        </div>
        <Flex gap="xs3">
          <Tooltip title="Excluir política">
            <div>
              <PillButton
                size="medium"
                variant="default"
                icon="IconTrash"
                onClick={() => {
                  if (policy.data) {
                    ModalService.show(DeletePolicyModal, {
                      policyId: policy.data._id,
                      onSuccess: () => {
                        navigate(-1);
                      },
                    });
                  }
                }}
              />
            </div>
          </Tooltip>
          <Tooltip
            title={
              policy.data.active ? 'Desativar política' : 'Ativar política'
            }
          >
            <div>
              <PillButton
                size="medium"
                variant="default"
                icon="IconToggleLeft"
                onClick={() => {
                  if (policy.data) {
                    patchActive.mutateAsync({
                      cardIds: policy.data.cardIds,
                      id: policy.data._id,
                      newActiveValue: !policy.data.active,
                    });
                  }
                }}
              />
            </div>
          </Tooltip>
          <Tooltip title="Editar política">
            <div>
              <PillButton
                size="medium"
                variant="default"
                icon="IconPencil"
                onClick={() => {
                  if (policy.data) {
                    ModalService.show(EditPolicy, {
                      policyId: policy.data._id,
                    });
                  }
                }}
              />
            </div>
          </Tooltip>
        </Flex>
      </Flex>
      <Spacer y="xs" />
      <Tab
        tabItens={[
          { label: 'Política', component: <PolicyData policy={policy.data} /> },
          {
            label: `Cartões vinculados (${policy.data.cardIds.length})`,
            component: <LinkedCards />,
          },
        ]}
        defaultTab={0}
      />
      <Spacer y="s" />
    </PageContainer>
  );
}
