import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import ModalService from '@frontend/services/ModalService';
import useUnlinkPolicy from './data/useUnlinkPolicy';

type Props = {
  cardId: string;
  policyId: string;
};

function UnlinkPolicyModal(props: Props) {
  const controller = ModalService.useModalController();
  const unlinkPolicy = useUnlinkPolicy({
    cardId: props.cardId,
    policyId: props.policyId,
    onSuccess: handleClose,
  });

  function handleClose() {
    controller.remove();
  }

  function handleUnlink() {
    unlinkPolicy.mutateAsync();
  }

  return (
    <ConfirmationModal
      buttons={{
        cancel: {
          text: 'Cancelar',
          onClick: handleClose,
        },
        confirm: {
          text: 'Confirmar desvinculação',
          onClick: handleUnlink,
        },
      }}
      isOpen={controller.visible}
      onClose={handleClose}
      loading={unlinkPolicy.isLoading}
      variant="error"
      text={{
        highlightedText: 'Atenção!',
        title:
          'Tem certeza que deseja desvincular a política de uso do cartão?',
        body: 'Ao desvincular a política, o cartão perderá as regras de uso definidas e poderá ser utilizado para realizar qualquer tipo de transação',
      }}
    />
  );
}

export default ModalService.create(UnlinkPolicyModal);
