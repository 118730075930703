import { IconTypes, Radio } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import Icon from '@frontend/components/Icon';
import Spacer from '@frontend/components/Spacer';
import Typography from '../../Typography';
import * as SC from './styled';

type Props = {
  disabled?: boolean;
  selected: boolean;
  onClick: () => void;
  metadata: {
    title: React.ReactNode;
    description: string;
    icon?: IconTypes;
  };
  errorMessage?: string;
  children?: React.ReactNode;
};

export default function RadioCard(props: Props) {
  return (
    <div>
      <SC.RadioCardContainer
        status={
          props.disabled ? 'DISABLED' : props.selected ? 'ACTIVE' : 'NEUTRAL'
        }
        onClick={() => {
          if (!props.disabled) props.onClick();
        }}
      >
        <Flex gap="xs3">
          <SC.LeftSide>
            <Radio checked={props.selected} />
            {props.metadata.icon && (
              <Icon
                background={props.disabled ? 'neutral' : 'default'}
                color={props.disabled ? 'neutral_40' : 'secondary_50'}
                name={props.metadata.icon}
                size={48}
              />
            )}
          </SC.LeftSide>
          <SC.RightSide>
            <Typography.Body3
              color={props.selected ? 'secondary_50' : 'neutral_20'}
              weight={700}
            >
              {props.metadata.title}
            </Typography.Body3>
            <Typography.Body4 color="neutral_30">
              {props.metadata.description}
            </Typography.Body4>
          </SC.RightSide>
        </Flex>
        {!!props.children && (
          <>
            <Spacer y="xs" />
            {props.children}
          </>
        )}
      </SC.RadioCardContainer>
      {props.errorMessage && (
        <>
          <Spacer y="xs5" />
          <SC.ErrorMessageArea>
            <Icon color="error_40" name="IconInfoCircle" size={16} />
            <Typography.Body4 color="neutral_40">
              {props.errorMessage}
            </Typography.Body4>
          </SC.ErrorMessageArea>
        </>
      )}
    </div>
  );
}
