import { useMatch, useNavigate } from 'react-router-dom';

export function useTabPage(basePath: string, routesMap: Array<string>) {
  const match = useMatch(`${basePath}/:key`);
  const navigate = useNavigate();

  const matchEntry =
    match?.params.key !== undefined
      ? routesMap.findIndex((route) => route === match?.params.key)
      : 0;
  const currentTab = matchEntry >= 0 ? matchEntry : 0;
  return {
    currentTab,
    setParams: (index: number) => navigate(`${basePath}/${routesMap[index]}`),
  };
}
