import Typography from '@frontend/components/Typography';
import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import ModalService from '@frontend/services/ModalService';
import OnboardingPoliciesTutorialModal from './OnboardingPoliciesTutorialModal';

function OnboardingPoliciesStartModal() {
  const modalController = ModalService.useModalController();
  return (
    <ConfirmationModal
      isOpen={modalController.visible}
      onClose={modalController.remove}
      text={{
        highlightedText: 'Novidade!',
        title: 'Políticas de cartões corporativos Flash',
        body: (
          <Typography.Body3 center color="neutral_40">
            Agora sua empresa pode definir regras de uso para os cartões
            corporativos da Flash, tendo assim um maior controle sobre os tipos
            e limites de gastos permitidos.
            <br />
            <br />
            Quer entender como? Vamos te mostrar!
          </Typography.Body3>
        ),
      }}
      variant="default"
      buttons={{
        cancel: {
          onClick: () => {
            modalController.remove();
          },
          text: 'Não, obrigado',
          variant: 'default',
        },
        confirm: {
          text: 'Vamos lá',
          onClick: () => {
            modalController.remove();
            ModalService.show(OnboardingPoliciesTutorialModal, {});
          },
        },
      }}
    ></ConfirmationModal>
  );
}

export default ModalService.create(OnboardingPoliciesStartModal);
