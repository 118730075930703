export function showChatbot() {
  const chatbotDiv = document.getElementById('blip-chat-container');
  if (chatbotDiv) {
    chatbotDiv.style.display = 'block';
  }
}

export function hideChatbot() {
  const chatbotDiv = document.getElementById('blip-chat-container');
  if (chatbotDiv) {
    chatbotDiv.style.display = 'none';
  }
}
