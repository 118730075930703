import { RouterInputs, trpc } from '@frontend/trpc';
import ModalService from '@frontend/services/ModalService';
import CreatePolicySuccessModal from '../modals/CreatePolicySuccessModal';
import CreatePolicyErrorModal from '../modals/CreatePolicyErrorModal';

export default function useCreatePolicy() {
  const createPolicy = trpc.company.policies.create.useMutation();
  const context = trpc.useContext();

  return {
    /** Creates a new policy
     *
     * Returns policy data if successful, void otherwise */
    mutateAsync: async (
      input: RouterInputs['company']['policies']['create'],
    ) => {
      const exec = async () => {
        return createPolicy
          .mutateAsync(input)
          .then((data) => {
            // Invalidates cache for the policy search
            context.company.policies.search.invalidate();
            ModalService.show(CreatePolicySuccessModal, {});
            return data;
          })
          .catch(() => {
            ModalService.show(CreatePolicyErrorModal, { retryCreation: exec });
          });
      };

      return exec();
    },
    data: createPolicy.data,
    isLoading: createPolicy.isLoading,
  };
}

export type UseCreatePolicyReturn = ReturnType<typeof useCreatePolicy>;
