import useGetStatement from './data/useGetStatement';
import { ROUTE_ExternalCardDetails } from '.';
import RouterService from '@frontend/services/RouterService';
import useTagFilters from './table/useTagFilters';
import useSearchFilter from './table/useSearchFilter';
import TableClient from '@frontend/components/TableClient';
import columns from './table/columns';
import { PageContainer } from '@flash-tecnologia/hros-web-ui-v2';
import EmployeeDataBox from './components/EmployeeDataBox';
import Spacer from '@frontend/components/Spacer';

export default function () {
  const { cardId } = RouterService.useParams(ROUTE_ExternalCardDetails);
  const statement = useGetStatement({ cardId });
  const tagFilters = useTagFilters({
    metadata: statement.metadata,
    disableAll: !statement.items.length,
  });
  const searchFilter = useSearchFilter({
    disable: !statement.items.length,
  });

  return (
    <PageContainer>
      <Spacer y="l" />
      <EmployeeDataBox cardId={cardId} />
      <TableClient
        columns={columns}
        data={statement.items}
        emptyState={{
          emptyText: 'A pessoa ainda não possui transações vinculadas',
          filteredEmptyText: 'Nenhuma transação encontrada',
        }}
        loading={statement.isLoading}
        TableFilters={tagFilters.FilterComponent}
        filter={tagFilters.formattedFilter}
        TableSearchFilter={searchFilter.SearchFilterComponent}
        searchFilter={searchFilter.appliedFilter}
        enablePagination
      />
      <Spacer y="xl2" />
    </PageContainer>
  );
}
