import DefaultModal from '@frontend/components/modals/DefaultModal';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import ModalService from '@frontend/services/ModalService';

function AboutComplementaryDeposit() {
  const controller = ModalService.useModalController();

  return (
    <DefaultModal
      header={{
        title: 'Como funciona o depósito automático complementar',
        subtitle: null,
      }}
      buttons={{
        confirm: { text: 'Entendi', onClick: () => controller.remove() },
      }}
      isOpen={controller.visible}
      onClose={() => controller.remove()}
    >
      <Typography.Body3 color={'neutral_30'}>
        Ao ativar a opção <b>Função complementar</b> para depósitos automáticos,
        o valor determinado como saldo para alguém na planilha representará o
        saldo máximo que essa pessoa pode ter em seu cartão. Se a pessoa já
        tiver um saldo corporativo, usaremos o valor do depósito para
        complementar o saldo existente, garantindo que o total não ultrapasse o
        valor máximo estabelecido.
      </Typography.Body3>
      <Spacer y="xs2" />
      <Typography.Body3 color={'neutral_30'}>
        Por exemplo: se você definiu um depósito de <b>R$1.000,00</b> que será
        disponibilizado todo dia 30 para alguém e, chegando o dia 30, essa
        pessoa ainda tiver <b>R$300,00</b> de saldo corporativo em seu cartão, o
        depósito automático complementar será de <b>R$700,00</b>. Esse valor é
        calculado para complementar o saldo atual e atingir o valor máximo de{' '}
        <b>R$1.000,00</b>.
      </Typography.Body3>
    </DefaultModal>
  );
}

export default ModalService.create(AboutComplementaryDeposit);
