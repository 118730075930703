import {
  Button,
  PillButton,
  tableControllers,
} from '@flash-tecnologia/hros-web-ui-v2';
import Table from '@frontend/components/DSTable';
import Icon from '@frontend/components/Icon';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import Flex from '@frontend/components/frames/Flex';
import { RouterInputs } from '@frontend/trpc';
import React from 'react';
import * as styled from '../styled';
import useReportSearch from './data/useReportSearch';
import { useColumns } from './table/columns';

type Props = {
  component?: React.ReactNode;
  description: React.ReactNode;
  template: string;
  leftIcon: React.ComponentProps<typeof Icon>['name'];
  title: React.ReactNode;
  retryReportCreation: (_: CreateReportInput | RetryCreateReportInput) => void;
  onClick: () => void;
};

type CreateReportInput = RouterInputs['company']['reports']['create'] & {
  action: 'create';
};
type RetryCreateReportInput = RouterInputs['company']['reports']['retry'] & {
  action: 'retry';
};

const pagination = { pageSize: 100, pageNumber: 1 };

export default function ReportCard(props: Props) {
  const [open, setOpen] = React.useState(false);

  const reportSearch = useReportSearch({
    template: props.template,
    enabled: open,
  });

  const table = tableControllers.useTableColumns({
    columns: useColumns({
      retry: props.retryReportCreation,
    }),
    data: reportSearch.data.entries,
    total: reportSearch.data.total,
    pagination,
    onPaginationChange: () => {},
  });

  return (
    <div>
      <styled.TopBorderedSection open={open} padding={'xs'} variant={'neutral'}>
        <Flex direction="row" justify="space-between" align="center" gap="l">
          <Flex align="center" gap="xs1">
            <Icon
              name={props.leftIcon}
              color="secondary_50"
              background="default"
              size={48}
            />
            <Flex direction="column" style={{ flex: 1 }}>
              <Typography.Body3 weight={600} color={'neutral_30'}>
                {props.title}
              </Typography.Body3>
              <Typography.Body4 weight={400} color={'neutral_50'}>
                {props.description}
              </Typography.Body4>
              {!!props.component && (
                <>
                  <Spacer y="xs" />
                  {props.component}
                </>
              )}
            </Flex>
          </Flex>
          <Flex direction="row" align="center" gap="xs3">
            <Button
              variant="secondary"
              size="medium"
              onClick={props.onClick}
              style={{ whiteSpace: 'nowrap', alignSelf: 'unset' }}
            >
              Criar Report
              <Icon name="IconFileDownload" color="secondary_50" />
            </Button>
            <div>
              <PillButton
                icon={open ? 'IconChevronUp' : 'IconChevronDown'}
                size="small"
                variant="default"
                type="primary"
                onClick={() => setOpen((prevState) => !prevState)}
              />
            </div>
          </Flex>
        </Flex>
      </styled.TopBorderedSection>
      {open && (
        <styled.BottomBorderedSection padding={'xs'} variant={'neutral'}>
          <Typography.Headline9>
            Últimos reports solicitados
          </Typography.Headline9>
          <Spacer y="xs" />
          <Table
            emptyState={{
              emptyText: 'Nenhum report gerado recentemente',
              filteredEmptyText: 'Nenhum report encontrado no período',
              isFiltered: false,
            }}
            loading={reportSearch.loading}
            getHeaderGroups={table.getHeaderGroups}
            rows={table.rows}
          />
        </styled.BottomBorderedSection>
      )}
    </div>
  );
}
