import { Button, LinkButton, Spinner } from '@flash-tecnologia/hros-web-ui-v2';
import Spacer from '@frontend/components/Spacer';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import BorderedSection from '@frontend/components/frames/BorderedSection';
import Flex from '@frontend/components/frames/Flex';
import SelectField from '@frontend/components/inputs/SelectField';
import { ROUTE_CompanyCustomization } from '@frontend/routes/ExternalRoutes';
import { Controller } from 'react-hook-form';
import { useGetPolicies } from '../data/useGetPolicies';
import type { UseFormReturn } from '../form/useForm';

type Props = {
  form: UseFormReturn;
};

export function PolicySetup(props: Props) {
  const policies = useGetPolicies();
  return (
    <BorderedSection padding="xs">
      <Flex direction="row" align="center" justify="space-between">
        <Flex gap="xs4" direction="row" align="center">
          <Typography.Headline8 color="neutral_30">
            Política de uso do cartão
          </Typography.Headline8>
          <Tag size="md" variant="neutral">
            Opcional
          </Tag>
        </Flex>
        <LinkButton
          variant="default"
          target="_blank"
          href={ROUTE_CompanyCustomization.buildPath(
            {},
            { tab: 'corporate-card' },
          )}
        >
          Ver políticas
        </LinkButton>
      </Flex>
      <Spacer y="xs4" />
      <Typography.Body4>
        Vincule ao cartão corporativo uma política de uso definida pela empresa.
      </Typography.Body4>
      <Spacer y="xs" />
      {policies.isLoading ? (
        <Flex justify="center" padding="s" style={{ width: '100%' }}>
          <Spinner size={48} variant="primary" />
        </Flex>
      ) : !!policies.data.length ? (
        <Controller
          control={props.form.control}
          name="policyId"
          render={(ctx) => (
            <SelectField
              label="Selecione uma política"
              variant="outlined"
              value={ctx.field.value}
              onChange={ctx.field.onChange}
              options={[
                { label: 'Nenhuma', value: undefined },
                ...policies.data,
              ]}
            />
          )}
        />
      ) : (
        <BorderedSection padding="xs" backgroundColor="neutral">
          <Flex direction="column" align="center">
            <Typography.Headline9 color="neutral_20">
              Nenhuma política de uso do cartão corporativo foi adicionada
              ainda.
            </Typography.Headline9>
            <Spacer y="xs4" />
            <Typography.Body4 color="neutral_40">
              Gostaria de adicionar uma agora? Ela poderá ser aplicada
              futuramente em novos cartões corporativos, assim como em cartões
              que já foram cadastrados.
            </Typography.Body4>
            <Spacer y="xs2" />
            <Button
              size="medium"
              variant="secondary"
              style={{ alignSelf: 'unset' }}
              onClick={() => {
                window.open('policies', '_blank')?.focus();
              }}
            >
              Adicionar política
            </Button>
            <Spacer y="xs3" />
            <LinkButton
              variant="neutral"
              style={{ alignSelf: 'unset' }}
              onClick={() => void policies.refetch()}
              disabled={policies.isRefetching}
            >
              Recarregar
            </LinkButton>
          </Flex>
        </BorderedSection>
      )}
    </BorderedSection>
  );
}
