import { DatePicker, SelectField } from '@flash-tecnologia/hros-web-ui-v2';
import { DateTime } from 'luxon';

const EXPIRATION_RULES = {
  DAYS: 'DAYS',
  MONTHS: 'MONTHS',
  CUSTOM: 'CUSTOM',
};

const MONTHS = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

const todayConstant = DateTime.now().startOf('day');
const today = todayConstant.toJSDate();
const fiveYears = todayConstant.plus({ years: 5 }).toJSDate();

const actualMonth = todayConstant.month;
const actualYear = todayConstant.year;

const monthOptions: { label: string; value: string }[] = [];

MONTHS.forEach((_, index) => {
  const month = (actualMonth + index - 1) % 12;
  const label = `${MONTHS[month]} / ${
    month + 1 >= actualMonth ? actualYear : actualYear + 1
  }`;
  monthOptions.push({
    label,
    value: (month + 1).toString(),
  });
});

const DAYS = [1, 2, 3, 4, 5, 6, 7].map((days) => ({
  label: days.toString(),
  value: days.toString(),
}));

type ExpirationDefinitionProps = {
  expirationRule: 'MONTHS' | 'DAYS' | 'CUSTOM';
  expirationMetadata: string;
  setExpirationMetadata: (e: unknown) => void;
  error?: string;
};
export const ExpirationDefinition = ({
  expirationRule,
  expirationMetadata,
  setExpirationMetadata,
  error,
}: ExpirationDefinitionProps) => {
  if (!expirationRule)
    return (
      <SelectField
        fullWidth={true}
        label="Definição"
        disabled={true}
        options={[]}
        error={!!error}
        helperText={error}
      />
    );

  if (expirationRule === EXPIRATION_RULES.CUSTOM)
    return (
      <DatePicker
        onDateChange={(date) => setExpirationMetadata(date?.toISOString())}
        value={expirationMetadata}
        label="Data de expiração"
        disabledDate={{
          before: today,
          after: fiveYears,
        }}
        error={!!error}
        helperText={error}
      />
    );

  return (
    <SelectField
      fullWidth={true}
      label="Definição"
      value={expirationMetadata}
      options={expirationRule === EXPIRATION_RULES.MONTHS ? monthOptions : DAYS}
      onSelectChange={(_event, { value }) => setExpirationMetadata(value)}
      error={!!error}
      helperText={error}
    />
  );
};
