import ModalService from '@frontend/services/ModalService';

export function getActionsByView({
  createCorporateCardUserMetadata,
  selectedView,
  setSelectedView,
  createCorporateCardUser,
  form,
}) {
  const modalController = ModalService.useModalController();

  const { cardIssuer } = createCorporateCardUserMetadata;

  const initialViewActions = {
    confirm: {
      onClick: () => cardIssuer && setSelectedView('DETAILS_VIEW'),
      text: 'Confirmar',
    },
    cancel: {
      onClick: () => modalController.remove(),
      text: 'Cancelar',
    },
  };

  const detailsViewActions = {
    confirm: {
      disabled: !form.formState.isValid,
      onClick: () => createCorporateCardUser(cardIssuer),
      text: 'Adicionar',
    },
    cancel: {
      onClick: () => modalController.remove(),
      text: 'Cancelar',
    },
  };

  return {
    INITIAL_VIEW: initialViewActions,
    DETAILS_VIEW: detailsViewActions,
  }[selectedView];
}
