import ModalService from '../../../../../services/ModalService';
import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';

type Props = {
  total?: number;
};

function AutomaticCreditSetWarningModal({ total }: Props) {
  const modalController = ModalService.useModalController();

  return (
    <ConfirmationModal
      isOpen={modalController.visible}
      onClose={modalController.remove}
      variant="error"
      text={{
        highlightedText: 'Atenção!',
        title:
          total === 1
            ? 'Este colaborador possui recarga agendada.'
            : 'Alguns destes colaboradores possuem recarga agendada',
        body: (
          <>
            {total === 1
              ? 'Este colaborador possui recarga agendada. É preciso que cancele a recarga para poder desativá-lo.'
              : 'Alguns colaboradores possuem recarga agendada. É preciso que cancele todas as recargas para poder desativá-los'}
          </>
        ),
      }}
    />
  );
}

export default ModalService.create(AutomaticCreditSetWarningModal);
