import Tag from '@frontend/components/TagV2';

type Props = {
  code?: string;
  translated?: string;
};

function accountabilityVariant(code: string) {
  switch (code) {
    case 'Open':
    case 'Submitted':
    case 'WaitingFinanceProcess':
    case 'ProcessingPayment':
    case 'ErrorProcessingPayment':
      return 'info';

    case 'Approved':
    case 'Refunded':
      return 'success';

    case 'Disapproved':
      return 'error';

    case 'None':
    case 'NA':
    case 'Reclassified':
    default:
      return 'neutral';
  }
}

export default function AccountabilityStatusTag(props: Props) {
  if (!props.code || !props.translated) return null;
  return (
    <Tag size="sm" variant={accountabilityVariant(props.code)}>
      {props.translated}
    </Tag>
  );
}
