import styled from 'styled-components';
type ContainerProps = {
  status: 'ACTIVE' | 'DEFAULT' | 'ERROR' | 'SUCCESS';
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 60px;
  border: 1px solid;
  border-radius: ${({ theme }) => theme.borders.radius.l};

  border-color: ${({ theme, status }) => {
    switch (status) {
      case 'ACTIVE':
      case 'DEFAULT':
        return theme.colors.secondary[50];
      case 'ERROR':
        return theme.colors.feedback.error[70];
      case 'SUCCESS':
        return theme.colors.feedback.success[40];
    }
  }};
  background-color: ${({ theme, status }) => {
    switch (status) {
      case 'ACTIVE':
        return theme.colors.secondary[95];
      case 'DEFAULT':
        return theme.colors.secondary[99];
      case 'ERROR':
      case 'SUCCESS':
        return theme.colors.neutral[100];
    }
  }};
`;
