import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  width: 100%;
  max-width: 588px;
  margin: auto;
  gap: 32px;
`;

export const HeaderContainer = styled.div`
  display: grid;
  justify-items: center;
`;
