import CurrencyUtils from '@frontend/utils/currencyUtils';
import { z } from 'zod';

export enum ParsingErrors {
  INSUFFICIENT_VALUE = 'INSUFFICIENT_VALUE',
  INVALID_DOCUMENT_NUMBER = 'INVALID_DOCUMENT_NUMBER',
  INVALID_VALUE = 'INVALID_VALUE',
  GENERIC_ERROR = 'GENERIC_ERROR',
}

/** Parses the imported spreadsheet data, and splits it into valid and invalid rows */
export default function parseSpreadsheet(input: object[]) {
  const data: DepositSchemaOutput[] = [];
  const errors: Record<ParsingErrors, number[]> = {
    // Document number (CPF)
    INVALID_DOCUMENT_NUMBER: [],
    // Value (money amount)
    INSUFFICIENT_VALUE: [],
    INVALID_VALUE: [],
    // Other unexpected errors
    GENERIC_ERROR: [],
  };

  input.forEach((deposit, index) => {
    const parsedDeposit = DepositSchema.safeParse(deposit);
    if (parsedDeposit.success) {
      data.push(parsedDeposit.data);
    } else {
      parsedDeposit.error.issues.forEach(({ message }) => {
        const validationError: ParsingErrors =
          message in ParsingErrors
            ? (message as ParsingErrors)
            : ParsingErrors.GENERIC_ERROR;
        errors[validationError].push(
          '__rowNum__' in deposit && typeof deposit.__rowNum__ === 'number'
            ? deposit.__rowNum__ + 1
            : index + 2,
        );
      });
    }
  });

  return {
    data,
    errors,
  };
}

/** Schema for each deposit row in the file */
const DepositSchema = z.object(
  {
    /** Internal field to identify the row */
    __rowNum__: z.number({
      required_error: ParsingErrors.GENERIC_ERROR,
      invalid_type_error: ParsingErrors.GENERIC_ERROR,
    }),
    CPF: z.coerce
      .string({
        required_error: ParsingErrors.INVALID_DOCUMENT_NUMBER,
        invalid_type_error: ParsingErrors.INVALID_DOCUMENT_NUMBER,
      })
      .transform((value) => value.replace(/[^\d]+/g, ''))
      .refine(
        (value) => !!value.length, // Checks if there is at least one digit left
        { message: ParsingErrors.INVALID_DOCUMENT_NUMBER },
      )
      .transform((value) => value.padStart(11, '0')), // Pads the rest with `0`
    Valor: z.coerce
      .string({
        required_error: ParsingErrors.INVALID_VALUE,
        invalid_type_error: ParsingErrors.INVALID_VALUE,
      })
      .min(1, ParsingErrors.INVALID_VALUE)
      .transform((value, ctx) => {
        const parsedValue = CurrencyUtils.fromInput(value);
        if (!parsedValue) {
          ctx.addIssue({
            code: 'custom',
            message: ParsingErrors.INVALID_VALUE,
          });
          return z.NEVER;
        }

        if (parsedValue.value < 10) {
          ctx.addIssue({
            code: 'custom',
            message: ParsingErrors.INSUFFICIENT_VALUE,
          });
          return z.NEVER;
        }
        return parsedValue.intValue;
      }),
  },
  {
    required_error: ParsingErrors.GENERIC_ERROR,
    invalid_type_error: ParsingErrors.GENERIC_ERROR,
  },
);
type DepositSchemaOutput = z.output<typeof DepositSchema>;
