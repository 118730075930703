/** Returns a list of conditional items, based on a tuple with:
 * [a boolean condition, item added to the list if the condition was true]
 */
export default function conditionalList<TItem>(items: [boolean, TItem][]) {
  const result: TItem[] = [];
  items.forEach((item) => {
    if (item[0]) result.push(item[1]);
  });
  return result;
}
