import { Dropzone } from '@flash-tecnologia/hros-web-ui-v2';
import Typography from '@frontend/components/Typography';
import { useTheme } from 'styled-components';

type Props = {
  onUpload: (
    fileListInput: Array<{ data?: ArrayBuffer | string | null }>,
  ) => void;
};

export const UploadModule = (props: Props) => {
  const theme = useTheme();

  return (
    <div
      style={{
        padding: '40px',
        border: `1px solid ${theme.colors.neutral[90]}`,
        borderRadius: '8px',
        gap: '24px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography.Headline8 color="neutral_30">
          Envie seu arquivo
        </Typography.Headline8>
        <Typography.Body4 color="neutral_50">
          Com o arquivo em mãos, agora é só anexar abaixo!
        </Typography.Body4>
      </div>
      <div>
        <Dropzone
          title="Seu arquivo"
          accept={['xlsx']}
          onChange={props.onUpload}
        />
      </div>
    </div>
  );
};
