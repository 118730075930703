import { BoxAdornment, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import BaseTagButton from '@frontend/components/TagButton/index';
import styled from 'styled-components';

export const StyledBoxAdornment = styled(BoxAdornment)`
  border: unset !important;
  white-space: nowrap;
`;

export const StyledIcon = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 50%;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  fill: transparent;
  align-items: center;
`;

export const BaseIcon = styled(Icons)`
  justify-content: center;
  fill: transparent;
  align-items: center;
  display: flex;
`;

export interface ICell {
  width: number;
}

export const Cell = styled.div`
  white-space: nowrap;
  display: flex;
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
`;

export const BottomWrapper = styled.div`
  position: relative;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
`;

export const TagButton = styled(BaseTagButton)`
  display: flex;
`;
