import useGetExternalCards from '@frontend/pages/ExternalCards/data/useGetExternalCards';
import { trpc } from '@frontend/trpc';
import _isEqual from 'lodash/isEqual';
import { TEMPLATE_COLUMNS, TEMPLATE_FIELDS } from '../constants';
import {
  cardExists,
  fieldExists,
  isForeignPurchase,
  isISOFormat,
  isValidMCC,
  isValidTransactionDate,
  validateHolderName,
} from './validationRules';

const getCompanyCurrency = () => {
  const { data } = trpc.company.getCurrency.useQuery();

  const currency = data ? data.currencycode : '';

  return currency;
};

const getCardList = () => {
  const { data } = useGetExternalCards();
  return data;
};

const isValidHeaders = (headers: string[]) => {
  return _isEqual(headers, Object.values(TEMPLATE_COLUMNS));
};

export const useValidation = () => {
  const actualCurrency = getCompanyCurrency();
  const cardList = getCardList();

  const validate = (table: string[][]) => {
    if (!table || !isValidHeaders(table[0])) {
      throw new Error('Planilha enviada com o formato incorreto');
    }

    const validationsByField: Record<
      string,
      ((row: string[]) => string | boolean)[]
    > = {
      [TEMPLATE_COLUMNS.CARD_NUMBER]: [
        (row: string[]) => fieldExists(row, TEMPLATE_FIELDS.CARD_NUMBER),
        (row: string[]) => cardExists(row, cardList),
      ],
      [TEMPLATE_COLUMNS.CARD_HOLDER]: [
        (row: string[]) => fieldExists(row, TEMPLATE_FIELDS.CARD_HOLDER),
        (row: string[]) => validateHolderName(row, cardList),
      ],
      [TEMPLATE_COLUMNS.CARD_FLAG]: [],
      [TEMPLATE_COLUMNS.BILLING_DATE]: [],
      [TEMPLATE_COLUMNS.TRANSACTION_DATE]: [
        (row: string[]) => fieldExists(row, TEMPLATE_FIELDS.TRANSACTION_DATE),
        (row: string[]) => isValidTransactionDate(row, cardList),
      ],
      [TEMPLATE_COLUMNS.TRANSACTION_CURRENCY]: [
        (row: string[]) =>
          fieldExists(row, TEMPLATE_FIELDS.TRANSACTION_CURRENCY),
        (row: string[]) => isISOFormat(row, 'TRANSACTION_CURRENCY'),
      ],
      [TEMPLATE_COLUMNS.TRANSACTION_VALUE]: [
        (row: string[]) => fieldExists(row, TEMPLATE_FIELDS.TRANSACTION_VALUE),
      ],
      [TEMPLATE_COLUMNS.CONVERTED_TRANSACTION_CURRENCY]: [
        (row: string[]) =>
          isForeignPurchase(row, actualCurrency) &&
          fieldExists(row, TEMPLATE_FIELDS.CONVERTED_TRANSACTION_CURRENCY),
      ],
      [TEMPLATE_COLUMNS.CONVERTED_TRANSACTION_VALUE]: [
        (row: string[]) =>
          isForeignPurchase(row, actualCurrency) &&
          fieldExists(row, TEMPLATE_FIELDS.CONVERTED_TRANSACTION_VALUE),
      ],
      [TEMPLATE_COLUMNS.TRANSACTION_TYPE]: [],
      [TEMPLATE_COLUMNS.MCC]: [(row: string[]) => isValidMCC(row)],
      [TEMPLATE_COLUMNS.ESTABLISHMENT_NAME]: [
        (row: string[]) => fieldExists(row, TEMPLATE_FIELDS.ESTABLISHMENT_NAME),
      ],
    };

    const columns = Object.values(TEMPLATE_COLUMNS);
    const foundErrors = {};
    table.forEach((row, index) => {
      if (!index) return;
      // Valida todos as celulas da linha para verificar se estão de acordo com as regras
      const errors = columns.reduce<string[]>((errorList, field) => {
        const validations = validationsByField[field];

        validations.find((validation) => {
          const error = validation(row);

          if (!!error) {
            errorList.push(String(error));
            return true;
          }
        });

        return errorList;
      }, []);

      if (errors.length) {
        foundErrors[index - 1] = errors.join('\n');
      }
    });

    return foundErrors;
  };

  return { validate };
};
