import { Toggle as UIToggle } from '@flash-tecnologia/hros-web-ui-v2';

type Props = {
  value: boolean | undefined;
  onChange: (newValue: boolean) => void;
};

export default function Toggle(props: Props) {
  return (
    <UIToggle
      checked={props.value}
      onChange={(_e, newValue) => {
        props.onChange(newValue);
      }}
    />
  );
}
