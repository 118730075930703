import { ModuleContainer } from '@frontend/components/FocusedFlow/styled';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import { getExpirationDate } from '../../form/useForm';

type Props = {
  nickname: string;
  username: string;
  expiration?: {
    expirationRule: 'MONTHS' | 'DAYS' | 'CUSTOM';
    expirationMetadata?: string;
  };
};
export const CardPreview = (props: Props) => {
  if (
    !props.nickname &&
    !props.username &&
    !props.expiration?.expirationMetadata
  )
    return <></>;

  const formattedExpiration = props.expiration?.expirationMetadata
    ? getExpirationDate({
        expirationRule: props.expiration.expirationRule,
        expirationMetadata: props.expiration.expirationMetadata,
      })
        ?.setLocale('pt-BR')
        .toFormat('dd/LL/yyyy')
    : null;

  return (
    <ModuleContainer gap="16px" padding="16px" style={{ minWidth: '280px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Headline8 color="primary">
          {props.nickname}
        </Typography.Headline8>
        <Tag size="md" variant="neutral">
          Virtual
        </Tag>
      </div>
      <Typography.Body4 weight={700}>{props.username}</Typography.Body4>
      <div style={{ display: 'flex', gap: '52px' }}>
        <div>
          <div>Expiração</div>
          <div>{formattedExpiration}</div>
        </div>
      </div>
    </ModuleContainer>
  );
};
