import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Box = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

export const BigIcon = styled(Icons)`
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: flex;
  fill: transparent;
  align-items: center;
  justify-content: center;
`;

export const MainInfoBox = styled.div`
  align-self: flex-start;
`;

export const BoxInfo = styled.div`
  width: 100%;
  max-width: 327px;
`;

type DetailInfoProps = {
  flex: number;
};

export const DetailInfo = styled.div<DetailInfoProps>`
  display: flex;
  flex: ${({ flex }) => flex};
`;

export const BoxTransactionInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 982px;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 8px;
`;

export const TagArea = styled.div`
  flex-grow: 1;
  padding: 5px 12px;
  align-self: flex-start;
`;
