import {
  PageContainer,
  PageHeader,
  Tab,
} from '@flash-tecnologia/hros-web-ui-v2';
import Spacer from '../../components/Spacer';

import { Outlet } from 'react-router-dom';
import { useTabPage } from '@frontend/utils/hooks/useTabPage';
import PlanFeaturesService from '@frontend/services/PlanFeatures';
import FeatureFlagService from '@frontend/services/FeatureFlagService';
import { useCheckIsCorporateCard } from './data/useCheckIsCorporateCard';

const BASE_PATH = '/corporateCard/statement';
const AVAILABLE_TABS = ['flashCard', 'corporateCard', 'externalCards'];

export default function Statement() {
  const { currentTab, setParams } = useTabPage(BASE_PATH, AVAILABLE_TABS);
  const externalCardsEnabled =
    FeatureFlagService.getFlag('externalCards') &&
    PlanFeaturesService.get('BASIC_CARDS');
  const { isCorporateCardUser } = useCheckIsCorporateCard();
  const tabItems = [
    {
      label: 'Cartão Flash',
      component: <Outlet />,
    },
  ];
  if (isCorporateCardUser)
    tabItems.push({
      label: 'Corporativo',
      component: <Outlet />,
    });
  if (externalCardsEnabled && isCorporateCardUser)
    tabItems.push({
      label: 'Terceiros',
      component: <Outlet />,
    });

  return (
    <>
      <PageHeader title="Meu extrato" />
      <PageContainer>
        <Spacer y="s" />
        <Tab
          defaultTab={currentTab}
          onTabChanged={setParams}
          tabItens={tabItems}
        />
        <Spacer y="s" />
      </PageContainer>
    </>
  );
}
