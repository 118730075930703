import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import Typography from '@frontend/components/Typography';
import ModalService from '@frontend/services/ModalService';

import OnboardingVirtualCardTutorialModal from './OnboardingVirtualCardTutorialModal';
import { CreateVirtualCorporateCard } from '../../FlashCard/focusedFlows/CreateVirtualCorporateCard';

function OnboardingAddEmployeeEndModal() {
  const modalController = ModalService.useModalController();

  function backToTutorial() {
    modalController.remove();
    ModalService.show(OnboardingVirtualCardTutorialModal, {});
  }

  function handleDismiss() {
    modalController.remove();
  }

  function handleCreateVirtualCard() {
    modalController.remove();
    ModalService.show(CreateVirtualCorporateCard, {});
  }

  return (
    <ConfirmationModal
      isOpen={modalController.visible}
      onClose={handleDismiss}
      variant="default"
      text={{
        highlightedText: 'Tudo certo!',
        title: 'Agora é só criar o primeiro cartão virtual corporativo!',
        body: (
          <>
            Tudo pronto para disponibilizar cartões virtuais para as pessoas da
            sua empresa.
            <br />
            Caso queira saber mais sobre, confira nossa{' '}
            <Typography.Link
              href="https://beneficios.flashapp.com.br/faq/como-funciona-o-cart%C3%A3o-corporativo-virtual"
              target="_blank"
            >
              FAQ
            </Typography.Link>
            .
          </>
        ),
      }}
      buttons={{
        cancel: {
          onClick: backToTutorial,
          text: 'Voltar',
        },
        confirm: {
          onClick: handleCreateVirtualCard,
          text: 'Criar cartão virtual',
        },
      }}
    />
  );
}

export default ModalService.create(OnboardingAddEmployeeEndModal);
