import { DateTime } from 'luxon';

const startingDisabledDate = DateTime.fromObject({ year: 2022, month: 5 });
const endingDisabledDate = DateTime.now().minus({ day: 1 });

export default function disabledDate(date: Date) {
  const dateTime = DateTime.fromJSDate(date);

  return (
    dateTime.diff(startingDisabledDate, 'days').days <= 0 ||
    dateTime.diff(endingDisabledDate, 'days').days >= 0
  );
}
