import { z } from 'zod';
import { useAuth } from '@flash-hros/auth-helper';

const authState = z.object({
  expenses: z.object({
    planFeatures: z.array(z.string()),
  }),
});

type AuthKeys = 'BASIC_CARDS';

export default class PlanFeaturesService {
  static get(key: AuthKeys): boolean {
    const state = useAuth.getState();
    const parsedData = authState.safeParse(state);
    if (!parsedData.success) {
      return false;
    }
    return parsedData.data.expenses.planFeatures.some(
      (feature) => feature === key,
    );
  }
}
