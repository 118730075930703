import { RouterInputs, trpc } from '@frontend/trpc';
import * as toasts from '../reports/shared/toasts';

type Input = RouterInputs['company']['reports']['create'];

export function useCreateReport() {
  const createReport = trpc.company.reports.create.useMutation();

  return {
    mutateAsync: (input: Input) => {
      return createReport.mutateAsync(input, {
        onError: (error) => {
          toasts.toastError(error.message);
        },
      });
    },
    isLoading: createReport.isLoading,
    error: createReport.error,
  };
}
