import FormService from '@frontend/services/FormService';
import ErrorMonitorService from '@frontend/services/MonitorService';
import { RouterInputs } from '@frontend/trpc';
import { useCreateReport } from '../data/useCreateReport';
import * as corporateCardStatementSchema from '../reports/CorporateCardStatement/schema';
import * as corporateCardUsersSchema from '../reports/CorporateCardUsers/schema';
import * as flashBalanceSchema from '../reports/FlashBalance/schema';
import * as flashCashStatementSchema from '../reports/FlashCashStatement/schema';
import * as toasts from '../reports/shared/toasts';

type Template = RouterInputs['company']['reports']['create']['template'];

type FormSchema =
  | typeof corporateCardStatementSchema.FormSchema
  | typeof corporateCardUsersSchema.FormSchema
  | typeof flashBalanceSchema.FormSchema
  | typeof flashCashStatementSchema.FormSchema;

type FormSchemaOutput =
  | corporateCardStatementSchema.FormSchemaOutput
  | corporateCardUsersSchema.FormSchemaOutput
  | flashBalanceSchema.FormSchemaOutput
  | flashCashStatementSchema.FormSchemaOutput;

type Input = {
  /** initial value */
  defaultValues?: Partial<Omit<FormSchemaOutput, 'template'>>;
  /** Report template key */
  reportTemplate: Template;
  /** Form schema to validate user input */
  formSchema: FormSchema;
  /** Callback for the submit success */
  onSuccess?: () => void;
};

export default function useForm(input: Input) {
  /* ------------------------------ Form setup ------------------------------ */
  const form = FormService.useCreateForm(input.formSchema, {
    mode: 'onChange',
    defaultValues: {
      ...input.defaultValues,
      template: input.reportTemplate,
    },
  });

  /* ------------------------ Schema validation check ----------------------- */
  const validSchema = form.formState.isValid;

  /* ------------------------------ Form submit ----------------------------- */
  const createReport = useCreateReport();

  const onSubmit = form.handleSubmit(
    async (values) => {
      await createReport
        .mutateAsync(values)
        .then((data) => {
          if (data) input.onSuccess?.();
        })
        .catch(() => {}); // Prevents loading loop
    },
    (errors) => {
      // Schema validation
      ErrorMonitorService.error({
        message: `Error while validating report form schema - ${input.reportTemplate}`,
        severity: 'fatal',
        extras: {
          zodErrors: JSON.stringify(errors),
        },
      });
      toasts.toastFormSchemaError();
    },
  );

  /* -------------------------------- Return -------------------------------- */
  return {
    ...form,
    isSubmitting: form.formState.isSubmitting || createReport.isLoading,
    onSubmit,
    error: createReport.error,
    validSchema,
  };
}

export type UseFormReturn = ReturnType<typeof useForm>;
