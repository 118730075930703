import { trpc } from '@frontend/trpc';

type Input = { template: string; enabled?: boolean };

export default function (input: Input) {
  const { data, isLoading, error } = trpc.company.reports.search.useQuery(
    {
      template: input.template,
    },
    {
      enabled: input.enabled ?? false,
      refetchInterval: 1000 * 30,
      staleTime: 1000,
    },
  );

  return {
    data: {
      entries: data?.entries ?? [],
      pageNumber: (data?.pageNumber ?? 0) + 1,
      pageSize: data?.pageSize ?? 0,
      pages: data?.pages ?? 0,
      total: data?.total ?? 0,
    },
    error,
    loading: isLoading,
  };
}
