import { dispatchToast } from '../../../../utils/dispatchEvents';
import { trpc } from '@frontend/trpc';
import { useParamsState } from '@frontend/utils/hooks/searchParams';
import { z } from 'zod';

export const tableEmptyState = {
  isFiltered: false,
  emptyText: 'Você ainda não fez depósito',
  filteredEmptyText: 'Não encontramos o Depósito',
};

const statusSchema = z.enum(['waiting', 'transferred', 'paid', 'cancelled']);
export type StatusMap = z.infer<typeof statusSchema>;

export const paramsSchema = z.object({
  status: z
    .string()
    .transform((data) => z.array(statusSchema).catch([]).parse(data.split(',')))
    .optional(),
  startDate: z.coerce.date().optional().catch(undefined),
  endDate: z.coerce.date().optional().catch(undefined),
  pageIndex: z.coerce.number().nonnegative().default(0).catch(0),
  pageSize: z.coerce.number().positive().default(10).catch(10),
});
export type Filter = z.infer<typeof paramsSchema>;

export function useTableData() {
  const [filters, setFilters] = useParamsState<Filter>(
    {
      pageIndex: 0,
      pageSize: 10,
    },
    paramsSchema,
  );

  const { pageIndex, pageSize, ...keys } = filters;

  const filter = {
    ...(!!keys.status && {
      status: keys.status,
    }),
    ...(!!keys.startDate &&
      !!keys.endDate && {
        creditDate: {
          from: keys.startDate,
          to: keys.endDate,
        },
      }),
  };

  const pagination = {
    currentPage: pageIndex,
    pageSize,
  };

  const { data: depositsData, isLoading: loading } =
    trpc.company.deposits.search.useQuery(
      {
        filter,
        pagination,
      },
      {
        onError({ message }) {
          dispatchToast({
            type: 'error',
            content: message,
          });
        },
      },
    );

  const data = depositsData ?? {
    deposits: [],
    pagination: { currentPage: 0, pageSize, totalItems: 0, totalPages: 0 },
  };

  return {
    filters,
    setFilters: function (newFilter: Omit<Filter, 'pageIndex' | 'pageSize'>) {
      setFilters({
        ...newFilter,
        pageIndex: 0,
        pageSize,
      });
    },
    pagination,
    setPagination: function (newPagination: {
      pageIndex: number;
      pageSize: number;
    }) {
      setFilters({
        ...filters,
        ...newPagination,
      });
    },
    data,
    loading,
  };
}
