import { trpc } from '@frontend/trpc';

type Input = {
  policyId: string;
};

export default function useGetPolicy(input: Input) {
  const policy = trpc.company.policies.get.useQuery({
    policyId: input.policyId,
  });

  return {
    data: policy.data,
    isLoading: policy.isLoading,
    isError: policy.isError,
  };
}

export type UseGetPolicyOutput = ReturnType<typeof useGetPolicy>;
