import { FilterFn } from '@tanstack/react-table';
import * as DateFns from 'date-fns';

declare module '@tanstack/table-core' {
  interface FilterFns {
    customInNumberRange: FilterFn<unknown>;
    customIncludes: FilterFn<unknown>;
    customInSet: FilterFn<unknown>;
    customInDateRange: FilterFn<unknown>;
  }
}

const customInNumberRange: FilterFn<unknown> = (
  row,
  columnId,
  filter?: { min: number; max: number },
) => {
  const cellValue = row.getValue<number>(columnId);
  return !filter || (filter.min <= cellValue && cellValue <= filter.max);
};

const customIncludes: FilterFn<unknown> = (
  row,
  columnId,
  filter: Array<unknown>,
) => {
  const cellValue = row.getValue(columnId);
  return filter.includes(cellValue);
};

const customInSet: FilterFn<unknown> = (
  row,
  columnId,
  filter: Set<unknown>,
) => {
  const cellValue = row.getValue(columnId);
  return !filter.size || filter.has(cellValue);
};

const customInDateRange: FilterFn<unknown> = (
  row,
  columnId,
  filter: {
    from?: Date;
    to?: Date;
  },
) => {
  const value = row.getValue<Date>(columnId);
  if (filter.from && DateFns.isAfter(filter.from, value)) return false;
  if (filter.to && DateFns.isBefore(DateFns.endOfDay(filter.to), value))
    return false;
  return true;
};

export default {
  customInNumberRange,
  customIncludes,
  customInSet,
  customInDateRange,
};
