import { toCurrency } from '@frontend/utils/masks';
import { trpc } from '@frontend/utils/trpc';

export function useCompanyAssetFreeze() {
  const {
    data: frozenAssets,
    isError: frozenAssetsError,
    isLoading: frozenAssetsLoading,
    isRefetching: frozenAssetsRefetching,
  } = trpc.company.assetFreezing.get.useQuery(undefined, {
    retry: false,
    retryOnMount: true,
    staleTime: 30 * 1000,
    cacheTime: 60 * 1000,
  });

  return {
    raw: frozenAssets?.frozen,
    formatted:
      (frozenAssets?.frozen !== undefined &&
        toCurrency(frozenAssets?.frozen)) ||
      '',
    error: frozenAssetsError,
    loading: frozenAssetsLoading || frozenAssetsRefetching,
  };
}
