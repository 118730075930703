import { Button, Focused, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import ModalService from '@frontend/services/ModalService';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { hideChatbot, showChatbot } from '../../utils/chatbot';
import { FocusedFlowContainer, FocusedFlowLimitBox } from './styled';

type Props = {
  headerMetadata: {
    breadcrumbs: {
      link?: string;
      text: string;
    }[];
    stepper?: {
      steps: string[];
      activeStep: number;
      disableClick?: boolean;
      setActiveStep?: React.Dispatch<React.SetStateAction<number>>;
    };
  };
  content: React.ReactNode;
  footer: {
    leftActions?: {
      onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
      label: React.ReactNode;
      isDisabled?: boolean;
    };
    rightActions: Readonly<
      Array<{
        onClick: React.MouseEventHandler<HTMLButtonElement>;
        label: React.ReactNode;
        isDisabled: boolean;
        isLoading: boolean;
        variant: 'primary' | 'secondary';
      }>
    >;
  };
};

export const FocusedFlow = (props: Props) => {
  React.useEffect(() => {
    hideChatbot();
    return () => {
      showChatbot();
    };
  });
  const navigate = useNavigate();

  const modalController = ModalService.useModalController();

  const { headerMetadata, footer } = props;

  const { stepper } = headerMetadata;
  const { leftActions, rightActions } = footer;

  return (
    <FocusedFlowContainer>
      <FocusedFlowLimitBox>
        <Focused
          hasHeader
          routes={headerMetadata.breadcrumbs.map((breadcrumb) => ({
            label: breadcrumb.text,
            route: breadcrumb.link,
            onClick: () => {
              modalController.remove();
              navigate(breadcrumb.link as string);
            },
          }))}
          footer={{
            startActions: leftActions
              ? [
                  <LinkButton
                    variant="neutral"
                    onClick={leftActions?.onClick}
                    style={{ alignSelf: 'center' }}
                    disabled={leftActions?.isDisabled}
                  >
                    {leftActions?.label}
                  </LinkButton>,
                ]
              : [],
            endActions: [
              rightActions.map((button) => (
                <Button
                  size="large"
                  loading={button.isLoading}
                  disabled={button.isDisabled}
                  onClick={button.onClick}
                  buttonType={button.variant}
                  variant={button.variant}
                  variantType="default"
                >
                  {button.label}
                </Button>
              )),
            ],
          }}
          stepper={stepper}
        >
          {props.content}
        </Focused>
      </FocusedFlowLimitBox>
    </FocusedFlowContainer>
  );
};
