import { BoxAdornment, Icons, Spinner } from '@flash-tecnologia/hros-web-ui-v2';
import { useRef } from 'react';
import styled from 'styled-components';
import EmptyTableSVG from '../../../assets/table/empty_table.svg';
import Spacer from '../../../components/Spacer';
import Typography from '../../../components/Typography';

export const BaseIcon = styled(Icons)`
  justify-content: center;
  fill: transparent;
  align-items: center;
  display: flex;
`;

export const BigIcon = styled(BaseIcon)`
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 50%;
  width: 48px;
  height: 48px;
`;

interface ICustomBoxAdornment {
  description: string;
  leftAdornment: React.ReactNode;
  title: string;
  loading?: boolean;
  hasError?: boolean;
}

const AlertIcon = styled(BigIcon)`
  background-color: #ffecd6;
`;

const AlertTypography = styled(Typography.Body3)`
  color: #83727d;
`;

const StyledBoxAdornment = styled(BoxAdornment)`
  background-color: #f8f6f8;
`;

const BigBoxAdornment = styled(BoxAdornment)`
  width: 100%;
  height: 100%;
`;

const BigDiv = styled.div`
  flex: 1;
  max-width: 380px;
  white-space: no-wrap;
`;

export function CustomBoxAdornment({
  description,
  hasError,
  title,
  leftAdornment,
  loading,
}: ICustomBoxAdornment) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  return (
    <BigDiv ref={wrapperRef}>
      {hasError ? (
        <StyledBoxAdornment
          style={{
            width: wrapperRef.current?.clientWidth,
            height: wrapperRef.current?.clientHeight,
          }}
          leftAdornment={
            <AlertIcon
              size={24}
              color="#C96C01"
              fill="#FFECD6"
              name="IconAlertCircle"
            />
          }
          title={<AlertTypography>Dado não encontrado</AlertTypography>}
          description={
            <Typography.Caption>
              Tivemos um problema tentando trazer essa informação. Entre em
              contato com suporte.
            </Typography.Caption>
          }
        />
      ) : (
        <BigBoxAdornment
          leftAdornment={
            loading ? <Spinner variant="primary" size={48} /> : leftAdornment
          }
          title={
            <Typography.Body3 color="neutral_50">{title}</Typography.Body3>
          }
          description={
            <Typography.Headline5>{description}</Typography.Headline5>
          }
        />
      )}
    </BigDiv>
  );
}

interface Container {
  justifyContent?: string;
}

export const Container = styled.div<Container>`
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'unset'};
`;

type BoxProps = {
  hasBorder?: boolean;
};

export const Box = styled.div<BoxProps>`
  width: 366px;
  min-width: 366px;
  padding-right: ${({ theme }) => theme.spacings.xs2};
  margin-right: ${({ theme }) => theme.spacings.xs2};
  ${({ hasBorder, theme }) =>
    hasBorder && `border-right: 1px solid ${theme.colors.neutral[90]};`}
`;

interface WrapperProps {
  isGrey?: boolean;
  padding?: number;
}

export const EmptyTable = styled.div`
  background-color: ${(p) => p.theme.colors.neutral[95]};
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ theme, isGrey }) =>
    isGrey ? theme.colors.neutral[95] : theme.colors.neutral[100]};
  width: 100%;
  padding: ${(props) => (!props.padding ? '40' : props.padding)}px;
  padding-bottom: ${(props) => (!props.padding ? '0' : props.padding)}px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
`;

interface StatusBoxProps {
  loading: boolean;
  title: string;
  subTitle: string;
}

export function StatusBox({ loading, title, subTitle }: StatusBoxProps) {
  return (
    <EmptyTable>
      {loading ? (
        <Spinner variant="primary" size={48} />
      ) : (
        <>
          <EmptyTableSVG />
          <Typography.Body3 weight={600}>{title}</Typography.Body3>
          <Spacer y="xs" />
          <Typography.Caption>{subTitle}</Typography.Caption>
        </>
      )}
    </EmptyTable>
  );
}

export const HorizontalList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

interface NextCreditStatusProps {
  error: boolean;
}

export function NextCreditStatus({ error }: NextCreditStatusProps) {
  return (
    <Wrapper padding={24} isGrey>
      {error ? (
        <StyledBoxAdornment
          style={{ width: '100%', height: '100%', padding: 0, border: 'unset' }}
          leftAdornment={
            <AlertIcon
              size={24}
              color="#C96C01"
              fill="#FFECD6"
              name="IconAlertCircle"
            />
          }
          title={<AlertTypography>Dado não encontrado</AlertTypography>}
          description={
            <Typography.Caption>
              Tivemos um problema tentando trazer essa informação. Entre em
              contato com suporte.
            </Typography.Caption>
          }
        />
      ) : (
        <>
          <Typography.Body3 weight={600} color="neutral_30">
            Sem previsão de próximas recargas
          </Typography.Body3>
          <Typography.Caption color="neutral_50">
            Nos próximos 30 dias.
          </Typography.Caption>
        </>
      )}
    </Wrapper>
  );
}
