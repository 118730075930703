import StepModal from '@frontend/components/modals/StepModal';
import ModalService from '@frontend/services/ModalService';
import OnboardingAddEmployeeEndModal from './OnboardingAddEmployeeEndModal';

function OnboardingAddEmployeeTutorialModal() {
  const modalController = ModalService.useModalController();

  function handleDismiss() {
    modalController.remove();
  }

  function showEndModal() {
    modalController.remove();
    ModalService.show(OnboardingAddEmployeeEndModal, {});
  }

  return (
    <StepModal
      isOpen={modalController.visible}
      onClose={handleDismiss}
      onEnd={showEndModal}
      steps={[
        {
          title: 'Pesquise o colaborador pelo CPF ou nome',
          subtitle:
            'O colaborador já deve estar cadastrado na plataforma Flash OS.',
          content: (
            <img
              src={
                'https://images.flashapp.com.br/expense/corporate-card/onboarding-add-employee-1.gif'
              }
            ></img>
          ),
        },
        {
          title: 'Cartão Corporativo liberado!',
          subtitle: 'Agora o seu colaborador tem acesso ao cartão corporativo.',
          content: (
            <img
              src={
                'https://images.flashapp.com.br/expense/corporate-card/onboarding-add-employee-2.gif'
              }
            ></img>
          ),
        },
      ]}
    />
  );
}

export default ModalService.create(OnboardingAddEmployeeTutorialModal);
