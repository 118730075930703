import {
  Dropzone,
  GenerateXLSXFile,
  Icons,
  LinkButton,
} from '@flash-tecnologia/hros-web-ui-v2';

const FLASH_CARD_FIELDS = ['Nome ou CPF'];
const EXTERNAL_CARD_FIELDS = [
  'Nome ou CPF',
  'Quatro últimos dígitos do Cartão',
  'Nome do portador',
  'Data de inicio da prestação de conta',
];

export const MultipleCards = ({ isFlashCard }) => {
  const fields = isFlashCard ? FLASH_CARD_FIELDS : EXTERNAL_CARD_FIELDS;

  return (
    <div
      style={{
        gap: '24px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Dropzone title="Tabela de colaboradores" accept={['xlsx']} />
      <GenerateXLSXFile
        data={[fields]}
        fileName="modelo_de_planilha.xlsx"
        buttonTitle="Teste"
        customButton={
          <LinkButton variant="default">
            Baixar modelo da tabela em .xlsx{' '}
            <Icons fill={'transparent'} name="IconFileDownload" />
          </LinkButton>
        }
      />
    </div>
  );
};
