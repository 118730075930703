import { trpc } from '../../../utils/trpc';

export default function useSetSeenAddEmployeeOnboarding() {
  const setSeenFlashCashOnboarding = trpc.notices.setSeen.useMutation();
  const trpcContext = trpc.useContext();

  return function () {
    setSeenFlashCashOnboarding.mutate({ template: 'onboarding_add-employee' });
    trpcContext.notices.get.invalidate({ template: 'onboarding_add-employee' });
  };
}
