import { Checkbox } from '@flash-tecnologia/hros-web-ui-v2';
import Typography from '@frontend/components/Typography';
import Flex from '@frontend/components/frames/Flex';
import styled from 'styled-components';
import InputErrorMessage from '../_primitives/InputErrorMessage';

type Props<T extends PropertyKey> = {
  options: Readonly<Array<{ label: string; value: T }>>;
  value: Record<T, boolean>;
  onChange: (value: Record<T, boolean>) => void;
  error?: string;
};

export default function CheckboxGroup<T extends PropertyKey>(props: Props<T>) {
  return (
    <Flex direction="column">
      <Flex gap={'xs4'} wrap="wrap">
        {props.options.map((option) => (
          <StyledItem checked={props.value[option.value]}>
            <Checkbox
              checked={props.value[option.value]}
              onChange={(_, newValue) =>
                props.onChange({ ...props.value, [option.value]: newValue })
              }
            />
            <Typography.Body3 color="neutral_30">
              {option.label}
            </Typography.Body3>
          </StyledItem>
        ))}
      </Flex>
      <InputErrorMessage error={props.error} />
    </Flex>
  );
}

type StyledItemProps = {
  checked: boolean;
};
const StyledItem = styled.div<StyledItemProps>`
  display: flex;
  align-items: center;
  border: 1px solid;
  border-color: ${({ theme, checked }) =>
    checked ? theme.colors.secondary[70] : theme.colors.neutral[80]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  padding-right: ${({ theme }) => theme.spacings.xs4};
`;
