import {
  Button,
  Icons,
  Menu,
  PageContainer,
  PageHeader,
  PillButton,
  Tab,
  Tooltip,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import { ROUTE_CompanyCustomization } from '@frontend/routes/ExternalRoutes';
import FeatureFlagService from '@frontend/services/FeatureFlagService';
import RouterService from '@frontend/services/RouterService';
import { useTabPage } from '@frontend/utils/hooks/useTabPage';
import { Outlet } from 'react-router-dom';
import { ROUTE_Home } from '.';
import ModalService from '../../services/ModalService';
import { useExternalCards } from '../ExternalCards/utils/hooks/useExternalCards';
import { CreateVirtualCorporateCard } from './FlashCard/focusedFlows/CreateVirtualCorporateCard';
import CreateCorporateCardUser from './FlashCard/modals/CreateCorporateCardUser/CreateCorporateCardUser';
import UploadTransactions from './FlashCard/modals/UploadTransactions/UploadTransactions';
import useShowAddEmployeeOnboarding from './data/useShowAddEmployeeOnboarding';
import CreateManyDeposits from './focusedFlows/CreateManyDeposits';
import AddUserModal from './modals/AddUserModal';
import * as styled from './styled';

const AVAILABLE_TABS = ['flashCard', 'externalCard'];

export default function Home() {
  const navigate = RouterService.useNavigate();
  const policiesEnabled = FeatureFlagService.getFlag('policies');

  useShowAddEmployeeOnboarding();
  const { currentTab, setParams } = useTabPage(ROUTE_Home.path, AVAILABLE_TABS);
  const externalCards = useExternalCards();

  const uploadTransactionsEnabled =
    FeatureFlagService.getFlag('uploadTransactions') &&
    externalCards.enabled &&
    !externalCards.useV2;

  const virtualCardEnabled = FeatureFlagService.getFlag('virtualCard');

  const tabItems = [
    {
      label: 'Cartão Flash',
      component: <Outlet />,
    },
  ];
  if (externalCards.enabled)
    tabItems.push({
      label: 'Cartões Terceiros',
      component: <Outlet />,
    });

  const menuOptions = [
    {
      children: (
        <styled.MenuItem>
          <Icons name="IconChartCircles" fill="none" />
          {externalCards.useV2
            ? 'Vincular uso corporativo'
            : 'Adicionar/Vincular cartão'}
        </styled.MenuItem>
      ),
      onClick: () => {
        if (externalCards.enabled && !externalCards.useV2) {
          ModalService.show(CreateCorporateCardUser, {
            isFlashCard: currentTab === 0,
          });
        } else {
          ModalService.show(AddUserModal, {});
        }
      },
    },
  ];

  if (uploadTransactionsEnabled) {
    menuOptions.push({
      children: (
        <styled.MenuItem>
          <Icons name="IconFileImport" fill="none" /> Importar transações de
          cartões terceiros
        </styled.MenuItem>
      ),
      onClick: () => {
        ModalService.show(UploadTransactions, {});
      },
    });
  }

  if (virtualCardEnabled) {
    menuOptions.push({
      children: (
        <styled.MenuItem>
          <Icons name="IconCreditCard" fill="none" /> Novo cartão virtual
        </styled.MenuItem>
      ),
      onClick: () => {
        ModalService.show(CreateVirtualCorporateCard, {});
      },
    });
  }

  return (
    <>
      <PageHeader style={{ justifyContent: 'space-between' }}>
        <Typography variant="headline6">Cartões corporativos</Typography>
        <Flex gap="xs" align="center">
          {policiesEnabled && (
            <Tooltip title="Políticas de uso do cartão">
              <div>
                <PillButton
                  icon="IconAdjustmentsHorizontal"
                  size="medium"
                  variant="default"
                  onClick={() => {
                    navigate({
                      route: ROUTE_CompanyCustomization,
                      params: {},
                      searchParams: { tab: 'corporate-card' },
                      state: {},
                    });
                  }}
                />
              </div>
            </Tooltip>
          )}
          <Button
            variant="secondary"
            size="large"
            onClick={() => ModalService.show(CreateManyDeposits, {})}
          >
            Depósito via planilha
          </Button>
          <Menu type="default" options={menuOptions}>
            <Button variant="primary" size="large">
              Adicionar
              <Icons name="IconPlus" />
            </Button>
          </Menu>
        </Flex>
      </PageHeader>
      <PageContainer>
        {externalCards.useV2 ? (
          <Outlet />
        ) : (
          <Tab
            defaultTab={currentTab}
            onTabChanged={setParams}
            tabItens={tabItems}
          />
        )}
      </PageContainer>
    </>
  );
}
