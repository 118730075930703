import { trpc } from '@frontend/trpc';
import { dispatchToast } from '../../../../utils/dispatchEvents';

export default function useCancelBillet() {
  const context = trpc.useContext();

  const cancelBilletMutation = trpc.company.billing.cancel.useMutation({
    onSuccess() {
      void context.company.billing.search.invalidate();
      successToast();
    },
    onError: errorToast,
  });

  function cancelBillet(billetId: string) {
    return cancelBilletMutation.mutateAsync({
      billetId,
    });
  }

  return {
    cancelBillet,
    loading: cancelBilletMutation.isLoading,
  };
}

function successToast() {
  dispatchToast({
    type: 'success',
    content: 'Fatura cancelado com sucesso!',
  });
}

function errorToast(error?: { message: string }) {
  const formattedError = error?.message;
  if (formattedError) {
    dispatchToast({
      type: 'error',
      content: formattedError,
    });
  } else {
    dispatchToast({
      type: 'error',
      content: 'Não foi possível cancelar a fatura.',
      description:
        'Tente novamente. Se o problema persistir, entre em contato com o suporte',
    });
  }
}
