import ModalService from '../../../../../../services/ModalService';
import { dispatchToast } from '../../../../../../utils/dispatchEvents';
import { trpc } from '@frontend/trpc';

type Props = {
  employeeDepositIdList: string[];
  isComplementaryCredit: boolean;
  availabilityDate: Date;
  creditAmount: number;
};

export const useUpdateDeposit = () => {
  const modalController = ModalService.useModalController();

  const { mutate: updateDepositMutation, isLoading: loading } =
    trpc.company.automaticCredit.update.useMutation();

  function updateDeposit(props: Props) {
    return updateDepositMutation(
      {
        employeeDepositIdList: props.employeeDepositIdList,
        amount: props.creditAmount,
        availabilityDate: props.availabilityDate,
        automaticCreditType: props.isComplementaryCredit
          ? 'fixedValue'
          : 'fixedDate',
      },
      {
        onSuccess() {
          modalController.remove();
          dispatchToast({
            type: 'success',
            content: 'A Recarga Automática foi editada com sucesso!',
          });
        },
        onError(error) {
          dispatchToast({
            type: 'error',
            content: error.message,
          });
        },
      },
    );
  }

  return {
    updateDeposit,
    loading: loading,
  };
};
