import errors from '@frontend/utils/commonTexts/errors';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

function toastSuccess() {
  dispatchToast({
    type: 'success',
    content: 'Política excluída com sucesso!',
  });
}

function toastError() {
  dispatchToast({
    type: 'error',
    content: 'Não foi possível excluir a política',
    description: errors.actions.toastDescription,
  });
}

export default {
  toastSuccess,
  toastError,
};
