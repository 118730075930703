import LazyComponent from '@frontend/components/LazyComponent';
import RouterService from '@frontend/services/RouterService';
import { Route } from 'react-router-dom';
import BasePath from '../../routes/BasePath';

export const ROUTE_Home_ExternalCard = RouterService.create(
  `${BasePath.relativePath}/home/externalCard`,
);

export const ROUTE_Home_ExternalCards_v2 = RouterService.create(
  `${BasePath.relativePath}/externalCards`,
);

const ExternalCards = () => (
  <LazyComponent factory={() => import('./ExternalCards')} />
);

export const RouteComponent_Home_ExternalCard = (
  <Route path={ROUTE_Home_ExternalCard.path} element={<ExternalCards />} />
);

const ExternalCardsPage = () => (
  <LazyComponent factory={() => import('./ExternalCardsPage')} />
);

export const RouteComponent_Home_ExternalCards_v2 = (
  <Route
    path={ROUTE_Home_ExternalCards_v2.path}
    element={<ExternalCardsPage />}
  />
);
