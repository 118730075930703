export const MCC_LIST = {
  '0742': 'CONVENIENCE',
  '0763': 'GROCERY',
  '0780': 'CONVENIENCE',
  '1520': 'CONVENIENCE',
  '1711': 'CONVENIENCE',
  '1731': 'CONVENIENCE',
  '1740': 'CONVENIENCE',
  '1750': 'CONVENIENCE',
  '1761': 'CONVENIENCE',
  '1771': 'CONVENIENCE',
  '1799': 'CONVENIENCE',
  '2741': 'CONVENIENCE',
  '2791': 'CONVENIENCE',
  '2838': 'CULTURE',
  '2842': 'CONVENIENCE',
  '3000': 'MOBILITY',
  '3001': 'MOBILITY',
  '3002': 'MOBILITY',
  '3003': 'MOBILITY',
  '3004': 'MOBILITY',
  '3005': 'MOBILITY',
  '3006': 'MOBILITY',
  '3007': 'MOBILITY',
  '3008': 'MOBILITY',
  '3009': 'MOBILITY',
  '3010': 'MOBILITY',
  '3011': 'MOBILITY',
  '3012': 'MOBILITY',
  '3013': 'MOBILITY',
  '3014': 'MOBILITY',
  '3015': 'MOBILITY',
  '3016': 'MOBILITY',
  '3017': 'MOBILITY',
  '3018': 'MOBILITY',
  '3019': 'MOBILITY',
  '3020': 'MOBILITY',
  '3021': 'MOBILITY',
  '3022': 'MOBILITY',
  '3023': 'MOBILITY',
  '3024': 'MOBILITY',
  '3025': 'MOBILITY',
  '3026': 'MOBILITY',
  '3027': 'MOBILITY',
  '3028': 'MOBILITY',
  '3029': 'MOBILITY',
  '3030': 'MOBILITY',
  '3031': 'MOBILITY',
  '3032': 'MOBILITY',
  '3033': 'MOBILITY',
  '3034': 'MOBILITY',
  '3035': 'MOBILITY',
  '3036': 'MOBILITY',
  '3037': 'MOBILITY',
  '3038': 'MOBILITY',
  '3039': 'MOBILITY',
  '3040': 'MOBILITY',
  '3041': 'MOBILITY',
  '3042': 'MOBILITY',
  '3043': 'MOBILITY',
  '3044': 'MOBILITY',
  '3045': 'MOBILITY',
  '3046': 'MOBILITY',
  '3047': 'MOBILITY',
  '3048': 'MOBILITY',
  '3049': 'MOBILITY',
  '3050': 'MOBILITY',
  '3051': 'MOBILITY',
  '3052': 'MOBILITY',
  '3053': 'MOBILITY',
  '3054': 'MOBILITY',
  '3055': 'MOBILITY',
  '3056': 'MOBILITY',
  '3057': 'MOBILITY',
  '3058': 'MOBILITY',
  '3059': 'MOBILITY',
  '3060': 'MOBILITY',
  '3061': 'MOBILITY',
  '3062': 'MOBILITY',
  '3063': 'MOBILITY',
  '3064': 'MOBILITY',
  '3065': 'MOBILITY',
  '3066': 'MOBILITY',
  '3067': 'MOBILITY',
  '3068': 'MOBILITY',
  '3069': 'MOBILITY',
  '3071': 'MOBILITY',
  '3072': 'MOBILITY',
  '3075': 'MOBILITY',
  '3076': 'MOBILITY',
  '3077': 'MOBILITY',
  '3078': 'MOBILITY',
  '3079': 'MOBILITY',
  '3082': 'MOBILITY',
  '3083': 'MOBILITY',
  '3084': 'MOBILITY',
  '3085': 'MOBILITY',
  '3086': 'MOBILITY',
  '3087': 'MOBILITY',
  '3088': 'MOBILITY',
  '3089': 'MOBILITY',
  '3090': 'MOBILITY',
  '3094': 'MOBILITY',
  '3096': 'MOBILITY',
  '3097': 'MOBILITY',
  '3098': 'MOBILITY',
  '3099': 'MOBILITY',
  '3100': 'MOBILITY',
  '3102': 'MOBILITY',
  '3103': 'MOBILITY',
  '3106': 'MOBILITY',
  '3110': 'MOBILITY',
  '3111': 'MOBILITY',
  '3112': 'MOBILITY',
  '3115': 'MOBILITY',
  '3117': 'MOBILITY',
  '3118': 'MOBILITY',
  '3125': 'MOBILITY',
  '3126': 'MOBILITY',
  '3127': 'MOBILITY',
  '3129': 'MOBILITY',
  '3130': 'MOBILITY',
  '3131': 'MOBILITY',
  '3132': 'MOBILITY',
  '3133': 'MOBILITY',
  '3135': 'MOBILITY',
  '3136': 'MOBILITY',
  '3137': 'MOBILITY',
  '3138': 'MOBILITY',
  '3143': 'MOBILITY',
  '3144': 'MOBILITY',
  '3145': 'MOBILITY',
  '3146': 'MOBILITY',
  '3148': 'MOBILITY',
  '3151': 'MOBILITY',
  '3154': 'MOBILITY',
  '3156': 'MOBILITY',
  '3159': 'MOBILITY',
  '3161': 'MOBILITY',
  '3164': 'MOBILITY',
  '3165': 'MOBILITY',
  '3167': 'MOBILITY',
  '3170': 'MOBILITY',
  '3171': 'MOBILITY',
  '3172': 'MOBILITY',
  '3174': 'MOBILITY',
  '3175': 'MOBILITY',
  '3176': 'MOBILITY',
  '3177': 'MOBILITY',
  '3178': 'MOBILITY',
  '3180': 'MOBILITY',
  '3181': 'MOBILITY',
  '3182': 'MOBILITY',
  '3183': 'MOBILITY',
  '3184': 'MOBILITY',
  '3185': 'MOBILITY',
  '3186': 'MOBILITY',
  '3187': 'MOBILITY',
  '3188': 'MOBILITY',
  '3190': 'MOBILITY',
  '3191': 'MOBILITY',
  '3193': 'MOBILITY',
  '3196': 'MOBILITY',
  '3197': 'MOBILITY',
  '3200': 'MOBILITY',
  '3203': 'MOBILITY',
  '3204': 'MOBILITY',
  '3206': 'MOBILITY',
  '3211': 'MOBILITY',
  '3212': 'MOBILITY',
  '3213': 'MOBILITY',
  '3215': 'MOBILITY',
  '3216': 'MOBILITY',
  '3217': 'MOBILITY',
  '3218': 'MOBILITY',
  '3219': 'MOBILITY',
  '3220': 'MOBILITY',
  '3221': 'MOBILITY',
  '3222': 'MOBILITY',
  '3223': 'MOBILITY',
  '3226': 'MOBILITY',
  '3228': 'MOBILITY',
  '3229': 'MOBILITY',
  '3231': 'MOBILITY',
  '3233': 'MOBILITY',
  '3234': 'MOBILITY',
  '3235': 'MOBILITY',
  '3236': 'MOBILITY',
  '3238': 'MOBILITY',
  '3239': 'MOBILITY',
  '3240': 'MOBILITY',
  '3241': 'MOBILITY',
  '3242': 'MOBILITY',
  '3243': 'MOBILITY',
  '3245': 'MOBILITY',
  '3246': 'MOBILITY',
  '3247': 'MOBILITY',
  '3248': 'MOBILITY',
  '3251': 'MOBILITY',
  '3252': 'MOBILITY',
  '3253': 'MOBILITY',
  '3254': 'MOBILITY',
  '3256': 'MOBILITY',
  '3259': 'MOBILITY',
  '3260': 'MOBILITY',
  '3261': 'MOBILITY',
  '3262': 'MOBILITY',
  '3263': 'MOBILITY',
  '3266': 'MOBILITY',
  '3267': 'MOBILITY',
  '3280': 'MOBILITY',
  '3282': 'MOBILITY',
  '3284': 'MOBILITY',
  '3285': 'MOBILITY',
  '3286': 'MOBILITY',
  '3287': 'MOBILITY',
  '3292': 'MOBILITY',
  '3293': 'MOBILITY',
  '3294': 'MOBILITY',
  '3295': 'MOBILITY',
  '3296': 'MOBILITY',
  '3297': 'MOBILITY',
  '3298': 'MOBILITY',
  '3299': 'MOBILITY',
  '3300': 'CONVENIENCE',
  '3301': 'MOBILITY',
  '3302': 'MOBILITY',
  '3351': 'MOBILITY',
  '3352': 'MOBILITY',
  '3353': 'MOBILITY',
  '3354': 'MOBILITY',
  '3355': 'MOBILITY',
  '3357': 'MOBILITY',
  '3359': 'MOBILITY',
  '3360': 'MOBILITY',
  '3361': 'MOBILITY',
  '3362': 'MOBILITY',
  '3364': 'MOBILITY',
  '3366': 'MOBILITY',
  '3368': 'MOBILITY',
  '3370': 'MOBILITY',
  '3374': 'MOBILITY',
  '3376': 'MOBILITY',
  '3380': 'MOBILITY',
  '3381': 'MOBILITY',
  '3385': 'MOBILITY',
  '3386': 'MOBILITY',
  '3387': 'MOBILITY',
  '3388': 'MOBILITY',
  '3389': 'MOBILITY',
  '3390': 'MOBILITY',
  '3391': 'MOBILITY',
  '3393': 'MOBILITY',
  '3394': 'MOBILITY',
  '3395': 'MOBILITY',
  '3396': 'MOBILITY',
  '3398': 'MOBILITY',
  '3400': 'MOBILITY',
  '3405': 'MOBILITY',
  '3409': 'MOBILITY',
  '3412': 'MOBILITY',
  '3414': 'MOBILITY',
  '3420': 'MOBILITY',
  '3421': 'MOBILITY',
  '3423': 'MOBILITY',
  '3425': 'MOBILITY',
  '3427': 'MOBILITY',
  '3428': 'MOBILITY',
  '3429': 'MOBILITY',
  '3430': 'MOBILITY',
  '3431': 'MOBILITY',
  '3432': 'MOBILITY',
  '3433': 'MOBILITY',
  '3434': 'MOBILITY',
  '3435': 'MOBILITY',
  '3436': 'MOBILITY',
  '3437': 'MOBILITY',
  '3438': 'MOBILITY',
  '3439': 'MOBILITY',
  '3501': 'CULTURE',
  '3502': 'CULTURE',
  '3503': 'CULTURE',
  '3504': 'CULTURE',
  '3505': 'CULTURE',
  '3506': 'CULTURE',
  '3507': 'CULTURE',
  '3508': 'CULTURE',
  '3509': 'CULTURE',
  '3510': 'CULTURE',
  '3511': 'CULTURE',
  '3512': 'CULTURE',
  '3513': 'CULTURE',
  '3514': 'CULTURE',
  '3515': 'CULTURE',
  '3516': 'CULTURE',
  '3517': 'CULTURE',
  '3518': 'CULTURE',
  '3519': 'CULTURE',
  '3520': 'CULTURE',
  '3521': 'CULTURE',
  '3522': 'CULTURE',
  '3523': 'CULTURE',
  '3524': 'CULTURE',
  '3525': 'CULTURE',
  '3526': 'CULTURE',
  '3527': 'CULTURE',
  '3528': 'CULTURE',
  '3529': 'CULTURE',
  '3530': 'CULTURE',
  '3531': 'CULTURE',
  '3532': 'CULTURE',
  '3533': 'CULTURE',
  '3534': 'CULTURE',
  '3535': 'CULTURE',
  '3536': 'CULTURE',
  '3537': 'CULTURE',
  '3538': 'CULTURE',
  '3539': 'CULTURE',
  '3540': 'CULTURE',
  '3541': 'CULTURE',
  '3542': 'CULTURE',
  '3543': 'CULTURE',
  '3544': 'CULTURE',
  '3545': 'CULTURE',
  '3546': 'CULTURE',
  '3548': 'CULTURE',
  '3549': 'CULTURE',
  '3550': 'CULTURE',
  '3551': 'CULTURE',
  '3552': 'CULTURE',
  '3553': 'CULTURE',
  '3554': 'CULTURE',
  '3555': 'CULTURE',
  '3556': 'CULTURE',
  '3557': 'CULTURE',
  '3558': 'CULTURE',
  '3559': 'CULTURE',
  '3560': 'CULTURE',
  '3561': 'CULTURE',
  '3562': 'CULTURE',
  '3563': 'CULTURE',
  '3564': 'CULTURE',
  '3565': 'CULTURE',
  '3566': 'CULTURE',
  '3567': 'CULTURE',
  '3568': 'CULTURE',
  '3569': 'CULTURE',
  '3570': 'CULTURE',
  '3571': 'CULTURE',
  '3572': 'CULTURE',
  '3573': 'CULTURE',
  '3574': 'CULTURE',
  '3575': 'CULTURE',
  '3576': 'CULTURE',
  '3577': 'CULTURE',
  '3578': 'CULTURE',
  '3579': 'CULTURE',
  '3580': 'CULTURE',
  '3581': 'CULTURE',
  '3582': 'CULTURE',
  '3583': 'CULTURE',
  '3584': 'CULTURE',
  '3586': 'CULTURE',
  '3587': 'CULTURE',
  '3588': 'CULTURE',
  '3589': 'CULTURE',
  '3590': 'CULTURE',
  '3591': 'CULTURE',
  '3592': 'CULTURE',
  '3593': 'CULTURE',
  '3594': 'CULTURE',
  '3595': 'CULTURE',
  '3596': 'CULTURE',
  '3597': 'CULTURE',
  '3598': 'CULTURE',
  '3599': 'CULTURE',
  '3602': 'CULTURE',
  '3603': 'CULTURE',
  '3604': 'CULTURE',
  '3607': 'CULTURE',
  '3608': 'CULTURE',
  '3609': 'CULTURE',
  '3612': 'CULTURE',
  '3613': 'CULTURE',
  '3614': 'CULTURE',
  '3615': 'CULTURE',
  '3617': 'CULTURE',
  '3618': 'CULTURE',
  '3619': 'CULTURE',
  '3620': 'CULTURE',
  '3621': 'CULTURE',
  '3622': 'CULTURE',
  '3623': 'CULTURE',
  '3624': 'CULTURE',
  '3625': 'CULTURE',
  '3626': 'CULTURE',
  '3627': 'CULTURE',
  '3628': 'CULTURE',
  '3629': 'CULTURE',
  '3630': 'CULTURE',
  '3631': 'CULTURE',
  '3632': 'CULTURE',
  '3633': 'CULTURE',
  '3634': 'CULTURE',
  '3635': 'CULTURE',
  '3636': 'CULTURE',
  '3637': 'CULTURE',
  '3638': 'CULTURE',
  '3639': 'CULTURE',
  '3640': 'CULTURE',
  '3641': 'CULTURE',
  '3642': 'CULTURE',
  '3643': 'CULTURE',
  '3644': 'CULTURE',
  '3645': 'CULTURE',
  '3646': 'CULTURE',
  '3647': 'CULTURE',
  '3648': 'CULTURE',
  '3649': 'CULTURE',
  '3650': 'CULTURE',
  '3651': 'CULTURE',
  '3652': 'CULTURE',
  '3653': 'CULTURE',
  '3654': 'CULTURE',
  '3655': 'CULTURE',
  '3656': 'CULTURE',
  '3657': 'CULTURE',
  '3658': 'CULTURE',
  '3659': 'CULTURE',
  '3660': 'CULTURE',
  '3661': 'CULTURE',
  '3662': 'CULTURE',
  '3663': 'CULTURE',
  '3664': 'CULTURE',
  '3665': 'CULTURE',
  '3666': 'CULTURE',
  '3667': 'CULTURE',
  '3668': 'CULTURE',
  '3669': 'CULTURE',
  '3670': 'CULTURE',
  '3671': 'CULTURE',
  '3672': 'CULTURE',
  '3673': 'CULTURE',
  '3674': 'CULTURE',
  '3675': 'CULTURE',
  '3676': 'CULTURE',
  '3677': 'CULTURE',
  '3678': 'CULTURE',
  '3679': 'CULTURE',
  '3680': 'CULTURE',
  '3681': 'CULTURE',
  '3682': 'CULTURE',
  '3683': 'CULTURE',
  '3684': 'CULTURE',
  '3685': 'CULTURE',
  '3686': 'CULTURE',
  '3687': 'CULTURE',
  '3688': 'CULTURE',
  '3689': 'CULTURE',
  '3690': 'CULTURE',
  '3691': 'CULTURE',
  '3692': 'CULTURE',
  '3693': 'CULTURE',
  '3694': 'CULTURE',
  '3695': 'CULTURE',
  '3696': 'CULTURE',
  '3697': 'CULTURE',
  '3698': 'CULTURE',
  '3699': 'CULTURE',
  '3700': 'CULTURE',
  '3701': 'CULTURE',
  '3702': 'CULTURE',
  '3703': 'CULTURE',
  '3704': 'CULTURE',
  '3705': 'CULTURE',
  '3706': 'CULTURE',
  '3707': 'CULTURE',
  '3708': 'CULTURE',
  '3709': 'CULTURE',
  '3710': 'CULTURE',
  '3711': 'CULTURE',
  '3712': 'CULTURE',
  '3713': 'CULTURE',
  '3714': 'CULTURE',
  '3715': 'CULTURE',
  '3716': 'CULTURE',
  '3717': 'CULTURE',
  '3718': 'CULTURE',
  '3719': 'CULTURE',
  '3720': 'CULTURE',
  '3721': 'CULTURE',
  '3722': 'CULTURE',
  '3723': 'CULTURE',
  '3724': 'CULTURE',
  '3725': 'CULTURE',
  '3726': 'CULTURE',
  '3727': 'CULTURE',
  '3728': 'CULTURE',
  '3729': 'CULTURE',
  '3730': 'CULTURE',
  '3731': 'CULTURE',
  '3732': 'CULTURE',
  '3734': 'CULTURE',
  '3735': 'CULTURE',
  '3736': 'CULTURE',
  '3737': 'CULTURE',
  '3738': 'CULTURE',
  '3739': 'CULTURE',
  '3740': 'CULTURE',
  '3741': 'CULTURE',
  '3742': 'CULTURE',
  '3743': 'CULTURE',
  '3744': 'CULTURE',
  '3745': 'CULTURE',
  '3746': 'CULTURE',
  '3747': 'CULTURE',
  '3748': 'CULTURE',
  '3749': 'CULTURE',
  '3750': 'CULTURE',
  '3751': 'CULTURE',
  '3752': 'CULTURE',
  '3753': 'CULTURE',
  '3754': 'CULTURE',
  '3755': 'CULTURE',
  '3757': 'CULTURE',
  '3758': 'CULTURE',
  '3759': 'CULTURE',
  '3760': 'CULTURE',
  '3761': 'CULTURE',
  '3762': 'CULTURE',
  '3763': 'CULTURE',
  '3764': 'CULTURE',
  '3765': 'CULTURE',
  '3766': 'CULTURE',
  '3767': 'CULTURE',
  '3768': 'CULTURE',
  '3769': 'CULTURE',
  '3770': 'CULTURE',
  '3771': 'CULTURE',
  '3772': 'CULTURE',
  '3773': 'CULTURE',
  '3774': 'CULTURE',
  '3775': 'CULTURE',
  '3776': 'CULTURE',
  '3777': 'CULTURE',
  '3778': 'CULTURE',
  '3779': 'CULTURE',
  '3780': 'CULTURE',
  '3781': 'CULTURE',
  '3782': 'CULTURE',
  '3783': 'CULTURE',
  '3784': 'CULTURE',
  '3785': 'CULTURE',
  '3786': 'CULTURE',
  '3787': 'CULTURE',
  '3788': 'CULTURE',
  '3789': 'CULTURE',
  '3790': 'CULTURE',
  '3791': 'CULTURE',
  '3792': 'CULTURE',
  '3793': 'CULTURE',
  '3794': 'CULTURE',
  '3795': 'CULTURE',
  '3796': 'CULTURE',
  '3797': 'CULTURE',
  '3798': 'CULTURE',
  '3799': 'CULTURE',
  '3800': 'CULTURE',
  '3802': 'CULTURE',
  '3807': 'CULTURE',
  '3808': 'CULTURE',
  '3811': 'CULTURE',
  '3812': 'CULTURE',
  '3813': 'CULTURE',
  '3814': 'CULTURE',
  '3815': 'CULTURE',
  '3818': 'CULTURE',
  '3822': 'CULTURE',
  '3823': 'CULTURE',
  '3824': 'CULTURE',
  '3825': 'CULTURE',
  '3826': 'CULTURE',
  '3827': 'CULTURE',
  '3834': 'CULTURE',
  '3835': 'CULTURE',
  '3836': 'CULTURE',
  '3837': 'CULTURE',
  '3838': 'CULTURE',
  '4011': 'MOBILITY',
  '4111': 'MOBILITY',
  '4112': 'MOBILITY',
  '4119': 'HEALTH',
  '4121': 'MOBILITY',
  '4131': 'MOBILITY',
  '4214': 'MOBILITY',
  '4215': 'MEAL',
  '4225': 'MEAL',
  '4411': 'CONVENIENCE',
  '4457': 'MOBILITY',
  '4468': 'MOBILITY',
  '4511': 'MOBILITY',
  '4582': 'MOBILITY',
  '4722': 'CULTURE',
  '4723': 'CONVENIENCE',
  '4784': 'MOBILITY',
  '4789': 'MOBILITY',
  '4812': 'CONVENIENCE',
  '4814': 'CONVENIENCE',
  '4816': 'CONVENIENCE',
  '4821': 'CONVENIENCE',
  '4829': 'CULTURE',
  '4899': 'CULTURE',
  '4900': 'CONVENIENCE',
  '5013': 'MOBILITY',
  '5021': 'CONVENIENCE',
  '5039': 'CONVENIENCE',
  '5044': 'CONVENIENCE',
  '5045': 'CONVENIENCE',
  '5046': 'CONVENIENCE',
  '5047': 'CONVENIENCE',
  '5051': 'CONVENIENCE',
  '5065': 'CONVENIENCE',
  '5072': 'CONVENIENCE',
  '5074': 'CONVENIENCE',
  '5085': 'CONVENIENCE',
  '5094': 'CONVENIENCE',
  '5099': 'CONVENIENCE',
  '5111': 'CONVENIENCE',
  '5122': 'HEALTH',
  '5131': 'CONVENIENCE',
  '5137': 'CONVENIENCE',
  '5139': 'CONVENIENCE',
  '5169': 'CONVENIENCE',
  '5172': 'CONVENIENCE',
  '5192': 'CULTURE',
  '5193': 'CONVENIENCE',
  '5198': 'CONVENIENCE',
  '5199': 'GROCERY',
  '5200': 'CONVENIENCE',
  '5211': 'CONVENIENCE',
  '5231': 'CONVENIENCE',
  '5251': 'CONVENIENCE',
  '5261': 'CONVENIENCE',
  '5262': 'CULTURE',
  '5271': 'CONVENIENCE',
  '5272': 'CONVENIENCE',
  '5300': 'GROCERY',
  '5309': 'CONVENIENCE',
  '5310': 'CONVENIENCE',
  '5311': 'CONVENIENCE',
  '5331': 'CONVENIENCE',
  '5399': 'CONVENIENCE',
  '5411': 'GROCERY',
  '5422': 'GROCERY',
  '5441': 'GROCERY',
  '5451': 'GROCERY',
  '5462': 'MEAL',
  '5499': 'MEAL',
  '5511': 'MOBILITY',
  '5512': 'MOBILITY',
  '5521': 'MOBILITY',
  '5531': 'MOBILITY',
  '5532': 'MOBILITY',
  '5533': 'MOBILITY',
  '5541': 'MOBILITY',
  '5542': 'MOBILITY',
  '5551': 'MOBILITY',
  '5552': 'MOBILITY',
  '5561': 'CONVENIENCE',
  '5571': 'MOBILITY',
  '5592': 'MOBILITY',
  '5598': 'MOBILITY',
  '5599': 'MOBILITY',
  '5611': 'CONVENIENCE',
  '5621': 'CONVENIENCE',
  '5631': 'CONVENIENCE',
  '5641': 'CONVENIENCE',
  '5651': 'CONVENIENCE',
  '5655': 'CONVENIENCE',
  '5661': 'CONVENIENCE',
  '5681': 'CONVENIENCE',
  '5691': 'CONVENIENCE',
  '5697': 'CONVENIENCE',
  '5698': 'CONVENIENCE',
  '5699': 'CONVENIENCE',
  '5712': 'CONVENIENCE',
  '5713': 'CONVENIENCE',
  '5714': 'CONVENIENCE',
  '5718': 'CONVENIENCE',
  '5719': 'CONVENIENCE',
  '5722': 'CONVENIENCE',
  '5732': 'CONVENIENCE',
  '5733': 'CONVENIENCE',
  '5734': 'CONVENIENCE',
  '5735': 'CULTURE',
  '5811': 'MEAL',
  '5812': 'MEAL',
  '5813': 'MEAL',
  '5814': 'MEAL',
  '5815': 'CULTURE',
  '5816': 'CONVENIENCE',
  '5817': 'CONVENIENCE',
  '5818': 'CONVENIENCE',
  '5912': 'HEALTH',
  '5921': 'MEAL',
  '5931': 'CONVENIENCE',
  '5932': 'CONVENIENCE',
  '5933': 'CONVENIENCE',
  '5935': 'MOBILITY',
  '5937': 'CONVENIENCE',
  '5940': 'MOBILITY',
  '5941': 'CONVENIENCE',
  '5942': 'CULTURE',
  '5943': 'EDUCATION',
  '5944': 'CONVENIENCE',
  '5945': 'CONVENIENCE',
  '5946': 'CONVENIENCE',
  '5947': 'CONVENIENCE',
  '5948': 'CONVENIENCE',
  '5949': 'CONVENIENCE',
  '5950': 'CONVENIENCE',
  '5960': 'CONVENIENCE',
  '5962': 'MOBILITY',
  '5963': 'CONVENIENCE',
  '5964': 'CONVENIENCE',
  '5965': 'CONVENIENCE',
  '5966': 'CONVENIENCE',
  '5967': 'CONVENIENCE',
  '5968': 'HEALTH',
  '5969': 'CULTURE',
  '5970': 'CONVENIENCE',
  '5971': 'CONVENIENCE',
  '5972': 'CONVENIENCE',
  '5973': 'CONVENIENCE',
  '5975': 'HEALTH',
  '5976': 'HEALTH',
  '5977': 'CONVENIENCE',
  '5978': 'CONVENIENCE',
  '5983': 'MOBILITY',
  '5992': 'CONVENIENCE',
  '5993': 'CONVENIENCE',
  '5994': 'CULTURE',
  '5995': 'CONVENIENCE',
  '5996': 'CONVENIENCE',
  '5997': 'CONVENIENCE',
  '5998': 'CONVENIENCE',
  '5999': 'CONVENIENCE',
  '6010': 'CULTURE',
  '6011': 'NOT_AVAILABLE',
  '6012': 'CONVENIENCE',
  '6051': 'CONVENIENCE',
  '6211': 'CONVENIENCE',
  '6300': 'CONVENIENCE',
  '6513': 'CONVENIENCE',
  '6536': 'CONVENIENCE',
  '6537': 'CONVENIENCE',
  '6540': 'CONVENIENCE',
  '7011': 'MEAL',
  '7012': 'MOBILITY',
  '7032': 'CULTURE',
  '7033': 'MOBILITY',
  '7210': 'CONVENIENCE',
  '7211': 'CONVENIENCE',
  '7216': 'CONVENIENCE',
  '7217': 'CONVENIENCE',
  '7221': 'CONVENIENCE',
  '7230': 'CONVENIENCE',
  '7251': 'CONVENIENCE',
  '7261': 'CONVENIENCE',
  '7273': 'CONVENIENCE',
  '7276': 'CONVENIENCE',
  '7277': 'CONVENIENCE',
  '7278': 'CULTURE',
  '7296': 'CONVENIENCE',
  '7297': 'CONVENIENCE',
  '7298': 'CONVENIENCE',
  '7299': 'CONVENIENCE',
  '7311': 'CONVENIENCE',
  '7321': 'CULTURE',
  '7333': 'CONVENIENCE',
  '7338': 'CONVENIENCE',
  '7339': 'CONVENIENCE',
  '7342': 'CONVENIENCE',
  '7349': 'MOBILITY',
  '7361': 'CONVENIENCE',
  '7372': 'CONVENIENCE',
  '7375': 'CONVENIENCE',
  '7379': 'CONVENIENCE',
  '7392': 'CONVENIENCE',
  '7393': 'CONVENIENCE',
  '7394': 'CONVENIENCE',
  '7395': 'CONVENIENCE',
  '7399': 'CONVENIENCE',
  '7512': 'MOBILITY',
  '7513': 'MOBILITY',
  '7519': 'MOBILITY',
  '7523': 'MOBILITY',
  '7531': 'MOBILITY',
  '7534': 'MOBILITY',
  '7535': 'MOBILITY',
  '7538': 'MOBILITY',
  '7542': 'MOBILITY',
  '7549': 'MOBILITY',
  '7622': 'CONVENIENCE',
  '7623': 'CONVENIENCE',
  '7629': 'CONVENIENCE',
  '7631': 'CONVENIENCE',
  '7641': 'CONVENIENCE',
  '7692': 'CONVENIENCE',
  '7699': 'CONVENIENCE',
  '7800': 'CULTURE',
  '7801': 'CONVENIENCE',
  '7802': 'CONVENIENCE',
  '7829': 'CONVENIENCE',
  '7832': 'CULTURE',
  '7841': 'CULTURE',
  '7911': 'HEALTH',
  '7922': 'CULTURE',
  '7929': 'CULTURE',
  '7932': 'CONVENIENCE',
  '7933': 'CULTURE',
  '7941': 'HEALTH',
  '7991': 'CULTURE',
  '7992': 'CULTURE',
  '7993': 'CONVENIENCE',
  '7994': 'CONVENIENCE',
  '7995': 'CULTURE',
  '7996': 'MEAL',
  '7997': 'HEALTH',
  '7998': 'CULTURE',
  '7999': 'CONVENIENCE',
  '8011': 'HEALTH',
  '8021': 'HEALTH',
  '8031': 'HEALTH',
  '8041': 'HEALTH',
  '8042': 'HEALTH',
  '8043': 'HEALTH',
  '8049': 'HEALTH',
  '8050': 'HEALTH',
  '8062': 'HEALTH',
  '8071': 'HEALTH',
  '8099': 'HEALTH',
  '8111': 'CONVENIENCE',
  '8211': 'EDUCATION',
  '8220': 'EDUCATION',
  '8241': 'EDUCATION',
  '8244': 'EDUCATION',
  '8249': 'EDUCATION',
  '8299': 'EDUCATION',
  '8351': 'EDUCATION',
  '8398': 'MEAL',
  '8641': 'CONVENIENCE',
  '8651': 'CONVENIENCE',
  '8661': 'CONVENIENCE',
  '8675': 'MOBILITY',
  '8699': 'CONVENIENCE',
  '8734': 'CONVENIENCE',
  '8911': 'CONVENIENCE',
  '8931': 'CONVENIENCE',
  '8999': 'CONVENIENCE',
  '9211': 'CONVENIENCE',
  '9222': 'CONVENIENCE',
  '9223': 'CONVENIENCE',
  '9311': 'CONVENIENCE',
  '9399': 'CONVENIENCE',
  '9402': 'CONVENIENCE',
  '9405': 'CONVENIENCE',
  '9406': 'CONVENIENCE',
  '9700': 'CONVENIENCE',
  '9701': 'CONVENIENCE',
  '9702': 'CONVENIENCE',
  '9751': 'CONVENIENCE',
  '9752': 'CONVENIENCE',
  '9950': 'CONVENIENCE',
};
