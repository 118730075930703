import { Divider, LinkButton, Spinner } from '@flash-tecnologia/hros-web-ui-v2';
import { Template } from '@frontend/components/FocusedFlow/Template';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import BorderedSection from '@frontend/components/frames/BorderedSection';

import Icon from '@frontend/components/Icon';
import NotificationCard from '@frontend/components/NotificationCard';
import NotificationCallout from '@frontend/components/display/NotificationCallout';
import PDFViewer from '@frontend/components/display/PDFViewer';
import { ROUTE_Deposit } from '@frontend/pages/Transactions/Deposits';
import RouterService from '@frontend/services/RouterService';
import { toCurrency } from '@frontend/utils/masks';
import { UseFormReturn } from '../../forms/useForm';
import useFormData from './data/useFormData';
import useGetOrder from './data/useGetOrder';
import * as SC from './styles';

type Props = {
  form: UseFormReturn;
  closeFocusedFlow: () => void;
};

export default function PaymentInstructions(props: Props) {
  const formData = useFormData({ form: props.form });
  const orderData = useGetOrder({
    orderId: formData.metadata.needsOrderInfo
      ? formData.metadata.result?._id
      : '',
  });
  const navigate = RouterService.useNavigate();

  return (
    <Template
      pageInfo={{
        title: 'Fazer depósito',
      }}
      contentInfo={{
        title: 'Solicitação de depósito',
        description:
          'Seu depósito foi agendado com seus créditos em Flash Cash Corporativo.',
      }}
      Modules={
        <>
          <BorderedSection padding="m">
            <SC.Header>
              <Icon
                name="IconCheck"
                color="neutral_50"
                background="success"
                size={48}
              />
            </SC.Header>
            <Spacer y="xs" />
            <Typography.Headline7 color={'neutral_20'}>
              Solicitação concluída!
            </Typography.Headline7>
            <Typography.Body3 color={'neutral_30'}>
              {formData.metadata.description}
            </Typography.Body3>
            <Spacer y="xs" />
            <SC.SummaryArea>
              <SC.SummaryItem>
                <Typography.Body3 weight={700} color={'neutral_50'}>
                  Total do depósito
                </Typography.Body3>
                <Spacer y="xs4" />
                <Typography.Headline6 color="secondary_50">
                  {`${formData.complementary ? 'Até ' : ''}${toCurrency(
                    formData.metadata.totalAmount,
                  )}`}
                </Typography.Headline6>
              </SC.SummaryItem>
              <SC.SummaryItem>
                <Typography.Body3 weight={700} color={'neutral_50'}>
                  Disponibilização
                </Typography.Body3>
                <Spacer y="xs4" />
                <Typography.Headline6>
                  {formData.metadata.creditDateDescription}
                </Typography.Headline6>
              </SC.SummaryItem>
            </SC.SummaryArea>
            <Spacer y="xs" />
            {formData.depositType === 'AUTOMATIC' && (
              <>
                <NotificationCard
                  type="info"
                  iconName="IconInfoCircle"
                  subtitle="Para depósitos automáticos, é necessário ter saldo disponível no Flash Cash Corporativo no dia da disponibilização para que o depósito consiga ser efetuado no mês."
                />
                <Spacer y="xs" />
              </>
            )}
            {formData.metadata.needsOrderInfo && (
              <>
                {orderData.isLoading && (
                  <SC.LoaderArea>
                    <Spinner size={64} variant="primary" />
                  </SC.LoaderArea>
                )}
                {orderData.pdfUrl && (
                  <>
                    <PDFViewer
                      url={orderData.pdfUrl}
                      options={{ fit: 'auto', showToolbar: true }}
                      width="100%"
                      height="600px"
                    />
                    <Spacer y="xs" />
                  </>
                )}
                {orderData.isError && (
                  <>
                    <NotificationCallout
                      variant="error"
                      title="Erro ao exibir o documento"
                      description="Não conseguimos exibir o documento, mas você ainda o receberá normalmente no seu e-mail."
                    >
                      <LinkButton
                        variant="error"
                        onClick={() => orderData.refetch()}
                      >
                        Tentar novamente
                      </LinkButton>
                    </NotificationCallout>
                    <Spacer y="xs" />
                  </>
                )}
                {formData.paymentMethod === 'PIX' && (
                  <>
                    <SC.PixInstructionsArea>
                      <div>
                        <Typography.Body3 color={'neutral_20'}>
                          Passo-a-passo para o pagamento via QR Code
                        </Typography.Body3>
                        <Spacer y="xs4" />
                        <SC.ListItem>
                          <Icon
                            name="IconNumber1"
                            color="secondary_50"
                            size={24}
                            background="default"
                          />
                          <Typography.Body4 color="neutral_30">
                            No app do banco ou instituição financeira, entre na
                            área PIX
                          </Typography.Body4>
                        </SC.ListItem>
                        <Spacer y="xs2" />
                        <SC.ListItem>
                          <Icon
                            name="IconNumber2"
                            color="secondary_50"
                            size={24}
                            background="default"
                          />
                          <Typography.Body4 color="neutral_30">
                            Escolha a opção "pagar com QR Code" e escaneie o
                            código
                          </Typography.Body4>
                        </SC.ListItem>
                        <Spacer y="xs2" />
                        <SC.ListItem>
                          <Icon
                            name="IconNumber3"
                            color="secondary_50"
                            size={24}
                            background="default"
                          />
                          <Typography.Body4 color="neutral_30">
                            Confirme as informações e finalize o pagamento
                          </Typography.Body4>
                        </SC.ListItem>
                      </div>
                      <div>
                        <Typography.Body3 color={'neutral_20'}>
                          Você pode pagar usando o código "Pix copia e cola"
                        </Typography.Body3>
                        <Spacer y="xs4" />
                        <Typography.Body4
                          color="neutral_30"
                          style={{ wordWrap: 'break-word' }}
                        >
                          {orderData.pixCode}
                        </Typography.Body4>
                        <Spacer y="xs" />
                        <LinkButton variant="default">
                          Copiar código Pix
                        </LinkButton>
                      </div>
                    </SC.PixInstructionsArea>
                    <Spacer y="xs" />
                  </>
                )}
              </>
            )}
            <Divider orientation="horizontal" />
            <Spacer y="xs" />
            <SC.ButtonArea>
              <LinkButton
                variant="neutral"
                disabled={!orderData.reportUrl}
                onClick={() => {
                  if (orderData.reportUrl) {
                    download(orderData.reportUrl);
                  }
                }}
              >
                Baixar relatório da solicitação
              </LinkButton>
              <LinkButton
                variant="neutral"
                onClick={() => {
                  navigate({
                    route: ROUTE_Deposit,
                    params: {},
                    searchParams: {},
                    state: {},
                  });
                  props.closeFocusedFlow();
                }}
              >
                Ir para depósito
              </LinkButton>
            </SC.ButtonArea>
          </BorderedSection>
          <Spacer y={'m'} />
        </>
      }
    />
  );
}

function download(url: string) {
  window.open(url, '_blank');
}
