import styled from 'styled-components';
import Typography from '../../../../../components/Typography';

export const TitleTypography = styled(Typography.Body3)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 700;
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  gap: 20px;

  border: 1px solid #d1c7ce;
  border-radius: 8px;
`;

export const ToggleTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ToggleTitle = styled(Typography.Body3)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 600;
`;

export const ToggleDescription = styled(Typography.Body3)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-weight: 400;
`;
