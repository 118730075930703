import { ComponentPadding, makePadding } from '@frontend/utils/theme/paddings';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
  /** The background color of the whole component */
  backgroundColor?: 'neutral' | 'secondary' | 'error';
  /** The padding of the component.
   *
   * @example `padding="m"` // Applies "m" to x and y paddings
   * @example `padding=["s", "l"]` // Applies "s" to y padding and "l" to x padding
   */
  padding?: ComponentPadding;
  /** The color variant of the border */
  variant?: 'error' | 'info' | 'neutral' | 'secondary' | 'success';

  /** Only use if the style is very specific to the component use */
  style?: React.CSSProperties;
};

export default function BorderedSection(props: Props) {
  return <BorderedSectionComponent {...props} />;
}

const BorderedSectionComponent = styled.div<Omit<Props, 'style'>>`
  background-color: ${(p) => {
    switch (p.backgroundColor) {
      case 'neutral':
        return p.theme.colors.neutral[95];
      case 'secondary':
        return p.theme.colors.secondary[99];
      case 'error':
        return p.theme.colors.feedback.error[90];
      default:
        return 'unset';
    }
  }};
  padding: ${({ theme, padding }) =>
    padding ? makePadding(theme, padding) : 'none'};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  border: 1px solid
    ${({ theme, variant }) => {
      switch (variant) {
        case 'secondary':
          return theme.colors.secondary[70];
        case 'error':
          return theme.colors.feedback.error[70];
        case 'success':
          return theme.colors.feedback.success[40];
        case 'info':
          return theme.colors.feedback.info[70];
        case 'neutral':
        default:
          return theme.colors.neutral[90];
      }
    }};
`;
