import RouterService from '@frontend/services/RouterService';
import { Route } from 'react-router-dom';
import { zod } from 'react-router-typesafe-routes/zod';
import { z } from 'zod';
import ExternalCardDetails from './ExternalCardDetails';

export const ROUTE_ExternalCardDetails = RouterService.create(
  'corporateCard/externalCards/:cardId/details',
  {
    params: {
      /** [Legacy] ExpenseOn card ID */
      cardId: zod(z.string()).defined(),
    },
  },
);

export const RouteComponent_ExternalCardDetails = (
  <Route
    path={ROUTE_ExternalCardDetails.path}
    element={<ExternalCardDetails />}
  />
);
