import styled from 'styled-components';

type ButtonContainerProps = {
  clickable?: boolean;
  disabled?: boolean;
  variant: 'primary' | 'secondary' | 'success' | 'info' | 'error' | 'neutral';
};

export const ButtonContainer = styled.button<ButtonContainerProps>`
  border-radius: ${(p) => p.theme.borders.radius.pill};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 8px;
  padding: 4px 12px;
  width: fit-content;

  ${(p) => {
    // Disabled
    if (p.disabled)
      return {
        backgroundColor: p.theme.colors.neutral[90],
        color: p.theme.colors.neutral[70],
        cursor: 'default',
      };

    // Color mapping
    const colorMap = {
      primary: {
        backgroundColor: p.theme.colors.secondary[95],
        color: p.theme.colors.secondary[50],
        focusBorderColor: p.theme.colors.secondary[70],
        hoverBackgroundColor: p.theme.colors.primary,
        hoverColor: 'white',
      },
      secondary: {
        backgroundColor: p.theme.colors.tertiary[90],
        color: p.theme.colors.secondary[20],
        focusBorderColor: p.theme.colors.secondary[70],
        hoverBackgroundColor: p.theme.colors.primary,
        hoverColor: 'white',
      },
      success: {
        backgroundColor: p.theme.colors.feedback.success[90],
        color: p.theme.colors.feedback.success[10],
        focusBorderColor: p.theme.colors.feedback.success[70],
        hoverBackgroundColor: p.theme.colors.feedback.success[40],
        hoverColor: 'white',
      },
      info: {
        backgroundColor: p.theme.colors.feedback.info[90],
        color: p.theme.colors.feedback.info[10],
        focusBorderColor: p.theme.colors.feedback.info[70],
        hoverBackgroundColor: p.theme.colors.feedback.info[40],
        hoverColor: 'white',
      },
      error: {
        backgroundColor: p.theme.colors.feedback.error[90],
        color: p.theme.colors.feedback.error[10],
        focusBorderColor: p.theme.colors.feedback.error[70],
        hoverBackgroundColor: p.theme.colors.feedback.error[40],
        hoverColor: 'white',
      },
      neutral: {
        backgroundColor: p.theme.colors.neutral[90],
        color: p.theme.colors.neutral[10],
        focusBorderColor: p.theme.colors.neutral[70],
        hoverBackgroundColor: p.theme.colors.neutral[40],
        hoverColor: 'white',
      },
    };

    const coloredStyles = {
      backgroundColor: colorMap[p.variant].backgroundColor,
      color: colorMap[p.variant].color,
      cursor: 'default',
    };

    // Adds focus and hover behavior for clickable Tags
    if (p.clickable) {
      coloredStyles.cursor = 'pointer';
      coloredStyles[':focus'] = {
        // Prevents border from overflowing tag
        padding: '3px 11px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: colorMap[p.variant].focusBorderColor,
      };

      coloredStyles[':hover'] = {
        backgroundColor: colorMap[p.variant].hoverBackgroundColor,
        color: colorMap[p.variant].hoverColor,
      };
    }

    return coloredStyles;
  }}
`;

type DotProps = {
  variant: 'primary' | 'secondary' | 'success' | 'info' | 'error' | 'neutral';
};

export const Dot = styled.div<DotProps>`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: ${(p) => {
    switch (p.variant) {
      case 'primary':
        return p.theme.colors.primary;
      case 'secondary':
        return p.theme.colors.secondary[20];
      case 'neutral':
        return p.theme.colors.neutral[70];
      case 'error':
        return p.theme.colors.feedback.error[50];
      case 'info':
        return p.theme.colors.feedback.info[50];
      case 'success':
        return p.theme.colors.feedback.success[50];
    }
  }};
`;
