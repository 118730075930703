import ModalService from '@frontend/services/ModalService';
import { useUploadTransactionController } from './data/useUploadTransactionController';
import { FocusedFlow } from '@frontend/components/FocusedFlow/FocusedFlow';

export const UploadTransactions = ({}) => {
  const { headerMetadata, content, footer } = useUploadTransactionController();

  return (
    <FocusedFlow
      content={content}
      headerMetadata={headerMetadata}
      footer={footer}
    />
  );
};

export default ModalService.create(UploadTransactions);
