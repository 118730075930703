import styled from 'styled-components';

interface flexColumnProps {
  gap?: '4px' | '8px' | '12px' | '16px' | '40px';
}

export const FlexColumn = styled.div<flexColumnProps>`
  display: flex;
  gap: ${({ gap }) => gap || '12px'};
  flex-direction: column;
`;
