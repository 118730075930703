import { MultiSelect } from '@flash-tecnologia/hros-web-ui-v2';
import { Controller } from 'react-hook-form';
import useFindUsers, {
  BackboneUsers,
} from '@frontend/pages/Home/data/useFindUsers';

export const FlashCardSection = ({ form }) => {
  const { findBackBoneUsers, options, loading } = useFindUsers(
    form.getValues('users'),
  );

  return (
    <Controller
      name="users"
      control={form.control}
      render={(props) => {
        const error = props.formState.errors.users;

        const emptyListError = error && error.message;
        const alreadyActiveUserError =
          error && !emptyListError && (error as any).flat()[0].message;

        return (
          <MultiSelect
            renderInput={() => <div />} // TODO: remove this prop from web-ui
            options={[...options, ...props.field.value]}
            fullWidth
            limitTags={2}
            error={alreadyActiveUserError}
            helperText={
              alreadyActiveUserError
                ? 'Colaborador selecionado na lista já possui Cartão Corporativo'
                : undefined
            }
            noOptionsText="Digite algo para buscar"
            filterOptions={(option) => option}
            getOptionDisabled={(option) => option.alreadyActive}
            loading={loading}
            loadingText="Carregando..."
            onChange={({ target }) => {
              const newValue = target.value;
              if (!!newValue.length) {
                findBackBoneUsers({ stringSearch: newValue });
              }
            }}
            onSelectChange={(_, selected: BackboneUsers | null) => {
              props.field.onChange(!!selected ? selected : []);
            }}
            label="Nome ou CPF da Pessoa"
          />
        );
      }}
    />
  );
};
