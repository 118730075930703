import { Menu, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import { formatDate, toCurrency } from '../../../utils/masks';
import * as styles from './styled';
// import Spacer from "../../components/Spacer";
import { TableProps } from '../../../components/TableV2';
// import Tag from "../../components/TagV2";
import { useNavigate } from 'react-router-dom';
import ModalService from '../../../services/ModalService';
import useCorporateStatus from '../data/useCorporateStatus';
import ReclassificationPreviewModal from '../modals/reclassification/ReclassificationPreviewModal';
import type { GetStatementOutput } from './data/useTableData';

type Rows = GetStatementOutput['items'][number];
type Columns = TableProps<Rows>['columns'];

export function statementTableColumns(): Columns {
  return [
    {
      accessorKey: 'date',
      header: 'Data',
      accessorFn: ({ date }) => formatDate(date),
    },
    {
      accessorKey: 'description',
      header: 'Descrição',
      cell: ({ cell }) => {
        const value = cell.row.original.description;
        return <styles.NoWrap>{value}</styles.NoWrap>;
      },
    },
    {
      accessorKey: 'amount',
      header: 'Valor',
      cell: ({ cell }) => {
        const value = cell.row.original.amount;
        return <styles.NoWrap>{toCurrency(value)}</styles.NoWrap>;
      },
    },
    {
      id: 'actions',
      header: 'Ações',
      cell: ({ cell }) => <ActionCell transaction={cell.row.original} />,
    },
  ];
}

type ActionCellProps = {
  transaction: Rows;
};

function ActionCell({ transaction }: ActionCellProps) {
  const { data } = useCorporateStatus();
  const navigate = useNavigate();

  const options = [
    {
      label: 'Ver mais detalhes',
      onClick: () => {
        navigate(`/corporateCard/statement/${transaction._id}`, {
          state: {
            transaction,
          },
        });
      },
    },
  ];

  if (data?.shouldShowSwitch && transaction.type === 'OPEN_LOOP_PAYMENT')
    options.push({
      label: 'Reclassificar compra',
      onClick: () => {
        ModalService.show(ReclassificationPreviewModal, {
          transactionId: transaction.aggregateId,
        });
      },
    });

  return (
    <Menu type="default" locked options={options}>
      <PillButton size="medium" variant="default" icon="IconDotsVertical" />
    </Menu>
  );
}
