import Typography from '@frontend/components/Typography';
import React from 'react';
import * as SC from './styles';

type TagProps = {
  /** Text rendered inside */
  children: React.ReactNode;
  /** Defines the size */
  size: 'lg' | 'md' | 'sm' | 'xs';
  /** Defines the color */
  variant: 'primary' | 'secondary' | 'success' | 'info' | 'error' | 'neutral';
  /** Adds a Dot to the left of the content. Used to indicate a 'status' */
  dot?: boolean;
  /** Disables the button and changes the color to gray */
  disabled?: boolean;
  /** Handler fired after a click.
   *
   * If defined, the Tag changes appearance on focus, hover, and click, behaving as a button. */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const TypographyComponentMap = {
  lg: Typography.Body3,
  md: Typography.Body4,
  sm: Typography.Caption,
  xs: Typography.Caption,
} as const satisfies Record<
  TagProps['size'],
  (typeof Typography)[keyof typeof Typography]
>;

export default function Tag(props: TagProps) {
  const TypographyComponent = TypographyComponentMap[props.size];

  return (
    <SC.ButtonContainer
      clickable={!!props.onClick}
      disabled={props.disabled}
      onClick={props.onClick}
      variant={props.variant}
    >
      {props.dot && <SC.Dot variant={props.variant} />}
      <TypographyComponent noWrap color="inherit">
        {props.children}
      </TypographyComponent>
    </SC.ButtonContainer>
  );
}
