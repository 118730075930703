import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { Template } from '@frontend/components/FocusedFlow/Template';
import ModalService from '@frontend/services/ModalService';
import { useState } from 'react';
import {
  BilletDepositFeedback,
  DepositSetup,
  PixDepositFeedback,
} from '../modules';
import { PAYMENT_METHOD, useCreateDeposit } from './useCreateDeposit';

const STEPS = {
  SETUP: 0,
  FEEDBACK: 1,
} as const;

export const useAddBalanceController = (amount?: number) => {
  const modalService = ModalService.useModalController();

  const [activeStep, setActiveStep] = useState<
    (typeof STEPS)[keyof typeof STEPS]
  >(STEPS.SETUP);

  const {
    form,
    createDepositFormSubmit,
    adminInfo,
    createOrderLoading,
    getBillingInfoLoading,
    billingInfo,
  } = useCreateDeposit(() => {
    setActiveStep(STEPS.FEEDBACK);
  }, amount);

  const getContentInfoByStep = () =>
    ({
      [STEPS.SETUP]: {
        title: 'Adicionar crédito',
        description:
          'Adicione crédito de Flash Cash à carteira corporativa da empresa para realizar depósitos instantâneos para as contas corporativas sempre que precisar.',
      },
      [STEPS.FEEDBACK]: {
        title: 'Solicitação de depósito',
        description: `Seu depósito foi solicitado e será processado após o pagamento do ${
          form.getValues().paymentMethod === PAYMENT_METHOD.PIX
            ? 'pix'
            : 'boleto'
        }.`,
      },
    })[activeStep];

  const getModulesByStep = () =>
    ({
      [STEPS.SETUP]: <DepositSetup form={form} />,
      [STEPS.FEEDBACK]:
        form.getValues().paymentMethod === PAYMENT_METHOD.PIX ? (
          <PixDepositFeedback
            form={form}
            adminInfo={adminInfo}
            pixMetadata={billingInfo}
            isLoading={getBillingInfoLoading}
          />
        ) : (
          <BilletDepositFeedback
            form={form}
            adminInfo={adminInfo}
            billetMetadata={billingInfo}
            isLoading={getBillingInfoLoading}
          />
        ),
    })[activeStep];

  const getActionsByStep = () =>
    ({
      [STEPS.SETUP]: {
        leftActions: {
          onClick: () => modalService.remove(),
          label: 'Cancelar',
        },
        rightActions: [
          {
            onClick: () => createDepositFormSubmit(),
            label: (
              <>
                Continuar <Icons name="IconArrowRight" />
              </>
            ),
            isDisabled: false,
            isLoading: createOrderLoading,
            variant: 'primary',
          },
        ],
      } as const,
      [STEPS.FEEDBACK]: {
        rightActions: [
          {
            onClick: () => modalService.remove(),
            label: 'Concluir',
            isDisabled: false,
            isLoading: false,
            variant: 'primary',
          },
        ],
      } as const,
    })[activeStep];

  return {
    headerMetadata: {
      breadcrumbs: [
        {
          link: '/corporateCard/management/deposits',
          text: 'Flash cash corporativo',
        },
        {
          text: 'Adicionar crédito',
        },
      ],
      stepper: {
        steps: [
          'Defina o valor e o método de depósito',
          'Solicitação de crédito concluída',
        ],
        activeStep,
      },
    },
    content: (
      <Template
        Modules={getModulesByStep()}
        contentInfo={getContentInfoByStep()}
        pageInfo={{
          title: 'Flash Cash Corporativo',
        }}
      />
    ),
    footer: getActionsByStep(),
  };
};
