import { Button, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { Drawer } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import TagButton from '../TagButton';
import Typography from '../Typography';

export default function TagFilterDrawer(
  props: React.PropsWithChildren<{
    onApply: () => void;
    onClear: () => void;
    onClose?: () => void;
    disabled?: boolean;
  }>,
) {
  const [isOpen, setIsOpen] = React.useState(false);

  function handleOnClose() {
    setIsOpen(false);
    props.onClose?.();
  }

  return (
    <>
      <TagButton
        status={props.disabled ? 'disabled' : 'neutral'}
        label="Mais filtros"
        onClick={() => setIsOpen(true)}
        leftIcon="IconPlus"
      />
      <Drawer
        anchor={'right'}
        open={isOpen}
        onClose={handleOnClose}
        keepMounted
      >
        <StyledDrawerContainer>
          <StyledDrawerMain>
            <Typography.Headline7>Filtros</Typography.Headline7>
            {props.children}
          </StyledDrawerMain>
          <StyledDrawerButtonArea>
            <LinkButton
              variant="default"
              onClick={props.onClear}
              style={{ alignSelf: 'center' }}
            >
              Limpar
            </LinkButton>
            <Button
              variant="primary"
              size="large"
              onClick={props.onApply}
              style={{ width: '70%' }}
            >
              Filtrar
            </Button>
          </StyledDrawerButtonArea>
        </StyledDrawerContainer>
      </Drawer>
    </>
  );
}

const StyledDrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledDrawerMain = styled.div`
  width: 670px;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 40px 64px;
  gap: 24px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const StyledDrawerButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 128px;
  border-top: 1px solid ${(p) => p.theme.colors.neutral[90]};
  align-items: center;
  justify-content: space-between;
`;
