import ModalService from '@frontend/services/ModalService';
import { trpc } from '@frontend/trpc';
import { zodResolver } from '@hookform/resolvers/zod';
import { CardJSON } from 'backend/src/externalServices/ExpenseManagementService/users/schemas/card.json-schema';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useRefundUserBalance } from '../../../data/useRefundUserBalance';
import RefundUserBalanceSuccess from '../feedback/RefundUserBalanceSuccess';

type Props = {
  allSelected?: boolean;
  callback?: () => void;
  filter: {
    active?: Array<'active' | 'disabled'>;
    balance?: {
      max: number;
      min: number;
    };
    behavior?: 'include' | 'exclude';
    cards?: {
      status?: Array<CardJSON['status']>;
    };
    employeeId?: Array<string>;
    stringInput?: string;
  };
  total: number;
};

export function useRefundBalance(input: Props, callback?: () => void) {
  const modalController = ModalService.useModalController();

  const { total, filter } = input;

  const { data, isLoading: getLoading } = trpc.company.cards.get.useQuery(
    total === 1 && filter.employeeId?.length === 1 ? filter.employeeId[0] : '',
    {
      enabled: total === 1 && filter.employeeId?.length === 1,
    },
  );

  const FormSchema = z
    .object({
      amountToReturn: z
        .number()
        .refine(
          (value) => !data || (!!data?.balance && value <= data.balance),
          {
            message: 'Este valor é maior que o Saldo disponível',
          },
        )
        .optional(),
      returnAllBalance: z.boolean().optional(),
    })
    .refine(
      ({ returnAllBalance, amountToReturn }) =>
        returnAllBalance || !!amountToReturn,
      { message: 'Valor deve ser maior que 0', path: ['amountToReturn'] },
    );

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: { amountToReturn: undefined, returnAllBalance: undefined },
    mode: 'onChange',
  });
  const { loading, refundUserBalance } = useRefundUserBalance();

  const handleSubmit = form.handleSubmit(
    ({ amountToReturn, returnAllBalance }) => {
      refundUserBalance(
        {
          ...filter,
          ids: filter.employeeId,
          search: filter.stringInput,
          total: total,
          refundValue: returnAllBalance ? 'all' : amountToReturn || 0,
        },
        () => {
          modalController.remove();
          ModalService.show(RefundUserBalanceSuccess, { totalItems: total });
          callback?.();
        },
      );
    },
  );

  return {
    form,
    handleSubmit,
    loading: loading || (total === 1 && getLoading),
    userBalance: data?.balance,
  };
}
