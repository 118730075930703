import Table from '@frontend/components/TableV2';
import { toCurrency } from '@frontend/utils/masks';
import { useTableData } from './data/useTableData';
import { statementTableColumns } from './tableSetup';
import TagFilters from '@frontend/components/TagFilters';
import fromCurrency from '@frontend/utils/masks/fromCurrency';

export default function FlashCardStatement() {
  const {
    amount,
    getStatement,
    getStatementLoading,
    isFiltered,
    filters,
    setFilters,
    pagination,
    setPagination,
  } = useTableData();

  return (
    <Table
      loading={getStatementLoading}
      pagination={{
        pageIndex: pagination.currentPage,
        pageSize: pagination.pageSize,
      }}
      emptyState={{
        isFiltered,
        emptyText: 'Você ainda não tem nenhuma transação',
        filteredEmptyText: 'Não encontramos nenhuma transação',
      }}
      onPaginationChange={({ pageIndex, pageSize }) => {
        setPagination({
          currentPage: pageIndex,
          pageSize,
        });
      }}
      columns={statementTableColumns()}
      data={getStatement?.items ?? []}
      pageCount={getStatement?.meta.totalPages}
      TableFilters={
        <>
          <TagFilters.DateRange
            label="Data"
            disabled={getStatementLoading}
            initialRange={{
              from: filters.startDate,
              to: filters.endDate,
            }}
            onApply={(value) => {
              const filterCopy = { ...filters };
              if (value?.from && value?.to) {
                filterCopy.startDate = value?.from;
                filterCopy.endDate = value?.to;
              } else {
                delete filterCopy.startDate;
                delete filterCopy.endDate;
              }
              setFilters(filterCopy);
            }}
          />
          <TagFilters.Range
            label="Valor"
            disabled={getStatementLoading || amount?.min === amount?.max}
            initialValue={
              filters.amountMin !== undefined && filters.amountMax !== undefined
                ? {
                    min: filters.amountMin,
                    max: filters.amountMax,
                  }
                : undefined
            }
            min={amount?.min ?? 0}
            max={amount?.max ?? 0}
            mask={toCurrency}
            fromMask={fromCurrency}
            onApply={(values) => {
              const { min, max } = values;
              setFilters({
                amountMax: max !== undefined ? Number(max) : undefined,
                amountMin: min !== undefined ? Number(min) : undefined,
              });
            }}
          />
        </>
      }
    />
  );
}
