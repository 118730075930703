import { Menu, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import React from 'react';
import { TableProps } from '../../../../components/TableV2';
import Tag from '../../../../components/TagV2';
import Typography from '../../../../components/Typography';
import ModalService from '../../../../services/ModalService';
import { type OutputData } from '../data/searchBillets';
import CancelBilletModal from '../modals/CancelBilletModal';

/* ------------------------------------------- Columns ------------------------------------------ */
type Rows = OutputData['data'][number];
type Columns = TableProps<Rows>['columns'];

const statusMap = {
  cancelled: {
    title: 'Cancelado',
    variant: 'neutral',
  },
  generating: {
    title: 'Gerando',
    variant: 'error',
  },
  paid: {
    title: 'Pago',
    variant: 'success',
  },
  waiting: {
    title: 'Aguardando pagamento',
    variant: 'info',
  },
  failed: {
    title: 'Falhou',
    variant: 'error',
  },
} as const satisfies Record<
  Rows['status'],
  {
    title: string;
    variant: React.ComponentProps<typeof Tag>['variant'];
  }
>;

export default [
  {
    accessorKey: 'code',
    header: 'Código',
    cell: (context) => (
      <Typography.Body4 weight={700} color={'neutral_30'} noWrap>
        {context.row.original.code}
      </Typography.Body4>
    ),
  },
  {
    accessorKey: 'createdAt',
    header: 'Emissão',
    cell: (context) => (
      <Typography.Body4 color={'neutral_40'} noWrap>
        {context.row.original.createdAt}
      </Typography.Body4>
    ),
  },
  {
    accessorKey: 'dueDate',
    header: 'Vencimento',
    cell: (context) => (
      <Typography.Body4 color={'neutral_40'} noWrap>
        {context.row.original.dueDate}
      </Typography.Body4>
    ),
  },
  {
    accessorKey: 'totalAmount',
    header: 'Valor',
    cell: (context) => (
      <Typography.Body4 color={'neutral_40'} noWrap>
        {context.row.original.totalAmount}
      </Typography.Body4>
    ),
  },
  {
    accessorKey: 'destination',
    header: 'Destino',
    cell: (context) => (
      <Typography.Body4 color={'neutral_40'} noWrap>
        {context.row.original.destination}
      </Typography.Body4>
    ),
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: (context) => {
      const status = statusMap[context.row.original.status];
      return (
        <Tag variant={status.variant} size="sm">
          {status.title}
        </Tag>
      );
    },
  },
  {
    accessorKey: 'actions',
    header: 'Ações',
    isSticky: true,
    cell: (context) => {
      const element = context.row.original;
      const options: React.ComponentProps<typeof Menu>['options'] = [];
      if (!!element.debitLink) {
        options.push({
          label: 'Baixar nota de débito',
          onClick: () => {
            const win = window.open(element.debitLink as string);
            if (win) {
              win.focus();
            }
          },
        });
      }
      if (element.billetLink) {
        options.push({
          label: 'Baixar fatura',
          onClick: () => {
            const win = window.open(element.billetLink as string);
            if (win) {
              win.focus();
            }
          },
        });
      }
      if (element.status === 'waiting') {
        options.push({
          label: 'Cancelar fatura',
          onClick: () =>
            ModalService.show(CancelBilletModal, { billet: element }),
        });
      }

      if (!options.length) return null;
      return (
        <Menu type="default" locked options={options}>
          <PillButton size="medium" variant="default" icon="IconDotsVertical" />
        </Menu>
      );
    },
  },
] satisfies Columns;
