import { trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const optionUser = z.object({
  alreadyActive: z.boolean().optional(),
  documentNumber: z.string(),
  id: z.string(),
});

const FlashCardFormSchema = z.object({
  users: z
    .array(
      optionUser.refine(({ alreadyActive }) => !alreadyActive, {
        message: 'Essa pessoa já possui Cartão Corporativo',
      }),
    )
    .min(1),
});

export const useCreateFlashCorporateCardUser = (onSuccessCallback) => {
  const context = trpc.useContext();
  const { isLoading, mutate } = trpc.company.cards.add.useMutation({
    onError: (error) => {
      dispatchToast({
        type: 'error',
        content: error.message,
      });
    },
    onSuccess: (data) => {
      context.company.cards.search.refetch(undefined);

      const userWithoutPlasticCard = data.some(({ hasCard }) => !hasCard);

      onSuccessCallback?.(userWithoutPlasticCard);
    },
  });

  const flashCardForm = useForm<z.infer<typeof FlashCardFormSchema>>({
    resolver: zodResolver(FlashCardFormSchema),
    defaultValues: { users: [] },
    mode: 'onChange',
  });

  const flashCardFormSubmit = flashCardForm.handleSubmit(({ users }) => {
    mutate({
      users: users.map((user) => ({
        id: user.id,
        documentNumber: user.documentNumber,
      })),
    });
  });

  return {
    flashCardForm,
    addFlashCardUser: flashCardFormSubmit,
    isLoading,
  };
};
