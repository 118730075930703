import { ShapeIcon } from '@flash-tecnologia/hros-web-ui-v2';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';

import BorderedSection from '@frontend/components/frames/BorderedSection';

export function StepFeedbackV1() {
  return (
    <BorderedSection padding="m">
      <Spacer y={'xs2'} />
      <ShapeIcon variant="info" stroke="info" name="IconRefresh" size={48} />
      <Spacer y={'xs2'} />
      <Typography.Headline7 color="neutral_20">
        Report em processo de criação
      </Typography.Headline7>
      <Spacer y={'xs2'} />
      <Typography.Body3 color="neutral_30">
        Você pode sair dessa tela e continuar navegando normalmente. Quando ele
        estiver pronto, você poderá baixá-lo na área de Reports e também o
        enviaremos para seu e-mail.
      </Typography.Body3>
    </BorderedSection>
  );
}
