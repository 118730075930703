import TableClient from '@frontend/components/TableClient';
import columns from './table/columns';
import useTagFilters from './table/useTagFilters';
import useSearchFilter from './table/useSearchFilter';
import useGetStatement from './data/useGetStatement';

export default function ExternalCardStatement() {
  const statement = useGetStatement();
  const tagFilters = useTagFilters({
    metadata: statement?.metadata,
    disableAll: !statement.items.length,
  });
  const searchFilter = useSearchFilter({
    disable: !statement.items?.length,
  });

  return (
    <TableClient
      columns={columns}
      data={statement.items}
      emptyState={{
        emptyText: 'Ainda não há transações',
        filteredEmptyText: 'Nenhuma transação encontrada',
      }}
      loading={statement.isLoading}
      TableFilters={tagFilters.FilterComponent}
      filter={tagFilters.formattedFilter}
      TableSearchFilter={searchFilter.SearchFilterComponent}
      searchFilter={searchFilter.appliedFilter}
      enablePagination
    />
  );
}
