import { trpc } from '@frontend/trpc';
import toasts from '../toasts';

type Input = {
  cardId: string;
  policyId: string;
  onSuccess: () => void;
};

export default function useUnlinkPolicy(input: Input) {
  const context = trpc.useContext();
  const unlinkPolicy = trpc.company.policies.cardLinks.patch.useMutation();
  return {
    mutateAsync: async () => {
      return unlinkPolicy
        .mutateAsync({
          policyId: input.policyId,
          unlinkCardIds: [input.cardId],
        })
        .then(() => {
          toasts.toastSuccess();
          context.company.cards.search.invalidate();
          context.company.policies.cardLinks.getCards.setData(
            { policyId: input.policyId },
            (old) => {
              if (!old) return;
              return old.filter((card) => card.id !== input.cardId);
            },
          );
          context.company.policies.get.setData(
            {
              policyId: input.policyId,
            },
            (old) => {
              if (!old) return;
              return {
                ...old,
                cardIds: old.cardIds.filter((card) => card !== input.cardId),
              };
            },
          );
          input.onSuccess();
        })
        .catch(() => {
          toasts.toastServerError();
        });
    },
    isLoading: unlinkPolicy.isLoading,
  };
}
