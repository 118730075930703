import { FocusedFlow } from '@frontend/components/FocusedFlow';
import ModalService from '@frontend/services/ModalService';
import { ROUTE_Home } from '../..';
import useController from './data/useController';
import useForm from './forms/useForm';
import PaymentMethod from './steps/01-PaymentMethod';
import Confirmation from './steps/02-Confirmation';
import PaymentInstructions from './steps/03-PaymentInstructions';

type Props = {
  initialValues: Parameters<typeof useForm>[0];
  useApiHook: () => {
    mutateAsync: (_: unknown) => Promise<unknown>;
    data?: {
      _id: string;
      value: number;
      creditDate: Date;
      paymentMethod: 'PIX' | 'FLASH_CASH' | 'BILLET';
    };
  };
};

function CreateDeposits(props: Props) {
  const modalController = ModalService.useModalController();
  const form = useForm(props.initialValues, props.useApiHook);
  const controller = useController({
    form,
    onClose: () => modalController.remove(),
  });

  const Content = () => {
    switch (controller.step) {
      case 1:
        return <PaymentMethod form={form} />;
      case 2:
        return <Confirmation form={form} />;
      case 3:
        return (
          <PaymentInstructions
            form={form}
            closeFocusedFlow={modalController.remove}
          />
        );
    }
  };

  return (
    <FocusedFlow
      headerMetadata={{
        breadcrumbs: [
          {
            text: 'Cartões corporativos',
            link: ROUTE_Home.path,
          },
          {
            text: 'Depósitos',
          },
        ],
        stepper: {
          activeStep: controller.step - 1,
          setActiveStep: controller.setStep,
          disableClick: true,
          steps: [
            'Definir pagamento e disponibilização',
            'Revisar detalhes e confirmar',
            'Finalizar solicitação',
          ],
        },
      }}
      content={Content()}
      footer={{
        rightActions: [
          {
            label: controller.confirmButton.label,
            variant: 'primary',
            isDisabled: !controller.confirmButton.enabled,
            isLoading: controller.loading,
            onClick: controller.confirmButton.onClick,
          },
        ],
        leftActions: controller.cancelButton.visible
          ? {
              label: 'Cancelar',
              onClick: controller.cancelButton.onClick,
              isDisabled: controller.loading,
            }
          : undefined,
      }}
    />
  );
}

export default ModalService.create(CreateDeposits);
