import { Template } from '@frontend/components/FocusedFlow/Template';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import BorderedSection from '@frontend/components/frames/BorderedSection';
import { FileFormatGroup } from '@frontend/components/inputs/FileFormatGroup';
import InputErrorMessage from '@frontend/components/inputs/_primitives/InputErrorMessage';
import {
  Controller,
  FieldPathValue,
  FieldValues,
  Path,
  UseFormReturn,
} from 'react-hook-form';

type BaseFieldValue = FieldValues & { format: 'CSV' | 'XLSX' | 'PDF' };

type Props<Values extends BaseFieldValue, PathValue extends Path<Values>> = {
  info: {
    title: string;
    description: string;
  };
  form: UseFormReturn<Values>;
  formats: Array<'CSV' | 'XLSX' | 'PDF'>;
  filters: Array<{
    name: PathValue;
    Component: (_: {
      onChange: (...event: unknown[]) => void;
      value: FieldPathValue<Values, PathValue>;
    }) => React.ReactElement;
    width?: string;
  }>;
  Table: () => React.ReactElement;
};

export default function <
  Values extends BaseFieldValue,
  PathValue extends Path<Values>,
>(props: Props<Values, PathValue>) {
  return (
    <Template
      pageInfo={{
        title: 'Criar Report',
      }}
      contentInfo={props.info}
      Modules={
        <BorderedSection padding="m">
          <Typography.Headline9 color="neutral_30">
            Selecione o formato do Report
          </Typography.Headline9>
          <Spacer y={'xs2'} />
          <Controller
            control={props.form.control}
            name={'format' as Path<Values>}
            render={({ field, fieldState }) => (
              <FileFormatGroup
                formats={props.formats}
                selected={field.value}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            )}
          />
          <Spacer y={'xs'} />
          <Typography.Headline9 color="neutral_30">
            Personalize seu relatório
          </Typography.Headline9>
          <Spacer y={'xs2'} />
          {props.filters.map((filter) => (
            <Controller
              control={props.form.control}
              name={filter.name}
              render={({ field, fieldState }) => (
                <div style={{ width: filter.width ?? '300px' }}>
                  <filter.Component
                    onChange={field.onChange}
                    value={field.value}
                  />
                  <InputErrorMessage error={fieldState.error?.message} />
                </div>
              )}
            />
          ))}
          <Spacer y={'xs'} />
          <Typography.Headline9 color="neutral_30">
            Formatação do Report
          </Typography.Headline9>
          <Spacer y={'xs2'} />
          <Typography.Body3 color="neutral_30">
            A tabela abaixo é uma visão da formatação final e os dados são
            apenas ilustrativos
          </Typography.Body3>
          <Spacer y={'xs3'} />
          <props.Table />
        </BorderedSection>
      }
    />
  );
}
