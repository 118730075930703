import FormService from '@frontend/services/FormService';
import ErrorMonitorService from '@frontend/services/MonitorService';
import { z } from 'zod';

export const FormSchema = z.object({
  employee: z.object(
    {
      label: z.string(),
      id: z.string(),
    },
    {
      required_error: 'Selecione pelo menos uma pessoa da lista',
    },
  ),
  cardNumber: z
    .string({
      required_error: 'Informar Número do cartão ou Proxy',
    })
    .refine((value) => value.length <= 16 && value.length >= 8, {
      message: 'Número do cartão inválido',
    }),
  cardOwnerName: z.string({
    required_error: 'Informar Nome do portador do cartão',
  }),
  accountabilityStartDate: z.date({
    required_error: 'Informar data de início da prestação de conta',
  }),
});

export type FormOutput = z.output<typeof FormSchema>;

export default function useForm(
  initialValue: Partial<z.infer<typeof FormSchema>>,
  useApiHook: () => {
    mutateAsync: (formValues: FormOutput) => Promise<void>;
    data?: string;
  },
) {
  const form = FormService.useCreateForm(FormSchema, {
    mode: 'onChange',
    defaultValues: initialValue,
  });
  const hookResponse = useApiHook();

  const onSubmit = form.handleSubmit(
    async (values) => {
      await hookResponse.mutateAsync(values);
    },
    (errors) => {
      ErrorMonitorService.error({
        message: 'Error while creating many deposits',
        severity: 'fatal',
        extras: {
          zodErrors: JSON.stringify(errors),
        },
      });
      throw new Error('Form parsing error');
    },
  );

  return {
    ...form,
    onSubmit,
    result: hookResponse.data,
  };
}

export type UseFormReturn = ReturnType<typeof useForm>;
