import styled from 'styled-components';

type ModuleContainerProps = {
  gap?: '16px' | '24px' | '32px';
  padding?: '16px' | '32px' | '40px';
};

export const ModuleContainer = styled.div<ModuleContainerProps>`
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => padding || '40px'};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  gap: ${({ gap }) => gap || '32px'};
`;

export const FocusedFlowContainer = styled.div`
  position: fixed;
  z-index: 10;
  background: white;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const FocusedFlowLimitBox = styled.div`
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;
