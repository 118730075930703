const options = [
  { label: '5 itens', value: 5 },
  { label: '10 itens', value: 10 },
  { label: '25 itens', value: 25 },
  { label: '50 itens', value: 50 },
];

const initial = {
  pageIndex: 0,
  pageSize: 10,
};

export default {
  options,
  initial,
};
