type PrimitiveTypes = string | number | Date | boolean;
export type ComplexTypes = PrimitiveTypes | ArrayToParse | JSONToParse;
type ArrayToParse = Array<ComplexTypes>;
type JSONToParse = {
  [key: string]: ComplexTypes;
};

function handleReservedSymbols(
  value: PrimitiveTypes,
  isNested?: boolean,
): string {
  if (isNested) {
    return `[${value.toString()}]`;
  }
  if (value instanceof Date) {
    return value.toISOString();
  }
  return value.toString();
}

function parseArray(array: ArrayToParse, prefix: string = '') {
  return array.reduce<string>(
    (encode: string, element: ComplexTypes, index: number) => {
      if (element instanceof Array) {
        const parsedArray = parseArray(element, `${prefix}[${index}]`);
        encode = `${encode}${parsedArray}`;
      } else if (
        element &&
        typeof element === 'object' &&
        !(element instanceof Date)
      ) {
        const parsedJson = parseJSON(element, `${prefix}[${index}]`);
        encode = `${encode}${parsedJson}`;
      } else if (prefix.includes('[')) {
        encode = `${encode}${prefix}=${handleReservedSymbols(element)}`;
      } else {
        encode = `${encode}${
          index === 0 ? `${prefix}=` : ','
        }${handleReservedSymbols(element)}`;
      }
      return encode;
    },
    '',
  );
}

function parseJSON(json: JSONToParse, prefix?: string) {
  return Object.keys(json).reduce((encoded: string, key: string) => {
    const parsedKey = handleReservedSymbols(key, !!prefix);
    if (!parsedKey || json[key] === undefined) {
      return encoded;
    }
    const nestedValue = json[key];
    if (nestedValue instanceof Array) {
      const parsedArray = parseArray(
        nestedValue,
        `${prefix ? prefix : '&'}${parsedKey}`,
      );
      encoded = `${encoded}${parsedArray}`;
    } else if (
      nestedValue &&
      typeof nestedValue === 'object' &&
      !(nestedValue instanceof Date)
    ) {
      const parsedJson = parseJSON(
        nestedValue,
        `${prefix ? prefix : '&'}${parsedKey}`,
      );
      encoded = `${encoded}${parsedJson}`;
    } else {
      encoded = `${encoded}${
        prefix ? prefix : '&'
      }${parsedKey}=${handleReservedSymbols(nestedValue)}`;
    }
    return encoded;
  }, '');
}

export default function encodeQueryParams(
  query: { [key: string]: ComplexTypes } | Array<ComplexTypes> | string,
): string {
  if (query instanceof Array) {
    return parseArray(query);
  } else if (!!query && typeof query === 'object') {
    return parseJSON(query).substring(1);
  }
  return query;
}
