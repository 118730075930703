import { trpc } from '../../../utils/trpc';

export default function useSetSeenRechargeOnboarding() {
  const setSeenFlashCashOnboarding = trpc.notices.setSeen.useMutation();
  const trpcContext = trpc.useContext();

  return function () {
    setSeenFlashCashOnboarding.mutate({ template: 'onboarding_recharge' });
    trpcContext.notices.get.invalidate({ template: 'onboarding_recharge' });
  };
}
