import TableClient from '@frontend/components/TableClient';
import columns from './columns';
import { useGetCards } from '../../../data/useGetCards';

type Props = {
  policyId: string;
};

export function Table(props: Props) {
  const cards = useGetCards({ policyId: props.policyId });
  return (
    <TableClient
      columns={columns}
      data={cards.data}
      emptyState={{
        emptyText: 'Nenhum cartão vinculado a essa política',
        filteredEmptyText: 'Nenhum resultado encontrado',
      }}
      loading={cards.isLoading}
      enablePagination
    />
  );
}
