import Callout from '@frontend/components/frames/Callout';
import Icon from '@frontend/components/Icon';
import SelectTable from '@frontend/components/inputs/SelectTable';
import {
  sortedMccGroups,
  translatedMccGroups,
} from '@frontend/utils/dataFormatters/mccGroups.dataFormatter';
import { Controller } from 'react-hook-form';
import { FormSchemaOutput, UseFormReturn } from '../../../forms/useForm';

type Props = {
  form: UseFormReturn;
};

export default function EstablishmentToggleList(props: Props) {
  return (
    <Callout
      title="Tipos de estabelecimento"
      description="Configure as categorias nas quais transações feitas com esse cartão serão aceitas."
      alignItems="start"
      leftAdornment={
        <Icon
          name="IconBuildingStore"
          size={48}
          color="secondary_50"
          background="default"
        />
      }
    >
      <Controller
        control={props.form.control}
        name="mccGroups"
        render={({ field, fieldState }) => (
          <SelectTable<keyof NonNullable<FormSchemaOutput['mccGroups']>>
            header="Tipos de estabelecimento"
            value={
              new Set(
                Object.entries(field.value ?? {})
                  .filter(([_, value]) => value)
                  .map(
                    ([key]) =>
                      key as keyof NonNullable<FormSchemaOutput['mccGroups']>,
                  ),
              )
            }
            onChange={(values) =>
              field.onChange({
                GROCERY: values.has('GROCERY'),
                MEAL: values.has('MEAL'),
                MOBILITY: values.has('MOBILITY'),
                // ACCOMMODATION: values.has('ACCOMMODATION'),
                EDUCATION: values.has('EDUCATION'),
                CULTURE: values.has('CULTURE'),
                HEALTH: values.has('HEALTH'),
                CONVENIENCE: values.has('CONVENIENCE'),
              })
            }
            error={fieldState.error?.message}
            options={sortedMccGroups.map((mccGroup) => ({
              ...translatedMccGroups[mccGroup],
              value: mccGroup,
            }))}
          />
        )}
      />
    </Callout>
  );
}
