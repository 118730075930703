import StepModal from '@frontend/components/modals/StepModal';
import Typography from '@frontend/components/Typography';
import ModalService from '@frontend/services/ModalService';
import OnboardingRequestBalanceEndModal from './OnboardingRequestBalanceEndModal';
import Spacer from '@frontend/components/Spacer';

function OnboardingRequestBalanceTutorialModal() {
  const modalController = ModalService.useModalController();
  return (
    <StepModal
      isOpen={modalController.visible}
      onClose={modalController.remove}
      onEnd={() => {
        modalController.remove();
        ModalService.show(OnboardingRequestBalanceEndModal, {});
      }}
      steps={[
        {
          title: 'Por que utilizar o pedido de saldo?',
          content: (
            <>
              <Typography.Body3 color="neutral_40">
                O{' '}
                <Typography.Body3 color="neutral_40" weight={700} as="span">
                  Pedido de saldo
                </Typography.Body3>{' '}
                facilitará a gestão de saldo em sua empresa, eliminando a
                necessidade de troca de mensagens com pedidos de abastecimento
                de cartão.
                <br />
                Agora, as pessoas de seu time poderão solicitar o saldo direto
                pelo{' '}
                <Typography.Body3 color="neutral_40" weight={700} as="span">
                  aplicativo Flash
                </Typography.Body3>{' '}
                e estes pedidos serão direcionados para sua análise, podendo
                serem aprovados ou reprovados, pela{' '}
                <Typography.Body3 color="neutral_40" weight={700} as="span">
                  plataforma Flash
                </Typography.Body3>
                .
              </Typography.Body3>
              <Spacer y="xs" />
              <img
                src={
                  'https://images.flashapp.com.br/expense/corporate-card/onboarding-request-balance-1.gif'
                }
              ></img>
            </>
          ),
        },
        {
          title: 'Como realizar o pedido de saldo?',
          content: (
            <>
              <Typography.Body3 color="neutral_40">
                Através do{' '}
                <Typography.Body3 color="neutral_40" weight={700} as="span">
                  aplicativo Flash
                </Typography.Body3>
                , a pessoa poderá realizar o pedido de saldo pelo menu de
                Carteira ou Cartões.
                <br />
                No momento do pedido, o colaborador informará o{' '}
                <Typography.Body3 color="neutral_40" weight={700} as="span">
                  valor solicitado, data sugerida para o depósito e motivo
                </Typography.Body3>
                .
              </Typography.Body3>
              <Spacer y="xs" />
              <img
                src={
                  'https://images.flashapp.com.br/expense/corporate-card/onboarding-request-balance-2.gif'
                }
              ></img>
            </>
          ),
        },
        {
          title: 'Como analisar o pedido recebido?',
          content: (
            <>
              <Typography.Body3 color="neutral_40">
                Já na{' '}
                <Typography.Body3 color="neutral_40" weight={700} as="span">
                  Plataforma Flash
                </Typography.Body3>
                , o aprovador terá visibilidade de todos os pedidos recebidos,
                constando informação do solicitante, motivo, valores e datas
                sugeridos para o depósito.
                <br />
                Nesta mesma tela, o aprovador terá opção de{' '}
                <Typography.Body3 color="neutral_40" weight={700} as="span">
                  aprovar ou reprovar o pedido
                </Typography.Body3>
                . Sendo aprovado, já é disponibilizado o fluxo para realização
                do depósito do saldo!
                <br />
                Que tal experimentar mais esse processo e facilitar a rotina do
                seu dia a dia?
              </Typography.Body3>
              <Spacer y="xs" />
              <img
                src={
                  'https://images.flashapp.com.br/expense/corporate-card/onboarding-request-balance-3.gif'
                }
              ></img>
            </>
          ),
        },
      ]}
    />
  );
}

export default ModalService.create(OnboardingRequestBalanceTutorialModal);
