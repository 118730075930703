import { PillButton, Stepper } from '@flash-tecnologia/hros-web-ui-v2';
import { Divider } from '@mui/material';
import React, { useState } from 'react';
import Spacer from '../../Spacer';
import Typography from '../../Typography';
import Modal from '../Modal';
import * as SC from './styles';

type Props = {
  /** Whether the modal is visible */
  isOpen: boolean;
  /** Callback to close the modal */
  onClose: () => void;
  /** Optional callback for when 'advancing' after the last step. **Will not** call onClose after */
  onEnd?: () => void;
  steps: Readonly<
    Array<{
      title: string;
      subtitle?: React.ReactNode;
      content: React.ReactElement;
    }>
  >;
};

export default function StepModal(props: Props) {
  const [activeStep, setActiveStep] = useState(0);

  function goToPreviousStep() {
    setActiveStep((current) => current - 1);
  }

  function goToNextStep() {
    if (activeStep <= props.steps.length - 2)
      setActiveStep((current) => current + 1);
    else {
      if (props.onEnd) props.onEnd();
      else props.onClose();
    }
  }

  return (
    <Modal open={props.isOpen} onClose={props.onClose}>
      <SC.Header>
        <Stepper
          steps={props.steps.map(() => '')}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          type="dotted"
        />
        <Spacer y="m" />
        <Typography.Headline6>
          {props.steps[activeStep].title}
        </Typography.Headline6>
        <Typography.Body3 color="neutral_50">
          {props.steps[activeStep].subtitle}
        </Typography.Body3>
      </SC.Header>
      <Divider orientation="horizontal" />
      <SC.Body>
        {activeStep !== 0 && (
          <div style={{ gridArea: 'left', alignSelf: 'center' }}>
            <PillButton
              size="medium"
              variant="default"
              icon="IconChevronLeft"
              onClick={goToPreviousStep}
            />
          </div>
        )}
        <div style={{ gridArea: 'main' }}>
          {props.steps[activeStep].content}
        </div>
        {(activeStep !== props.steps.length - 1 || props.onEnd) && (
          <div style={{ gridArea: 'right', alignSelf: 'center' }}>
            <PillButton
              size="medium"
              variant="default"
              icon="IconChevronRight"
              onClick={goToNextStep}
            />
          </div>
        )}
      </SC.Body>
    </Modal>
  );
}
