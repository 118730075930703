import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import ModalService from '@frontend/services/ModalService';
import { useNavigate } from 'react-router-dom';

type Props = {
  singleCard: boolean;
  cardIssuer: string;
  userWithoutPlasticCard?: boolean;
};

function CreateCorporateCardUserFeedbackSuccessModal(props: Props) {
  const modalController = ModalService.useModalController();
  const navigate = useNavigate();

  const isFlashCard = props.cardIssuer === 'FLASH';

  const actionSucceedMessage = `Cartão ${
    isFlashCard ? 'vinculado' : 'adicionado'
  } com sucesso! `;

  const externalCardActionDetails = `Agora é possível realizar a gestão dos gastos para ${
    props.singleCard ? 'a Pessoa' : 'as Pessoas'
  }.`;

  const flashCardActionDetails = `Agora é possível realizar recargas para ${
    props.singleCard ? 'a Pessoa' : 'as Pessoas'
  } e fazer a gestão dos gastos.`;

  const body =
    actionSucceedMessage +
    (isFlashCard ? flashCardActionDetails : externalCardActionDetails);

  const Body = () => (
    <>
      <span>{body}</span>
      {isFlashCard && props.userWithoutPlasticCard && (
        <strong>
          &nbsp;{props.singleCard ? 'A Pessoa ' : 'Pessoas '}ainda não&nbsp;
          {props.singleCard ? 'possui' : 'possuem'} cartão físico, é necessário
          solicitar.
        </strong>
      )}
    </>
  );

  const actions = {
    confirm: {
      text: 'Concluir',
      onClick: () => modalController.remove(),
    },
    ...(isFlashCard &&
      props.userWithoutPlasticCard && {
        actionButton: {
          text: 'Solicitar Cartão Físico',
          onClick: () => {
            modalController.remove();
            navigate('/benefits/dashboard/company/cards');
          },
        },
      }),
  };

  return (
    <ConfirmationModal
      buttons={actions}
      isOpen={modalController.visible}
      onClose={modalController.remove}
      variant="default"
      text={{
        title: 'Pronto!',
        body: <Body />,
      }}
    />
  );
}

export default ModalService.create(CreateCorporateCardUserFeedbackSuccessModal);
