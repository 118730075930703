import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import ModalService from '../../../../services/ModalService';
import useNotifyReclassification from '../../data/useNotifyReclassification';

type Props = {
  transactionId: string;
  nowIsCorporate: boolean;
};

function ReclassificationSuccessModal(props: Props) {
  const modalController = ModalService.useModalController();
  const { notifyReclassification, loading } = useNotifyReclassification({
    transactionId: props.transactionId,
  });

  function handleNotifyReclassification() {
    notifyReclassification();
  }

  return (
    <ConfirmationModal
      isOpen={modalController.visible}
      onClose={modalController.remove}
      variant="default"
      text={{
        title: 'Compra reclassificada com sucesso!',
        body: `Agora sua compra está na categoria de ${
          props.nowIsCorporate ? 'despesas corporativas' : 'benefício'
        }. Lembre-se que é possível reclassificar até 3x cada compra.`,
      }}
      buttons={{
        actionButton: {
          text: 'Enviar por email',
          onClick: handleNotifyReclassification,
        },
        confirm: {
          text: 'Concluir',
          onClick: modalController.remove,
        },
      }}
      loading={loading}
    />
  );
}

export default ModalService.create(ReclassificationSuccessModal);
