import {
  DatePicker,
  TextField,
  Toggle,
} from '@flash-tecnologia/hros-web-ui-v2';
import { DateTime } from 'luxon';
import DefaultModal from '../../../../../components/modals/DefaultModal';
import ModalService from '../../../../../services/ModalService';
import { onlyNumbers, toCurrency } from '../../../../../utils/masks';
import * as SC from './styled';
import { useEditAutomaticCredit } from './data/useEditAutomaticCreditController';
import { AutomaticCreditMetadata } from '../../types';

function disabledDate(date: Date): boolean {
  return (
    DateTime.now().startOf('day').diff(DateTime.fromJSDate(date), 'days').days >
    0
  );
}

export type EditAutomaticCreditProps = {
  automaticCredit: AutomaticCreditMetadata;
};

function EditAutomaticCreditModal(props: EditAutomaticCreditProps) {
  const modalController = ModalService.useModalController();

  const {
    actions,
    automaticCreditMetadata,
    setUpdatedAutomaticCredit,
    loading,
  } = useEditAutomaticCredit({
    automaticCreditMetadata: props.automaticCredit,
  });

  return (
    <DefaultModal
      header={{
        title: 'Editar Recarga Automática',
        subtitle:
          'O saldo da Recarga Automática é disponibilizado no cartão do colaborador mensalmente.',
      }}
      children={
        <SC.BodyWrapper>
          <SC.TitleTypography>Dados da Recarga Automática</SC.TitleTypography>
          <TextField
            label="Valor da recarga"
            value={
              automaticCreditMetadata.creditAmount !== null
                ? toCurrency(automaticCreditMetadata.creditAmount)
                : ''
            }
            onChange={({ target }) => {
              const amount = onlyNumbers(target.value.replace(',', ''));
              const amountAsInt = parseInt(amount.replace(/\.|\,/, ''));

              setUpdatedAutomaticCredit((automaticCreditMetadata) => ({
                ...automaticCreditMetadata,
                creditAmount: amountAsInt,
              }));
            }}
          />
          <SC.ToggleWrapper>
            <Toggle
              onChange={(event) => {
                setUpdatedAutomaticCredit((automaticCreditMetadata) => ({
                  ...automaticCreditMetadata,
                  type: event.target.checked ? 'fixedValue' : 'fixedDate',
                }));
              }}
              value={automaticCreditMetadata.type === 'fixedValue'}
              defaultChecked={automaticCreditMetadata.type === 'fixedValue'}
            />
            <SC.ToggleTextWrapper>
              <SC.ToggleTitle>Função complementar</SC.ToggleTitle>
              <SC.ToggleDescription>
                Se ativado, o colaborador irá receber apenas o valor que
                complete o saldo que ele já possui no cartão totalizando no
                valor da recarga. Esse valor é definido no momento da recarga,
                podendo ter variações do valor depositado.
              </SC.ToggleDescription>
            </SC.ToggleTextWrapper>
          </SC.ToggleWrapper>
          <DatePicker
            onDateChange={(date) => {
              setUpdatedAutomaticCredit((automaticCreditMetadata) => ({
                ...automaticCreditMetadata,
                availabilityDate: date ? date.toDate() : undefined,
              }));
            }}
            value={automaticCreditMetadata.availabilityDate as Date}
            label="Disponibilização do Saldo Corporativo"
            disabledDate={disabledDate}
          />
        </SC.BodyWrapper>
      }
      buttons={actions}
      loading={loading}
      isOpen={modalController.visible}
      onClose={modalController.remove}
    />
  );
}

export default ModalService.create(EditAutomaticCreditModal);
