import { RouterInputs, trpc } from '@frontend/trpc';
import { dispatchToast } from '../../../../utils/dispatchEvents';

type Input = RouterInputs['company']['reports']['userStatement'];

export default function useEmployeeStatement() {
  const { mutate, isLoading: loading } =
    trpc.company.reports.createSync.useMutation({
      onMutate() {
        loadingToast();
      },
      onSuccess(data) {
        const file = new Blob([data], {
          type: 'application/json',
        });
        const fileURL = window.URL.createObjectURL(file);
        const tempLink = document.createElement('a');
        tempLink.href = fileURL;
        tempLink.setAttribute(
          'download',
          'Relatório - extrato de colaborador.csv',
        );
        tempLink.click();
      },
      onError: (error) => errorToast(error.message),
    });

  return {
    employeeStatementReport: (input: Input, callback: () => void) =>
      mutate(input, { onSettled: callback }),
    loading,
  };
}

function loadingToast() {
  dispatchToast({
    type: 'warning',
    content: 'Baixando o extrato...',
    description: 'Isso pode demorar alguns segundos.',
  });
}

function errorToast(formattedError?: string) {
  if (formattedError) {
    dispatchToast({
      type: 'error',
      content: formattedError,
    });
  } else {
    dispatchToast({
      type: 'error',
      content: 'Não foi possível baixar o extrato.',
      description:
        'Tente novamente. Se o problema persistir, entre em contato com o suporte',
    });
  }
}
