import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import StepModal from '@frontend/components/modals/StepModal';
import ModalService from '@frontend/services/ModalService';
import OnboardingPoliciesEndModal from './OnboardingPoliciesEndModal';

function OnboardingPoliciesTutorialModal() {
  const modalController = ModalService.useModalController();
  return (
    <StepModal
      isOpen={modalController.visible}
      onClose={modalController.remove}
      onEnd={() => {
        modalController.remove();
        ModalService.show(OnboardingPoliciesEndModal, {});
      }}
      steps={[
        {
          title: 'Criar uma política',
          content: (
            <>
              <Typography.Body3 color="neutral_40">
                Em uma política de cartão corporativo, é possível definir regras
                como limite de gastos, habilitar saque, além de configurar dias
                de uso e tipos de estabelecimentos permitidos.
              </Typography.Body3>
              <Spacer y="xs" />
              <img
                src={
                  'https://images.flashapp.com.br/expense/corporate-card/onboarding-policies-1.gif'
                }
              ></img>
            </>
          ),
        },
        {
          title: 'Vincular cartões',
          content: (
            <>
              <Typography.Body3 color="neutral_40">
                Depois de criada, você pode vincular a política tanto aos
                cartões corporativos físicos como virtuais. Basta acessar a área
                de Cartões corporativos e selecionar os que você deseja fazer o
                vínculo. Também é possível vincular uma política durante a
                criação de um cartão.
              </Typography.Body3>
              <Spacer y="xs" />
              <img
                src={
                  'https://images.flashapp.com.br/expense/corporate-card/onboarding-policies-2.gif'
                }
              ></img>
            </>
          ),
        },
        {
          title: 'Ver políticas da empresa',
          content: (
            <>
              <Typography.Body3 color="neutral_40">
                Administradores poderão consultar os detalhes das políticas
                criadas para a empresa, assim como ativar, desativar e excluir
                políticas.
              </Typography.Body3>
              <Spacer y="xs" />
              <img
                src={
                  'https://images.flashapp.com.br/expense/corporate-card/onboarding-policies-3.gif'
                }
              ></img>
            </>
          ),
        },
        {
          title: 'Ver política vinculada ao cartão',
          content: (
            <>
              <Typography.Body3 color="neutral_40">
                Colaboradores poderão verificar qual política de uso está
                vinculada ao seu cartão corporativo (físico e virtual) acessando
                a área Meus Cartões na web e Cartões no app.
              </Typography.Body3>
              <Spacer y="xs" />
              <img
                src={
                  'https://images.flashapp.com.br/expense/corporate-card/onboarding-policies-4.gif'
                }
              ></img>
            </>
          ),
        },
      ]}
    />
  );
}

export default ModalService.create(OnboardingPoliciesTutorialModal);
