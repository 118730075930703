import {
  Breadcrumbs,
  Icons,
  LinkButton,
  PageContainer,
  PageHeader,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Spacer from '../../../components/Spacer';
import Typography from '../../../components/Typography';
import { formatDate, formatDateTime, toCurrency } from '../../../utils/masks';
import { GetStatementOutput } from '../EmployeeStatement/data/useTableData';
import { useTransactionInfo } from './data/useTransactionInfo';
import * as styled from './styled';

type Rows = GetStatementOutput['transactions'][number];

interface LocationState {
  transaction: Rows;
}

export default function EmployeeTransactionDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const { employeeId } = useParams();
  const { transaction } = location.state as LocationState;
  const { automaticDebitDate, automaticCredit } = transaction;

  const theme = useTheme();
  const { transactionType } = useTransactionInfo({
    transactionType: transaction.type || transaction.movement || '',
    metadata: {
      automaticCredit: !!automaticCredit,
    },
  });

  return (
    <>
      <PageHeader>
        <Breadcrumbs
          breadcrumbs={[
            <LinkButton
              onClick={() => {
                navigate(`/corporateCard/home`, { replace: true });
              }}
              variant="neutral"
            >
              Gerenciar cartão
            </LinkButton>,
            <LinkButton
              onClick={() => {
                navigate(`/corporateCard/home/${employeeId}/statement`, {
                  replace: true,
                });
              }}
              variant="neutral"
            >
              Detalhes do Cartão
            </LinkButton>,
            <LinkButton variant="neutral" disabled>
              Detalhes da transação
            </LinkButton>,
          ]}
          separator={<Icons name="IconChevronRight" fill="#ffffff" />}
        />
      </PageHeader>
      <PageContainer>
        <styled.Box>
          <styled.BigIcon
            name="IconBasket"
            size={45}
            color={theme.colors.primary}
          />
          <Spacer x="xs" />
          <styled.MainInfoBox>
            <Typography.Headline6 color="neutral_30">
              {transaction.description}
            </Typography.Headline6>
          </styled.MainInfoBox>
        </styled.Box>
        <Spacer y="s" />
        <styled.Box>
          <styled.BoxInfo>
            <Typography.Body4 color="neutral_50">Valor</Typography.Body4>
            <Typography.Headline5 color="neutral_30">
              {`${
                transaction.type === 'DEPOSIT' || transaction.type === 'CREDIT'
                  ? ''
                  : '-'
              } ${toCurrency(transaction.amount)}`}
            </Typography.Headline5>
          </styled.BoxInfo>
          <styled.BoxInfo>
            <Typography.Body4 color="neutral_50">Data e hora</Typography.Body4>
            <Typography.Headline8 color="neutral_30">
              {formatDateTime(transaction.date)}
            </Typography.Headline8>
          </styled.BoxInfo>
          <styled.BoxInfo>
            <Typography.Body4 color="neutral_50">Saldo</Typography.Body4>
            <Typography.Headline8 color="neutral_30">
              Corporativo
            </Typography.Headline8>
          </styled.BoxInfo>
        </styled.Box>
        <Spacer y="s" />
        <styled.BoxTransactionInfo>
          <Typography.Headline8 color="neutral_30">
            Descrição
          </Typography.Headline8>
          <Spacer y="xs" />
          <styled.Box>
            <styled.DetailInfo flex={2}>
              <Typography.Body3 color="neutral_30">Tipo</Typography.Body3>
            </styled.DetailInfo>
            <styled.DetailInfo flex={3}>
              <Typography.Body3 weight={700} color="neutral_30">
                {transactionType}
              </Typography.Body3>
            </styled.DetailInfo>
          </styled.Box>
          <Spacer y="xs" />
          {!!automaticDebitDate && (
            <styled.Box>
              <styled.DetailInfo flex={2}>
                <Typography.Body3 color="neutral_30">
                  Data de expiração
                </Typography.Body3>
              </styled.DetailInfo>
              <styled.DetailInfo flex={3}>
                <Typography.Body3 weight={700} color="neutral_30">
                  {formatDate(automaticDebitDate)}
                </Typography.Body3>
              </styled.DetailInfo>
            </styled.Box>
          )}
          {/* <Spacer y="xs" />
          <Typography.Headline8 color='neutral_30'>
            Cotação
          </Typography.Headline8>
          <Spacer y="xs" />
          <styled.Box>
            <styled.DetailInfo flex={2}>
              <Typography.Body3 color='neutral_30'>
                Valor Total
              </Typography.Body3>
            </styled.DetailInfo>
            <styled.DetailInfo flex={3}>
              <Typography.Body3 weight={700} color='neutral_30'>
                {toCurrency(transaction.amount)}
              </Typography.Body3>
            </styled.DetailInfo>
          </styled.Box> */}
        </styled.BoxTransactionInfo>
        <Spacer y="s" />
      </PageContainer>
    </>
  );
}
