import { trpc } from './trpc';

export function useBffLog() {
  const { mutate } = trpc.app.frontLog.useMutation();

  return {
    log: (message: string, body: unknown) =>
      mutate({
        message,
        body: JSON.stringify(body),
      }),
  };
}
