import StepModal from '@frontend/components/modals/StepModal';
import ModalService from '@frontend/services/ModalService';
import OnboardingRechargeEndModal from './OnboardingRechargeEndModal';

type Props = {
  /** Function that opens the recharge modal */
  callback: () => void;
};

function OnboardingRechargeTutorialModal(props: Props) {
  const modalController = ModalService.useModalController();

  function handleDismiss() {
    modalController.remove();
    props.callback();
  }

  function showEndModal() {
    modalController.remove();
    ModalService.show(OnboardingRechargeEndModal, { callback: props.callback });
  }

  return (
    <StepModal
      isOpen={modalController.visible}
      onClose={handleDismiss}
      onEnd={showEndModal}
      steps={[
        {
          title: 'Escolha o tipo de recarga',
          subtitle:
            'Recargas únicas acontecerão apenas uma vez. Recargas automáticas acontecem com recorrência mensal.',
          content: (
            <img
              src={
                'https://images.flashapp.com.br/expense/corporate-card/onboarding-recharge-1.gif'
              }
            ></img>
          ),
        },
        {
          title: 'Recarga Única',
          subtitle:
            'Escolha o valor e data para liberar a recarga para o colaborador. Você também pode selecionar uma data de expiração para essa recarga.',
          content: (
            <img
              src={
                'https://images.flashapp.com.br/expense/corporate-card/onboarding-recharge-2.gif'
              }
            ></img>
          ),
        },
        {
          title: 'Recarga Automática',
          subtitle:
            'Escolha o valor e data para liberar a recarga automática mensal. Você também pode ativar a função de saldo complementar.',
          content: (
            <img
              src={
                'https://images.flashapp.com.br/expense/corporate-card/onboarding-recharge-3.gif'
              }
            ></img>
          ),
        },
        {
          title: 'Seu colaborador já pode utilizar o Cartão Corporativo!',
          subtitle:
            'Agora que o seu colaborador consegue usar o cartão corporativo, você poderá fazer toda a gestão e acompanhamento de gastos.',
          content: (
            <img
              src={
                'https://images.flashapp.com.br/expense/corporate-card/onboarding-recharge-4.gif'
              }
            ></img>
          ),
        },
      ]}
    />
  );
}

export default ModalService.create(OnboardingRechargeTutorialModal);
