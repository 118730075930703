import {
  Button,
  LinkButton,
  PillButton,
} from '@flash-tecnologia/hros-web-ui-v2';
import React from 'react';
import Typography from '../../Typography';
import * as SC from './styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  loading?: boolean;
  header: {
    title: React.ReactNode;
    subtitle: React.ReactNode;
  };
  OverHeader?: React.ReactNode;
  children: React.ReactNode;
  buttons: {
    confirm: {
      text: string;
      disabled?: boolean;
      onClick: () => void;
    };
    cancel?: {
      text: string;
      onClick: () => void;
      variant?: 'neutral' | 'default';
    };
  };
};

export default function DefaultModal(props: Props) {
  return (
    <SC.Modal
      style={{ height: '100%' }}
      open={props.isOpen}
      onClose={props.onClose}
      header={
        <SC.Header>
          <SC.CloseButtonWrapper>
            <PillButton
              size="medium"
              variant="default"
              icon="IconX"
              onClick={props.onClose}
            />
          </SC.CloseButtonWrapper>
          {props.OverHeader || (
            <SC.TitleWrapper>
              <Typography.Headline6 color="neutral_20">
                {props.header.title}
              </Typography.Headline6>
              <Typography.Body3 color="neutral_50">
                {props.header.subtitle}
              </Typography.Body3>
            </SC.TitleWrapper>
          )}
        </SC.Header>
      }
      children={<SC.Body>{props.children}</SC.Body>}
      footer={
        <SC.Footer>
          {props.buttons.cancel && (
            <LinkButton
              variant={props.buttons.cancel.variant || 'neutral'}
              onClick={props.buttons.cancel.onClick}
              style={{ alignSelf: 'center' }}
              disabled={props.loading}
            >
              {props.buttons.cancel.text}
            </LinkButton>
          )}
          <Button
            size="large"
            onClick={props.buttons.confirm.onClick}
            disabled={props.buttons.confirm.disabled}
            buttonType="primary"
            variantType="default"
            loading={props.loading}
          >
            {props.buttons.confirm.text}
          </Button>
        </SC.Footer>
      }
    />
  );
}
