import { SelectField, TextField } from '@flash-tecnologia/hros-web-ui-v2';
import Typography from '@frontend/components/Typography';
import { FlexColumn } from '@frontend/pages/Management/tabs/FlashCard/focusedFlows/AddBalance/modules/styled';
import { CardPreview } from './components/CardPreview';
import { ExpirationDefinition } from './components/ExpirationDefinition';

const EXPIRATION_RULES = {
  DAYS: 'DAYS',
  MONTHS: 'MONTHS',
  CUSTOM: 'CUSTOM',
};

const expirationRulesMetadata = [
  {
    label: 'Dias',
    value: EXPIRATION_RULES.DAYS,
  },
  {
    label: 'Meses',
    value: EXPIRATION_RULES.MONTHS,
  },
  {
    label: 'Selecionar data de expiração',
    value: EXPIRATION_RULES.CUSTOM,
  },
];

type CardSetupProps = {
  card: {
    nickname: string;
    expiration: {
      expirationMetadata: string;
      expirationRule: keyof typeof EXPIRATION_RULES;
    };
  };
  employeeName: string;
  cardIndex: number;
  setCardMetadata: (e: unknown) => void;
  error: {
    nickname: {
      message: string;
    };
    expiration: {
      expirationMetadata: {
        message: string;
      };
      expirationRule: {
        message: string;
      };
      message?: string;
    };
  };
};
export const CardSetup = ({
  card,
  employeeName,
  cardIndex,
  setCardMetadata,
  error,
}: CardSetupProps) => {
  const { nickname, expiration } = card;
  const { expirationRule, expirationMetadata } = expiration || {};

  const expirationRuleError = !!error?.expiration?.message
    ? error.expiration.message
    : error.expiration?.expirationRule?.message;

  return (
    <FlexColumn gap="16px">
      <Typography.Headline9 color="neutral_30">
        Cartão {cardIndex + 1}
      </Typography.Headline9>
      <div style={{ display: 'flex', gap: '16px' }}>
        <FlexColumn gap="16px" style={{ flex: 1 }}>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <TextField
              name="nickname"
              style={{ width: '100%' }}
              label="Apelido do cartão"
              value={nickname}
              onChange={({ target }) => {
                setCardMetadata({
                  ...card,
                  nickname: target.value,
                });
              }}
              error={!!error.nickname}
              helperText={error.nickname?.message ?? ''}
            />
          </div>
          <div style={{ display: 'flex', gap: '16px', width: '100%' }}>
            <div style={{ flex: 1 }}>
              <SelectField
                fullWidth={true}
                label="Expira em"
                options={expirationRulesMetadata}
                onSelectChange={(_event, { value }) => {
                  setCardMetadata({
                    ...card,
                    expiration: {
                      expirationRule: value,
                    },
                  });
                }}
                value={expirationRule}
                error={!!expirationRuleError}
                helperText={expirationRuleError}
              />
            </div>
            <div style={{ flex: 1 }}>
              <ExpirationDefinition
                expirationRule={expirationRule}
                expirationMetadata={expirationMetadata}
                error={error?.expiration?.expirationMetadata?.message}
                setExpirationMetadata={(expirationMetadata) => {
                  setCardMetadata({
                    ...card,
                    expiration: {
                      ...card.expiration,
                      expirationMetadata,
                    },
                  });
                }}
              />
            </div>
          </div>
        </FlexColumn>
        <CardPreview
          expiration={expiration}
          nickname={nickname}
          username={employeeName}
        />
      </div>
    </FlexColumn>
  );
};
