import currency from 'currency.js';

function fromInput(input: string) {
  // Keeps only numeric and separator characters
  const strippedInput = input.replace(/[^\d.,]+/g, '');
  if (strippedInput === '') return null;

  /* Checks for the US format
  * Will match:
  000,000.00 - Complete format
  0000.00 - Decimal `.` followed by 2 digits 
  000.0 - Decimal `.` followed by 1 digit
  0,000. - Decimal `.` not followed

  * Won't match:
  000.000,00 - BR format
  0000,00 - BR format without thousand separator
  000.000 - `.` followed by 3 or more digits
  000,000 - `,` as thousand separator without `.` as decimal separator (BR format will take precedence)
  */
  if (strippedInput.match(/^[\d\,]+\.\d{0,2}$/gm)) {
    return currency(strippedInput, {
      decimal: '.',
      separator: ',',
    });
  }
  return currency(strippedInput, {
    decimal: ',',
    separator: '.',
  });
}

const CurrencyUtils = {
  fromInput,
};

export default CurrencyUtils;
