import { trpc } from '@frontend/trpc';

export default function useCreateDeposits() {
  const createMany = trpc.company.deposits.createManyBySheet.useMutation({
    retry: 2,
    retryDelay: 5000,
  });

  return {
    mutateAsync: createMany.mutateAsync,
    data: createMany.data,
  };
}

export type UseCreateDepositsReturn = ReturnType<typeof useCreateDeposits>;
