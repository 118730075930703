import {
  Pagination,
  Spinner,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  PaginationState,
  TableOptions,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useEffect } from 'react';
import EmptyFilteredTableSVG from '../../assets/table/empty_filtered_table.svg';
import EmptyTableSVG from '../../assets/table/empty_table.svg';
import Spacer from '../Spacer';
import customFilters from '../TableClient/customFilters';
import TagPaginationSelect from '../TagPaginationSelect';
import * as SC from './styles';

export type TableRef = {
  resetPageIndex: () => void;
};

export type TableProps<Columns> = {
  columns: Array<
    TableOptions<Columns>['columns'][number] & {
      isSticky?: true;
    }
  >;
  data: TableOptions<Columns>['data'];
  initialState?: { pagination: PaginationState };
  pagination?: PaginationState;
  onPaginationChange?: (_pagination: PaginationState) => void;
  loading: boolean;
  pageCount?: number;
  pageSizeOptions?: { value: number; label: string }[];
  emptyState: {
    emptyText: string;
    filteredEmptyText: string;
    isFiltered: boolean;
  };
  TableFilters?: React.ReactNode;
  tableRef?: React.Ref<TableRef>;
};

export default function Table<Columns>(props: TableProps<Columns>) {
  const [pagination, setPagination] = React.useState<PaginationState>(
    props.initialState?.pagination ?? { pageIndex: 0, pageSize: 10 },
  );

  useEffect(() => {
    props.onPaginationChange?.(pagination);
  }, [pagination]);

  const table = useReactTable<Columns>({
    columns: props.columns,
    data: props.data,
    getCoreRowModel: getCoreRowModel(),
    initialState: props.initialState,
    filterFns: customFilters,
    state: {
      pagination: props.pagination ?? props.initialState?.pagination,
    },
  });
  const tableState = table.getState();

  React.useImperativeHandle<TableRef, TableRef>(props.tableRef, () => ({
    resetPageIndex: () => {
      if (!props.initialState?.pagination && props.onPaginationChange) {
        props.onPaginationChange({ ...tableState.pagination, pageIndex: 0 });
      } else {
        setPagination({ ...pagination, pageIndex: 0 });
      }
    },
  }));

  const RenderedTable = () => {
    // Loading
    if (props.loading)
      return (
        <SC.EmptyTable>
          <Spinner variant="primary" size={48} />
        </SC.EmptyTable>
      );

    // Empty
    if (!props.data?.length)
      return (
        <SC.EmptyTable>
          {props.emptyState.isFiltered ? (
            <EmptyFilteredTableSVG />
          ) : (
            <EmptyTableSVG />
          )}
          <Typography variant="body3">
            {props.emptyState.isFiltered
              ? props.emptyState.filteredEmptyText
              : props.emptyState.emptyText}
          </Typography>
        </SC.EmptyTable>
      );

    let allHeaderElements: Array<number> = [];
    return (
      <div className="root">
        <SC.Table>
          <SC.TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => (
                  <SC.Th
                    sticky={header.column.columnDef['isSticky']}
                    ref={(element: HTMLTableHeaderCellElement) => {
                      if (element) {
                        const { headers } = headerGroup;
                        if (allHeaderElements.length === 0) {
                          const elements: HTMLTableHeaderCellElement[] =
                            Array.prototype.slice.call(
                              element.parentElement!.children,
                            );
                          allHeaderElements = elements.map(
                            ({ clientWidth }) => clientWidth,
                          );
                        }

                        if (
                          index <= headers.length / 2 - 1 &&
                          header.column.columnDef['isSticky']
                        ) {
                          element.style.left = `${element.offsetLeft}px`;
                        }
                        if (
                          index >= headers.length / 2 + 1 &&
                          header.column.columnDef['isSticky']
                        ) {
                          element.style.right = `${allHeaderElements
                            .slice(index + 1)
                            .reverse()
                            .reduce((offset, width) => offset + width, 0)}px`;
                        }
                      }
                    }}
                    key={header.id}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </SC.Th>
                ))}
              </tr>
            ))}
          </SC.TableHeader>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell, index) => (
                  <SC.TableCell
                    sticky={cell.column.columnDef['isSticky']}
                    ref={(element: HTMLTableDataCellElement) => {
                      if (element) {
                        const rowsLength = row.getVisibleCells().length;
                        if (
                          index <= rowsLength / 2 - 1 &&
                          cell.column.columnDef['isSticky']
                        ) {
                          element.style.left = `${element.offsetLeft}px`;
                        }
                        if (
                          index >= rowsLength / 2 + 1 &&
                          cell.column.columnDef['isSticky']
                        ) {
                          element.style.right = `${allHeaderElements
                            .slice(index + 1)
                            .reverse()
                            .reduce((offset, width) => offset + width, 0)}px`;
                        }
                      }
                    }}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </SC.TableCell>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext(),
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </SC.Table>
      </div>
    );
  };

  return (
    <>
      {!!props.TableFilters && (
        <SC.TableFilterContainer>
          <SC.FilterText variant="body3">Filtrar por</SC.FilterText>
          {props.TableFilters}
        </SC.TableFilterContainer>
      )}
      <Spacer y="xs" useMargin />
      <SC.TableContainer>
        {/* <SC.TableContainer ref={headerRef}> */}
        <RenderedTable />
      </SC.TableContainer>
      {props.pageCount !== undefined && (
        <SC.PaginationContainer>
          <TagPaginationSelect
            selectedValue={tableState.pagination.pageSize}
            options={props.pageSizeOptions ?? defaultPaginationOptions}
            onChange={(newValue) => {
              props.onPaginationChange?.({
                pageIndex: 0,
                pageSize: newValue,
              });
            }}
          />
          <Pagination
            page={tableState.pagination.pageIndex + 1}
            onChange={(_event, page) => {
              props.onPaginationChange?.({
                pageIndex: page - 1,
                pageSize: tableState.pagination.pageSize,
              });
            }}
            count={props.pageCount}
          />
        </SC.PaginationContainer>
      )}
    </>
  );
}

const defaultPaginationOptions = [
  { label: '5 itens', value: 5 },
  { label: '10 itens', value: 10 },
  { label: '25 itens', value: 25 },
  { label: '50 itens', value: 50 },
];
