import { toCurrency } from '@frontend/utils/masks';
import { trpc } from '@frontend/utils/trpc';

export function useCompanyBalance() {
  const {
    data: companyBalance,
    isError: companyBalanceError,
    isLoading: companyBalanceLoading,
    isRefetching: companyBalanceRefetching,
  } = trpc.company.balance.get.useQuery(undefined, {
    retry: false,
    retryOnMount: true,
    staleTime: 30 * 1000,
    cacheTime: 60 * 1000,
  });

  return {
    amount: companyBalance,
    balance: companyBalance !== undefined ? toCurrency(companyBalance) : '-',
    error: companyBalanceError,
    loading: companyBalanceLoading || companyBalanceRefetching,
  };
}
