import {
  Icons,
  LinkButton,
  Tab,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import Spacer from '@frontend/components/Spacer';
import { useTabPage } from '@frontend/utils/hooks/useTabPage';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useCompanyAssetFreeze } from './data/useCompanyAssetFreeze';
import { useCompanyBalance } from './data/useCompanyBalance';
import { useManagementInfo } from './data/useManagementInfo';
import * as styles from './styled';

const BASE_PATH = '/corporateCard/management/deposits';
const routesMap = ['nextCredit', 'failedCredit'];

export default function FlashCash() {
  const theme = useTheme();
  const assetFreeze = useCompanyAssetFreeze();
  const companyBalance = useCompanyBalance();
  const { billetToPayCount, nextMonthTotalAmount } = useManagementInfo();
  const navigate = useNavigate();
  const { currentTab, setParams } = useTabPage(BASE_PATH, routesMap);
  const footnote = assetFreeze?.raw ? (
    <styles.AssetFreezeFootnote value={`${assetFreeze?.formatted}`} />
  ) : undefined;

  return (
    <>
      <Spacer y="s" />
      <Spacer y="s" />
      <styles.SpaceBetween>
        <Typography variant="headline8">Insights</Typography>
        <LinkButton
          variant="default"
          onClick={() => {
            navigate('/corporateCard/transactions');
          }}
        >
          Histórico de movimentações
          <Icons name="IconChevronRight" fill="transparent" />
        </LinkButton>
      </styles.SpaceBetween>
      <Spacer y="s" />
      <styles.Container>
        <styles.CustomBoxAdornment
          loading={companyBalance.loading}
          hasError={!!companyBalance.error}
          leftAdornment={
            <styles.BigIcon
              size={24}
              color={theme.colors.primary}
              name="IconCurrencyDollar"
            />
          }
          title={'Saldo no Flash Cash Corporativo'}
          footnote={footnote}
          description={companyBalance.balance}
        />
        <Spacer x="xs" useMargin />
        <styles.CustomBoxAdornment
          loading={nextMonthTotalAmount.loading}
          hasError={!!nextMonthTotalAmount.error}
          leftAdornment={
            <styles.BigIcon
              size={24}
              color={theme.colors.primary}
              name="IconCalendarTime"
            />
          }
          title="Recargas para próximos 30 dias"
          description={nextMonthTotalAmount.amount}
        />
        <Spacer x="xs" useMargin />
        <styles.CustomBoxAdornment
          loading={billetToPayCount.loading}
          hasError={!!billetToPayCount.error}
          leftAdornment={
            <styles.BigIcon
              size={24}
              color={theme.colors.primary}
              name="IconReceipt2"
            />
          }
          title="Faturas em aberto"
          description={billetToPayCount.count}
        />
      </styles.Container>
      <Spacer y="s" />
      <styles.Wrapper title="Lançamentos">
        <Tab
          defaultTab={currentTab}
          onTabChanged={setParams}
          tabItens={[
            {
              label: 'Lançamentos futuros',
              component: <Outlet />,
            },
            {
              label: 'Lançamentos que falharam',
              component: <Outlet />,
            },
          ]}
        />
      </styles.Wrapper>
      <Spacer y="l" />
      <Spacer y="m" />
    </>
  );
}
