import { DateTime } from 'luxon';

export function formatDateTime(date: Date): string {
  return DateTime.fromJSDate(date)
    .setZone('America/Sao_Paulo')
    .toFormat("dd/LL/yy' às 'HH:mm");
}

export function formatDate(date: Date): string {
  return DateTime.fromJSDate(date)
    .setZone('America/Sao_Paulo')
    .toFormat('dd/LL/yyyy');
}

export function formatDateWithoutYear(date: Date): string {
  return DateTime.fromJSDate(date)
    .setZone('America/Sao_Paulo')
    .toFormat('dd/LL');
}

export function formatDatePeriod(startDate: Date, endDate: Date) {
  return `${formatDate(startDate)} a ${formatDate(endDate)}`;
}

export function getDateFromBRL(date: string): DateTime {
  return DateTime.fromFormat(date, 'dd/MM/yyyy');
}
