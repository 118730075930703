import { trpc } from '@frontend/trpc';
import toasts from './toasts';

type Input = {
  policyId: string;
  onSuccess: () => void;
};

export default function useDeletePolicy() {
  const deletePolicy = trpc.company.policies.deleteOne.useMutation();
  const context = trpc.useContext();
  return {
    mutateAsync: async (input: Input) => {
      return deletePolicy
        .mutateAsync({ policyId: input.policyId })
        .then(() => {
          // Invalidates cache for the policy search
          context.company.policies.search.invalidate();
          toasts.toastSuccess();
          input.onSuccess();
        })
        .catch(() => {
          toasts.toastError();
        });
    },
    isLoading: deletePolicy.isLoading,
  };
}
