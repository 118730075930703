import {
  Icons,
  LinkButton,
  PillButton,
  Tooltip,
  tableControllers,
} from '@flash-tecnologia/hros-web-ui-v2';
import Icon from '@frontend/components/Icon';
import Spacer from '@frontend/components/Spacer';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import Flex from '@frontend/components/frames/Flex';
import { RouterInputs, RouterOutputs } from '@frontend/trpc';
import { fileFormatsMap } from '@frontend/utils/dataFormatters/fileFormat.dataFormatter';
import { formatDate } from '@frontend/utils/masks';
import useReportDownload from '../data/useReportDownload';

type CreateReportInput = RouterInputs['company']['reports']['create'] & {
  action: 'create';
};
type RetryCreateReportInput = RouterInputs['company']['reports']['retry'] & {
  action: 'retry';
};

type Props = {
  retry: (_: CreateReportInput | RetryCreateReportInput) => void;
};

type Row = RouterOutputs['company']['reports']['search']['entries'][number];

type Columns = Parameters<
  typeof tableControllers.useTableColumns<Row>
>[0]['columns'];

export function useColumns(props: Props) {
  return [
    {
      id: 'createdAt',
      accessorFn: (row) => formatDate(row.createdAt),
      header: () => <Header>Data de solicitação</Header>,
    },
    {
      id: 'filter',
      accessorFn: (row) => {
        switch (row.template) {
          case 'expense-balance-flash':
          case 'expense-company-statement':
          case 'expense-employees-statement':
            const { startDate, endDate } = row.filter;
            return `${formatDate(startDate)} à ${formatDate(endDate)}`;
          case 'expense-employees':
            const { date } = row.filter;
            return `${formatDate(date)}`;
        }
      },
      header: () => <Header>Período</Header>,
    },
    {
      id: 'format',
      cell: ({ row }) => {
        const format = fileFormatsMap[row.original.format];
        return (
          <Flex direction="row">
            <Icon name={format.icon} color="neutral_40" />
            <Spacer x="xs3" />
            {format.name}
          </Flex>
        );
      },
      header: () => <Header>Formato</Header>,
    },
    {
      id: 'status',
      cell: ({ row }) => <TagStatus retry={props.retry} row={row.original} />,
      header: () => <Header>Status</Header>,
    },
    {
      id: 'action',
      cell: ({ row }) => {
        const reportDownload = useReportDownload();

        return (
          <PillButton
            icon="IconDownload"
            size="small"
            loading={reportDownload.isLoading}
            disabled={!row.original.url}
            variant="default"
            type="primary"
            onClick={() => {
              if (!!row.original.url) {
                reportDownload.mutate(row.original.id);
              }
            }}
          />
        );
      },
      header: () => <Header>Download</Header>,
    },
  ] satisfies Columns;
}

function Header(props: { children: React.ReactNode }) {
  return (
    <Typography.Body3 weight={700} color="neutral_40" noWrap>
      {props.children}
    </Typography.Body3>
  );
}

function TagStatus(props: {
  row: Row;
  retry: (_: CreateReportInput | RetryCreateReportInput) => void;
}) {
  switch (props.row.status) {
    case 'failed':
      return (
        <Status
          tooltip="Tente gerar o report novamente e, caso o erro persista, entre em contato com o nosso suporte."
          variant="error"
          action={{
            onClick: () =>
              props.retry({
                action: 'retry',
                format: props.row.format,
                template: props.row.template,
                id: props.row.id,
              }),
            label: (
              <>
                Tentar novamente
                <Icon name="IconRefresh" color="error_40" />
              </>
            ),
          }}
        >
          Falha ao processar
        </Status>
      );

    case 'generating':
      return (
        <Tag dot size="sm" variant="info">
          Em processamento
        </Tag>
      );

    default:
      if (props.row.url) {
        return (
          <Tag dot size="sm" variant="success">
            Concluído
          </Tag>
        );
      }
      return (
        <Status
          tooltip="A empresa não possui dados no período solicitado para gerar um relatório."
          variant="error"
          action={{
            onClick: () =>
              props.retry({
                ...(props.row.template === 'expense-employees'
                  ? {
                      date: props.row.filter.date,
                      template: props.row.template,
                    }
                  : {
                      dateInterval: {
                        from: props.row.filter.startDate,
                        to: props.row.filter.endDate,
                      },
                      template: props.row.template,
                    }),
                action: 'create',
                format: props.row.format,
              }),
            label: (
              <>
                Alterar período <Icon name="IconArrowRight" color="error_40" />
              </>
            ),
          }}
        >
          Report sem dados
        </Status>
      );
  }
}

function Status(props: {
  children: React.ReactNode;
  variant: 'primary' | 'error' | 'info';
  tooltip?: string;
  action?: {
    label: React.ReactNode;
    onClick: () => void;
  };
}) {
  return (
    <Flex direction="column">
      <Flex direction="row">
        <Tag dot size="sm" variant={props.variant}>
          {props.children}
        </Tag>
        {!!props.tooltip && (
          <>
            <Spacer x="xs4" />
            <Tooltip title={props.tooltip} arrow={true}>
              <div>
                <Icons
                  name="IconInfoCircle"
                  fill="transparent"
                  color="#83727D"
                />
              </div>
            </Tooltip>
          </>
        )}
      </Flex>
      {props.action && (
        <>
          <Spacer y="xs4" />
          <LinkButton variant="error" onClick={props.action.onClick}>
            {props.action?.label}
          </LinkButton>
        </>
      )}
    </Flex>
  );
}
