import { UseFormReturn } from '../data/forms/externalCardForm';
import { ExternalCardSection } from './ExternalCardSection';
import { FlashCardSection } from './FlashCardSection';

type SingleCardProps = {
  isFlashCard: boolean;
  externalCardForm: UseFormReturn;
  flashCardForm: any;
};

export const SingleCard = ({
  isFlashCard,
  externalCardForm,
  flashCardForm,
}: SingleCardProps) => {
  if (isFlashCard) return <FlashCardSection form={flashCardForm} />;

  return <ExternalCardSection form={externalCardForm} />;
};
