import {
  DatePicker,
  Divider,
  LinkButton,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Template } from '@frontend/components/FocusedFlow/Template';
import Icon from '@frontend/components/Icon';
import Spacer from '@frontend/components/Spacer';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import BorderedSection from '@frontend/components/frames/BorderedSection';
import BalanceField from '@frontend/components/inputs/BalanceField';
import RadioCard from '@frontend/components/inputs/RadioCard';
import SelectField from '@frontend/components/inputs/SelectField';
import ToggleField from '@frontend/components/inputs/ToggleField';
import ModalService from '@frontend/services/ModalService';
import conditionalList from '@frontend/utils/conditionalList';
import { toCurrency } from '@frontend/utils/masks';
import { Controller } from 'react-hook-form';
import useGetFlashCashBalance from '../../data/useGetFlashCashBalance';
import { UseFormReturn } from '../../forms/useForm';
import AboutComplementaryDepositModal from '../../modals/AboutComplementaryDepositModal';
import DepositsByEmployeeModal from '../../modals/DepositsByEmployeeModal';
import useFormData from './data/useFormData';
import * as SC from './styles';

type Props = {
  form: UseFormReturn;
};

export default function PaymentMethod(props: Props) {
  const flashCashBalance = useGetFlashCashBalance();
  const formData = useFormData({ form: props.form, flashCashBalance });

  return (
    <Template
      pageInfo={{
        title: 'Fazer depósito',
      }}
      Modules={
        <>
          <BorderedSection padding="m">
            <Typography.Headline8 color={'neutral_20'}>
              Escolha o método de pagamento
            </Typography.Headline8>
            <Typography.Body3 color={'neutral_30'}>
              Selecione a forma de pagamento, entre Flash Cash Corporativo,
              Boleto ou Pix.
            </Typography.Body3>
            <Spacer y={'xs'} />
            {/* ---------------- Payment method Radio Input ---------------- */}
            <Controller
              control={props.form.control}
              name="paymentMethod"
              render={({ field, fieldState }) => (
                <>
                  <RadioCard
                    disabled={!!fieldState.error}
                    metadata={{
                      icon: 'IconWallet',
                      title: (
                        <SC.RadioHeader>
                          Flash Cash Corporativo
                          {flashCashBalance.balance !== undefined ? (
                            <Tag
                              variant={
                                flashCashBalance.balance <
                                formData.metadata.totalAmount
                                  ? 'error'
                                  : 'success'
                              }
                              size="sm"
                            >
                              Saldo disponível:{' '}
                              {toCurrency(flashCashBalance.balance)}
                            </Tag>
                          ) : (
                            <Tag variant={'neutral'} size="sm">
                              {flashCashBalance.isLoading
                                ? 'Carregando saldo...'
                                : 'Erro ao carregar saldo'}
                            </Tag>
                          )}
                        </SC.RadioHeader>
                      ),
                      description:
                        'Faça depósitos em tempo real. Tenha dinheiro na conta de Flash Cash para fazer depósitos a qualquer hora.',
                    }}
                    selected={field.value === 'FLASH_CASH'}
                    onClick={() => {
                      field.onChange('FLASH_CASH');
                    }}
                    errorMessage={
                      flashCashBalance.balance === undefined
                        ? flashCashBalance.isLoading
                          ? undefined
                          : 'Não foi possível carregar o saldo de Flash Cash corporativo.'
                        : fieldState.error?.message
                    }
                  />
                  <Spacer y={'xs3'} />
                  <RadioCard
                    metadata={{
                      icon: 'BarcodeMethod',
                      title: 'Boleto bancário',
                      description:
                        'Pode ser pago através de uma conta ou agência bancária e tem prazo de compensação de até 3 dias úteis.',
                    }}
                    selected={field.value === 'BILLET'}
                    onClick={() => field.onChange('BILLET')}
                  />
                  <Spacer y={'xs3'} />
                  <RadioCard
                    metadata={{
                      icon: 'Pix',
                      title: 'Pix',
                      description:
                        'Com o Pix, você tem pagamento instantâneo que pode ser feito em poucos segundos. É rápido e seguro.',
                    }}
                    selected={field.value === 'PIX'}
                    onClick={() => field.onChange('PIX')}
                  />
                </>
              )}
            />
            <Spacer y={'m'} />
            <Typography.Headline8 color={'neutral_20'}>
              Detalhes do depósito
            </Typography.Headline8>
            <Typography.Body3 color={'neutral_30'}>
              Personalize o depósito de acordo com a necessidade.
            </Typography.Body3>
            <Spacer y={'xs'} />
            {/* ----------------- Deposit amount field Input ---------------- */}
            <SC.FieldInput>
              <Controller
                control={props.form.control}
                name="value"
                render={({ field }) => (
                  <BalanceField
                    fullWidth
                    label={`Valor da recarga ${
                      formData.deposits.length > 1 ? 'por colaborador' : ''
                    }`}
                    value={field.value}
                    onChange={(newValue) => field.onChange(newValue)}
                  />
                )}
              />
            </SC.FieldInput>
            <Spacer y={'xs'} />
            {/* ----------------- Deposit type Select Input ---------------- */}
            <SC.FieldInputsArea>
              <Controller
                control={props.form.control}
                name="depositType"
                render={({ field, fieldState }) => (
                  <SelectField<'SINGLE' | 'AUTOMATIC'>
                    disabled={!formData.paymentMethod}
                    variant="standard"
                    value={field.value}
                    label="Tipo de depósito"
                    options={conditionalList([
                      [
                        true,
                        {
                          value: 'SINGLE',
                          label: 'Depósito único',
                          caption:
                            'Programe um deposito para uma data específica',
                        },
                      ],
                      [
                        formData.paymentMethod === 'FLASH_CASH',
                        {
                          value: 'AUTOMATIC',
                          label: 'Depósito automático',
                          caption:
                            'Programe um deposito para uma data específica',
                        },
                      ],
                    ])}
                    onChange={(selectedValue) => field.onChange(selectedValue)}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onBlur={field.onBlur}
                  />
                )}
              />
              {/* -------------------- Credit date Input ------------------- */}
              <Controller
                control={props.form.control}
                name="creditDate"
                render={({ field, fieldState }) => (
                  <DatePicker
                    label="Data de disponibilização do depósito"
                    fromDate={formData.metadata.creditDateFrom}
                    onDateChange={(date) =>
                      field.onChange(date?.toDate() ?? null)
                    }
                    value={field.value}
                    helperText={fieldState.error?.message}
                    error={!!fieldState.error}
                    onBlur={field.onBlur}
                  />
                )}
              />
            </SC.FieldInputsArea>
            <Spacer y={'xs'} />
            {/* ------------------- Expiration date Input ------------------ */}
            {formData.depositType === 'SINGLE' && (
              <Controller
                control={props.form.control}
                name="expires"
                render={({ field }) => (
                  <ToggleField
                    title="Expiração de saldo"
                    value={field.value}
                    description={
                      <>
                        Ativando essa opção, o saldo adicionado retornará para
                        Flash Cash Corporativo na <b>madrugada</b> da data de
                        expiração selecionada por você
                      </>
                    }
                    onChange={(newValue) => field.onChange(newValue)}
                  >
                    <Controller
                      control={props.form.control}
                      name="expirationDate"
                      render={({ field, fieldState }) => (
                        <DatePicker
                          value={field.value}
                          disabled={!formData.expires}
                          label="Data de expiração"
                          onDateChange={(date) =>
                            field.onChange(date?.toDate() ?? null)
                          }
                          helperText={fieldState.error?.message}
                          error={!!fieldState.error}
                          onBlur={field.onBlur}
                          fromDate={formData.metadata.expirationDateFrom}
                        />
                      )}
                    />
                  </ToggleField>
                )}
              />
            )}
            {/* ------------ Complementary deposit Toggle Input ------------ */}
            {formData.depositType === 'AUTOMATIC' && (
              <Controller
                control={props.form.control}
                name="complementary"
                render={({ field }) => (
                  <ToggleField
                    title="Função complementar"
                    value={field.value}
                    description="Ativando a função complementar, as pessoas listadas irão receber todo mês um valor que complete o saldo existente nos seus cartões, totalizando no saldo máximo predefinido neste depósito."
                    onChange={(newValue) => field.onChange(newValue)}
                  >
                    <LinkButton
                      variant="default"
                      onClick={() =>
                        ModalService.show(AboutComplementaryDepositModal, {})
                      }
                    >
                      <Icon name="IconInfoCircle" color="primary" size={16} />
                      Saiba mais
                    </LinkButton>
                  </ToggleField>
                )}
              />
            )}
          </BorderedSection>
          <Spacer y={'m'} />
        </>
      }
      ResultModule={
        <>
          <Typography.Headline7 color={'neutral_20'}>
            Resumo do pedido
          </Typography.Headline7>
          <Spacer y={'xs'} />
          <SC.Row>
            <Icon name="IconUsers" color="neutral_30" />
            <Typography.Body3 color={'neutral_30'}>
              Depósito para {formData.metadata.depositsCount}
            </Typography.Body3>
          </SC.Row>
          <Spacer y={'xs2'} />
          <LinkButton
            variant="default"
            onClick={() =>
              ModalService.show(DepositsByEmployeeModal, {
                deposits: formData.deposits,
              })
            }
          >
            Ver depósito por pessoa
          </LinkButton>
          <Spacer y={'xs'} />
          <Divider orientation="horizontal" />
          <Spacer y={'xs'} />
          <Typography.Headline8 color={'neutral_20'}>
            Total dos itens do pedido
          </Typography.Headline8>
          <Spacer y={'xs2'} />
          <SC.SpaceBetween>
            <SC.Row>
              <Icon name="IconCreditCard" color="neutral_30" />
              <Typography.Body4 color={'neutral_30'}>
                Despesas corporativas
              </Typography.Body4>
            </SC.Row>
            <Typography.Body4 weight={700} color="neutral_30">
              {toCurrency(formData.metadata.totalAmount)}
            </Typography.Body4>
          </SC.SpaceBetween>
          <Spacer y={'xs'} />
          <Typography.Headline8 color={'neutral_20'}>
            Valor parcial
          </Typography.Headline8>
          <Spacer y={'xs4'} />
          <Typography.Headline6 color="secondary_50">
            {`${formData.complementary ? 'Até ' : ''}${toCurrency(
              formData.metadata.totalAmount,
            )}`}
          </Typography.Headline6>
        </>
      }
    />
  );
}
