import ModalService from '../../../services/ModalService';
import { trpc } from '../../../utils/trpc';
import ReclassificationError from '../modals/reclassification/ReclassificationError';
import ReclassificationPreviewModal from '../modals/reclassification/ReclassificationPreviewModal';
import ReclassificationSuccessModal from '../modals/reclassification/ReclassificationSuccessModal';

type Input = {
  transactionId: string;
};

export default function useReclassifyTransaction(input: Input) {
  const trpcContext = trpc.useContext();
  const reclassifyMutation =
    trpc.employee.corporateCard.statement.update.action.useMutation({
      onSuccess: async (result) => {
        ModalService.hide(ReclassificationPreviewModal);
        ModalService.show(ReclassificationSuccessModal, {
          transactionId: input.transactionId,
          nowIsCorporate: result.isCorporate,
        });
        await trpcContext.employee.corporateCard.statement.update.preview.invalidate(
          {
            transactionId: input.transactionId,
          },
        );
      },
      onError: (error) => {
        ModalService.hide(ReclassificationPreviewModal);
        ModalService.show(ReclassificationError, {
          message:
            error.message ??
            'Tente novamente. Se o problema persistir, entre em contato com o suporte',
        });
      },
    });

  return {
    reclassifyTransaction: () => reclassifyMutation.mutate(input),
    requesting: reclassifyMutation.isLoading,
  };
}
