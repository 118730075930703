import { trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

export const useGetBillingInfo = (externalId?: string) => {
  const { data, isLoading } = trpc.company.billing.paymentInfo.useQuery(
    externalId ?? '',
    {
      enabled: !!externalId,
      retryDelay: 1000 * 10,
      retry: 7,
      onError(error) {
        dispatchToast({
          type: 'error',
          content: error.message,
        });
      },
    },
  );

  return {
    billingInfo: data,
    isLoading,
  };
};
