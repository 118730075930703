import { ThemesType } from '@flash-tecnologia/hros-web-ui-v2';

type Padding = keyof ThemesType['spacings'];

export type ComponentPadding = Padding | [Padding, Padding];

export function makePadding(theme: ThemesType, padding: ComponentPadding) {
  if (Array.isArray(padding)) {
    return padding.map((p) => theme.spacings[p]).join(' ');
  }
  return theme.spacings[padding];
}
