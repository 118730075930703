import BasePath from '@frontend/routes/BasePath';
import RouterService from '@frontend/services/RouterService';
import { Route } from 'react-router-dom';
import { zod } from 'react-router-typesafe-routes/zod';
import { z } from 'zod';
import PolicyDetails from './PolicyDetails';

export const ROUTE_PolicyDetails = RouterService.create(
  `${BasePath.relativePath}/policies/:policyId`,
  {
    params: {
      policyId: zod(z.string()).defined(),
    },
  },
);

export const RouteComponent_PolicyDetails = (
  <Route path={ROUTE_PolicyDetails.path} element={<PolicyDetails />} />
);
