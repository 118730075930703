import Typography from '@frontend/components/Typography';

type ReportColumnProps = {
  title: string;
  children: string;
};

export function ReportColumn({ title, children }: ReportColumnProps) {
  return (
    <div>
      <Typography.Caption color="neutral_40">{title}</Typography.Caption>

      <Typography.Body4 color="neutral_20" weight={600}>
        {children}
      </Typography.Body4>
    </div>
  );
}
