import { useExternalCards } from '@frontend/pages/ExternalCards/utils/hooks/useExternalCards';
import ModalService from '@frontend/services/ModalService';
import { useState } from 'react';
import { getActionsByView } from '../Actions';
import { getHeaderByView } from '../Headers';
import LinkCorporateCardSuccess from '../feedbacks/CreateCorporateCardUserSuccess';
import { getComponentByView } from '../views/getViewComponent';
import { useCreateCorporateCardUser } from './useCreateCorporateCardUser';

export type CorporateCardUserMetadata = {
  isSingleCard: boolean;
  cardIssuer: 'FLASH' | 'EXTERNAL';
};

export type AddCardStep = 'INITIAL_VIEW' | 'DETAILS_VIEW';

export const useCreateCorporateCardUserController = ({ isFlashCard }) => {
  const modalController = ModalService.useModalController();
  const externalCards = useExternalCards();

  const [selectedView, setSelectedView] = useState<AddCardStep>(
    externalCards.enabled && !externalCards.useV2
      ? 'INITIAL_VIEW'
      : 'DETAILS_VIEW',
  );

  const [createCorporateCardUserMetadata, setCreateCorporateCardUserMetadata] =
    useState<CorporateCardUserMetadata>({
      isSingleCard: true,
      cardIssuer: isFlashCard ? 'FLASH' : 'EXTERNAL',
    });

  const onLinkSuccessfully = (userWithoutPlasticCard = false) => {
    modalController.remove();
    ModalService.show(LinkCorporateCardSuccess, {
      singleCard: createCorporateCardUserMetadata.isSingleCard,
      cardIssuer: createCorporateCardUserMetadata.cardIssuer,
      userWithoutPlasticCard,
    });
  };

  const {
    externalCardForm,
    flashCardForm,
    createCorporateCardUser,
    isLoading,
  } = useCreateCorporateCardUser(onLinkSuccessfully);

  const overHeader = getHeaderByView({
    actualView: selectedView,
    flashIssuer: createCorporateCardUserMetadata.cardIssuer === 'FLASH',
    onlyOneEmployee: createCorporateCardUserMetadata.isSingleCard,
  });

  const viewComponent = getComponentByView({
    selectedView,
    createCorporateCardUserMetadata,
    setCreateCorporateCardUserMetadata,
    externalCardForm,
    flashCardForm,
  });

  const viewActions = getActionsByView({
    setSelectedView,
    selectedView,
    createCorporateCardUserMetadata,
    createCorporateCardUser,
    form:
      createCorporateCardUserMetadata.cardIssuer === 'FLASH'
        ? flashCardForm
        : externalCardForm,
  });

  return {
    viewComponent,
    viewActions,
    overHeader,
    isLoading,
  };
};
