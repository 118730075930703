import { dispatchToast } from '@frontend/utils/dispatchEvents';
import { useState } from 'react';
import { z } from 'zod';
import { trpc } from '@frontend/trpc';
import { BackboneUsers } from './useFindUsers';
import ModalService from '@frontend/services/ModalService';
import SuccessAddUserModal from '../modals/SuccessAddUserModal';

const optionUser = z.object({
  alreadyActive: z.boolean().optional(),
  documentNumber: z.string(),
  id: z.string(),
});

export const selectBackboneUsersSchema = z.object({
  users: z
    .array(
      optionUser.refine(({ alreadyActive }) => !alreadyActive, {
        message: 'Usuário já está vinculado!',
      }),
    )
    .min(1),
});

export const addBackboneUsersOutputSchema = z
  .object({
    users: z
      .array(
        z.object({
          _id: z.string().describe('Backbone user nano id'),
          hasCard: z.boolean().optional(),
        }),
      )
      .default([]),
  })
  .default({});

export function useFindBackboneUsers() {
  const [selectedUsers, setSelectedUsers] = useState<BackboneUsers>([]);
  const [disabledConfirm, setDisableConfirm] = useState<boolean>(true);

  const {
    data: addUsersData,
    isLoading: addUsersLoading,
    mutate: addUsers,
  } = trpc.company.cards.add.useMutation({
    onError: (error) => {
      dispatchToast({
        type: 'error',
        content: error.message,
      });
    },
    onSuccess: () => {
      ModalService.show(SuccessAddUserModal, {});
    },
  });

  const context = trpc.useContext();
  return {
    onConfirm: (callback?: () => void) => {
      const result = selectBackboneUsersSchema.safeParse({
        users: selectedUsers,
      });
      if (!result.success) {
        setDisableConfirm(true);
        dispatchToast({
          type: 'error',
          content: result.error.issues[0].message,
        });
      }
      addUsers(
        {
          users: selectedUsers,
        },
        {
          onSuccess: () => {
            context.company.cards.search.refetch(undefined);
            callback?.();
          },
        },
      );
    },
    addUsersData: addUsersData ? addUsersData : [],
    selectedUsers,
    disabledConfirm,
    inputShowHelperText: disabledConfirm && !!selectedUsers.length,
    setSelectedUsers: (selected: BackboneUsers) => {
      const result = selectBackboneUsersSchema.safeParse({
        users: selected,
      });
      setDisableConfirm(!result.success);
      setSelectedUsers(selected);
    },
    addUsersLoading,
  };
}
