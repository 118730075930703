import RouterService from '@frontend/services/RouterService';
import { zod } from 'react-router-typesafe-routes/zod';
import { z } from 'zod';

/** Details from an ExpenseOn expense */
export const ROUTE_ExpenseDetails = RouterService.create(
  'expenseManagement/expenses/details/:expenseId',
  {
    params: {
      /** [Legacy] ExpenseOn expense ID */
      expenseId: zod(z.string()).defined(),
    },
  },
);

/** Login page */
export const ROUTE_Login = RouterService.create('authentication/login');

/** Company customization main menu */
export const ROUTE_CompanyCustomization = RouterService.create(
  'expenseManagement/customizations',
  {
    searchParams: {
      tab: zod(z.enum(['expense', 'supplier', 'corporate-card'])).defined(),
    },
  },
);

export const ROUTE_Home_FlashOS = RouterService.create('home');
