import { MultiSelect } from '@flash-tecnologia/hros-web-ui-v2';
import Callout from '@frontend/components/frames/Callout';
import Icon from '@frontend/components/Icon';
import {
  sortedMccGroups,
  translatedMccGroups,
} from '@frontend/utils/dataFormatters/mccGroups.dataFormatter';
import { Controller } from 'react-hook-form';
import { FormSchemaOutput, UseFormReturn } from '../../../forms/useForm';

type Props = {
  form: UseFormReturn;
};

export default function CategoriesMultiselect(props: Props) {
  return (
    <Callout
      title="Categorias de uso"
      description="Configure as categorias nas quais transações feitas com esse cartão serão aceitas."
      alignItems="start"
      leftAdornment={
        <Icon
          name="IconCategory"
          size={48}
          color="secondary_50"
          background="default"
        />
      }
    >
      <Controller
        control={props.form.control}
        name="mccGroups"
        render={({ field }) => {
          return (
            <MultiSelect
              options={sortedMccGroups.map((mcc) => ({
                id: mcc,
                label: translatedMccGroups[mcc].title,
              }))}
              value={Object.keys(field.value).map(
                (mcc: keyof typeof translatedMccGroups) => ({
                  id: mcc,
                  label: translatedMccGroups[mcc].title,
                }),
              )}
              renderInput={() => <div />}
              label="Categorias ativas na política"
              onSelectChange={(
                _,
                selectedOptions: {
                  id: keyof typeof translatedMccGroups;
                  label: string;
                }[],
              ) => {
                field.onChange(
                  selectedOptions.reduce(
                    (
                      categories: NonNullable<FormSchemaOutput['mccGroups']>,
                      option: { id: keyof typeof translatedMccGroups },
                    ) => ({
                      ...categories,
                      [option.id]: true,
                    }),
                    {} as NonNullable<FormSchemaOutput['mccGroups']>,
                  ),
                );
              }}
            />
          );
        }}
      />
    </Callout>
  );
}
