import { trpc } from '@frontend/trpc';
import { downloadFromUrl } from '@frontend/utils/downloadFile/downloadFromUrl';
import * as toasts from '../../reports/shared/toasts';

type Input = string;

export default function () {
  const getReport = trpc.company.reports.get.useMutation({
    onSuccess: function (data) {
      if (data.url) {
        downloadFromUrl(data.url);
      }
    },
    onError: function () {
      toasts.toastError();
    },
  });

  return {
    mutate: (input: Input) => {
      toasts.toastProcessing();

      getReport.mutate({ id: input });
    },
    isLoading: getReport.isLoading,
    error: getReport.error,
  };
}
