import errors from '@frontend/utils/commonTexts/errors';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

/** Dispatched when an error occurs during the unlinking mutation */
const toastServerError = () => {
  dispatchToast({
    type: 'error',
    content: 'Não foi possível desvincular a política',
    description: errors.actions.toastDescription,
  });
};

/** Dispatched when the unlinking is successful */
const toastSuccess = () => {
  dispatchToast({
    type: 'success',
    content: 'Política desvinculada com sucesso',
  });
};

export default {
  toastSuccess,
  toastServerError,
};
