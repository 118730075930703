import Typography from '@frontend/components/Typography';
import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import ModalService from '@frontend/services/ModalService';
import OnboardingCoupledVirtualCardTutorialModal from './OnboardingCoupledVirtualCardTutorialModal';

function OnboardingCoupledVirtualCardEndModal() {
  const modalController = ModalService.useModalController();

  function backToTutorial() {
    modalController.remove();
    ModalService.show(OnboardingCoupledVirtualCardTutorialModal, {});
  }

  function handleDismiss() {
    modalController.remove();
  }

  return (
    <ConfirmationModal
      isOpen={modalController.visible}
      onClose={handleDismiss}
      variant="default"
      text={{
        highlightedText: 'Tudo certo!',
        title: 'Agora é só comunicar sua equipe!',
        body: (
          <>
            Sabendo da novidade, comunique sua equipe para facilitarmos ainda
            mais seu dia a dia!
            <br />
            Em caso de dúvidas, acesse nossa{' '}
            <Typography.Link
              href="https://beneficios.flashapp.com.br/faq/como-funciona-o-cart%C3%A3o-corporativo-virtual"
              target="_blank"
            >
              FAQ
            </Typography.Link>
            .
          </>
        ),
      }}
      buttons={{
        cancel: {
          onClick: backToTutorial,
          text: 'Voltar',
        },
        confirm: {
          onClick: handleDismiss,
          text: 'Concluir',
        },
      }}
    />
  );
}

export default ModalService.create(OnboardingCoupledVirtualCardEndModal);
