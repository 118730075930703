import { DebouncedFunc } from 'lodash';
import debounce from 'lodash.debounce';
import { useState } from 'react';

export function useStringSearch(
  initialValue?: string,
): [string, DebouncedFunc<(_: string) => void>] {
  const [stringInput, setStringInput] = useState<string>(initialValue || '');

  const setDebounceStringInput = debounce(
    (value: string) => {
      setStringInput(stringInput !== value ? value : stringInput);
    },
    500,
    { maxWait: 1500 },
  );

  return [stringInput, setDebounceStringInput];
}
