type Props = {
  transactionType: string;
  metadata: {
    automaticCredit?: boolean;
  };
};

type TransactionInfo = {
  transactionType: string;
};

export function useTransactionInfo({
  transactionType,
  metadata,
}: Props): TransactionInfo {
  const translatedInfo = {
    transactionType: '',
  };
  switch (transactionType) {
    case 'VOUCHER_PURCHASE':
      translatedInfo.transactionType = 'Compra';
      break;
    case 'OPEN_LOOP_PAYMENT':
      translatedInfo.transactionType = 'Compra';
      break;
    case 'BILLET_PAYMENT':
      translatedInfo.transactionType = 'Pagamento';
      break;
    case 'COMPANY_WITHDRAW':
      translatedInfo.transactionType = 'Estorno de saldo para empresa';
      break;
    case 'REVERSAL':
      translatedInfo.transactionType = 'Estorno de compra';
      break;
    case 'CREDIT':
    case 'DEPOSIT':
      translatedInfo.transactionType = `Recarga ${
        metadata.automaticCredit ? 'automática' : 'única'
      }`;
      break;
    case 'TED_CASH_OUT':
      translatedInfo.transactionType = 'Transferência';
      break;
    case 'PIX_CASH_OUT':
      translatedInfo.transactionType = 'Transferência';
      break;
    case 'PIX_CASH_IN':
      translatedInfo.transactionType = 'Transferência';
      break;
    case 'OTHER':
      translatedInfo.transactionType = 'Outro';
      break;
    default:
      translatedInfo.transactionType = 'Compra';
  }
  return translatedInfo;
}
