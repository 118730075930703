import { useEffect, useRef, useState } from 'react';
import { dispatchToast } from '../../../utils/dispatchEvents';
import { RouterOutputs, trpc } from '@frontend/trpc';
import debounce from 'lodash.debounce';

export type BackboneUsers =
  RouterOutputs['company']['cards']['findToAdd']['users'];

export default function useFindUsers(selectedUsers: BackboneUsers) {
  const [search, setSearch] = useState<string>('');

  const debouncedSearch = debounce(
    (data) => {
      setSearch(data);
    },
    1000,
    { maxWait: 5000 },
  );

  const {
    data: EMfindUsersToAdd,
    isLoading,
    refetch,
  } = trpc.company.cards.findToAdd.useQuery(
    { search },
    {
      retry: false,
      keepPreviousData: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      notifyOnChangeProps: 'all',
      enabled: false,
      onError: (error) => {
        dispatchToast({
          type: 'error',
          content: error.message,
        });
      },
    },
  );

  const ref = useRef('');
  useEffect(() => {
    if (ref.current !== search) {
      ref.current = search;
      refetch();
    }
  }, [search]);

  return {
    findBackBoneUsers: (input: { stringSearch: string }) => {
      debouncedSearch(input.stringSearch);
    },
    loading: isLoading,
    options:
      EMfindUsersToAdd?.users.filter(({ id: selectedId }) => {
        return !selectedUsers.find(({ id }) => id === selectedId);
      }) || [],
  };
}
