import { trpc } from '@frontend/trpc';

export function useGetPolicies() {
  const policies = trpc.company.policies.search.useQuery({
    active: true,
  });

  return {
    data: (policies.data ?? []).map((policy) => ({
      label: policy.title,
      value: policy._id,
    })),
    isLoading: policies.isLoading,
    isRefetching: policies.isFetching,
    refetch: policies.refetch,
  };
}
