import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import ModalService from '@frontend/services/ModalService';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

type Props = {
  onConfirm: () => void;
};

function CancelFlowModal(props: Props) {
  const controller = ModalService.useModalController();
  return (
    <ConfirmationModal
      isOpen={controller.visible}
      onClose={controller.remove}
      text={{
        highlightedText: 'Atenção!',
        title: 'Tem certeza que deseja cancelar o depósito?',
        body: 'Todas as informações preenchidas serão perdidas e essa ação não poderá ser desfeita.',
      }}
      variant="error"
      buttons={{
        confirm: {
          text: 'Cancelar depósito',
          onClick: () => {
            props.onConfirm();
            controller.remove();
            dispatchToast({
              type: 'error',
              content: 'Sua solicitação de depósito foi cancelada',
            });
          },
        },
        cancel: {
          text: 'Continuar depósito',
          onClick: controller.remove,
          variant: 'neutral',
        },
      }}
    />
  );
}

export default ModalService.create(CancelFlowModal);
