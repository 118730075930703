import Avatar from '@frontend/components/display/Avatar';
import styled from 'styled-components';
import useGetEmployeeData from '../data/useGetEmployeeData';
import Typography from '@frontend/components/Typography';
import Icon from '@frontend/components/Icon';
import Tag from '@frontend/components/TagV2';
import CardMenu from './CardMenu';

type Props = {
  cardId: string;
};

export default function EmployeeDataBox(props: Props) {
  const employeeData = useGetEmployeeData({ cardId: props.cardId });
  const cardStatus = employeeData.data?.active ? 'active' : 'disabled';

  if (!employeeData.data) {
    // TODO: loading state
    return null;
  }

  return (
    <StyledContainer>
      <Avatar alt={employeeData.data.cardOwnerName} />
      <StyledTextArea>
        <StyledTitleArea>
          <Typography.Headline7>
            {employeeData.data.cardOwnerName}
          </Typography.Headline7>
          <Tag variant={cardStatusMap[cardStatus].variant} size="sm" dot>
            {cardStatusMap[cardStatus].label}
          </Tag>
        </StyledTitleArea>
        <StyledDescriptionArea>
          <Icon name="IconCreditCard" color="neutral_40" />
          <Typography.Body4 color="neutral_40">
            ––– {employeeData.data.cardNumber}
          </Typography.Body4>
          {/* <Icon name="IconMail" color="neutral_40" />
          <Typography.Body4 color="neutral_40">
            {employeeData.data.email}
          </Typography.Body4> */}
        </StyledDescriptionArea>
      </StyledTextArea>
      <CardMenu cardStatus={cardStatus} cardId={props.cardId} />
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  gap: 24px;
`;

const StyledTextArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledTitleArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

const StyledDescriptionArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

const cardStatusMap = {
  active: {
    label: 'Ativo',
    variant: 'success',
  },
  disabled: {
    label: 'Desativado',
    variant: 'neutral',
  },
} as const;
