import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import StepModal from '@frontend/components/modals/StepModal';
import ModalService from '@frontend/services/ModalService';
import OnboardingAddEmployeeEndModal from './OnboardingVirtualCardEndModal';

function OnboardingVirtualCardTutorialModal() {
  const modalController = ModalService.useModalController();

  function handleDismiss() {
    modalController.remove();
  }

  function showEndModal() {
    modalController.remove();
    ModalService.show(OnboardingAddEmployeeEndModal, {});
  }

  return (
    <StepModal
      isOpen={modalController.visible}
      onClose={handleDismiss}
      onEnd={showEndModal}
      steps={[
        {
          title: 'Por que ter um cartão virtual?',
          content: (
            <>
              <ul>
                <Typography.Body4 color="neutral_40" as="li">
                  Flexibilidade para pagamentos corporativos sem depender do
                  cartão físico;
                </Typography.Body4>
                <Typography.Body4 color="neutral_40" as="li">
                  Facilidade e segurança nos pagamentos recorrentes e digitais;
                </Typography.Body4>
                <Typography.Body4 color="neutral_40" as="li">
                  Uso exclusivo do saldo corporativo disponibilizado ao
                  portador.
                </Typography.Body4>
              </ul>
              <Spacer y="s" />
              <img
                src={
                  'https://images.flashapp.com.br/expense/corporate-card/onboarding-virtual-card/introduction.png'
                }
              />
            </>
          ),
        },
        {
          title: 'Como criar um cartão virtual?',
          content: (
            <>
              <Typography.Body4 color="neutral_40">
                Através da Plataforma Flash, na página de Cartões corporativos,{' '}
                <Typography.Body4 weight={700} color="neutral_40" as="span">
                  o administrador poderá criar cartões
                </Typography.Body4>{' '}
                utilizando o botão{' '}
                <Typography.Body4 weight={700} color="neutral_40" as="span">
                  Adicionar
                  {' >'} Novo cartão virtual
                </Typography.Body4>
                , no canto superior direito.
                <br />
                Em breve, a própria pessoa da empresa poderá gerar seu cartão
                virtual, caso ela tenha acesso ao uso do saldo corporativo.
              </Typography.Body4>
              <Spacer y="s" />
              <img
                src={
                  'https://images.flashapp.com.br/expense/corporate-card/onboarding-virtual-card/step-1.gif'
                }
              />
            </>
          ),
        },
        {
          title: 'Preencha os dados do cartão',
          content: (
            <>
              <Typography.Body4 color="neutral_40">
                Selecione a pessoa titular, defina um apelido e a data de
                validade para o cartão e clique em concluir.
              </Typography.Body4>
              <Spacer y="s" />
              <img
                src={
                  'https://images.flashapp.com.br/expense/corporate-card/onboarding-virtual-card/step-2.gif'
                }
              />
            </>
          ),
        },
        {
          title: 'Onde encontrar o cartão virtual criado?',
          content: (
            <>
              <Typography.Body4 color="neutral_40">
                Pronto, o cartão será disponibilizado para o titular na área{' '}
                <Typography.Body4 weight={700} color="neutral_40" as="span">
                  Cartões
                </Typography.Body4>{' '}
                no aplicativo Flash, ou na nossa plataforma web. O titular do
                cartão poderá utilizar para{' '}
                <Typography.Body4 weight={700} color="neutral_40" as="span">
                  pagamentos online, serviços recorrentes, pagamentos via
                  carteira virtual
                </Typography.Body4>{' '}
                e mais!
              </Typography.Body4>
              <Spacer y="s" />
              <img
                src={
                  'https://images.flashapp.com.br/expense/corporate-card/onboarding-virtual-card/step-3.gif'
                }
              />
            </>
          ),
        },
      ]}
    />
  );
}

export default ModalService.create(OnboardingVirtualCardTutorialModal);
