import { PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import { Modal as ModalMUI } from '@mui/material';
import styled from 'styled-components';

type Props = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

/** Base component for all modals.
 *
 * Includes the close button, background color, border radius, and sizing limits */
export default function Modal(props: Props) {
  return (
    <ModalMUI
      open={props.open}
      onClose={props.onClose}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <StyledContainer>
        <div
          style={{
            position: 'absolute',
            right: '24px',
            top: '24px',
            zIndex: 999,
          }}
        >
          <PillButton
            icon="IconX"
            variant="default"
            onClick={props.onClose}
            size={'small'}
          />
        </div>
        {props.children}
      </StyledContainer>
    </ModalMUI>
  );
}

const StyledContainer = styled.div`
  background-color: ${(p) => p.theme.colors.neutral[100]};
  border-radius: 16px;
  position: relative;
  max-width: 900px;
  min-width: 600px;
  max-height: 90vh;
  min-height: 50vh;
`;
