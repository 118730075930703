import { trpc } from '@frontend/trpc';

export default function usePendencyCounter() {
  const { data, isLoading, error } =
    trpc.company.requestBalance.countPendencies.useQuery();

  return {
    totalPendencies: data?.totalPendencies,
    isLoading,
    hasError: !!error,
  };
}
