import TagFilters from '@frontend/components/TagFilters';
import Spacer from '../../../components/Spacer';
import Table from '../../../components/TableV2';
import { toCurrency } from '../../../utils/masks';
import { useTableData } from './data/useTableData';
import { flashCashTableColumns } from './tableSetup';
import fromCurrency from '@frontend/utils/masks/fromCurrency';
import SearchField from '@frontend/components/inputs/SearchField';

export default function FlashCash() {
  const {
    amount,
    getCompanyData,
    getCompanyLoading,
    isFiltered,
    filters,
    setSearchInput,
    setFilters,
    pagination,
    setPagination,
  } = useTableData();

  return (
    <>
      <Spacer y="s" />
      <SearchField
        disabled={getCompanyLoading}
        label="Buscar por Flash Cash Corporativo"
        size="lg"
        initialValue={filters.stringInput}
        onChange={({ target }) => {
          setSearchInput(target.value);
        }}
      />
      <Spacer y="s" />
      <Table
        pagination={{
          pageIndex: pagination.currentPage,
          pageSize: pagination.pageSize,
        }}
        loading={getCompanyLoading}
        emptyState={{
          isFiltered,
          emptyText: 'Você ainda não tem saldo no Flash Cash',
          filteredEmptyText: 'Não encontramos nenhuma transação',
        }}
        onPaginationChange={({ pageIndex, pageSize }) => {
          setPagination({
            currentPage: pageIndex,
            pageSize,
          });
        }}
        columns={flashCashTableColumns}
        data={getCompanyData.statement}
        pageCount={getCompanyData.pagination.totalPages}
        TableFilters={
          <>
            <TagFilters.DateRange
              label="Disponível"
              disabled={getCompanyLoading}
              onApply={(value) => {
                setFilters({
                  startDate: value?.from?.toISOString(),
                  endDate: value?.to?.toISOString(),
                });
              }}
            />
            <TagFilters.Range
              label="Valor"
              disabled={getCompanyLoading || amount?.max === amount?.min}
              min={amount?.min ?? 0}
              max={amount?.max ?? 0}
              mask={toCurrency}
              fromMask={fromCurrency}
              onApply={(values) => {
                const { min, max } = values;
                setFilters({
                  amountMax: max,
                  amountMin: min,
                });
              }}
            />
          </>
        }
      />
      <Spacer y="s" />
    </>
  );
}
