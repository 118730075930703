import { toCurrency } from '@frontend/utils/masks';
import { capitalizeWords } from '@frontend/utils/masks/capitalizeWords';
import { trpc } from '../../../../utils/trpc';

export function useEmployeeBalance(employeeId: string) {
  const {
    data: userInfoData,
    isInitialLoading: userInfoLoading,
    error: userInfoError,
  } = trpc.company.cards.get.useQuery(employeeId, {
    enabled: !!employeeId,
    keepPreviousData: true,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: 'always',
    staleTime: 60 * 1000,
    refetchInterval: 20 * 1000,
  });
  return {
    data: {
      active: userInfoData?.active ?? false,
      balance:
        userInfoData?.balance !== undefined
          ? toCurrency(userInfoData.balance)
          : '-',
      balanceAmount:
        userInfoData?.balance !== undefined ? userInfoData.balance : 0,
      cardNumber: !!userInfoData?.cardNumber.length
        ? `---- ${userInfoData?.cardNumber}`
        : '',
      documentNumber: userInfoData?.documentNumber ?? '',
      email: userInfoData?.email ?? '',
      employeeId: userInfoData?.employeeId ?? '',
      employeeNanoId: userInfoData?.employeeNanoId ?? '',
      name: userInfoData?.name ? capitalizeWords(userInfoData?.name) : '',
    },
    loading: userInfoLoading,
    error: userInfoError,
  };
}
