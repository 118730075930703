import { Menu, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import TableClient from '@frontend/components/TableClient';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import ModalService from '@frontend/services/ModalService';
import { formatDate, toCurrency } from '@frontend/utils/masks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useCorporateStatus from '../../data/useCorporateStatus';
import ReclassificationPreviewModal from '../../modals/reclassification/ReclassificationPreviewModal';
import { UseGetStatementOutput } from '../data/useGetStatement';
import mappings from './mappings';

type Row = UseGetStatementOutput['items'][number];
type Columns = React.ComponentProps<typeof TableClient<Row>>['columns'];

export default [
  {
    accessorKey: 'createdAt',
    header: 'Data',
    filterFn: 'customInDateRange',
    enableGlobalFilter: false,
    cell: (context) => (
      <Typography.Body4 noWrap color="neutral_30">
        {formatDate(context.row.original.createdAt)}
      </Typography.Body4>
    ),
  },
  {
    accessorKey: 'description',
    header: 'Descrição',
    enableGlobalFilter: true,
    cell: (context) => (
      <Typography.Body4 noWrap color="neutral_30">
        {context.row.original.description}
      </Typography.Body4>
    ),
  },
  {
    accessorKey: 'amount',
    header: 'Valor',
    filterFn: 'customInNumberRange',
    enableGlobalFilter: false,
    cell: (context) => {
      if (context.row.original.type === 'CREDIT')
        return (
          <Typography.Body4 noWrap color="success_40">
            + {toCurrency(context.row.original.amount)}
          </Typography.Body4>
        );
      return (
        <Typography.Body4 noWrap color="neutral_30">
          {toCurrency(context.row.original.amount)}
        </Typography.Body4>
      );
    },
  },
  {
    accessorKey: 'movement',
    header: 'Transação',
    filterFn: 'customInSet',
    enableGlobalFilter: false,
    cell: (context) => {
      const movement = mappings.movement[context.row.original.movement];
      return (
        <Tag size="sm" variant={movement.variant}>
          {movement.label}
        </Tag>
      );
    },
  },
  {
    accessorKey: 'accountability',
    header: 'Status do Relatório',
    filterFn: 'customInSet',
    enableGlobalFilter: false,
    cell: (context) => {
      const accountability =
        mappings.accountability[context.row.original.accountability];
      return (
        <Tag size="sm" variant={accountability.variant} dot>
          {accountability.label}
        </Tag>
      );
    },
  },
  {
    accessorKey: 'status',
    header: 'Status da compra',
    filterFn: 'customInSet',
    enableGlobalFilter: false,
    cell: (context) => {
      const status = mappings.status[context.row.original.status];
      return (
        <Tag size="sm" variant={status.variant} dot>
          {status.label}
        </Tag>
      );
    },
  },
  {
    id: 'actions',
    header: 'Ações',
    enableGlobalFilter: false,
    isSticky: true,
    cell: (context) => {
      const { data } = useCorporateStatus();
      const navigate = useNavigate();
      const row = context.row.original;

      const options: React.ComponentProps<typeof Menu>['options'] = [
        {
          label: 'Ver mais detalhes',
          onClick: () => {
            navigate(`/corporateCard/statement/${row._id}`, {
              state: {
                transaction: {
                  accountability: {
                    code: row.accountability,
                    translation:
                      mappings.accountability[row.accountability].label,
                  },
                  aggregateId: row.openLoopPaymentId,
                  amount: row.amount,
                  date: row.createdAt,
                  description: row.description,
                  type: row.movement,
                },
              },
            });
          },
        },
      ];

      if (row.expenseId)
        options.push({
          label: 'Prestar Conta',
          onClick: () => {
            navigate(`/expenseManagement/expenses/details/${row.expenseId}`);
          },
        });

      if (data?.shouldShowSwitch && row.movement === 'OPEN_LOOP_PAYMENT')
        options.push({
          label: 'Reclassificar compra',
          onClick: () => {
            ModalService.show(ReclassificationPreviewModal, {
              transactionId: row.openLoopPaymentId,
            });
          },
        });

      return (
        <Menu type="default" locked options={options}>
          <PillButton size="medium" variant="default" icon="IconDotsVertical" />
        </Menu>
      );
    },
  },
] satisfies Columns;
