import TagFilters from '@frontend/components/TagFilters';
import React from 'react';
import { z } from 'zod';
import { UseGetStatementOutput } from '../data/useGetStatement';
import { SetElements } from '@frontend/utils/types/setElements';
import { toCurrency } from '@frontend/utils/masks';
import fromCurrency from '@frontend/utils/masks/fromCurrency';

const FilterSchema = z
  .object({
    createdAt: z.object({ from: z.date().optional(), to: z.date().optional() }),
    type: z.set(z.enum(['CREDIT', 'DEBIT'])),
    amount: z.object({ min: z.number(), max: z.number() }),
    movement: z.set(
      z.enum([
        'VOUCHER_PURCHASE',
        'OPEN_LOOP_PAYMENT',
        'BILLET_PAYMENT',
        'REVERSAL',
        'DEPOSIT',
        'TED_CASH_OUT',
        'PIX_CASH_OUT',
        'PIX_CASH_IN',
        'OTHER',
      ]),
    ),
    accountability: z.set(
      z.enum([
        'PENDING',
        'OPEN',
        'PROCESSING',
        'SUBMITTED',
        'APPROVED',
        'REFUNDED',
        'DISAPPROVED',
        'NA',
      ]),
    ),
    status: z.set(
      z.enum([
        'CANCELED',
        'COMPLETED',
        'CONFIRMED',
        'CREATED',
        'DECLINED',
        'FAILED',
        'PARTIALLY_CANCELED',
        'PROCESSING',
        'SETTLED',
        'REVERTED',
      ]),
    ),
  })
  .partial();

type FilterSchemaInputType = z.input<typeof FilterSchema>;

type Input = {
  disableAll: boolean;
  metadata: UseGetStatementOutput['metadata'];
};

export default function useTagFilters(input: Input) {
  const [rawFilter, setRawFilter] = React.useState<FilterSchemaInputType>({});
  const ref_movement =
    React.useRef<
      React.ComponentRef<
        typeof TagFilters.Checkbox<
          SetElements<FilterSchemaInputType['movement']>
        >
      >
    >(null);
  const ref_accountability =
    React.useRef<
      React.ComponentRef<
        typeof TagFilters.Checkbox<
          SetElements<FilterSchemaInputType['accountability']>
        >
      >
    >(null);
  const ref_status =
    React.useRef<
      React.ComponentRef<
        typeof TagFilters.Checkbox<SetElements<FilterSchemaInputType['status']>>
      >
    >(null);
  /* ----------------------------------------- Handlers ----------------------------------------- */
  /** Triggered by the `Filter` button */
  function handleApplyDrawerFilter() {
    handleUpdateFilter({
      movement: ref_movement.current?.triggerApply(),
      accountability: ref_accountability.current?.triggerApply(),
      status: ref_status.current?.triggerApply(),
    });
  }

  /** Triggered by the `Clear` button */
  function handleClearDrawerFilter() {
    handleUpdateFilter({
      movement: ref_movement.current?.triggerClear(),
      accountability: ref_accountability.current?.triggerClear(),
      status: ref_status.current?.triggerClear(),
    });
  }

  /** Triggered by closing the Drawer */
  function handleCloseDrawerFilter() {
    ref_movement.current?.triggerClose();
    ref_accountability.current?.triggerClose();
    ref_status.current?.triggerClose();
  }

  function handleUpdateFilter(partialFilter: FilterSchemaInputType) {
    const newFilter = { ...rawFilter, ...partialFilter };
    if (FilterSchema.safeParse(newFilter).success) setRawFilter(newFilter);
  }

  const FilterComponent = (
    <>
      <TagFilters.DateRange
        label="Data"
        disabled={input.disableAll}
        onApply={(value) => {
          handleUpdateFilter({ createdAt: value });
        }}
      />
      <TagFilters.Range
        label="Valor"
        disabled={input.disableAll}
        min={input.metadata?.amount.min ?? 0}
        max={input.metadata?.amount.max ?? 0}
        mask={toCurrency}
        fromMask={fromCurrency}
        onApply={(value) => {
          handleUpdateFilter({ amount: value });
        }}
      />
      <TagFilters.Drawer
        disabled={input.disableAll}
        onApply={handleApplyDrawerFilter}
        onClear={handleClearDrawerFilter}
        onClose={handleCloseDrawerFilter}
      >
        <TagFilters.Checkbox
          asDrawerItem
          label="Transação"
          disabled={input.disableAll}
          options={[
            { label: 'Compra em parceiros', value: 'VOUCHER_PURCHASE' },
            { label: 'Compra no cartão', value: 'OPEN_LOOP_PAYMENT' },
            { label: 'Pagamento de boleto', value: 'BILLET_PAYMENT' },
            { label: 'Débito empresa', value: 'REVERSAL' },
            { label: 'Depósito empresa', value: 'DEPOSIT' },
            { label: 'Transferência via TED', value: 'TED_CASH_OUT' },
            { label: 'Transferência via PIX', value: 'PIX_CASH_OUT' },
            { label: 'Depósito via PIX', value: 'PIX_CASH_IN' },
            { label: 'Outros', value: 'OTHER' },
          ]}
          onApply={(value) => {
            handleUpdateFilter({ movement: value });
          }}
          ref={ref_movement}
        />
        <TagFilters.Checkbox
          asDrawerItem
          label="Status do Relatório"
          disabled={input.disableAll}
          options={[
            { label: 'Pendente', value: 'PENDING' },
            { label: 'Em aberto', value: 'OPEN' },
            { label: 'Aguardando processamento', value: 'PROCESSING' },
            { label: 'Submetido', value: 'SUBMITTED' },
            { label: 'Aprovado', value: 'APPROVED' },
            { label: 'Reembolsado', value: 'REFUNDED' },
            { label: 'Reprovado', value: 'DISAPPROVED' },
            { label: 'N/A', value: 'NA' },
          ]}
          onApply={(value) => {
            handleUpdateFilter({ accountability: value });
          }}
          ref={ref_accountability}
        />
        <TagFilters.Checkbox
          asDrawerItem
          label="Status"
          disabled={input.disableAll}
          options={[
            { label: 'Cancelada', value: 'CANCELED' },
            { label: 'Completa', value: 'COMPLETED' },
            { label: 'Confirmada', value: 'CONFIRMED' },
            { label: 'Criada', value: 'CREATED' },
            { label: 'Recusada', value: 'DECLINED' },
            { label: 'Falha', value: 'FAILED' },
            { label: 'Parcialmente Cancelada', value: 'PARTIALLY_CANCELED' },
            { label: 'Processando', value: 'PROCESSING' },
            { label: 'Liquidada', value: 'SETTLED' },
            { label: 'Estornada', value: 'REVERTED' },
          ]}
          onApply={(value) => {
            handleUpdateFilter({ status: value });
          }}
          ref={ref_status}
        />
      </TagFilters.Drawer>
    </>
  );

  const formattedFilter = Object.entries(FilterSchema.parse(rawFilter)).map(
    (entry) => ({
      id: entry[0],
      value: entry[1],
    }),
  );

  return {
    formattedFilter,
    FilterComponent,
  };
}
