import { Stepper } from '@flash-tecnologia/hros-web-ui-v2';
import Typography from '@frontend/components/Typography';
import { useExternalCards } from '@frontend/pages/ExternalCards/utils/hooks/useExternalCards';
import * as SC from './styled';

const InitialView = () => {
  return (
    <SC.HeaderWrapper>
      <Stepper steps={['Emissor', 'Detalhes', 'Confirmação']} activeStep={0} />
      <SC.HeaderDescriptionWrapper>
        <Typography.Headline6>Vincular Cartão Corporativo</Typography.Headline6>
        <SC.HeaderDescriptionSubtitle>
          Vincule um cartão ao colaborador já cadastrado na plataforma.
        </SC.HeaderDescriptionSubtitle>
      </SC.HeaderDescriptionWrapper>
    </SC.HeaderWrapper>
  );
};

type DetailsViewProps = {
  onlyOneEmployee: boolean;
  flashIssuer: boolean;
};

const DetailsView = (props: DetailsViewProps) => {
  const externalCards = useExternalCards();

  return (
    <SC.HeaderWrapper>
      {!externalCards.useV2 && (
        <Stepper
          steps={['Emissor', 'Detalhes', 'Confirmação']}
          activeStep={1}
        />
      )}
      <SC.HeaderDescriptionWrapper>
        <Typography.Headline6>
          Adicionar cartão de terceiros
        </Typography.Headline6>
        {!externalCards.useV2 && (
          <SC.HeaderDescriptionSubtitle>
            {props.flashIssuer ? 'Ativar' : 'Vincular'} cartão corporativo{' '}
            {props.onlyOneEmployee ? 'ao colaborador' : 'aos colaboradores'}{' '}
            Flash.
          </SC.HeaderDescriptionSubtitle>
        )}
      </SC.HeaderDescriptionWrapper>
    </SC.HeaderWrapper>
  );
};

type GetHeaderByViewProps = {
  actualView: string;
  onlyOneEmployee: boolean;
  flashIssuer: boolean;
};

export const getHeaderByView = (props: GetHeaderByViewProps) => {
  const HeaderByView = {
    INITIAL_VIEW: <InitialView />,
    DETAILS_VIEW: (
      <DetailsView
        onlyOneEmployee={props.onlyOneEmployee}
        flashIssuer={props.flashIssuer}
      />
    ),
  };

  return HeaderByView[props.actualView];
};
