import { trpc } from '@frontend/trpc';
import React from 'react';
import parseSpreadsheet, { ParsingErrors } from './parseSpreadsheet';

/** Parses the imported deposits and grafts additional employee data */
export default function useCheckEmployees() {
  const getEmployeesByDocumentNumber =
    trpc.company.employees.getManyByDocumentNumber.useMutation();

  const [errors, setErrors] = React.useState<Partial<Record<Errors, number[]>>>(
    {},
  );

  async function queryAsync(
    input: object[],
    setProgress: (progress: number) => void,
  ) {
    // Parses the spreadsheet input
    const parsedInput = parseSpreadsheet(input);
    setProgress(10);

    // Early return if there are no valid rows
    if (!parsedInput.data.length) {
      setErrors(parsedInput.errors);
      return [];
    }

    // Fetches employees by document number
    const employees = await getEmployeesByDocumentNumber.mutateAsync({
      documentNumberList: parsedInput.data.map((deposit) => deposit.CPF),
    });
    setProgress(80);

    // Grafts the employee data to the corresponding deposit, or appends it to the error list
    const data: DetailedDeposit[] = [];
    const validationErrors: Record<ProcessingErrors, number[]> = {
      DUPLICATED_EMPLOYEE: [],
      EMPLOYEE_NOT_FOUND: [],
    };
    parsedInput.data.forEach((deposit) => {
      const employee = employees.find(
        (employee) => employee.documentNumber === deposit.CPF,
      );
      if (employee) {
        const detailedDeposit = data.find(
          (deposit) => deposit.documentNumber === employee.documentNumber,
        );
        if (!detailedDeposit) {
          data.push({
            documentNumber: employee.documentNumber,
            name: employee.name,
            id: employee.id,
            value: deposit.Valor,
          });
        } else {
          validationErrors.DUPLICATED_EMPLOYEE.push(deposit.__rowNum__ + 1);
        }
      } else {
        validationErrors.EMPLOYEE_NOT_FOUND.push(deposit.__rowNum__ + 1);
      }
    });

    // Updates the error list with the invalid rows
    setErrors({
      DUPLICATED_EMPLOYEE: validationErrors.DUPLICATED_EMPLOYEE,
      EMPLOYEE_NOT_FOUND: validationErrors.EMPLOYEE_NOT_FOUND,
      INSUFFICIENT_VALUE: parsedInput.errors.INSUFFICIENT_VALUE,
      INVALID_DOCUMENT_NUMBER: parsedInput.errors.INVALID_DOCUMENT_NUMBER,
      INVALID_VALUE: parsedInput.errors.INVALID_VALUE,
      GENERIC_ERROR: parsedInput.errors.GENERIC_ERROR,
    });

    setProgress(100);
    return data;
  }
  return {
    queryAsync,
    resetErrors: () => setErrors({}),
    errors: {
      hasErrors: !!(
        errors.DUPLICATED_EMPLOYEE?.length ||
        errors.EMPLOYEE_NOT_FOUND?.length ||
        errors.INSUFFICIENT_VALUE?.length ||
        errors.INVALID_DOCUMENT_NUMBER?.length ||
        errors.INVALID_VALUE?.length ||
        errors.GENERIC_ERROR?.length
      ),
      errorCategories: {
        DUPLICATED_EMPLOYEE: {
          message: validationErrorMap['DUPLICATED_EMPLOYEE'],
          rows: errors.DUPLICATED_EMPLOYEE,
        },
        EMPLOYEE_NOT_FOUND: {
          message: validationErrorMap['EMPLOYEE_NOT_FOUND'],
          rows: errors.EMPLOYEE_NOT_FOUND,
        },
        INSUFFICIENT_VALUE: {
          message: validationErrorMap['INSUFFICIENT_VALUE'],
          rows: errors.INSUFFICIENT_VALUE,
        },
        INVALID_DOCUMENT_NUMBER: {
          message: validationErrorMap['INVALID_DOCUMENT_NUMBER'],
          rows: errors.INVALID_DOCUMENT_NUMBER,
        },
        INVALID_VALUE: {
          message: validationErrorMap['INVALID_VALUE'],
          rows: errors.INVALID_VALUE,
        },
        GENERIC_ERROR: {
          message: validationErrorMap['GENERIC_ERROR'],
          rows: errors.GENERIC_ERROR,
        },
      } as const,
    },
  };
}

/** Deposit with additional employee data */
type DetailedDeposit = {
  id: string;
  documentNumber: string;
  name: string;
  value: number;
};

type Errors = ProcessingErrors | ParsingErrors;

/** Errors that can happen during processing of the deposits */
enum ProcessingErrors {
  DUPLICATED_EMPLOYEE = 'DUPLICATED_EMPLOYEE',
  EMPLOYEE_NOT_FOUND = 'EMPLOYEE_NOT_FOUND',
}

const validationErrorMap = {
  DUPLICATED_EMPLOYEE: 'Colaborador duplicado na planilha',
  EMPLOYEE_NOT_FOUND: 'Colaborador não encontrado',
  INSUFFICIENT_VALUE: 'Valor abaixo do mínimo (R$ 10,00)',
  INVALID_DOCUMENT_NUMBER: 'CPF inválido',
  INVALID_VALUE: 'Valor inválido',
  GENERIC_ERROR: 'Não foi possível identificar os dados inseridos',
} as const satisfies Readonly<Record<Errors, string>>;
