import { UseGetExternalCardsOutput } from '@frontend/pages/ExternalCards/data/useGetExternalCards';
import { DateTime } from 'luxon';
import { TEMPLATE_COLUMNS, TEMPLATE_FIELDS } from '../constants';
import { MCC_LIST } from './availableMccList';
import { ISO_CURRENCY_LIST } from './isoCurrencyList';

export function fieldExists(
  register: string[],
  field: TEMPLATE_FIELDS,
): string {
  if (!register[field]) {
    return `Campo ${TEMPLATE_COLUMNS[field]} é obrigatório`;
  }

  return '';
}

export function isISOFormat(
  register: string[],
  field: keyof typeof TEMPLATE_FIELDS,
): string {
  if (!ISO_CURRENCY_LIST.includes(register[TEMPLATE_FIELDS[field]])) {
    return `Campo ${TEMPLATE_COLUMNS[field]} deve estar no formato ISO`;
  }

  return '';
}

export function cardExists(
  register: string[],
  availableCards: UseGetExternalCardsOutput['data'],
): string {
  const cardNumber: string = String(
    register[TEMPLATE_FIELDS.CARD_NUMBER],
  ).replace(/\D/g, '');

  if (
    !cardNumber?.length ||
    !availableCards.find(
      (card) => String(card.cardNumber) === cardNumber.slice(-4),
    )
  ) {
    return `Cartão ${register[TEMPLATE_FIELDS.CARD_NUMBER]} inválido ou inexistente`;
  }

  return '';
}

export function isForeignPurchase(
  register: string[],
  actualCurrency: string,
): boolean {
  if (!Boolean(actualCurrency)) return false;

  return register[TEMPLATE_FIELDS.TRANSACTION_CURRENCY] !== actualCurrency;
}

export function isValidMCC(register: string[]): string {
  const mcc: string = register[TEMPLATE_FIELDS.MCC];

  if (!mcc) {
    return '';
  }

  if (!Object.keys(MCC_LIST).includes(String(mcc))) {
    return 'Código MCC inválido';
  }

  return '';
}

export function getDate(date: string): string | DateTime {
  try {
    const transactionDate = DateTime.fromFormat(date, 'dd/MM/yyyy');

    if (!transactionDate.isValid) {
      throw new Error('Invalid transaction date');
    }

    return transactionDate;
  } catch (e) {
    return 'Data deve estar no padrão DD/MM/YYYY';
  }
}

export function isValidTransactionDate(
  register: string[],
  cardList: UseGetExternalCardsOutput['data'],
): string {
  const cardNumber: string = String(
    register[TEMPLATE_FIELDS.CARD_NUMBER],
  ).replace(/\D/g, '');

  const card = cardList.find(
    (card) => String(card.cardNumber) === cardNumber.slice(-4),
  );

  if (!card) {
    return '';
  }

  try {
    const transactionDate = getDate(register[TEMPLATE_FIELDS.TRANSACTION_DATE]);

    if (typeof transactionDate === 'string') {
      return transactionDate;
    }

    const accountabilityStartDate = DateTime.fromJSDate(
      card.accountabilityStartDate,
    );

    if (transactionDate <= accountabilityStartDate) {
      return 'Data da transação deve ser superior a data de início de prestação';
    }

    return '';
  } catch (e) {
    return 'Data da transação deve estar no padrão DD/MM/YYYY';
  }
}

export function validateHolderName(
  register: string[],
  cardList: UseGetExternalCardsOutput['data'],
): string {
  const cardNumber: string = String(
    register[TEMPLATE_FIELDS.CARD_NUMBER],
  ).replace(/\D/g, '');

  const card = cardList.find(
    (card) => String(card.cardNumber) === cardNumber.slice(-4),
  );

  if (!cardNumber?.length || !card) {
    return '';
  }

  const fullNameList = register[TEMPLATE_FIELDS.CARD_HOLDER]?.split?.(' ');

  const hasFistName = card?.name?.includes?.(fullNameList?.[0]);
  const hasLastName = card?.name?.includes?.(
    fullNameList?.[fullNameList?.length - 1],
  );

  if (hasFistName && hasLastName) {
    return '';
  }

  return 'O campo Nome do titular deve conter nome e sobrenome';
}
