import { trpc } from '@frontend/trpc';

type UseGetEmployeeDataInput = {
  cardId: string;
};

export default function useGetEmployeeData(input: UseGetEmployeeDataInput) {
  const card = trpc.company.externalCards.get.useQuery({
    cardId: input.cardId,
  });

  return {
    data: card.data,
    isLoading: card.isLoading,
  } as const;
}
