import errors from '@frontend/utils/commonTexts/errors';
import { PeriodTypeEnum } from 'backend/src/utils/periodTypeEnum';
import { z } from 'zod';

export const FormSchema = z
  .object({
    // Identification
    /** Title of the policy, defined by the admin */
    title: z
      .string({ required_error: errors.forms.generic.required })
      .min(1, errors.forms.generic.required)
      .max(64, 'O título deve ter no máximo 64 caracteres'),
    /** Additional description of the policy, defined by the admin */
    description: z
      .string({ required_error: errors.forms.generic.required })
      .min(1, errors.forms.generic.required)
      .max(255, 'A descrição deve ter no máximo 255 caracteres'),

    // Usage rules
    /** Used only to check if `limit` is required */
    limitEnabled: z.boolean(),
    limit: z
      .object({
        /** Allowed expense amount for the `period` */
        amount: z
          .number({
            required_error: errors.forms.generic.required,
            invalid_type_error: errors.forms.generic.required,
          })
          .int(errors.forms.generic.required)
          .positive('Valor deve ser maior que R$ 0,00'),
        /** Period for resetting the amount spent that is limited by `amount` */
        period: z.nativeEnum(PeriodTypeEnum),
      })
      .optional(),
    /** Whether the user can withdraw money. Only works with plastic cards */
    withdrawEnabled: z.boolean(),
    /** Days when the user can make payments with the card */
    weekdaysEnabled: z
      .object({
        SUNDAY: z.boolean(),
        MONDAY: z.boolean(),
        TUESDAY: z.boolean(),
        WEDNESDAY: z.boolean(),
        THURSDAY: z.boolean(),
        FRIDAY: z.boolean(),
        SATURDAY: z.boolean(),
      })
      .refine(
        (weekdaysEnabled) => {
          return Object.values(weekdaysEnabled).some((value) => value);
        },
        {
          message:
            'É necessário habilitar o uso em pelo menos um dia da semana',
        },
      ),
    /** Allowed MCC groups for payments */
    mccGroups: z
      .object({
        CONVENIENCE: z.boolean(),
        CULTURE: z.boolean(),
        EDUCATION: z.boolean(),
        GROCERY: z.boolean(),
        HEALTH: z.boolean(),
        MEAL: z.boolean(),
        MOBILITY: z.boolean(),
      })
      .refine(
        (mccGroups) => {
          return Object.values(mccGroups).some((value) => value);
        },
        {
          message: 'É necessário habilitar o uso em pelo menos uma categoria',
        },
      ),
    /** Allowed MCC groups for payments */
    mccLimits: z.object({
      CONVENIENCE: z
        .object({
          amount: z
            .number({
              required_error: errors.forms.generic.required,
              invalid_type_error: errors.forms.generic.required,
            })
            .int(errors.forms.generic.required)
            .positive('Valor deve ser maior que R$ 0,00'),
          period: z.nativeEnum(PeriodTypeEnum),
        })
        .nullish()
        .nullable()
        .optional(),
      CULTURE: z
        .object({
          amount: z
            .number({
              required_error: errors.forms.generic.required,
              invalid_type_error: errors.forms.generic.required,
            })
            .int(errors.forms.generic.required)
            .positive('Valor deve ser maior que R$ 0,00'),
          period: z.nativeEnum(PeriodTypeEnum),
        })
        .nullish()
        .nullable()
        .optional(),
      EDUCATION: z
        .object({
          amount: z
            .number({
              required_error: errors.forms.generic.required,
              invalid_type_error: errors.forms.generic.required,
            })
            .int(errors.forms.generic.required)
            .positive('Valor deve ser maior que R$ 0,00'),
          period: z.nativeEnum(PeriodTypeEnum),
        })
        .nullish()
        .nullable()
        .optional(),
      GROCERY: z
        .object({
          amount: z
            .number({
              required_error: errors.forms.generic.required,
              invalid_type_error: errors.forms.generic.required,
            })
            .int(errors.forms.generic.required)
            .positive('Valor deve ser maior que R$ 0,00'),
          period: z.nativeEnum(PeriodTypeEnum),
        })
        .nullish()
        .nullable()
        .optional(),
      HEALTH: z
        .object({
          amount: z
            .number({
              required_error: errors.forms.generic.required,
              invalid_type_error: errors.forms.generic.required,
            })
            .int(errors.forms.generic.required)
            .positive('Valor deve ser maior que R$ 0,00'),
          period: z.nativeEnum(PeriodTypeEnum),
        })
        .nullish()
        .nullable()
        .optional(),
      MEAL: z
        .object({
          amount: z
            .number({
              required_error: errors.forms.generic.required,
              invalid_type_error: errors.forms.generic.required,
            })
            .int(errors.forms.generic.required)
            .positive('Valor deve ser maior que R$ 0,00'),
          period: z.nativeEnum(PeriodTypeEnum),
        })
        .nullish()
        .nullable()
        .optional(),
      MOBILITY: z
        .object({
          amount: z
            .number({
              required_error: errors.forms.generic.required,
              invalid_type_error: errors.forms.generic.required,
            })
            .int(errors.forms.generic.required)
            .positive('Valor deve ser maior que R$ 0,00'),
          period: z.nativeEnum(PeriodTypeEnum),
        })
        .nullish()
        .nullable()
        .optional(),
      WITHDRAW: z
        .object({
          amount: z
            .number({
              required_error: errors.forms.generic.required,
              invalid_type_error: errors.forms.generic.required,
            })
            .int(errors.forms.generic.required)
            .positive('Valor deve ser maior que R$ 0,00'),
          period: z.nativeEnum(PeriodTypeEnum),
        })
        .nullish()
        .nullable()
        .optional(),
    }),
  })
  .refine(
    (policy) => {
      Object.entries(policy.mccLimits).every(([key, value]) => {
        if (key === 'WITHDRAW') {
          return (
            (value && policy.withdrawEnabled) ||
            (!value && !policy.withdrawEnabled)
          );
        }

        return (
          (value && policy.mccGroups[key]) || (!value && !policy.mccGroups[key])
        );
      });
    },
    {
      message:
        'Para definir um limite para a categoria é necessário habilitá-la na política',
    },
  );
