import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 2fr auto 1fr;
`;

export const Columns = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: ${(p) => p.theme.spacings.xs};
`;

export const Row = styled.div`
  display: flex;
  gap: ${(p) => p.theme.spacings.xs3};
  flex: 1;
`;

export const SpacerColumns = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ResultColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
