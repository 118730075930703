import React from 'react';
import { FormOutput, UseFormReturn } from '../../../forms/useForm';
import Icon from '@frontend/components/Icon';
import { DateTime } from 'luxon';

type Input = {
  form: UseFormReturn;
};

export default function useFormData(input: Input) {
  const formData: Partial<FormOutput> = input.form.getValues();

  const creditDate =
    formData.creditDate &&
    DateTime.fromJSDate(formData.creditDate, {
      zone: 'America/Sao_Paulo',
    }).startOf('day');

  const creditDateDescription =
    creditDate &&
    (formData.depositType === 'AUTOMATIC'
      ? `Todo dia ${creditDate.toFormat(
          'dd',
        )}, a partir de ${creditDate.toFormat('dd/MM/yyyy')}`
      : creditDate.toFormat('dd/MM/yyyy'));

  const description =
    formData.depositType === 'AUTOMATIC'
      ? formData.complementary
        ? 'Depósito automático complementar'
        : 'Depósito automático'
      : formData.expires
        ? 'Depósito único com expiração de saldo'
        : 'Depósito único';

  const paymentMethod =
    formData.paymentMethod && paymentMethodMap[formData.paymentMethod];

  const totalAmount =
    formData.deposits?.reduce((acc, deposit) => acc + deposit.value, 0) ?? 0;

  return {
    ...formData,
    metadata: {
      creditDateDescription,
      description,
      totalAmount,
      paymentMethod,
    },
  };
}

const paymentMethodMap = {
  FLASH_CASH: {
    title: 'Flash Cash corporativo',
    description:
      'Faça depósitos em tempo real. Tenha dinheiro na conta de Flash Cash para fazer depósitos a qualquer hora.',
    icon: 'IconWallet',
  },
  BILLET: {
    title: 'Boleto',
    description:
      'Pode ser pago através de uma conta ou agência bancária e tem prazo de compensação de até 3 dias úteis.',
    icon: 'BarcodeMethod',
  },
  PIX: {
    title: 'Pix',
    description:
      'Com o Pix, você tem pagamento instantâneo que pode ser feito em poucos segundos. É rápido e seguro.',
    icon: 'Pix',
  },
} as const satisfies Record<
  string,
  {
    title: string;
    description: string;
    icon: React.ComponentProps<typeof Icon>['name'];
  }
>;
