import { Template } from '@frontend/components/FocusedFlow/Template';
import { FlexColumn } from '@frontend/pages/Management/tabs/FlashCard/focusedFlows/AddBalance/modules/styled';
import FeatureFlagService from '@frontend/services/FeatureFlagService';
import ModalService from '@frontend/services/ModalService';
import { Cards } from '../modules/Cards';
import { PersonInfoCard } from '../modules/PersonInfoCard';
import { PolicySetup } from '../modules/PolicySetup';
import { SelectPerson } from '../modules/SelectPerson';
import { useCreateVirtualCard } from './useCreateVirtualCard';

const MAX_VIRTUAL_CARDS = 10;

type Input = {
  name: string;
  email?: string;
  nanoId: string;
};

export const useCreateVirtualCorporateCardController = (input?: Input) => {
  const modalController = ModalService.useModalController();

  const { isLoading, virtualCardForm, virtualCardFormSubmit } =
    useCreateVirtualCard();
  const policyEnabled = FeatureFlagService.getFlag('policies');

  const getModules = () => {
    return (
      <FlexColumn>
        {!!input ? (
          <PersonInfoCard
            email={input.email}
            name={input.name}
            nanoId={input.nanoId}
            form={virtualCardForm}
          />
        ) : (
          <SelectPerson
            form={virtualCardForm}
            limit={MAX_VIRTUAL_CARDS}
            loading={false}
          />
        )}
        <Cards form={virtualCardForm} />
        {policyEnabled && <PolicySetup form={virtualCardForm} />}
      </FlexColumn>
    );
  };

  const getActions = () => {
    return {
      leftActions: {
        onClick: () => modalController.remove(),
        label: 'Cancelar',
      },
      rightActions: [
        {
          onClick: () => virtualCardFormSubmit(),
          label: 'Criar cartão',
          isDisabled: false,
          isLoading: isLoading,
          variant: 'primary',
        },
      ],
    } as const;
  };

  return {
    content: (
      <Template
        pageInfo={{
          title: 'Novo cartão virtual corporativo',
        }}
        contentInfo={{
          title: 'Titular e cartões',
          description: 'O titular já deve possui uma conta corporativa.',
        }}
        Modules={getModules()}
      />
    ),
    footer: getActions(),
  };
};
