import { trpc } from '../../../utils/trpc';

export default function useCorporateStatus() {
  const getStatus = trpc.card.getStatus.useQuery();

  return {
    data: getStatus.data,
    loading: getStatus.isLoading,
  };
}
