import DefaultModal from '@frontend/components/modals/DefaultModal';
import ModalService from '@frontend/services/ModalService';
import { useCreateCorporateCardUserController } from './data/useCreateCorporateCardUserController';

type Props = {
  isFlashCard?: boolean;
};

function CreateCorporateCardUser({ isFlashCard = true }: Props) {
  const modalController = ModalService.useModalController();

  const { viewComponent, viewActions, overHeader, isLoading } =
    useCreateCorporateCardUserController({ isFlashCard });

  return (
    <DefaultModal
      header={{
        title: '',
        subtitle: '',
      }}
      children={viewComponent}
      buttons={viewActions}
      isOpen={modalController.visible}
      onClose={modalController.remove}
      OverHeader={overHeader}
      loading={isLoading}
    />
  );
}

export default ModalService.create(CreateCorporateCardUser);
