import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import StepModal from '@frontend/components/modals/StepModal';
import ModalService from '@frontend/services/ModalService';
import OnboardingCoupledVirtualCardEndModal from './OnboardingCoupledVirtualCardEndModal';

function OnboardingCoupledVirtualCardTutorialModal() {
  const modalController = ModalService.useModalController();

  function handleDismiss() {
    modalController.remove();
  }

  function showEndModal() {
    modalController.remove();
    ModalService.show(OnboardingCoupledVirtualCardEndModal, {});
  }

  return (
    <StepModal
      isOpen={modalController.visible}
      onClose={handleDismiss}
      onEnd={showEndModal}
      steps={[
        {
          title: 'Saldo e limite do cartão virtual',
          content: (
            <>
              <Typography.Body4 color="neutral_40">
                O cartão virtual corporativo usará apenas o{' '}
                <Typography.Body4 weight={700} color="neutral_40" as="span">
                  saldo de Despesas Corporativas
                </Typography.Body4>{' '}
                e será vinculado ao cartão físico. As mesmas regras e limites do
                cartão físico serão aplicadas ao virtual, garantindo segurança
                na gestão.
                <br />
                <Typography.Body4 weight={700} color="neutral_40">
                  Benefícios do processo:
                </Typography.Body4>
                <ul>
                  <Typography.Body4 color="neutral_40" as="li">
                    Autonomia para a equipe, que poderá gerar o cartão virtual
                    diretamente pelo Flash App.
                  </Typography.Body4>
                  <Typography.Body4 color="neutral_40" as="li">
                    Cartões virtuais associados ao cartão físico, seguindo as
                    mesmas políticas de uso definidas pela empresa.
                  </Typography.Body4>
                  <Typography.Body4 color="neutral_40" as="li">
                    Uso de saldo corporativo específico para pagamentos
                    recorrentes
                  </Typography.Body4>
                </ul>
              </Typography.Body4>
              <Spacer y="s" />
              <img
                src={
                  'https://images.flashapp.com.br/expense/corporate-card/onboarding-coupled-virtual-card/image.png'
                }
              />
            </>
          ),
        },
        {
          title: 'Criação do cartão virtual',
          content: (
            <>
              <Typography.Body4 color="neutral_40">
                Através da área de Cartões do Flash App,{' '}
                <Typography.Body4 weight={700} color="neutral_40" as="span">
                  o colaborador poderá gerar seu próprio cartão virtual.
                </Typography.Body4>{' '}
                Você terá visão total dos cartões através da plataforma. Quer
                saber como? A gente te explica na visão a seguir!
              </Typography.Body4>
              <Spacer y="s" />
              <img
                src={
                  'https://images.flashapp.com.br/expense/corporate-card/onboarding-coupled-virtual-card/step-1.gif'
                }
              />
            </>
          ),
        },
        {
          title: 'Visão dos cartões na plataforma',
          content: (
            <>
              <Typography.Body4 color="neutral_40">
                Em{' '}
                <Typography.Body4 weight={700} color="neutral_40" as="span">
                  Cartões corporativos
                </Typography.Body4>
                , você possui acesso à relação de pessoas e cartões criados por
                sua equipe. Os cartões virtuais vinculados ao físico terão a
                informação de "Uso associado ao cartão físico", permitindo sua
                fácil identificação.
              </Typography.Body4>
              <Spacer y="s" />
              <img
                src={
                  'https://images.flashapp.com.br/expense/corporate-card/onboarding-coupled-virtual-card/step-2.gif'
                }
              />
            </>
          ),
        },
      ]}
    />
  );
}

export default ModalService.create(OnboardingCoupledVirtualCardTutorialModal);
