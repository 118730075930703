import { ListItemText, MenuItem, MenuList, Popover } from '@mui/material';
import TagButton from '../TagButton';
import React from 'react';
import styled from 'styled-components';

type Props = {
  disabled?: boolean;
  onChange: (_newValue: number) => void;
  options: Readonly<
    Array<{
      label: string;
      value: number;
    }>
  >;
  selectedValue: number;
};

export default function TagPaginationSelect(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  function handleOnClose() {
    setAnchorEl(null);
  }

  function handleSelect(value: number) {
    props.onChange(value);
    handleOnClose();
  }

  return (
    <>
      <TagButton
        label={
          props.options.find((option) => option.value === props.selectedValue)
            ?.label ?? ''
        }
        status={props.disabled ? 'disabled' : 'neutral'}
        rightIcon="IconChevronDown"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleOnClose}
        PaperProps={{
          style: {
            padding: '12px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuList>
          {props.options.map((item) => {
            const selected = item.value === props.selectedValue;
            return (
              <StyledMenuItem
                selected={selected}
                key={item.value}
                onClick={() => handleSelect(item.value)}
              >
                <ListItemText>{item.label}</ListItemText>
              </StyledMenuItem>
            );
          })}
        </MenuList>
      </Popover>
    </>
  );
}

const StyledMenuItem = styled(MenuItem)<{ selected: boolean }>`
  border-radius: 8px;
  margin-bottom: 4px;
  padding: 16px;
  background-color: ${(p) =>
    p.selected ? p.theme.colors.secondary[95] : 'transparent'};
`;
