import styled from 'styled-components';

type RadioCardProps = {
  status: 'DISABLED' | 'ACTIVE' | 'NEUTRAL';
};

export const RadioCardContainer = styled.div<RadioCardProps>`
  padding: 20px 24px;
  border-radius: 8px 20px 8px 8px;
  border: 1px solid
    ${({ theme, status }) => {
      switch (status) {
        case 'ACTIVE':
          return theme.colors.secondary[80];
        case 'DISABLED':
          return theme.colors.neutral[80];
        case 'NEUTRAL':
          return theme.colors.neutral[90];
      }
    }};
  background: ${({ theme, status }) => {
    switch (status) {
      case 'ACTIVE':
        return theme.colors.secondary[99];
      case 'DISABLED':
        return theme.colors.neutral[95];
      case 'NEUTRAL':
        return theme.colors.neutral[100];
    }
  }};
  cursor: ${({ status }) => {
    switch (status) {
      case 'DISABLED':
        return 'not-allowed';
      default:
        return 'pointer';
    }
  }};
`;

export const LeftSide = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ErrorMessageArea = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 24px;
`;
