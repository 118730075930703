import styled from 'styled-components';
import { Modal as UIModal } from '@flash-tecnologia/hros-web-ui-v2';

export const Modal = styled(UIModal.Root)`
  .modal-container {
    max-height: 90vh !important;
    overflow-y: scroll;
  }
  .modal-content-area {
    overflow: unset !important;
    padding: 0 112px 50px !important;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 420px;
`;

export const Footer = styled.div<{ buttonQuantity: 1 | 2 }>`
  border-top: 1px solid ${(p) => p.theme.colors.neutral[90]};
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 32px 112px;
  align-items: center;
  justify-content: ${({ buttonQuantity }) => {
    if (buttonQuantity == 2) {
      return 'space-between';
    }
    return 'center';
  }};
`;

export const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
  padding-right: 24px;
  position: fixed;
  width: 564px;
`;

export const ConfirmationWordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
`;
