import { Route } from 'react-router-dom';
import Billet from './Billet';
import BasePath from '../../../routes/BasePath';
import RouterService from '@frontend/services/RouterService';

export const ROUTE_Billet = RouterService.create(
  `${BasePath.relativePath}/transactions/billet`,
);

export const RouteComponent_Billet_Index = <Route index element={<Billet />} />;

export const RouteComponent_Billet = (
  <Route path={ROUTE_Billet.path} element={<Billet />} />
);
