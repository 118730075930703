import { Route } from 'react-router-dom';
import Onboardings from './Onboardings';
import BasePath from '../../../routes/BasePath';
import RouterService from '@frontend/services/RouterService';

export const ROUTE_Onboardings = RouterService.create(
  `${BasePath.relativePath}/playground/onboardings`,
);

export const RouteComponent_Onboardings = (
  <Route path={ROUTE_Onboardings.path} element={<Onboardings />}></Route>
);
