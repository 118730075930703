import { Menu } from '@flash-tecnologia/hros-web-ui-v2';
import ModalService from '../../../../services/ModalService';
import CancelDepositModal from '../CancelDepositModal/CancelDepositModal';
import { RouterOutputs } from 'backend';

type Input = {
  deposit: RouterOutputs['company']['deposits']['search']['deposits'][number];
};

export const useMenuActions = (input: Input) => {
  const menuOptions: React.ComponentProps<typeof Menu>['options'] = [];

  if (input.deposit.reportLink) {
    menuOptions.push({
      label: 'Baixar relatório',
      onClick: () => {
        if (input.deposit.reportLink) {
          window.open(input.deposit.reportLink)?.focus();
        }
      },
    });
  }

  if (
    input.deposit.employeeDepositId &&
    !input.deposit.paid &&
    !input.deposit.cancelled
  ) {
    menuOptions.push({
      label: 'Cancelar depósito',
      onClick: () =>
        ModalService.show(CancelDepositModal, {
          employeeDepositId: input.deposit.employeeDepositId,
          amount: input.deposit.amount,
          date: input.deposit.creditDate,
          employeeName: input.deposit.name,
        }),
    });
  }

  return {
    menuOptions,
  };
};
