import { GenerateXLSXFile } from '@flash-tecnologia/hros-web-ui-v2';
import { Template } from '@frontend/components/FocusedFlow/Template';
import NotificationCard from '@frontend/components/NotificationCard';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import NotificationCallout from '@frontend/components/display/NotificationCallout';
import BorderedSection from '@frontend/components/frames/BorderedSection';
import SpreadsheetUpload from '@frontend/components/inputs/SpreadsheetUpload';
import { TRPCClientError } from '@trpc/client';
import { Controller } from 'react-hook-form';
import { UseFormReturn } from '../../forms/useForm';
import useCheckEmployees from './data/useCheckEmployees';

type Props = {
  form: UseFormReturn;
};

export default function AddFile(props: Props) {
  const checkEmployees = useCheckEmployees();

  return (
    <Template
      pageInfo={{
        title: 'Fazer depósito via planilha',
      }}
      contentInfo={{
        title: 'Envie os dados',
        description:
          'Envie a planilha com os valores de depósito para cada pessoa.',
      }}
      Modules={
        <>
          <BorderedSection padding="m">
            <Typography.Headline8 color={'neutral_20'}>
              Baixe o modelo Flash e insira os dados
            </Typography.Headline8>
            <Typography.Body3 color={'neutral_30'}>
              Para fazer seu depósito em lote, baixe o modelo de arquivo da
              Flash e use para preencher os dados necessários.
            </Typography.Body3>
            <Spacer y={'xs'} />
            <GenerateXLSXFile
              fileName="depósito-cartão-corporativo-flash.xlsx"
              data={[['CPF', 'Valor']]}
              docIconSize={24}
              buttonTitle="Baixar o modelo da Flash em XLSX"
              sheetName="deposito"
            />
          </BorderedSection>
          <Spacer y={'xs'} />
          <BorderedSection padding="m">
            <Typography.Headline8 color={'neutral_20'}>
              Envie seu arquivo
            </Typography.Headline8>
            <Typography.Body3 color={'neutral_30'}>
              Com o arquivo preenchido, agora é só anexá-lo abaixo!
            </Typography.Body3>
            <Spacer y={'xs'} />
            <Controller
              control={props.form.control}
              name="deposits"
              render={({ field }) => (
                <>
                  <SpreadsheetUpload
                    onReset={() => checkEmployees.resetErrors()}
                    onUpload={async (data, setProgress) => {
                      try {
                        const result = await checkEmployees.queryAsync(
                          data,
                          setProgress,
                        );
                        field.onChange(result);
                        if (!result.length) {
                          return {
                            success: false,
                            customError: validationErrors.NO_VALID_ROWS,
                          };
                        }
                        return {
                          success: true,
                        };
                      } catch (error) {
                        if (error instanceof TRPCClientError) {
                          return {
                            success: false,
                            customError: validationErrors.QUERY_FAILED,
                          };
                        }
                        return {
                          success: false,
                          customError: validationErrors.GENERIC_ERROR,
                        };
                      }
                    }}
                  />
                </>
              )}
            />
            <Spacer y={'xs'} />
            {checkEmployees.errors.hasErrors && (
              <>
                <NotificationCallout
                  variant="error"
                  title="Encontramos alguns errors no preenchimento da planilha"
                  description="Você pode continuar o depósito com os registros que estão corretos, ou então verificar o arquivo e tentar enviá-lo novamente."
                >
                  {Object.values(checkEmployees.errors.errorCategories)
                    .filter((errorList) => errorList.rows?.length)
                    .map((errorList, index) => (
                      <Typography.Body4 key={errorList.message}>
                        <b>
                          {index + 1}. {errorList.message}
                        </b>{' '}
                        - Registros: {errorList.rows?.join(', ')}
                      </Typography.Body4>
                    ))}
                </NotificationCallout>
                <Spacer y={'xs'} />
              </>
            )}
            <NotificationCard
              iconName="IconInfoCircle"
              type="info"
              subtitle="Se na planilha houverem pessoas que ainda não tiveram a ativação de seus cartões corporativos completa, eles serão ativados e carregados com os saldos informados."
            />
          </BorderedSection>
          <Spacer y={'m'} />
        </>
      }
    />
  );
}

const validationErrors = {
  NO_VALID_ROWS:
    'Não conseguimos identificar os dados da planilha. Verifique se você está utilizando o modelo da Flash e tente enviá-la novamente.',
  QUERY_FAILED:
    'Não foi possível buscar as informações dos colaboradores. Por favor, tente novamente.',
  GENERIC_ERROR:
    'Não foi possível validar o arquivo. Verifique se você está utilizando o modelo da Flash e tente enviá-lo novamente.',
} as const;
