import { dispatchToast } from '@frontend/utils/dispatchEvents';
import { RouterInputs, RouterOutputs, trpc } from '@frontend/utils/trpc';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

type GetCreditInfo = RouterInputs['company']['deposits']['projection'];

export type CreditInfo = RouterOutputs['company']['deposits']['projection'];

export const INITIAL_DATE = DateTime.now().startOf('day');
export const LIMIT_DATE = INITIAL_DATE.plus({ years: 10 });

export function useCreditInfo() {
  const [filters, setFilters] = useState<GetCreditInfo['filter']>({
    date: {
      from: INITIAL_DATE.toISO(),
      to: LIMIT_DATE.toISO(),
    },
    value: {
      max: 999999999999,
      min: -999999999999,
    },
  });
  const [pagination, setPagination] = useState<GetCreditInfo['pagination']>({
    currentPage: 0,
    pageSize: 10,
  });

  const filter = {
    ...(!!filters && {
      ...filters,
    }),
  };
  const { data, isInitialLoading, isStale, isRefetching } =
    trpc.company.deposits.projection.useQuery(
      {
        filter,
        pagination,
      },
      {
        keepPreviousData: true,
        retry: false,
        retryOnMount: true,
        staleTime: 60 * 1000,
        refetchInterval: 30 * 1000,
        onError({ message }) {
          dispatchToast({
            type: 'error',
            content: message,
          });
        },
      },
    );

  const creditInfoData: CreditInfo = data || {
    credits: [],
    filter: { value: { max: 0, min: 0 } },
    pagination: { totalPages: 0, pageSize: 0, currentPage: 0, totalItems: 0 },
  };
  const { value } = creditInfoData.filter;

  const [valueFilters, setValueFilters] = useState<{
    max: number;
    min: number;
  }>();

  useEffect(() => {
    if (value?.max !== undefined && value?.min !== undefined) {
      setValueFilters(value);
    }
  }, [value?.max, value?.min]);

  return {
    isFiltered: Object.keys(filter).length > 0,
    setFilters,
    value: valueFilters,
    pagination,
    setPagination,
    filter,
    creditInfoData,
    loading: isInitialLoading || (isStale && isRefetching),
  };
}
