import { useCreateFlashCorporateCardUser } from './useCreateFlashCardUser';
import { useCreateExternalCorporateCardUser } from './useCreateExternalCardUser';

export const useCreateCorporateCardUser = (onSuccessCallback: () => void) => {
  const {
    addFlashCardUser,
    flashCardForm,
    isLoading: isLoadingFlashCardUser,
  } = useCreateFlashCorporateCardUser(onSuccessCallback);

  const {
    createExternalCardUser,
    externalCardForm,
    isLoading: isLoadingExternalCardUser,
  } = useCreateExternalCorporateCardUser(onSuccessCallback);

  const createCorporateCardUser = (cardIssuer) => {
    if (cardIssuer === 'FLASH') {
      return addFlashCardUser();
    } else {
      return createExternalCardUser();
    }
  };

  return {
    createCorporateCardUser,
    isLoading: isLoadingFlashCardUser || isLoadingExternalCardUser,
    externalCardForm,
    flashCardForm,
  };
};
