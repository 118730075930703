import NiceModal from '@ebay/nice-modal-react';
import { ThemeProvider } from '@flash-tecnologia/hros-web-ui-v2';
import { FeatureFlagProvider } from '@frontend/services/FeatureFlagService';
import { TrpcProvider } from '@frontend/trpc';
import { ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';

export function ExternalRoot(props: { children: ReactNode }) {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <TrpcProvider>
          <FeatureFlagProvider>
            <NiceModal.Provider>{props.children}</NiceModal.Provider>
          </FeatureFlagProvider>
        </TrpcProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}
