import ModalService from '@frontend/services/ModalService';
import { trpc } from '@frontend/trpc';
import PatchActiveModal from '../modals/PatchActiveModal';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

export default function usePatchActive() {
  const patchActive = trpc.company.policies.patchActive.useMutation();
  const context = trpc.useContext();

  const mutateAsync = (input: {
    id: string;
    cardIds: string[];
    newActiveValue: boolean;
  }) => {
    /** Used for optimistic updates and updating the cache */
    const setCache = (value: boolean) => {
      function updatePolicy(
        policy: { active: boolean; cardIds: string[] },
        activeNewValue: boolean,
      ) {
        policy.active = activeNewValue;
        // Remove all cardIds if the policy is deactivated
        if (!activeNewValue) policy.cardIds = [];
      }

      context.company.policies.search.setData({}, (oldData) => {
        if (oldData) {
          const index = oldData.findIndex((item) => item._id === input.id);
          if (index > -1) updatePolicy(oldData[index], value);
        }
        return oldData;
      });
      context.company.policies.get.setData(
        { policyId: input.id },
        (oldData) => {
          if (oldData) {
            updatePolicy(oldData, value);
          }
          return oldData;
        },
      );
    };

    /** Handles the mutation, optimistic update and toasts */
    const runMutation = async () => {
      // Optimistic update
      setCache(input.newActiveValue);

      return patchActive
        .mutateAsync({
          id: input.id,
          active: input.newActiveValue,
        })
        .then(() => {
          toastSuccess(input.newActiveValue);
        })
        .catch(() => {
          // Undo optimistic update
          setCache(!input.newActiveValue);
          toastError(input.newActiveValue);
        });
    };

    if (input.newActiveValue) {
      return runMutation();
    } else {
      ModalService.show(PatchActiveModal, {
        policy: input,
        onConfirm: runMutation,
      });
    }
  };

  return {
    mutateAsync,
    data: patchActive.data,
  };
}

function toastError(newActiveValue: boolean) {
  dispatchToast({
    type: 'error',
    content: `Não foi possível ${
      newActiveValue ? 'ativar' : 'desativar'
    } a política.`,
    description: 'Tente novamente. Se o problema persistir, contate o suporte.',
  });
}

function toastSuccess(newActiveValue: boolean) {
  dispatchToast({
    type: 'success',
    content: `Política ${
      newActiveValue ? 'ativada' : 'desativada'
    } com sucesso!`,
  });
}
