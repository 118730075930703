import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { ROUTE_Home_FlashOS } from '@frontend/routes/ExternalRoutes';
import RouterService from '@frontend/services/RouterService';
import { useEffect, useState } from 'react';

export function useHandleChangeCompany() {
  const navigation = RouterService.useNavigate();
  const selectedCompany = useSelectedCompany();

  const [currentCompanyId, setCurrentCompany] = useState<string>();

  useEffect(() => {
    const hasChangeCompany =
      currentCompanyId &&
      selectedCompany.selectedCompany.id !== currentCompanyId;

    if (hasChangeCompany) {
      navigation({
        route: ROUTE_Home_FlashOS,
        params: {},
        searchParams: {},
        state: {},
      });
    }

    setCurrentCompany(selectedCompany.selectedCompany.id);
  }, [selectedCompany.selectedCompany.id]);
}
