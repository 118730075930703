import { UseFormReturn } from '../data/forms/externalCardForm';
import { AddCardStep } from '../data/useCreateCorporateCardUserController';
import { CardDetails } from './CardDetails';
import { CardIssuer } from './CardIssuer';

type GetComponentByViewProps = {
  selectedView: AddCardStep;
  createCorporateCardUserMetadata: any; // TODO: Update this type
  setCreateCorporateCardUserMetadata: any; // TODO: Update this type
  externalCardForm: UseFormReturn;
  flashCardForm: any; // TODO: Update this type
};

export function getComponentByView(props: GetComponentByViewProps) {
  switch (props.selectedView) {
    case 'INITIAL_VIEW':
      return (
        <CardIssuer
          createCorporateCardUserMetadata={
            props.createCorporateCardUserMetadata
          }
          setCreateCorporateCardUserMetadata={
            props.setCreateCorporateCardUserMetadata
          }
        />
      );
    case 'DETAILS_VIEW':
      return (
        <CardDetails
          isFlashCard={
            props.createCorporateCardUserMetadata.cardIssuer === 'FLASH'
          }
          createCorporateCardUserMetadata={
            props.createCorporateCardUserMetadata
          }
          externalCardForm={props.externalCardForm}
          flashCardForm={props.flashCardForm}
        />
      );
  }
}
