import {
  BoxAdornment,
  Menu,
  MenuOptionProps,
  PillButton,
} from '@flash-tecnologia/hros-web-ui-v2';
import Spacer from '@frontend/components/Spacer';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import styled from 'styled-components';

interface NoWrapProps {
  width?: number;
}

export const NoWrap = styled.div<NoWrapProps>`
  white-space: nowrap;
  ${({ width }) => width !== undefined && `width: ${width}px`}
`;

const Box = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacings.xs2};
`;

export const StyledIcon = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 50%;
  width: 62px;
  height: 62px;
  display: flex;
  justify-content: center;
  fill: transparent;
  align-items: center;
`;

export const Line = styled.div`
  align-items: center;
  display: flex;
`;

export const SpaceBetween = styled(Line)`
  justify-content: space-between;
  width: 100%;
`;

export function Accordion({
  title,
  description,
  status,
  leftAdornment,
  options,
  onClick,
}: {
  title: string;
  description: React.ReactNode;
  leftAdornment: React.ReactNode;
  status: boolean;
  options: Partial<MenuOptionProps>[];
  onClick?: () => void;
}) {
  return (
    <Box>
      <BoxAdornment
        style={{ borderWidth: 0, width: '100%' }}
        title={
          <Line>
            <Typography.Headline7>{title}</Typography.Headline7>
            <Spacer x="xs4" />
            <Tag variant={status ? 'success' : 'neutral'} size="sm">
              {status ? 'Ativo' : 'Desativado'}
            </Tag>
          </Line>
        }
        description={<Typography.Body4>{description}</Typography.Body4>}
        leftAdornment={leftAdornment}
      />
      <Menu type="default" options={options}>
        <PillButton
          size="medium"
          variant="default"
          icon="IconDotsVertical"
          onClick={onClick}
        />
      </Menu>
    </Box>
  );
}
