import ChipFlashColor from '../../../assets/providerChips/flash/color.svg';
import ChipFlashForeground from '../../../assets/providerChips/flash/foreground.svg';
import ChipMastercardColor from '../../../assets/providerChips/mastercard/color.svg';
import ChipMastercardDark from '../../../assets/providerChips/mastercard/dark.svg';
import ChipMastercardForeground from '../../../assets/providerChips/mastercard/foreground.svg';
import ChipMastercardLight from '../../../assets/providerChips/mastercard/light.svg';
import ChipVisaColor from '../../../assets/providerChips/visa/color.svg';
import ChipVisaDark from '../../../assets/providerChips/visa/dark.svg';
import ChipVisaForeground from '../../../assets/providerChips/visa/foreground.svg';
import ChipVisaLight from '../../../assets/providerChips/visa/light.svg';

type Props = {
  /** Card provider. E.G.: MASTERCARD, VISA, AGILLITAS, FLASH */
  provider: string;
  /** Color variant */
  variant: 'color' | 'dark' | 'light' | 'foreground';
};

export default function CardProviderChip(props: Props) {
  switch (props.provider.toUpperCase()) {
    case 'MASTERCARD':
      switch (props.variant) {
        case 'color':
          return <ChipMastercardColor />;
        case 'dark':
          return <ChipMastercardDark />;
        case 'foreground':
          return <ChipMastercardForeground />;
        case 'light':
          return <ChipMastercardLight />;
      }
    case 'VISA':
    case 'AGILLITAS':
      switch (props.variant) {
        case 'color':
          return <ChipVisaColor />;
        case 'dark':
          return <ChipVisaDark />;
        case 'foreground':
          return <ChipVisaForeground />;
        case 'light':
          return <ChipVisaLight />;
      }
    case 'FLASH':
      switch (props.variant) {
        case 'color':
          return <ChipFlashColor />;
        case 'foreground':
          return <ChipFlashForeground />;
      }
    default:
      return <ChipFlashColor />;
  }
}
