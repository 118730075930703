import FormService from '@frontend/services/FormService';
import { z } from 'zod';
import ErrorMonitorService from '@frontend/services/MonitorService';
import { DateTime } from 'luxon-business-days';
import errors from '@frontend/utils/commonTexts/errors';

export const FormSchema = z.object({
  _id: z.string().catch(''),
  /** List of deposits imported from the spreadsheet */
  deposits: z.array(
    z.object({
      id: z.string(),
      documentNumber: z.string(),
      name: z.string(),
    }),
  ),
  /** Payment method chosen by the admin */
  paymentMethod: z.enum(['FLASH_CASH', 'BILLET', 'PIX']),
  /** Whether the deposit should happen only once (SINGLE) or repeat every month (AUTOMATIC) */
  depositType: z.enum(['SINGLE', 'AUTOMATIC'], {
    required_error: errors.forms.generic.required,
  }),
  /** Date of the (first) employee credit */
  creditDate: z
    .date({
      invalid_type_error: errors.forms.date.invalid,
      required_error: errors.forms.generic.required,
    })
    .min(DateTime.now().startOf('day').toJSDate(), {
      message: errors.forms.date.future,
    }),
  /** Whether the credited balance should expire at the `expirationDate`. Only valid for single deposits */
  expires: z.boolean().catch(false),
  /** Expiration date of the credited balance. Only valid for single deposits, when `expires: true` */
  expirationDate: z
    .date({
      invalid_type_error: errors.forms.date.invalid,
    })
    .min(DateTime.now().startOf('day').toJSDate(), {
      message: errors.forms.date.future,
    })
    .optional(),
  /** Whether the deposit should only complement the employee balance to a target amount. Only valid for automatic deposits */
  complementary: z.boolean().catch(false),
  /** Deposit amount for each employee */
  value: z.number().positive(),
});

export type FormOutput = z.output<typeof FormSchema>;

export default function useForm(
  initialValue: {
    _id?: z.infer<typeof FormSchema>['_id'];
    creditDate?: z.infer<typeof FormSchema>['creditDate'];
    employees: z.infer<typeof FormSchema>['deposits'];
    value?: z.infer<typeof FormSchema>['value'];
  },
  useApiHook: () => {
    mutateAsync: (_: unknown) => Promise<unknown>;
    data?: {
      _id: string;
      value: number;
      creditDate: Date;
      paymentMethod: 'PIX' | 'FLASH_CASH' | 'BILLET';
    };
  },
) {
  const { employees: deposits, ...values } = initialValue;

  const form = FormService.useCreateForm(FormSchema, {
    mode: 'onChange',
    defaultValues: {
      ...values,
      deposits,
    },
  });
  const hookResponse = useApiHook();

  const onSubmit = form.handleSubmit(
    async (values) => {
      await hookResponse.mutateAsync(values);
    },
    (errors) => {
      ErrorMonitorService.error({
        message: 'Error while creating many deposits',
        severity: 'fatal',
        extras: {
          zodErrors: JSON.stringify(errors),
        },
      });
      throw new Error('Form parsing error');
    },
  );

  return {
    ...form,
    onSubmit,
    result: hookResponse.data,
  };
}

export type UseFormReturn = ReturnType<typeof useForm>;
