import Tag from '@frontend/components/TagV2';
import type { UseGetStatementOutput } from '../data/useGetStatement';

type TagProps<T extends React.Key> = Record<
  T,
  {
    label: string;
    variant: React.ComponentProps<typeof Tag>['variant'];
  }
>;

const source = {
  CREDIT: {
    label: 'Crédito',
    variant: 'primary',
  },
  PREPAID: {
    label: 'Pré-pago',
    variant: 'primary',
  },
} as const satisfies TagProps<UseGetStatementOutput['items'][number]['source']>;

const accountabilityStatus = {
  OPEN: {
    label: 'Em aberto',
    variant: 'info',
  },
  PENDING: {
    label: 'Pendente',
    variant: 'info',
  },
  PROCESSING: {
    label: 'Aguardando processamento',
    variant: 'info',
  },
  SUBMITTED: {
    label: 'Submetido',
    variant: 'info',
  },
  APPROVED: {
    label: 'Aprovado',
    variant: 'success',
  },
  DISAPPROVED: {
    label: 'Reprovado',
    variant: 'error',
  },
  REFUNDED: {
    label: 'Reembolsado',
    variant: 'success',
  },
  NA: {
    label: 'N/A',
    variant: 'neutral',
  },
} as const satisfies TagProps<
  UseGetStatementOutput['items'][number]['accountabilityStatus']
>;

export default {
  accountabilityStatus,
  source,
};
