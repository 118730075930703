import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import ModalService from '@frontend/services/ModalService';
import useDeleteTransaction from '../data/useDeleteTransaction';

type Props = {
  transactionId: string;
};

function DeleteTransactionModal(props: Props) {
  const controller = ModalService.useModalController();
  const deleteItem = useDeleteTransaction();

  function handleOnClick() {
    void deleteItem
      .mutateAsync({ transactionId: props.transactionId })
      .then(() => controller.remove());
  }

  return (
    <ConfirmationModal
      isOpen={controller.visible}
      onClose={controller.remove}
      variant="error"
      loading={deleteItem.isLoading}
      text={{
        title: 'Tem certeza de que deseja excluir a transação?',
        body: 'Essa transação será excluída permanentemente.',
      }}
      buttons={{
        cancel: {
          text: 'Cancelar',
          onClick: controller.remove,
        },
        confirm: {
          text: 'Excluir transação',
          onClick: handleOnClick,
        },
      }}
    />
  );
}

export default ModalService.create(DeleteTransactionModal);
