import styled from 'styled-components';
import Typography from '../../Typography';

type Props = {
  /** Image url */
  imageUrl?: string;
  /** Alternative text. If the image is not available, the first initial will be displayed */
  alt: string;
};

export default function Avatar(props: Props) {
  return (
    <StyledContainer>
      {props.imageUrl ? (
        <StyledImage alt={props.alt} src={props.imageUrl} />
      ) : (
        <Typography.Body3>{getInitial(props.alt)}</Typography.Body3>
      )}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.secondary[95]};
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

function getInitial(name: string) {
  return name.length ? name[0].toUpperCase() : '';
}
