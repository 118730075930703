import ConfirmationModal from '../../../../../../components/modals/ConfirmationModal';
import ModalService from '../../../../../../services/ModalService';

function UploadTransactionsFeedback() {
  const modalController = ModalService.useModalController();

  return (
    <ConfirmationModal
      buttons={{
        confirm: {
          text: 'Concluir',
          onClick: () => modalController.remove(),
        },
      }}
      isOpen={modalController.visible}
      onClose={modalController.remove}
      variant="default"
      text={{
        title: 'Transações importadas com sucesso!',
        body: 'Confira as transações nos extratos referentes aos cartões que você importou as transações.',
      }}
    />
  );
}

export default ModalService.create(UploadTransactionsFeedback);
