import {
  Auth,
  getAccessTokenPayloadSync,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import { z } from 'zod';

const AuthService = {
  useCompany,
  useEmployeeId,
};
export default AuthService;

/** Currently selected employeeId */
function useEmployeeId() {
  const accessToken = getAccessTokenPayloadSync();

  if (!accessToken?.employeeId) {
    forceSignOut();
    return null;
  }
  return accessToken.employeeId;
}

/** Currently selected company */
function useCompany() {
  const selectedCompany = useSelectedCompany();

  /** Currently selected company */
  const selectedCompanyParsed =
    selectedCompanySchema.safeParse(selectedCompany);

  if (!selectedCompanyParsed.success) {
    forceSignOut();
    return null;
  }

  return selectedCompanyParsed.data.selectedCompany;
}

const selectedCompanySchema = z.object({
  selectedCompany: z.object({
    /** Company id */
    id: z.string(),
    /** Company registration number */
    registrationNumber: z.string(),
  }),
});

function forceSignOut() {
  dispatchToast({
    content: 'Sessão expirada',
    type: 'error',
    description: 'Por favor, realize o login novamente.',
  });
  void Auth.signOut();
}
