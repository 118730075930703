import React from 'react';

export default function (props: {
  factory: () => Promise<{ default: React.ComponentType<unknown> }>;
}) {
  const LazyComponent = React.lazy(props.factory);

  return (
    <React.Suspense fallback={<div />}>
      <LazyComponent />
    </React.Suspense>
  );
}
