import React from 'react';
import { UseFormReturn } from '../forms/useForm';
import ModalService from '@frontend/services/ModalService';
import CancelFlowModal from '../modals/CancelFlowModal';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

type Input = {
  form: UseFormReturn;
  onClose: () => void;
};

export default function useController(input: Input) {
  const [step, setStep] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const deposits = input.form.watch('deposits');
  // CreditDate and ExpirationDate have manual validations
  const formValid =
    input.form.formState.isValid &&
    !input.form.formState.errors.creditDate &&
    !input.form.formState.errors.expirationDate;

  /* ----------------------------------- Confirm button setup ----------------------------------- */
  let confirmEnabled: boolean;
  switch (step) {
    case 1:
      confirmEnabled = deposits?.length > 0;
      break;
    case 2:
      confirmEnabled = formValid;
      break;
    default:
      confirmEnabled = true;
  }

  async function onConfirm() {
    if (step >= 4) {
      input.onClose();
      return;
    }
    if (step === 3) {
      setLoading(true);
      try {
        await input.form.onSubmit();
        setStep(step + 1);
      } catch (error) {
        dispatchToast({
          type: 'error',
          content: 'Falha ao criar depósitos',
          description:
            error.message ??
            'Tente novamente. Se o problema persistir, contate o suporte.',
        });
      } finally {
        setLoading(false);
        return;
      }
    }
    setStep(step + 1);
  }

  const onConfirmLabel =
    step < 3 ? 'Continuar' : step === 3 ? 'Finalizar solicitação' : 'Concluir';

  /* ------------------------------------ Cancel button setup ----------------------------------- */
  function onCancel() {
    if (step === 1) {
      input.onClose();
      return;
    }
    ModalService.show(CancelFlowModal, {
      onConfirm: input.onClose,
    });
  }
  return {
    step,
    loading,
    backButton: {
      visible: step === 3,
      onClick: () => setStep(2),
    },
    confirmButton: {
      label: onConfirmLabel,
      enabled: confirmEnabled,
      onClick: onConfirm,
    },
    cancelButton: {
      visible: step < 4,
      onClick: onCancel,
    },
  };
}
