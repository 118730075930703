import { segmentEventTracking } from '@flash-tecnologia/hros-web-utility';

type TrackingEvent = {
  track: string;
  module: 'corporate-card';
  businessUnit: 'flash-expense';
  params?: Record<string, string>;
};

type Employee = {
  employeeId: string;
  companyId: string;
};

export class TrackingService {
  private static event(
    track: string,
    data: TrackingEvent['params'],
    employee: Employee,
  ) {
    const message = {
      name: track,
      module: 'corporate-card',
      businessUnit: 'expense',
      params: {
        ...data,
        'employee-id': employee.employeeId,
        'company-id': employee.companyId,
      },
    };

    segmentEventTracking(message);
  }

  static track(name: string, data: Record<string, string>, employee: Employee) {
    this.event(name, data, employee);
  }
}
