import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import ModalService from '@frontend/services/ModalService';

type Props = {
  totalItems: number;
};

function RefundUserBalanceSuccessModal(props: Props) {
  const modalController = ModalService.useModalController();

  const modalData =
    props.totalItems > 1
      ? {
          title: 'Estamos retirando o Saldo...',
          body: 'Te notificaremos quando finalizarmos essa ação.',
        }
      : {
          title: 'Saldo retornado com sucesso!',
          body: 'O saldo da(s) Pessoa(s) foi retirado e num instante estará disponível no Flash Cash Corporativo.',
        };

  return (
    <ConfirmationModal
      buttons={{
        confirm: {
          text: 'Concluir',
          onClick: () => modalController.remove(),
        },
      }}
      isOpen={modalController.visible}
      onClose={modalController.remove}
      variant="default"
      text={modalData}
    />
  );
}

export default ModalService.create(RefundUserBalanceSuccessModal);
