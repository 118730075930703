import FormService from '@frontend/services/FormService';
import { z } from 'zod';
import useCreateDeposits from '../data/useCreateDeposits';
import ErrorMonitorService from '@frontend/services/MonitorService';
import { DateTime } from 'luxon-business-days';
import errors from '@frontend/utils/commonTexts/errors';

export const FormSchema = z.object({
  /** List of deposits imported from the spreadsheet */
  deposits: z.array(
    z.object({
      id: z.string(),
      documentNumber: z.string(),
      name: z.string(),
      value: z.number(),
    }),
  ),
  /** Payment method chosen by the admin */
  paymentMethod: z.enum(['FLASH_CASH', 'BILLET', 'PIX']),
  /** Whether the deposit should happen only once (SINGLE) or repeat every month (AUTOMATIC) */
  depositType: z.enum(['SINGLE', 'AUTOMATIC'], {
    required_error: errors.forms.generic.required,
  }),
  /** Date of the (first) employee credit */
  creditDate: z
    .date({
      invalid_type_error: errors.forms.date.invalid,
      required_error: errors.forms.generic.required,
    })
    .min(DateTime.now().startOf('day').toJSDate(), {
      message: errors.forms.date.future,
    }),
  /** Whether the credited balance should expire at the `expirationDate`. Only valid for single deposits */
  expires: z.boolean().catch(false),
  /** Expiration date of the credited balance. Only valid for single deposits, when `expires: true` */
  expirationDate: z
    .date({
      invalid_type_error: errors.forms.date.invalid,
    })
    .min(DateTime.now().startOf('day').toJSDate(), {
      message: errors.forms.date.future,
    })
    .optional(),
  /** Whether the deposit should only complement the employee balance to a target amount. Only valid for automatic deposits */
  complementary: z.boolean().catch(false),
});

export type FormOutput = z.output<typeof FormSchema>;

export default function useForm() {
  const form = FormService.useCreateForm(FormSchema, {
    mode: 'onChange',
  });
  const createDeposits = useCreateDeposits();

  const onSubmit = form.handleSubmit(
    async (values) => {
      await createDeposits.mutateAsync(values);
    },
    (errors) => {
      ErrorMonitorService.error({
        message: 'Error while creating many deposits from spreadsheet',
        severity: 'fatal',
        extras: {
          zodErrors: JSON.stringify(errors),
        },
      });
      throw new Error('Form parsing error');
    },
  );

  return {
    ...form,
    onSubmit,
    result: createDeposits.data,
  };
}

export type UseFormReturn = ReturnType<typeof useForm>;
