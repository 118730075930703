import TableClient from '@frontend/components/TableClient';

export function FlashBalanceTable() {
  return (
    <TableClient
      columns={[
        {
          accessorKey: 'date',
          header: 'Data',
          enableSorting: false,
          meta: {
            tooltip: 'Data de fechamento referente ao saldo apresentado',
          },
        },
        {
          accessorKey: 'flashCash',
          header: 'Flash Cash',
          enableSorting: false,
          meta: {
            tooltip: 'Valor disponível em Flash Cash na data referenciada',
          },
        },
        {
          accessorKey: 'cards',
          header: 'Cartões Flash',
          enableSorting: false,
          meta: {
            tooltip:
              'Valor total disponível nos cartões Flash na data referenciada',
          },
        },
        {
          accessorKey: 'totalBalance',
          header: 'Total Dia',
          enableSorting: false,
          meta: {
            tooltip:
              'Valor total de saldo disponível na data referenciada, considerando soma de Flash Cash e Cartões Flash',
          },
        },
      ]}
      data={[
        {
          date: '01/02/2024',
          flashCash: 'R$ 28.500,00',
          cards: 'R$ 36.754,00',
          totalBalance: 'R$ 65.254,00',
        },
        {
          date: '02/02/2024',
          flashCash: 'R$ 23.000,00',
          cards: 'R$ 48.000,00',
          totalBalance: 'R$ 71.000,00',
        },
        {
          date: '03/02/2024',
          flashCash: 'R$ 18.000,00',
          cards: 'R$ 34.347,00',
          totalBalance: 'R$ 52.347,00',
        },
      ]}
      emptyState={{ emptyText: '', filteredEmptyText: '' }}
      loading={false}
    />
  );
}
