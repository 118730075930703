const status = {
  AVAILABLE: {
    label: 'Disponibilizado',
    variant: 'success',
  },
  WAITING_FOR_AVAILABILITY: {
    label: 'Aguardando disponibilização',
    variant: 'info',
  },
  CANCELLED: {
    label: 'Cancelado',
    variant: 'primary',
  },
  WAITING_FOR_PAYMENT: {
    label: 'Aguardando pagamento',
    variant: 'error',
  },
} as const;

export default {
  status,
};
