import { Button } from '@flash-tecnologia/hros-web-ui-v2';
import RouterService from '@frontend/services/RouterService';
import { ROUTE_ErrorMonitor } from './ErrorMonitor';

export default function Playground() {
  const navigate = RouterService.useNavigate();
  return (
    <>
      <Button
        size="medium"
        variant="primary"
        onClick={() =>
          navigate({
            route: ROUTE_ErrorMonitor,
            params: {},
            searchParams: {},
            state: {},
          })
        }
      >
        Error Monitor
      </Button>
    </>
  );
}
