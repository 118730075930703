import Icon from '@frontend/components/Icon';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import Callout from '@frontend/components/frames/Callout';
// import FeatureFlagService from '@frontend/services/FeatureFlagService';

export function ReportNewsBanner() {
  // const reportsEnabled = FeatureFlagService.getFlag('reports');
  // if (!reportsEnabled) return null;
  return null;

  return (
    <>
      <Callout
        alignItems="center"
        backgroundColor="secondary"
        variant="secondary"
        leftAdornment={
          <Icon
            background="default"
            name="IconSparkles"
            color="primary"
            size={40}
          />
        }
        title={
          <Typography.Body3 weight={600} color="secondary_40">
            Novidade!
          </Typography.Body3>
        }
        description={
          <>
            <Typography.Body4 color="neutral_40" as="span">
              <Typography.Body4 weight={700} color={'neutral_30'} as="span">
                Reports:
              </Typography.Body4>{' '}
              Agora você possui novos reports em{' '}
              <Typography.Body4 weight={700} color={'neutral_30'} as="span">
                Cartão corporativo Flash
              </Typography.Body4>
              , com dados mais completos para as rotinas do seu dia-a-dia.{' '}
            </Typography.Body4>
            <Typography.Body4
              weight={700}
              color={'primary'}
              as="button"
              onClick={() => null}
            >
              Saiba mais
            </Typography.Body4>
          </>
        }
      />
      <Spacer y="m" />
    </>
  );
}
