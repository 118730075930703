import { trpc } from '@frontend/trpc';

type Props = {
  policyId: string;
};

export function useGetPolicy(props: Props) {
  const getPolicy = trpc.company.policies.get.useQuery({
    policyId: props.policyId,
  });

  return {
    data: getPolicy.data,
  };
}
