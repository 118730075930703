import ModalService from '@frontend/services/ModalService';
import { trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import { getDateFromBRL } from '@frontend/utils/masks/formatDate';
import { TEMPLATE_COLUMNS, TEMPLATE_FIELDS } from '../constants';
import UploadTransactionsFeedback from '../feedback/UploadTransactionsFeedback';

export const useUploadTransaction = () => {
  const { isLoading, mutate } =
    trpc.company.externalCards.importTransactions.useMutation({
      onError: (error) => {
        dispatchToast({
          type: 'error',
          content: error.message,
        });
      },
    });

  const upload = (file: string[][], onUpload: () => void) => {
    const map = {
      CardNumber: TEMPLATE_COLUMNS.CARD_NUMBER,
      CardHolderName: TEMPLATE_COLUMNS.CARD_HOLDER,
      Brand: TEMPLATE_COLUMNS.CARD_FLAG,
      Date: TEMPLATE_COLUMNS.TRANSACTION_DATE,
      BillingDate: TEMPLATE_COLUMNS.BILLING_DATE,
      OriginalCurrencyCode: TEMPLATE_COLUMNS.TRANSACTION_CURRENCY,
      OriginalAmount: TEMPLATE_COLUMNS.TRANSACTION_VALUE,
      CurrencyCode: TEMPLATE_COLUMNS.CONVERTED_TRANSACTION_CURRENCY,
      Amount: TEMPLATE_COLUMNS.CONVERTED_TRANSACTION_VALUE,
      TransactionType: TEMPLATE_COLUMNS.TRANSACTION_TYPE,
      EstablishmentMCC: TEMPLATE_COLUMNS.MCC,
      EstablishmentName: TEMPLATE_COLUMNS.ESTABLISHMENT_NAME,
    };

    const source = file.map((register) => ({
      [TEMPLATE_COLUMNS.CARD_NUMBER]: parseInt(
        register[TEMPLATE_FIELDS.CARD_NUMBER],
      ),
      [TEMPLATE_COLUMNS.CARD_HOLDER]: register[TEMPLATE_FIELDS.CARD_HOLDER],
      [TEMPLATE_COLUMNS.CARD_FLAG]: register[TEMPLATE_FIELDS.CARD_FLAG],
      [TEMPLATE_COLUMNS.BILLING_DATE]: getDateFromBRL(
        register[TEMPLATE_FIELDS.BILLING_DATE],
      ).toJSDate(),
      [TEMPLATE_COLUMNS.TRANSACTION_DATE]: getDateFromBRL(
        register[TEMPLATE_FIELDS.TRANSACTION_DATE],
      ).toJSDate(),
      [TEMPLATE_COLUMNS.TRANSACTION_CURRENCY]:
        register[TEMPLATE_FIELDS.TRANSACTION_CURRENCY],
      [TEMPLATE_COLUMNS.TRANSACTION_VALUE]: parseFloat(
        register[TEMPLATE_FIELDS.TRANSACTION_VALUE],
      ),
      [TEMPLATE_COLUMNS.CONVERTED_TRANSACTION_CURRENCY]:
        register[TEMPLATE_FIELDS.CONVERTED_TRANSACTION_CURRENCY],
      [TEMPLATE_COLUMNS.CONVERTED_TRANSACTION_VALUE]: register[
        TEMPLATE_FIELDS.CONVERTED_TRANSACTION_VALUE
      ]
        ? parseFloat(register[TEMPLATE_FIELDS.CONVERTED_TRANSACTION_VALUE])
        : null,
      [TEMPLATE_COLUMNS.TRANSACTION_TYPE]:
        register[TEMPLATE_FIELDS.TRANSACTION_TYPE],
      [TEMPLATE_COLUMNS.MCC]: String(register[TEMPLATE_FIELDS.MCC]),
      [TEMPLATE_COLUMNS.ESTABLISHMENT_NAME]:
        register[TEMPLATE_FIELDS.ESTABLISHMENT_NAME],
    }));

    mutate(
      { map, source },
      {
        onSuccess: () => {
          ModalService.show(UploadTransactionsFeedback, {});
          onUpload();
        },
      },
    );
  };

  return {
    upload,
    isLoading,
  };
};
