import { Icons, ShapeIcon } from '@flash-tecnologia/hros-web-ui-v2';
import useColors, { Color } from '@frontend/utils/theme/colors';

type IconsPropsType = React.ComponentProps<typeof Icons>;
type ShapeIconPropsType = React.ComponentProps<typeof ShapeIcon>;

type Props = {
  name: IconsPropsType['name'];
  color: Color;
  size?: IconsPropsType['size'];
  background?: ShapeIconPropsType['variant'];
  onClick?(): void;
};

export default function Icon(props: Props) {
  const colors = useColors();

  if (props.background) {
    return (
      <ShapeIcon
        name={props.name}
        color={colors[props.color]}
        size={props.size}
        variant={props.background}
        style={{ flexShrink: 0 }}
        onClick={props.onClick}
      />
    );
  }

  return (
    <Icons
      name={props.name}
      fill="transparent"
      color={colors[props.color]}
      size={props.size}
      style={{ flexShrink: 0 }}
      onClick={props.onClick}
    />
  );
}
