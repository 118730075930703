import TagFilterCheckbox from './TagFilterCheckbox';
import TagFilterDateRange from './TagFilterDateRange';
import TagFilterDrawer from './TagFilterDrawer';
import TagFilterRange from './TagFilterRange';

export default {
  Checkbox: TagFilterCheckbox,
  DateRange: TagFilterDateRange,
  Drawer: TagFilterDrawer,
  Range: TagFilterRange,
};
