import styled from 'styled-components';

export const FieldInputsArea = styled.div`
  display: grid;
  gap: ${(p) => p.theme.spacings.xs};
  grid-template-columns: 1fr 1fr;
`;

export const Row = styled.div`
  display: flex;
  gap: ${(p) => p.theme.spacings.xs4};
  align-items: center;
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RadioHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
