import Icon from '@frontend/components/Icon';
import Callout from '@frontend/components/frames/Callout';

type Props = Omit<
  React.ComponentProps<typeof Callout>,
  'leftAdornment' | 'variant'
> & {
  variant: 'success' | 'error' | 'info';
};

export default function NotificationCallout(props: Props) {
  return (
    <Callout
      {...props}
      leftAdornment={<Icon {...typeMap[props.variant]} size={48} />}
    />
  );
}

const typeMap = {
  error: {
    name: 'IconAlertTriangle',
    color: 'error_40',
    background: 'error',
  },
  success: {
    name: 'IconCheck',
    color: 'success_40',
    background: 'success',
  },
  info: {
    name: 'IconInfoCircle',
    color: 'info_40',
    background: 'info',
  },
} satisfies Record<
  Props['variant'],
  Pick<React.ComponentProps<typeof Icon>, 'name' | 'color' | 'background'>
>;
