import {
  Button,
  LinkButton,
  PillButton,
  TextField,
} from '@flash-tecnologia/hros-web-ui-v2';
import Icon from '@frontend/components/Icon';
import React, { useState } from 'react';
import Spacer from '../../Spacer';
import Typography from '../../Typography';
import * as SC from './styles';

type Props = {
  variant: 'default' | 'error' | 'info';
  isOpen: boolean;
  onClose: () => void;
  loading?: boolean;
  text: {
    highlightedText?: React.ReactNode;
    title: React.ReactNode;
    body: React.ReactNode;
  };
  buttons?: {
    confirm: {
      disabled?: boolean;
      text: string;
      onClick: () => void;
    };
    cancel?: {
      text: string;
      onClick: () => void;
      variant?: 'default' | 'neutral';
    };
    actionButton?: {
      text: string;
      onClick: () => void;
    };
  };
  confirmationWord?: string;
};

export default function ConfirmationModal(props: Props) {
  const [confirmationChecked, setConfirmationChecked] = useState(false);
  const [confirmationWordError, setConfirmationWordError] = useState(false);

  function checkConfirmationWord(event: React.ChangeEvent<HTMLInputElement>) {
    const word = event.target.value;

    if (
      word &&
      word.length == props.confirmationWord?.length &&
      props.confirmationWord?.toUpperCase() === word.toUpperCase()
    ) {
      setConfirmationChecked(true);
    } else if (confirmationChecked) {
      setConfirmationChecked(false);
    }
  }

  return (
    <SC.Modal
      open={props.isOpen}
      onClose={props.onClose}
      header={
        <SC.CloseButtonWrapper>
          <PillButton
            size="medium"
            variant="default"
            icon="IconX"
            onClick={props.onClose}
          />
        </SC.CloseButtonWrapper>
      }
      children={
        <SC.Body>
          <Icon {...iconMap[props.variant]} size={80} />
          <Spacer y="xs2" />
          <Typography.Body3
            color={highlightedTextColorMap[props.variant]}
            weight={700}
            center
          >
            {props.text.highlightedText}
          </Typography.Body3>
          <Spacer y="xs2" />
          <Typography.Headline6 center>{props.text.title}</Typography.Headline6>
          <Spacer y="xs2" />
          <Typography.Body4 color="neutral_50" center>
            {props.text.body}
          </Typography.Body4>
          {props.confirmationWord && (
            <SC.ConfirmationWordWrapper>
              <Spacer y="xs2" />
              <Typography.Body4 color="neutral_30" weight={700} center>
                Digite {props.confirmationWord} e confirme
              </Typography.Body4>
              <TextField
                style={{ width: '100%' }}
                label={'Digite ' + props.confirmationWord.toUpperCase()}
                onChange={checkConfirmationWord}
                error={confirmationWordError}
                helperText={
                  confirmationWordError &&
                  'Palavra digitada incorreta. Verifique e tente novamente.'
                }
              />
            </SC.ConfirmationWordWrapper>
          )}
          {props.buttons?.actionButton && (
            <>
              <Spacer y="s" />
              <LinkButton
                variant="default"
                onClick={props.buttons.actionButton.onClick}
                style={{ alignSelf: 'center' }}
                disabled={props.loading}
              >
                {props.buttons.actionButton.text}
              </LinkButton>
            </>
          )}
        </SC.Body>
      }
      footer={
        props.buttons ? (
          <SC.Footer
            buttonQuantity={
              props.buttons.confirm && props.buttons.cancel ? 2 : 1
            }
          >
            {!!props.buttons.cancel && (
              <LinkButton
                variant={props.buttons.cancel.variant ?? 'neutral'}
                onClick={props.buttons.cancel.onClick}
                style={{ alignSelf: 'center' }}
                disabled={props.loading}
              >
                {props.buttons.cancel.text}
              </LinkButton>
            )}
            <Button
              size="large"
              onClick={() => {
                if (!props.confirmationWord) {
                  setConfirmationWordError(false);
                  return props.buttons && props.buttons.confirm.onClick();
                }

                if (confirmationChecked) {
                  return props.buttons && props.buttons.confirm.onClick();
                }

                return setConfirmationWordError(true);
              }}
              buttonType="primary"
              disabled={!!props.buttons.confirm.disabled}
              variantType={props.variant}
              loading={props.loading}
            >
              {props.buttons.confirm.text}
            </Button>
          </SC.Footer>
        ) : undefined
      }
    />
  );
}

const iconMap = {
  default: {
    name: 'IconCheck',
    color: 'primary',
    background: 'default',
  },
  error: {
    name: 'IconAlertCircle',
    color: 'error_40',
    background: 'error',
  },
  info: {
    name: 'IconCheck',
    color: 'info_40',
    background: 'info',
  },
} as const satisfies Record<
  Props['variant'],
  Pick<React.ComponentProps<typeof Icon>, 'name' | 'color' | 'background'>
>;

const highlightedTextColorMap = {
  default: 'primary',
  error: 'error_40',
  info: 'info_40',
} as const satisfies Record<
  Props['variant'],
  React.ComponentProps<typeof Typography.Body3>['color']
>;
