import BorderedSection from '@frontend/components/frames/BorderedSection';
import styled from 'styled-components';

export const TopBorderedSection = styled(BorderedSection)<{ open: boolean }>`
  ${({ open }) =>
    !open
      ? ''
      : `
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-width: 0.5px;
    `}
`;

export const BottomBorderedSection = styled(BorderedSection)`
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top-width: 0.5px;
`;
