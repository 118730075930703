import styled from 'styled-components';
import { Modal as UIModal } from '@flash-tecnologia/hros-web-ui-v2';

export const Modal = styled(UIModal.Root)`
  .modal-container {
    width: 870px;
  }
  .modal-content-area {
    padding: 0 !important;
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral[90]};
`;

export const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px 24px 0px;
  position: absolute;
  width: 100%;
`;

interface TitleWrapper {
  noWrap?: boolean;
}

export const TitleWrapper = styled.div<TitleWrapper>`
  padding: 44px 224px 40px 112px;
  white-space: ${(props) => (!!props.noWrap ? 'nowrap' : 'unset')};
`;

export const Body = styled.div`
  padding: 40px 112px;
`;

export const Footer = styled.div`
  border-top: 1px solid ${(p) => p.theme.colors.neutral[90]};
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 24px 112px;
  align-items: center;
  justify-content: space-between;
`;
