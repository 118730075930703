import errors from '@frontend/utils/commonTexts/errors';
import { z } from 'zod';

export const FormSchema = z.object({
  format: z.enum(['CSV', 'XLSX'], {
    required_error: errors.forms.generic.required,
  }),
  template: z.literal('expense-balance-flash'),
  /** Will consider from `from` at 00:00 to `to` at 23:59 */
  dateInterval: z.object(
    {
      from: z.date(),
      to: z.date(),
    },
    {
      required_error: errors.forms.generic.required,
      invalid_type_error: errors.forms.generic.required,
    },
  ),
});

export type FormSchemaOutput = z.output<typeof FormSchema>;
