import TableClient from '@frontend/components/TableClient';

export function CorporateCardStatementTable() {
  return (
    <TableClient
      columns={[
        {
          header: 'Data',
          accessorKey: 'date',
          enableSorting: false,
          meta: { tooltip: 'Data da movimentação' },
        },
        {
          header: 'Hora',
          accessorKey: 'time',
          enableSorting: false,
          meta: { tooltip: 'Hora da movimentação' },
        },
        {
          header: 'CNPJ Empresa',
          accessorKey: 'cnpj',
          enableSorting: false,
          meta: { tooltip: 'Cnpj da empresa consultada' },
        },
        {
          header: 'Tipo de Lançamento',
          accessorKey: 'type',
          enableSorting: false,
          meta: { tooltip: 'Se débito ou crédito no saldo de Flash Cash' },
        },
        {
          header: 'Movimentação',
          accessorKey: 'description',
          enableSorting: false,
          meta: { tooltip: 'O tipo de movimentação realizado na conta' },
        },
        {
          header: 'Meio de Pagamento',
          accessorKey: 'paymentMethod',
          enableSorting: false,
          meta: {
            tooltip:
              'Meio de pagamento utilizado, podendo ser Flash Cash, Pix ou Boleto',
          },
        },
        {
          header: 'CPF',
          accessorKey: 'documentNumber',
          enableSorting: false,
          meta: { tooltip: 'CPF da pessoa de posse do cartão' },
        },
        {
          header: 'Nome',
          accessorKey: 'name',
          enableSorting: false,
          meta: { tooltip: 'Nome da pessoa de posse do cartão' },
        },
        {
          header: 'Nº Cartão',
          accessorKey: 'cardNumber',
          enableSorting: false,
          meta: { tooltip: 'Número final do cartão informado' },
        },
        {
          header: 'Tipo Cartão',
          accessorKey: 'cardType',
          enableSorting: false,
          meta: { tooltip: 'Tipo de cartão, podendo ser físico ou virtual' },
        },
        {
          header: 'Status Transação',
          accessorKey: 'status',
          enableSorting: false,
          meta: {
            tooltip:
              'Status atual da transação de cartão, podendo estar CONFIRMADA ou CANCELADA',
          },
        },
        {
          header: 'Moeda Lançamento',
          accessorKey: 'originalCurrency',
          enableSorting: false,
          meta: { tooltip: 'Moeda original de lançamento da transação' },
        },
        {
          header: 'Valor Lançamento',
          accessorKey: 'originalAmount',
          enableSorting: false,
          meta: { tooltip: 'Valor original de lançamento da transação' },
        },
        {
          header: 'Moeda Conversão',
          accessorKey: 'convertedCurrency',
          enableSorting: false,
          meta: { tooltip: 'Moeda de conversão da transação (Real)' },
        },
        {
          header: 'Valor Real',
          accessorKey: 'convertedAmount',
          enableSorting: false,
          meta: { tooltip: 'Valor de conversão da transação (Real)' },
        },
        {
          header: 'Estabelecimento',
          accessorKey: 'establishment',
          enableSorting: false,
          meta: {
            tooltip:
              'Estabelecimento onde foi realizada a transação, em caso de despesas',
          },
        },
        {
          header: 'Categoria Estabelecimento',
          accessorKey: 'establishmentCategory',
          enableSorting: false,
          meta: {
            tooltip:
              'Categoria de identificação do estabelecimento, em caso de despesas',
          },
        },
        {
          header: 'Status Prestação de Contas',
          accessorKey: 'accountabilityStatus',
          enableSorting: false,
          meta: { tooltip: 'Status da transação na prestação de contas' },
        },
        {
          header: 'Nome Relatório Prestação de Contas',
          accessorKey: 'accountabilityReportName',
          enableSorting: false,
          meta: { tooltip: 'Nome do relatório de prestação de contas' },
        },
        {
          header: 'Código Relatório Prestação de Contas',
          accessorKey: 'accountabilityReportCode',
          enableSorting: false,
          meta: { tooltip: 'Código do relatório de prestação de contas' },
        },
        {
          header: 'Centro de Custos',
          accessorKey: 'costCenter',
          enableSorting: false,
          meta: { tooltip: 'Centro de custos informado' },
        },
        {
          header: 'Identificador',
          accessorKey: 'identifier',
          enableSorting: false,
          meta: { tooltip: 'Identificador único da operação' },
        },
      ]}
      data={[
        {
          date: '01/02/2023',
          time: '10:00',
          cnpj: '00.000.000/000-01',
          type: 'CREDITO',
          description: 'DEPOSITO COLABORADOR',
          paymentMethod: 'FLASH CASH',
          documentNumber: '057.***.***-31',
          name: 'FLAVIA MINGO',
          cardNumber: 'Não aplicável',
          cardType: 'Não aplicável',
          originalCurrency: 'BRL',
          originalAmount: 'R$ 12,00',
          convertedCurrency: 'BRL',
          convertedAmount: ' R$ 12,00 ',
          establishment: 'Não aplicável',
          establishmentCategory: 'Não aplicável',
          accountabilityStatus: 'Não aplicável',
          accountabilityReportName: 'Não aplicável',
          accountabilityReportCode: '',
          costCenter: '',
          identifier: 'JK1234',
          status: 'CONFIRMADA',
        },
        {
          date: '01/02/2023',
          time: '11:23',
          cnpj: '00.000.000/000-01',
          type: 'CREDITO',
          description: 'DEPOSITO COLABORADOR',
          paymentMethod: 'PIX',
          documentNumber: '057.***.***-31',
          name: 'FLAVIA MINGO',
          cardNumber: 'Não aplicável',
          cardType: 'Não aplicável',
          originalCurrency: 'BRL',
          originalAmount: 'R$ 15,00',
          convertedCurrency: 'BRL',
          convertedAmount: ' R$ 15,00 ',
          establishment: 'Não aplicável',
          establishmentCategory: 'Não aplicável',
          accountabilityStatus: 'Não aplicável',
          accountabilityReportName: 'Não aplicável',
          accountabilityReportCode: '',
          costCenter: '',
          identifier: 'AC5678',
          status: 'CONFIRMADA',
        },
        {
          date: '01/02/2023',
          time: '16:25',
          cnpj: '00.000.000/000-01',
          type: 'DEBITO',
          description: 'DESPESA',
          paymentMethod: 'CARTAO FLASH',
          documentNumber: '057.***.***-31',
          name: 'FLAVIA MINGO',
          cardNumber: '************9614',
          cardType: 'VIRTUAL',
          originalCurrency: 'US',
          originalAmount: 'US -10,00',
          convertedCurrency: 'BRL',
          convertedAmount: ' R$ (10,00)',
          establishment: 'POSTO VERDE',
          establishmentCategory: 'ALIMENTACAO',
          accountabilityStatus: 'PENDENTE',
          accountabilityReportName: 'VIAGEM SP',
          accountabilityReportCode: '',
          costCenter: '',
          identifier: 'AC5678',
          status: 'CANCELADA',
        },
      ]}
      emptyState={{ emptyText: '', filteredEmptyText: '' }}
      loading={false}
    />
  );
}
