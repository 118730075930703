import ModalService from '@frontend/services/ModalService';
import { FocusedFlow } from '@frontend/components/FocusedFlow';
import { useAddBalanceController } from './data/useAddBalanceController';

type Props = {
  amount?: number;
};

export const AddBalance = (props: Props) => {
  const { headerMetadata, content, footer } = useAddBalanceController(
    props.amount,
  );

  return (
    <FocusedFlow
      content={content}
      headerMetadata={headerMetadata}
      footer={footer}
    />
  );
};

export default ModalService.create(AddBalance);
