import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import ModalService from '@frontend/services/ModalService';
import { useReproveRequest } from '../data/useReproveRequest';

type Props = {
  orderId: string;
};

function ReproveRequestModal(props: Props) {
  const modalController = ModalService.useModalController();

  const { mutate, loading } = useReproveRequest();

  return (
    <ConfirmationModal
      loading={loading}
      buttons={{
        confirm: {
          text: 'Reprovar pedido',
          onClick: () => {
            modalController.remove();
            mutate({ id: props.orderId });
          },
        },
        cancel: {
          text: 'Decidir mais tarde',
          onClick: () => {
            modalController.remove();
          },
        },
      }}
      variant="error"
      text={{
        title: 'Tem certeza que deseja reprovar o pedido de saldo?',
        highlightedText: 'Atenção',
        body: (
          <>
            O pedido será cancelado e o colaborador receberá uma notificação
            sobre a reprovação
          </>
        ),
      }}
      isOpen={modalController.visible}
      onClose={modalController.remove}
    />
  );
}

export default ModalService.create(ReproveRequestModal);
