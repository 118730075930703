import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 44px 112px 40px;
  position: relative;
  width: 870px;
`;

export const Body = styled.div`
  display: grid;
  padding: 40px 0px 24px;
  grid-template-areas: 'left main right';
  grid-template-columns: 112px 1fr 112px;
  justify-items: center;
  width: 870px;
`;
