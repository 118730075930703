// ref: https://www.figma.com/file/h6L9nxp9GcD5bTYErb11bg/Flamingo-UI-%7C-Global-Tokens?node-id=1994%3A1610&t=BVo6AMZMrmmVVKnU-0

import styled from 'styled-components';

export type Color =
  | 'inherit'
  | 'error_10'
  | 'error_40'
  | 'error_50'
  | 'error_70'
  | 'error_90'
  | 'info_10'
  | 'info_40'
  | 'info_50'
  | 'info_70'
  | 'info_90'
  | 'neutral_0'
  | 'neutral_100'
  | 'neutral_10'
  | 'neutral_20'
  | 'neutral_30'
  | 'neutral_40'
  | 'neutral_50'
  | 'neutral_60'
  | 'neutral_70'
  | 'neutral_80'
  | 'neutral_90'
  | 'neutral_95'
  | 'primary'
  | 'secondary_10'
  | 'secondary_20'
  | 'secondary_30'
  | 'secondary_40'
  | 'secondary_50'
  | 'secondary_70'
  | 'secondary_80'
  | 'secondary_90'
  | 'secondary_95'
  | 'secondary_99'
  | 'success_10'
  | 'success_40'
  | 'success_50'
  | 'success_70'
  | 'success_90';

type GloballySharedType = {
  /** Font color. Defaults to #000000 */
  color?: Color;
  /** Text alignment */
  center?: boolean;
  /** Prevents text from breaking line */
  noWrap?: boolean;
};

const GloballyShared = styled.p<GloballySharedType>`
  color: ${(p) => {
    if (!p.color) return '#000000';
    if (p.color === 'inherit' || p.color.startsWith('#')) return p.color;
    const colorMap = {
      // Error
      error_10: p.theme.colors.feedback.error[10],
      error_40: p.theme.colors.feedback.error[40],
      error_50: p.theme.colors.feedback.error[50],
      error_70: p.theme.colors.feedback.error[70],
      error_90: p.theme.colors.feedback.error[90],
      // Info
      info_10: p.theme.colors.feedback.info[10],
      info_40: p.theme.colors.feedback.info[40],
      info_50: p.theme.colors.feedback.info[50],
      info_70: p.theme.colors.feedback.info[70],
      info_90: p.theme.colors.feedback.info[90],
      // Neutral
      neutral_0: p.theme.colors.neutral[0],
      neutral_100: p.theme.colors.neutral[100],
      neutral_10: p.theme.colors.neutral[10],
      neutral_20: p.theme.colors.neutral[20],
      neutral_30: p.theme.colors.neutral[30],
      neutral_40: p.theme.colors.neutral[40],
      neutral_50: p.theme.colors.neutral[50],
      neutral_60: p.theme.colors.neutral[60],
      neutral_70: p.theme.colors.neutral[70],
      neutral_80: p.theme.colors.neutral[80],
      neutral_90: p.theme.colors.neutral[90],
      neutral_95: p.theme.colors.neutral[95],
      // Primary
      primary: p.theme.colors.primary,
      // Secondary
      secondary_10: p.theme.colors.secondary[10],
      secondary_20: p.theme.colors.secondary[20],
      secondary_30: p.theme.colors.secondary[30],
      secondary_40: p.theme.colors.secondary[40],
      secondary_50: p.theme.colors.secondary[50],
      secondary_70: p.theme.colors.secondary[70],
      secondary_80: p.theme.colors.secondary[80],
      secondary_90: p.theme.colors.secondary[90],
      secondary_95: p.theme.colors.secondary[95],
      secondary_99: p.theme.colors.secondary[99],
      success_10: p.theme.colors.feedback.success[10],
      success_40: p.theme.colors.feedback.success[40],
      success_50: p.theme.colors.feedback.success[50],
      success_70: p.theme.colors.feedback.success[70],
      success_90: p.theme.colors.feedback.success[90],
    } as const;
    return colorMap[p.color];
  }};
  text-align: ${(p) => (p.center ? 'center' : 'left')};
  white-space: ${(p) => (p.noWrap ? 'nowrap' : 'inherit')};
`;

const TitleBase = styled(GloballyShared)`
  font-family: ${(p) => p.theme.fonts.primary};
`;

type BodyType = GloballySharedType & {
  /** Font weight. Defaults to 400 */
  weight?: 400 | 600 | 700;
};

const BodyBase = styled(GloballyShared)<BodyType>`
  font-family: ${(p) => p.theme.fonts.secondary};
  font-weight: ${(p) => p.weight ?? 400};
`;

/* ------------------------------------- Typography variants ------------------------------------ */

const Display1 = styled(TitleBase)`
  font-size: 96px;
  font-weight: 800;
  line-height: 112px;
`;

const Display2 = styled(TitleBase)`
  font-size: 88px;
  font-weight: 800;
  line-height: 98px;
`;

const Headline1 = styled(TitleBase)`
  font-size: 72px;
  font-weight: 700;
  line-height: 80px;
`;

const Headline2 = styled(TitleBase)`
  font-size: 64px;
  font-weight: 700;
  line-height: 72px;
`;

const Headline3 = styled(TitleBase)`
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
`;

const Headline4 = styled(TitleBase)`
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
`;

const Headline5 = styled(TitleBase)`
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
`;

const Headline6 = styled(TitleBase)`
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
`;

const Headline7 = styled(TitleBase)`
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
`;

const Headline8 = styled(TitleBase)`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
`;

const Headline9 = styled(TitleBase)`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

const Headline10 = styled(TitleBase)`
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
`;

const Body1 = styled(BodyBase)`
  font-size: 24px;
  line-height: 32px;
`;

const Body2 = styled(BodyBase)`
  font-size: 20px;
  line-height: 28px;
`;

const Body3 = styled(BodyBase)`
  font-size: 16px;
  line-height: 22px;
`;

const Body4 = styled(BodyBase)`
  font-size: 14px;
  line-height: 20px;
`;

const Caption = styled(BodyBase)`
  font-size: 12px;
  line-height: 18px;
`;

const Overline = styled(BodyBase)`
  font-size: 8px;
  line-height: 12px;
`;

/* ------------------------------------------ Modifiers ----------------------------------------- */

const Link = styled.a`
  color: ${(p) => p.theme.colors.primary};
  text-decoration: underline;
`;

export default {
  /** font-size: 96px; line-height: 112px */
  Display1,
  /** font-size: 88px; line-height: 98px */
  Display2,
  /** font-size: 72px; line-height: 80px */
  Headline1,
  /** font-size: 64px; line-height: 72px */
  Headline2,
  /** font-size: 56px; line-height: 64px */
  Headline3,
  /** font-size: 48px; line-height: 58px */
  Headline4,
  /** font-size: 40px; line-height: 48px */
  Headline5,
  /** font-size: 32px; line-height: 38px */
  Headline6,
  /** font-size: 24px; line-height: 28px */
  Headline7,
  /** font-size: 20px; line-height: 24px */
  Headline8,
  /** font-size: 16px; line-height: 24px */
  Headline9,
  /** font-size: 14px; line-height: 24px */
  Headline10,
  /** font-size: 24px; line-height: 32px */
  Body1,
  /** font-size: 20px; line-height: 28px */
  Body2,
  /** font-size: 16px; line-height: 22px */
  Body3,
  /** font-size: 14px; line-height: 20px */
  Body4,
  /** font-size: 12px; line-height: 18px */
  Caption,
  /** font-size: 8px; line-height: 12px */
  Overline,
  /** color: primary; decoration: underline */
  Link,
};
