import { Menu, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import TableClient from '@frontend/components/TableClient';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import { ROUTE_ExpenseDetails } from '@frontend/routes/ExternalRoutes';
import ModalService from '@frontend/services/ModalService';
import RouterService from '@frontend/services/RouterService';
import { formatDate, toCurrency } from '@frontend/utils/masks';
import React from 'react';
import { UseGetStatementOutput } from '../data/useGetStatement';
import DeleteTransactionModal from '../modals/DeleteTransactionModal';
import mappings from './mappings';

type Row = UseGetStatementOutput['items'][number];
type Columns = React.ComponentProps<typeof TableClient<Row>>['columns'];

export default [
  {
    accessorKey: 'date',
    header: 'Data',
    filterFn: 'customInDateRange',
    enableGlobalFilter: false,
    cell: (context) => (
      <Typography.Body4 noWrap color="neutral_30">
        {formatDate(context.row.original.date)}
      </Typography.Body4>
    ),
  },
  {
    accessorKey: 'source',
    header: 'Tipo de cartão',
    filterFn: 'customInSet',
    enableGlobalFilter: false,
    cell: (context) => {
      const { label, variant } = mappings.source[context.row.original.source];
      return (
        <Tag size="sm" variant={variant}>
          {label}
        </Tag>
      );
    },
  },
  {
    accessorKey: 'merchantName',
    header: 'Descrição',
    cell: (context) => (
      <Typography.Body4 noWrap color="neutral_30">
        {context.row.original.merchantName}
      </Typography.Body4>
    ),
  },
  {
    accessorKey: 'amount',
    header: 'Valor',
    // filterFn: 'inNumberRange',
    enableGlobalFilter: false,
    cell: (context) => (
      <Typography.Body4 noWrap color="neutral_30">
        {toCurrency(context.row.original.amount)}
      </Typography.Body4>
    ),
  },
  // > Será adicionado quando mapearmos corretamente os possíveis valores
  // {
  //   accessorKey: 'type',
  //   header: 'Transação',
  //   filterFn: 'customInSet',
  //   enableGlobalFilter: false,
  //   cell: (context) => (
  //     <Typography.Body4 noWrap color="neutral_30">
  //       {context.row.original.type}
  //     </Typography.Body4>
  //   ),
  // },
  {
    accessorKey: 'accountabilityStatus',
    header: 'Status do Relatório',
    filterFn: 'customInSet',
    enableGlobalFilter: false,
    cell: (context) => {
      const { label, variant } =
        mappings.accountabilityStatus[
          context.row.original.accountabilityStatus
        ];
      return (
        <Tag size="sm" variant={variant} dot>
          {label}
        </Tag>
      );
    },
  },
  {
    id: 'actions',
    header: 'Ações',
    enableGlobalFilter: false,
    isSticky: true,
    cell: (context) => {
      const { expenseId } = context.row.original;
      const navigate = RouterService.useNavigate();

      const options: React.ComponentProps<typeof Menu>['options'] = [
        // {
        //   label: 'Ver mais detalhes',
        //   onClick: () => alert('Não implementado'),
        // },
        {
          label: 'Excluir transação',
          onClick: () =>
            ModalService.show(DeleteTransactionModal, {
              transactionId: context.row.original._id,
            }),
        },
      ];

      if (expenseId) {
        options.push({
          label: 'Visualizar relatório',
          onClick: () =>
            navigate({
              route: ROUTE_ExpenseDetails,
              params: { expenseId },
              searchParams: {},
              state: {},
            }),
        });
      }

      return (
        <Menu type="default" locked options={options}>
          <PillButton size="medium" variant="default" icon="IconDotsVertical" />
        </Menu>
      );
    },
  },
] satisfies Columns;
