import * as XLSX from 'xlsx';
import FileUpload, { UploadStatus } from '../FileUpload';

type Props = {
  /** Callback for when the file is uploaded.
   *
   * Returns the upload status, for a chance of custom validation
   */
  onUpload: (
    _data: object[],
    _setProgress: (_progress: number) => void,
  ) => Promise<UploadStatus>;
  /** Callback fired when the component is reset */
  onReset?: () => void;
  setFileName?: (_input: string) => void;
};

export default function SpreadsheetUpload(props: Props) {
  return (
    <FileUpload
      acceptedExtensions={['xlsx', 'xls', 'csv']}
      onUpload={async (file, setProgress) => {
        const reader = new FileReader();
        const loadedContent = await new Promise((resolve, reject) => {
          reader.onload = (event) => {
            resolve(event?.target?.result);
          };
          reader.onerror = reject;
          reader.readAsBinaryString(file);
        });

        const sheets = XLSX.read(loadedContent, { type: 'binary' });
        const sheetName = sheets?.SheetNames[0];
        const sheet = sheets?.Sheets[sheetName];

        const data = XLSX.utils.sheet_to_json(sheet);

        props.setFileName?.(file.name ?? '');
        return props.onUpload(data as object[], setProgress);
      }}
      onReset={props.onReset}
    />
  );
}
