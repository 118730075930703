import SearchField from '@frontend/components/inputs/SearchField';
import debounce from 'lodash.debounce';
import React from 'react';

type Props = { disable: boolean };

export default function useSearchFilter(props: Props) {
  const [appliedFilter, setAppliedFilter] = React.useState<string | undefined>(
    undefined,
  );
  const handleDebouncedUpdate = debounce<(_: string | undefined) => void>(
    (newFilter) => setAppliedFilter(newFilter ?? undefined),
    500,
    {
      maxWait: 1500,
    },
  );

  const SearchFilterComponent = (
    <SearchField
      label="Buscar transação"
      size="lg"
      disabled={props.disable}
      onChange={(e) => handleDebouncedUpdate(e.target.value)}
    />
  );

  return {
    SearchFilterComponent,
    appliedFilter,
  };
}
