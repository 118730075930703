import { ThemesType } from '@flash-tecnologia/hros-web-ui-v2';
import { ComponentPadding, makePadding } from '@frontend/utils/theme/paddings';
import styled from 'styled-components';

type Props = {
  /** align-items */
  align?: 'start' | 'end' | 'center' | 'space-around' | 'space-between';
  /** flex-direction */
  direction?: 'row' | 'column';
  /** Gap between children
   * @default 0
   *
   * Options:
   *   - xs5: '4px'
   *   - xs4: '8px'
   *   - xs3: '12px'
   *   - xs2: '16px'
   *   - xs1: '20px'
   *   - xs: '24px'
   *   - s: '32px'
   *   - m: '40px'
   *   - l: '64px'
   *   - xl5: '80px'
   *   - xl4: '96px'
   *   - xl3: '120px'
   *   - xl2: '144px'
   *   - xl: '160px'
   */
  gap?: keyof ThemesType['spacings'];
  /** justify-content */
  justify?: 'start' | 'end' | 'center' | 'space-around' | 'space-between';
  /** padding */
  padding?: ComponentPadding;
  /** Custom styles */
  style?: React.CSSProperties;
  /** wrap */
  wrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  /** Children */
  children: React.ReactNode;
  /** Flex-grow */
  grow?: number;
};

const StyledFlex = styled.div<Props>`
  align-items: ${(props) => props.align};
  display: flex;
  flex-direction: ${(props) => props.direction};
  flex-wrap: ${(props) => props.wrap};
  gap: ${(props) => (props.gap ? props.theme.spacings[props.gap] : 0)};
  justify-content: ${(props) => props.justify};
  padding: ${(props) =>
    props.padding ? makePadding(props.theme, props.padding) : 0};
  flex: ${(props) => props.grow};
`;

export default function Flex(props: Props) {
  return <StyledFlex {...props} />;
}
