import { ShapeIcon } from '@flash-tecnologia/hros-web-ui-v2';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';

import * as ReportType from '@frontend/external/ReportsSection/consts';

import { Template } from '@frontend/components/FocusedFlow/Template';
import { SurveyStore } from '@frontend/components/SurveyStore';

import FeatureFlagService from '@frontend/services/FeatureFlagService';
import { useMemo } from 'react';
import { ReportStatus } from '../ReportStatus';
import { StepFeedbackV1 } from './StepFeedbackV1';
import * as SC from './styled';

type StepFeedbackProps = {
  reportTemplate:
    | 'expense-company-statement'
    | 'expense-balance-flash'
    | 'expense-employees'
    | 'expense-employees-statement';
  fileType: 'CSV' | 'XLSX';
  startDate: Date;
  endDate?: Date;
};

function StepFeedback(props: StepFeedbackProps) {
  const reportTemplateName = useMemo(() => {
    const mappedType: Record<StepFeedbackProps['reportTemplate'], string> = {
      'expense-balance-flash': ReportType.REPORTS_FLASH_BALANCE,
      'expense-company-statement': ReportType.REPORTS_FLASH_CASH_STATEMENT,
      'expense-employees': ReportType.REPORTS_CORPORATE_CARD_USERS,
      'expense-employees-statement':
        ReportType.REPORTS_CORPORATE_CARD_STATEMENT,
    };

    return mappedType[props.reportTemplate];
  }, []);

  return (
    <SC.Container>
      <SC.HeaderContainer>
        <Spacer y={'xs2'} />

        <ShapeIcon variant="info" stroke="info" name="IconRefresh" size={48} />

        <Spacer y={'xs2'} />

        <Typography.Headline7 color="neutral_20">
          Relatório em processo de criação
        </Typography.Headline7>

        <Spacer y={'xs2'} />

        <Typography.Body3 color="neutral_30" center>
          Você pode sair dessa tela e continuar navegando normalmente. Quando
          ele estiver pronto, você poderá baixá-lo na área de Reports e também o
          enviaremos para seu e-mail.
        </Typography.Body3>
      </SC.HeaderContainer>

      <ReportStatus
        startDate={props.startDate}
        endDate={props.endDate}
        fileType={props.fileType}
        reportType={reportTemplateName}
      />

      <SurveyStore
        title={`Como tem sido sua experiência com os relatórios de ${reportTemplateName}?`}
        type="report-feedback"
        params={{
          reportTemplate: props.reportTemplate,
          fileType: props.fileType,
        }}
      />
    </SC.Container>
  );
}

function Module(props: StepFeedbackProps) {
  const feedbackEnabled = FeatureFlagService.getFlag('exportReportFeedback');

  if (feedbackEnabled) {
    return <StepFeedback {...props} />;
  }

  return (
    <Template
      pageInfo={{
        title: 'Criar Report',
      }}
      Modules={<StepFeedbackV1 />}
    />
  );
}

export default Module;
