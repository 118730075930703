import { FocusedFlow } from '@frontend/components/FocusedFlow';
import ModalService from '@frontend/services/ModalService';
import conditionalList from '@frontend/utils/conditionalList';
import { ROUTE_Home } from '../..';
import useController from './data/useController';
import useForm from './forms/useForm';
import AddFile from './steps/01-AddFile';
import PaymentMethod from './steps/02-PaymentMethod';
import Confirmation from './steps/03-Confirmation';
import PaymentInstructions from './steps/04-PaymentInstructions';

function CreateManyDeposits() {
  const modalController = ModalService.useModalController();
  const form = useForm();
  const controller = useController({
    form,
    onClose: () => modalController.remove(),
  });

  const Content = () => {
    switch (controller.step) {
      case 1:
        return <AddFile form={form} />;
      case 2:
        return <PaymentMethod form={form} />;
      case 3:
        return <Confirmation form={form} />;
      case 4:
        return (
          <PaymentInstructions
            form={form}
            closeFocusedFlow={modalController.remove}
          />
        );
    }
  };

  return (
    <FocusedFlow
      headerMetadata={{
        breadcrumbs: [
          {
            text: 'Cartões corporativos',
            link: ROUTE_Home.path,
          },
          {
            text: 'Depósitos via planilha',
          },
        ],
        stepper: {
          disableClick: true,
          activeStep: controller.step - 1,
          steps: [
            'Enviar arquivo',
            'Definir pagamento e disponibilização',
            'Revisar detalhes e confirmar',
            'Finalizar solicitação',
          ],
        },
      }}
      content={Content()}
      footer={{
        rightActions: conditionalList([
          [
            controller.backButton.visible,
            {
              label: 'Voltar',
              onClick: controller.backButton.onClick,
              variant: 'secondary',
              isDisabled: controller.loading,
              isLoading: false,
            },
          ],
          [
            true,
            {
              label: controller.confirmButton.label,
              variant: 'primary',
              isDisabled: !controller.confirmButton.enabled,
              isLoading: controller.loading,
              onClick: controller.confirmButton.onClick,
            },
          ],
        ]),
        leftActions: controller.cancelButton.visible
          ? {
              label: 'Cancelar',
              onClick: controller.cancelButton.onClick,
              isDisabled: controller.loading,
            }
          : undefined,
      }}
    />
  );
}

export default ModalService.create(CreateManyDeposits);
