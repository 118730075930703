import ModalService from '../../../services/ModalService';
import { trpc } from '../../../utils/trpc';
import RechargeOnboardingStartModal from '../modals/onboardingRecharge/OnboardingRechargeStartModal';

export default function useShowRechargeOnboarding() {
  const { data } = trpc.notices.get.useQuery(
    {
      template: 'onboarding_recharge',
    },
    {
      cacheTime: 10000000000,
      staleTime: 10000000000,
      refetchOnWindowFocus: false,
    },
  );

  return function shouldShowNotice(callback: () => void) {
    if (data?.show)
      ModalService.show(RechargeOnboardingStartModal, { callback });
    else callback();
  };
}
