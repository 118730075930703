import { dispatchToast } from '../../../../utils/dispatchEvents';
import { useEffect, useState } from 'react';
import { trpc, RouterOutputs } from '@frontend/trpc';
import { z } from 'zod';
import { useParamsState } from '@frontend/utils/hooks/searchParams';
import { useStringSearch } from './useStringSearch';

export type CompanyStatementOutput = RouterOutputs['company']['statement'];

export const paramsSchema = z.object({
  amountMax: z.coerce.number().optional().catch(undefined),
  amountMin: z.coerce.number().optional().catch(undefined),
  startDate: z.string().datetime().optional().catch(undefined),
  endDate: z.string().datetime().optional().catch(undefined),
  currentPage: z.coerce.number().nonnegative().default(0).catch(0),
  pageSize: z.coerce.number().positive().default(10).catch(10),
  stringInput: z.string().optional(),
});

type Filter = z.infer<typeof paramsSchema>;

export const useTableData = () => {
  const [filters, setFilters] = useParamsState<Filter>(
    {
      currentPage: 0,
      pageSize: 10,
    },
    paramsSchema,
  );

  const [amountFilters, setAmountFilters] = useState<{
    max: number;
    min: number;
  }>({ max: 1000000, min: 0 });

  const [searchInput, setStringSearch] = useStringSearch(filters.stringInput);

  useEffect(() => {
    setFilters((prevValue) => ({
      ...prevValue,
      currentPage: 0,
      stringInput: searchInput,
    }));
  }, [searchInput !== filters.stringInput]);

  const { pageSize, currentPage, amountMin, amountMax, startDate, endDate } =
    filters;

  const filter = {
    ...(searchInput && {
      stringInput: searchInput,
    }),
    amount: amountFilters,
    ...(amountMax !== undefined &&
      amountMin !== undefined && {
        amount: {
          max: amountMax,
          min: amountMin,
        },
      }),
    ...(startDate !== undefined &&
      endDate !== undefined && {
        createdDate: {
          start: startDate,
          end: endDate,
        },
      }),
  };
  const pagination = {
    currentPage,
    pageSize,
  };

  const { data, isInitialLoading, isStale, isRefetching } =
    trpc.company.statement.useQuery(
      {
        filter,
        pagination,
      },
      {
        keepPreviousData: true,
        retry: false,
        retryOnMount: false,
        staleTime: 20 * 60 * 1000,
        refetchInterval: 10 * 60 * 1000,
        onError({ message }) {
          dispatchToast({
            type: 'error',
            content: message,
          });
        },
      },
    );
  const getCompanyData = data ?? {
    filter: {
      amount: null,
    },
    statement: [],
    pagination: {
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      pageSize: 0,
    },
  };
  const { amount } = getCompanyData.filter ?? { amount: null };

  useEffect(() => {
    if (amount?.max !== undefined && amount?.min !== undefined) {
      setAmountFilters(amount);
    }
  }, [amount?.max, amount?.min]);

  return {
    amount: amountFilters,
    pagination,
    filters,
    getCompanyData,
    setSearchInput: setStringSearch,
    setPagination: (pagination: { currentPage: number; pageSize: number }) => {
      setFilters({ ...filters, ...pagination });
    },
    setFilters: (data: Omit<Filter, 'pageSize' | 'currentPage'>) => {
      setFilters({ ...filters, ...data, currentPage: 0 });
    },
    isFiltered: Object.keys(filter).length > 0,
    getCompanyLoading: isInitialLoading || (isStale && isRefetching),
  };
};
