import Spacer from '../../../components/Spacer';
import { useEmployeeInfo } from './dataSource/useEmployeeInfo';
import * as styles from './styled';
import { useTheme } from 'styled-components';
import Typography from '../../../components/Typography';
import ModalService from '../../../services/ModalService';
import NextCreditStatusModal from './modals/NextCreditStatusModal';
import Tag from '../../../components/TagV2';

export default function CorporateCard() {
  const theme = useTheme();

  const { employeeInfo, employeeInfoLoading, employeeInfoError } =
    useEmployeeInfo();

  return (
    <>
      <Spacer y="s" />
      {employeeInfo.hasCard === false || employeeInfoLoading ? (
        <styles.StatusBox
          loading={employeeInfoLoading}
          title={'Você não tem cartão cadastrado'}
          subTitle={'Procure seu gestor para solicitar o seu cartão Flash.'}
        />
      ) : (
        <>
          <styles.Container>
            <styles.CustomBoxAdornment
              leftAdornment={
                <styles.BigIcon
                  size={24}
                  color={theme.colors.primary}
                  name="IconCurrencyDollar"
                />
              }
              title={'Saldo disponível'}
              hasError={!!employeeInfoError}
              loading={employeeInfoLoading}
              description={employeeInfo.balance}
            />
            <Spacer x="xs" useMargin />
            <styles.CustomBoxAdornment
              leftAdornment={
                <styles.BigIcon
                  size={24}
                  color={theme.colors.primary}
                  name="IconCalendarTime"
                />
              }
              title="Recargas para próximos 30 dias"
              hasError={!!employeeInfoError}
              loading={employeeInfoLoading}
              description={employeeInfo.nextMonthCreditAmount}
            />
          </styles.Container>
          <Spacer y="xs" />
          <styles.Wrapper>
            <styles.Container>
              <Typography.Headline8>
                Previsão para próximas recargas
              </Typography.Headline8>
              <Spacer x="xs5" useMargin />
              <styles.BaseIcon
                onClick={() => ModalService.show(NextCreditStatusModal, {})}
                fill="transparent"
                name="IconInfoCircle"
                size={18}
              />
            </styles.Container>
            <Typography.Body4 color="neutral_50">
              Confira o detalhe da suas recargas.
            </Typography.Body4>
            <Spacer y="s" />
            {!employeeInfo.nextCreditInfo?.length || !!employeeInfoError ? (
              <>
                <styles.NextCreditStatus error={!!employeeInfoError} />
                <Spacer y="m" />
              </>
            ) : (
              <styles.HorizontalList>
                {employeeInfo.nextCreditInfo?.map(
                  (
                    {
                      availableDate,
                      amount,
                      expirationDate,
                      type,
                      status,
                      statusVariant,
                    },
                    index,
                  ) => (
                    <div key={index}>
                      <styles.Box
                        hasBorder={
                          index < (employeeInfo.nextCreditInfo?.length || 0) - 1
                        }
                      >
                        <styles.Container justifyContent="space-between">
                          <Typography.Body4 weight={700}>
                            {type}
                          </Typography.Body4>
                          <Tag size="md" variant={statusVariant}>
                            {status}
                          </Tag>
                        </styles.Container>
                        <Spacer y="xs4" />
                        <styles.Container justifyContent="space-between">
                          <div>Data de disponibilização</div>
                          <div>{availableDate}</div>
                        </styles.Container>
                        <Spacer y="xs4" />
                        {!!expirationDate && (
                          <styles.Container justifyContent="space-between">
                            <div>Data de expiração</div>
                            <div>{expirationDate}</div>
                          </styles.Container>
                        )}
                        <Spacer y="xs4" />
                        <styles.Container justifyContent="space-between">
                          <Typography.Body4 weight={700}>
                            Valor
                          </Typography.Body4>
                          <Typography.Body4 weight={700}>
                            {amount}
                          </Typography.Body4>
                        </styles.Container>
                      </styles.Box>
                      <Spacer y="m" />
                    </div>
                  ),
                )}
              </styles.HorizontalList>
            )}
          </styles.Wrapper>
          <Spacer y="m" />
        </>
      )}
    </>
  );
}
