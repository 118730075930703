import { TextField as UITextField } from '@flash-tecnologia/hros-web-ui-v2';

type Props = {
  // Required
  /** Input's label */
  label: string;
  /** Input's value */
  value: string;
  /** Triggered when the input value changes */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

  // Optional
  /** Whether the input is disabled */
  disabled?: boolean;
  /** Readable error message displayed under the input container */
  error?: string;
  /** Whether the input occupies the full available width */
  fullWidth?: boolean;
};

export default function TextField(props: Props) {
  return (
    <UITextField {...props} error={!!props.error} helperText={props.error} />
  );
}
