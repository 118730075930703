import Typography from '@frontend/components/Typography';
import CardProviderChip from '@frontend/components/chips/CardProviderChip';
import styled from 'styled-components';

type Props = {
  balances: Readonly<Array<'BENEFITS' | 'CORPORATE'>>;
  cardNumber: string;
  provider: 'MASTERCARD' | 'VISA' | 'AGILLITAS';
  type: 'CREDIT' | 'PREPAID';
};

export default function CardFilterItem(props: Props) {
  return (
    <>
      <StyledItem>
        <Typography.Body3
          noWrap
          style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        >{`— ${props.cardNumber}`}</Typography.Body3>
        <CardProviderChip provider={props.provider} variant="color" />
      </StyledItem>
      <Typography.Body4 color="neutral_30">
        {`${typeMap[props.type]} • ${props.balances
          .map((b) => balancesMap[b])
          .join('/')}`}
      </Typography.Body4>
    </>
  );
}

const StyledItem = styled.div`
  display: grid;
  grid-template-columns: 180px auto;
  justify-content: space-between;
`;

const typeMap = {
  CREDIT: 'Crédito',
  PREPAID: 'Pré-pago',
};

const balancesMap = {
  BENEFITS: 'Benefícios',
  CORPORATE: 'Corporativo',
};
