import { Template } from '@frontend/components/FocusedFlow/Template';
import Icon from '@frontend/components/Icon';
import Spacer from '@frontend/components/Spacer';
import Callout from '@frontend/components/frames/Callout';
import Flex from '@frontend/components/frames/Flex';
import BalanceField from '@frontend/components/inputs/BalanceField';
import CheckboxGroup from '@frontend/components/inputs/CheckboxGroup';
import SelectField from '@frontend/components/inputs/SelectField';
import SelectTable from '@frontend/components/inputs/SelectTable';
import ToggleField from '@frontend/components/inputs/ToggleField';
import {
  sortedMccGroups,
  translatedMccGroups,
} from '@frontend/utils/dataFormatters/mccGroups.dataFormatter';
import {
  sortedWeekdays,
  translatedWeekdays,
} from '@frontend/utils/dataFormatters/weekdays.dataFormatter';
import { PeriodTypeEnum } from 'backend/src/utils/periodTypeEnum';
import { Controller } from 'react-hook-form';
import { translatedPeriodTypes } from '../../../../shared/mappers';
import { FormSchemaOutput, UseFormReturn } from '../../forms/useForm';

type Props = {
  form: UseFormReturn;
};

export default function Rules(props: Props) {
  return (
    <Template
      pageInfo={{
        title: 'Editar política de uso do cartão corporativo',
      }}
      contentInfo={{
        title: 'Regras de aplicação',
        description:
          'Defina de quais formas os cartões corporativos que tiverem essa política aplicada poderão ser utilizados. As pessoas que forem titulares dos cartões verão essas regras ao acessá-los',
      }}
      Modules={
        <>
          <Controller
            control={props.form.control}
            name="limitEnabled"
            render={(fieldProps) => (
              <ToggleField
                title="Limite de gastos"
                description="Você pode definir um valor máximo que um cartão corporativo poderá utilizar em um período (diário, semanal ou mensal)."
                value={fieldProps.field.value}
                onChange={fieldProps.field.onChange}
              >
                {fieldProps.field.value && (
                  <Flex justify="space-between" gap="xs">
                    <Controller
                      control={props.form.control}
                      name="limit.amount"
                      render={({ field, fieldState }) => (
                        <BalanceField
                          fullWidth
                          label="Limite de gastos"
                          value={field.value}
                          onChange={field.onChange}
                          error={fieldState.error?.message}
                        />
                      )}
                    />
                    <Controller
                      control={props.form.control}
                      name="limit.period"
                      render={({ field, fieldState }) => (
                        <SelectField<
                          NonNullable<FormSchemaOutput['limit']>['period']
                        >
                          fullWidth
                          label="Período"
                          value={field.value}
                          onChange={field.onChange}
                          options={Object.values(PeriodTypeEnum).map(
                            (periodType) => ({
                              label: translatedPeriodTypes[periodType],
                              value: periodType,
                            }),
                          )}
                          variant="standard"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Flex>
                )}
              </ToggleField>
            )}
          />
          <Spacer y="xs" />
          <Controller
            control={props.form.control}
            name="withdrawEnabled"
            render={({ field }) => (
              <ToggleField
                value={field.value}
                onChange={field.onChange}
                title="Habilitar saque no cartão físico"
                description="Defina se o colaborador poderá sacar o saldo corporativo usando o cartão físico da Flash. Limite de 2 saques por dia para o colaborador e limite de R$ 20.000,00 por dia para toda a empresa. Para cada saque, haverá uma taxa de operação de R$ 5,99."
              />
            )}
          />
          <Spacer y="xs" />
          <Callout
            title="Dias de uso"
            description="Dias da semana em que transações com esse cartão estão permitidas."
            alignItems="start"
            leftAdornment={
              <Icon
                name="IconCalendar"
                size={48}
                color="secondary_50"
                background="default"
              />
            }
          >
            <Controller
              control={props.form.control}
              name="weekdaysEnabled"
              render={({ field, fieldState }) => (
                <CheckboxGroup
                  options={sortedWeekdays.map((weekday) => ({
                    label: translatedWeekdays[weekday],
                    value: weekday,
                  }))}
                  value={field.value}
                  onChange={field.onChange}
                  error={fieldState.error?.message}
                />
              )}
            />
          </Callout>
          <Spacer y="xs" />
          <Callout
            title="Tipos de estabelecimento"
            description="Configure as categorias nas quais transações feitas com esse cartão serão aceitas."
            alignItems="start"
            leftAdornment={
              <Icon
                name="IconBuildingStore"
                size={48}
                color="secondary_50"
                background="default"
              />
            }
          >
            <Controller
              control={props.form.control}
              name="mccGroups"
              render={({ field, fieldState }) => (
                <SelectTable<keyof NonNullable<FormSchemaOutput['mccGroups']>>
                  header="Tipos de estabelecimento"
                  value={
                    new Set(
                      Object.entries(field.value ?? {})
                        .filter(([_, value]) => value)
                        .map(
                          ([key]) =>
                            key as keyof NonNullable<
                              FormSchemaOutput['mccGroups']
                            >,
                        ),
                    )
                  }
                  onChange={(values) =>
                    field.onChange({
                      GROCERY: values.has('GROCERY'),
                      MEAL: values.has('MEAL'),
                      MOBILITY: values.has('MOBILITY'),
                      // ACCOMMODATION: values.has('ACCOMMODATION'),
                      EDUCATION: values.has('EDUCATION'),
                      CULTURE: values.has('CULTURE'),
                      HEALTH: values.has('HEALTH'),
                      CONVENIENCE: values.has('CONVENIENCE'),
                    })
                  }
                  error={fieldState.error?.message}
                  options={sortedMccGroups.map((mccGroup) => ({
                    ...translatedMccGroups[mccGroup],
                    value: mccGroup,
                  }))}
                />
              )}
            />
          </Callout>
        </>
      }
    />
  );
}
