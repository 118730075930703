import Icon from '@frontend/components/Icon';
import styled from 'styled-components';

export const CloseButton = styled(Icon)`
  cursor: pointer;
`;

export const CloseButtonContainer = styled.div`
  margin-top: 0;
  margin-bottom: auto;
`;
