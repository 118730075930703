import LazyComponent from '@frontend/components/LazyComponent';
import BasePath from '@frontend/routes/BasePath';
import RouterService from '@frontend/services/RouterService';
import { Route } from 'react-router-dom';
import { zod } from 'react-router-typesafe-routes/zod';
import { z } from 'zod';

export const ROUTE_Home_FlashCard = RouterService.create(
  `${BasePath.relativePath}/home/flashCard`,
  {
    searchParams: {
      currentPage: zod(z.coerce.number().catch(0)),
      pageSize: zod(z.coerce.number().catch(10)),
    },
  },
);

const FlashCard = () => <LazyComponent factory={() => import('./FlashCard')} />;

export const RouteComponent_Home_FlashCard_Index = (
  <Route index element={<FlashCard />} />
);

export const RouteComponent_Home_FlashCard = (
  <Route path={ROUTE_Home_FlashCard.path} element={<FlashCard />} />
);
