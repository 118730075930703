import { trpc } from '@frontend/trpc';

export default function useGetFlashCashBalance() {
  const balance = trpc.company.balance.get.useQuery();

  return {
    balance: balance.data,
    isLoading: balance.isLoading,
  };
}
