import { RadioButtonCard } from '@flash-tecnologia/hros-web-ui-v2';

export const CardIssuer = ({
  createCorporateCardUserMetadata,
  setCreateCorporateCardUserMetadata,
}) => {
  const { cardIssuer } = createCorporateCardUserMetadata;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <span>
        <strong>Escolha a emissora para adicionar o cartão</strong>
      </span>
      <div style={{ display: 'flex', gap: '24px' }}>
        <RadioButtonCard
          checked={cardIssuer === 'FLASH'}
          title={'Flash'}
          description={
            'Faça a gestão de gastos e liberação de saldo da Pessoa.'
          }
          icon={'IconArrowRight'}
          onClick={() =>
            setCreateCorporateCardUserMetadata(
              (createCorporateCardUserMetadata) => ({
                ...createCorporateCardUserMetadata,
                cardIssuer: 'FLASH',
              }),
            )
          }
        />
        <RadioButtonCard
          checked={cardIssuer === 'EXTERNAL'}
          title={'Cartões Terceiros'}
          description={'Acompanhe a gestão da Pessoa.'}
          icon={'IconCreditCard'}
          onClick={() =>
            setCreateCorporateCardUserMetadata(
              (createCorporateCardUserMetadata) => ({
                ...createCorporateCardUserMetadata,
                cardIssuer: 'EXTERNAL',
              }),
            )
          }
        />
      </div>
    </div>
  );
};
