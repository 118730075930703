import { Toggle } from '@flash-tecnologia/hros-web-ui-v2';
import Callout from '@frontend/components/frames/Callout';
import React from 'react';

type Props = {
  title: string;
  description: React.ReactNode;
  value?: boolean;
  onChange?: (_newValue: boolean) => void;
  children?: React.ReactNode;
};
export default function ToggleField(props: Props) {
  return (
    <Callout
      title={props.title}
      description={props.description}
      leftAdornment={
        <Toggle
          checked={props.value}
          onChange={(_e, newValue) => {
            props.onChange?.(newValue);
          }}
        />
      }
    >
      {props.children}
    </Callout>
  );
}
