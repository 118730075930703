import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import ModalService from '@frontend/services/ModalService';
import useSetSeenAddEmployeeOnboarding from '../../data/useSetSeenAddEmployeeOnboarding';
import OnboardingAddEmployeeTutorialModal from './OnboardingAddEmployeeTutorialModal';

function OnboardingAddEmployeeStartModal() {
  const modalController = ModalService.useModalController();
  const setSeenAddEmployeeOnboarding = useSetSeenAddEmployeeOnboarding();

  function handleDismiss() {
    setSeenAddEmployeeOnboarding();
    modalController.remove();
  }

  function handleAccept() {
    ModalService.show(OnboardingAddEmployeeTutorialModal, {});
    setSeenAddEmployeeOnboarding();
    modalController.remove();
  }

  return (
    <ConfirmationModal
      isOpen={modalController.visible}
      onClose={handleDismiss}
      variant="default"
      text={{
        highlightedText: 'Vamos explorar juntos?',
        title:
          'Vincular um colaborador ao Cartão Corporativo ficou muito mais fácil!',
        body: 'Preparamos algumas dicas rápidas para você dar seus primeiros passos aqui.',
      }}
      buttons={{
        cancel: {
          onClick: handleDismiss,
          text: 'Não, obrigado',
        },
        confirm: {
          onClick: handleAccept,
          text: 'Me mostre o caminho',
        },
      }}
    />
  );
}

export default ModalService.create(OnboardingAddEmployeeStartModal);
