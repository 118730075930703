import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import BorderedSection from '../BorderedSection';
import Flex from '../Flex';
import * as SC from './styled';

type Props = {
  /** Aligns the left-adornment and content vertically
   *
   * @default 'center' */
  alignItems?: 'center' | 'start';
  description?: React.ReactNode;
  leftAdornment?: React.ReactNode;
  title?: React.ReactNode;
  backgroundColor?: 'neutral' | 'secondary';
  variant?: 'neutral' | 'secondary' | 'success' | 'error' | 'info';
  children?: React.ReactNode;
  onClose?(): void;
};

export default function Callout(props: Props) {
  return (
    <BorderedSection
      backgroundColor={props.backgroundColor}
      padding={['xs', 'xs2']}
      variant={props.variant}
    >
      <Flex align={props.alignItems ?? 'center'} gap="xs1">
        {props.leftAdornment}
        <Flex direction="column" style={{ flex: 1 }}>
          {props.title && (
            <Typography.Body3 weight={600} color={'neutral_30'}>
              {props.title}
            </Typography.Body3>
          )}
          {props.description && (
            <Typography.Body4 weight={400} color={'neutral_50'}>
              {props.description}
            </Typography.Body4>
          )}
          {props.children && (
            <>
              <Spacer y={'xs2'} />
              {props.children}
            </>
          )}
        </Flex>

        {props.onClose && (
          <SC.CloseButtonContainer>
            <SC.CloseButton
              name="IconX"
              size={16}
              color="secondary_50"
              onClick={() => props.onClose?.()}
            />
          </SC.CloseButtonContainer>
        )}
      </Flex>
    </BorderedSection>
  );
}
