import {
  DatePicker,
  SelectField,
  TextField,
} from '@flash-tecnologia/hros-web-ui-v2';
import { trpc } from '@frontend/trpc';
import { DateTime } from 'luxon';
import { Controller } from 'react-hook-form';
import { UseFormReturn } from '../data/forms/externalCardForm';
import useListUsers from '../data/useListUsers';

const MIN_FLASH_ACCOUNTABILITY_DATE = 30;
const MIN_AGILITAS_ACCOUNTABILITY_DATE = 60;

type ExternalCardSectionProps = {
  form: UseFormReturn;
};

export const ExternalCardSection = ({ form }: ExternalCardSectionProps) => {
  const { findBackBoneUsers, options, loading } = useListUsers();

  const { data } = trpc.company.getConfiguration.useQuery();

  const days_limit = !!data?.AgillitasPrepaid
    ? MIN_AGILITAS_ACCOUNTABILITY_DATE
    : MIN_FLASH_ACCOUNTABILITY_DATE;

  return (
    <>
      <Controller
        name="employee"
        control={form.control}
        render={(props) => (
          <SelectField
            filterOptions={(options, state) => {
              const filterString = state.inputValue.toLowerCase();

              return options.filter(
                (option) =>
                  option.value.toLowerCase().includes(filterString) ||
                  option.label.toLowerCase().includes(filterString),
              );
            }}
            searchable={true}
            label="Nome ou CPF da Pessoa"
            options={options}
            name="employee"
            noOptionsText={loading ? 'Buscando...' : 'Digite algo para buscar'}
            onChange={({ target }) => {
              const newValue = target.value;
              if (!!newValue.length) {
                findBackBoneUsers({ stringSearch: newValue });
              }
            }}
            onSelectChange={(_, selected) => {
              props.field.onChange(!!selected ? selected : {});
            }}
            error={!!props.formState.errors.employee}
            helperText={props.formState.errors.employee?.message as any}
          />
        )}
      />
      <Controller
        name="cardNumber"
        control={form.control}
        render={(props) => (
          <div
            style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <TextField
              name="cardNumber"
              label="Número do cartão ou Proxy"
              value={props.field.value}
              onChange={({ target }) => {
                const num = target.value.replace(/[^0-9]/g, '');
                props.field.onChange(num ? num : '');
              }}
              error={!!props.formState.errors.cardNumber}
              helperText={props.formState.errors.cardNumber?.message as any}
            />
          </div>
        )}
      />
      <Controller
        name="cardOwnerName"
        control={form.control}
        render={(props) => (
          <div
            style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <TextField
              name="cardOwnerName"
              label="Nome do portador do cartão"
              value={props.field.value}
              onChange={({ target }) => {
                props.field.onChange(target.value);
              }}
              error={!!props.formState.errors.cardOwnerName}
              helperText={props.formState.errors.cardOwnerName?.message as any}
            />
          </div>
        )}
      />
      <Controller
        name="accountabilityStartDate"
        control={form.control}
        render={(props) => (
          <DatePicker
            name="accountabilityStartDate"
            onDateChange={(date) => {
              props.field.onChange(date ? date.toDate() : undefined);
            }}
            value={props.field.value}
            label="Início da prestação de conta"
            error={!!props.formState.errors.accountabilityStartDate}
            helperText={
              props.formState.errors.accountabilityStartDate?.message as any
            }
            disabledDate={(date) => {
              return (
                DateTime.fromJSDate(date).diff(DateTime.local()).as('days') >
                  days_limit ||
                DateTime.fromJSDate(date).diff(DateTime.local()).as('days') <
                  days_limit * -1
              );
            }}
          />
        )}
      />
    </>
  );
};
