import { trpc } from '@frontend/trpc';
import React from 'react';

export type UseGetStatementOutput = ReturnType<typeof useGetStatement>;

export default function useGetStatement() {
  const statement = trpc.employee.corporateCard.statement.search.useQuery();

  return React.useMemo(
    () => ({
      items: statement.data?.items ?? [],
      metadata: statement.data?.metadata,
      isLoading: statement.isLoading,
    }),
    [statement.data, statement.isLoading],
  );
}
