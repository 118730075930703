import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import ModalService from '@frontend/services/ModalService';
import OnboardingVirtualCardTutorialModal from './OnboardingVirtualCardTutorialModal';

function OnboardingVirtualCardStartModal() {
  const modalController = ModalService.useModalController();

  function handleDismiss() {
    modalController.remove();
  }

  function handleAccept() {
    ModalService.show(OnboardingVirtualCardTutorialModal, {});
    modalController.remove();
  }

  return (
    <ConfirmationModal
      isOpen={modalController.visible}
      onClose={handleDismiss}
      variant="default"
      text={{
        highlightedText: 'Novidade!',
        title: 'Cartão virtual corporativo',
        body: (
          <>
            Agora as pessoas de seu time podem ter um cartão virtual específico
            de saldo corporativo para usar nas despesas do dia-a-dia.
            <br />
            <br />
            Quer entender como? Confira as dicas a seguir.
          </>
        ),
      }}
      buttons={{
        cancel: {
          onClick: handleDismiss,
          text: 'Não, obrigado',
        },
        confirm: {
          onClick: handleAccept,
          text: 'Conferir',
        },
      }}
    />
  );
}

export default ModalService.create(OnboardingVirtualCardStartModal);
