import styled from 'styled-components';

interface ICurrencyWrapperProps {
  isNegative: boolean;
}

const CurrencyWrapper = styled.div<ICurrencyWrapperProps>`
  color: ${(props) => (props.isNegative ? '#c96c01' : 'unset')};
`;

export { CurrencyWrapper };
