import { trpc } from '@frontend/trpc';
import React from 'react';

export type UseGetExternalCardsOutput = ReturnType<typeof useGetExternalCards>;

export default function useGetExternalCards() {
  const externalCards = trpc.company.externalCards.getAll.useQuery();

  return React.useMemo(() => {
    return {
      data: externalCards.data?.items ?? [],
      metadata: externalCards.data?.metadata,
      isLoading: externalCards.isLoading,
    };
  }, [externalCards.data, externalCards.isLoading]);
}
