import ModalService from '@frontend/services/ModalService';
import { useState } from 'react';
import CancelAutomaticCreditFeedbackModal from '../CancelAutomaticCreditFeedback';
import { useUpdateDeposit } from './useUpdateDeposit';

export const useEditAutomaticCredit = ({ automaticCreditMetadata }) => {
  const { updateDeposit, loading } = useUpdateDeposit();
  const modalController = ModalService.useModalController();

  const [updatedAutomaticCredit, setUpdatedAutomaticCredit] = useState<{
    availabilityDate: undefined | Date;
    creditAmount: null | number;
    type: null | string;
  }>({
    availabilityDate: new Date(automaticCreditMetadata.date),
    creditAmount: automaticCreditMetadata.amount,
    type: automaticCreditMetadata.type,
  });

  const actions = {
    cancel: {
      onClick: () => {
        modalController.remove();
        ModalService.show(CancelAutomaticCreditFeedbackModal, {
          automaticCreditMetadata: {
            ...automaticCreditMetadata,
            employeeDepositIdList: [automaticCreditMetadata.legacyId],
          },
        });
      },
      text: 'Cancelar Recarga Automática',
      variant: 'default',
    },
    confirm: {
      onClick: () => {
        const isSameDate =
          updatedAutomaticCredit.availabilityDate ===
          automaticCreditMetadata.date;
        const isSameAmount =
          updatedAutomaticCredit.creditAmount ===
          automaticCreditMetadata.amount;
        const isSameType =
          updatedAutomaticCredit.type === automaticCreditMetadata.type;

        const isAutomaticCreditSetupDifferent =
          !isSameDate || !isSameAmount || !isSameType;

        if (isAutomaticCreditSetupDifferent) {
          updateDeposit({
            employeeDepositIdList: [automaticCreditMetadata.legacyId],
            availabilityDate: updatedAutomaticCredit.availabilityDate as Date,
            creditAmount: updatedAutomaticCredit.creditAmount as number,
            isComplementaryCredit: updatedAutomaticCredit.type === 'fixedValue',
          });
        }
      },
      text: 'Confirmar',
    },
  } as const;

  return {
    actions,
    automaticCreditMetadata: updatedAutomaticCredit,
    setUpdatedAutomaticCredit,
    loading: loading,
  };
};
