import { Button, Icons, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import Icon from '@frontend/components/Icon';
import Typography from '@frontend/components/Typography';
import { useTheme } from 'styled-components';
import writeXlsxFile from 'write-excel-file';
import { TEMPLATE_COLUMNS } from '../../constants';

type Props = {
  validInputList: string[][];
  invalidInputList: string[][];
  callback: () => void;
};

export const ValidationErrorModule = (props: Props) => {
  const { validInputList, invalidInputList } = props;
  const theme = useTheme();

  async function downloadErros() {
    const headerFields = [...Object.values(TEMPLATE_COLUMNS), 'Erros'];
    const sheet = [
      headerFields.map((field) => {
        return {
          value: field,
          fontWeight: 'bold',
          backgroundColor: '#BFBFBF',
        };
      }),
      ...invalidInputList.map((input) =>
        input.map((cell) => ({ value: cell })),
      ),
    ];

    await writeXlsxFile(sheet, {
      columns: headerFields.map((field) => ({
        width: field.length + 2,
      })),
      fileName: 'lista-de-erros.xlsx',
      dateFormat: 'dd/mm/yyyy',
    });
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '64px',
        padding: '40px',
        border: `1px solid ${theme.colors.neutral[90]}`,
        borderRadius: '8px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
          alignItems: 'start',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Icons
              name={'IconAlertCircle'}
              color={theme.colors.feedback.error[40]}
              fill={theme.colors.feedback.error[90]}
              size={32}
            />
            <Typography.Headline8 color="neutral_30">
              Planilha processada
            </Typography.Headline8>
          </div>
          <Typography.Body4 color="neutral_50">
            {validInputList.length}{' '}
            {validInputList.length === 1
              ? 'transação foi processada'
              : 'transações foram processadas'}{' '}
            com sucesso.
            <br />
            {invalidInputList.length}{' '}
            {invalidInputList.length === 1
              ? 'transação não pôde ser processada.'
              : 'transações não puderam ser processadas.'}
          </Typography.Body4>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Typography.Body3 color="neutral_20" weight={700}>
            Algumas transações não puderam ser processados
          </Typography.Body3>
          <Typography.Body3 color="neutral_50">
            Confira no arquivo abaixo os erros. Você deverá refazer o pedido
            dessas pessoas.
          </Typography.Body3>
        </div>
        <div
          style={{
            display: 'flex',
            padding: '16px',
            gap: '24px',
            border: `1px solid ${theme.colors.neutral[90]}`,
            borderRadius: '10px',
            justifyContent: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
            <Icon color="primary" name="XLSXDownload" />
            <div>
              <Typography.Body4 color="neutral_10" weight={700}>
                Lista de erros
              </Typography.Body4>
              <Typography.Body4 color="neutral_50">
                lista-de-erros.xlsx
              </Typography.Body4>
            </div>
          </div>
          <PillButton
            size="small"
            variant="default"
            icon="IconDownload"
            onClick={() => void downloadErros()}
          />
        </div>
      </div>
      <Button variant="secondary" size="large" onClick={() => props.callback()}>
        Reenviar planilha
      </Button>
    </div>
  );
};
