import FormService from '@frontend/services/FormService';
import ErrorMonitorService from '@frontend/services/MonitorService';
import errors from '@frontend/utils/commonTexts/errors';
import { RouterOutputs } from 'backend';
import React from 'react';
import { z } from 'zod';
import useEditPolicy from '../data/useEditPolicy';
import { useGetPolicy } from '../data/useGetPolicy';
import { FormSchema } from './schema';
import { toasts } from './toasts';

export type FormSchemaOutput = z.output<typeof FormSchema>;

type Input = {
  policyId: string;
  /** Callback for the submit success */
  onSuccess: (policy: RouterOutputs['company']['policies']['patch']) => void;
};

export default function useForm(input: Input) {
  const originalPolicy = useGetPolicy({ policyId: input.policyId });
  /* ------------------------------ Form setup ------------------------------ */
  const form = FormService.useCreateForm(FormSchema, {
    mode: 'onChange',
    values: {
      description: originalPolicy.data?.description ?? '',
      limit: originalPolicy.data?.limit,
      title: originalPolicy.data?.title ?? '',
      weekdaysEnabled: originalPolicy.data?.weekdaysEnabled ?? {
        MONDAY: true,
        TUESDAY: true,
        WEDNESDAY: true,
        THURSDAY: true,
        FRIDAY: true,
        SATURDAY: true,
        SUNDAY: true,
      },
      withdrawEnabled: originalPolicy.data?.withdrawEnabled ?? false,
      mccGroups: originalPolicy.data?.mccGroups ?? {
        CONVENIENCE: true,
        CULTURE: true,
        EDUCATION: true,
        GROCERY: true,
        HEALTH: true,
        MEAL: true,
        MOBILITY: true,
      },
      mccLimits: originalPolicy.data?.mccLimits ?? {
        CONVENIENCE: null,
        CULTURE: null,
        EDUCATION: null,
        GROCERY: null,
        HEALTH: null,
        MEAL: null,
        MOBILITY: null,
        WITHDRAW: null,
      },
      limitEnabled: !!originalPolicy.data?.limit,
    },
  });

  /* --------------------- Additional schema validations -------------------- */
  // If `limitEnabled` is true, `limit` is required
  const limitEnabled = form.watch('limitEnabled');
  const limit = form.watch('limit');
  React.useEffect(() => {
    if (limitEnabled) {
      if (!limit?.amount) {
        form.setError('limit.amount', {
          message: errors.forms.generic.required,
        });
      }
      if (!limit?.period) {
        form.setError('limit.period', {
          message: errors.forms.generic.required,
        });
      }
    } else {
      form.resetField('limit');
    }
  }, [limitEnabled, limit?.amount, limit?.period]);

  /* ------------------------ Schema validation check ----------------------- */
  const validSchema =
    form.formState.isValid &&
    !form.formState.errors.limit?.amount &&
    !form.formState.errors.limit?.period;

  /* ------------------------------ Form submit ----------------------------- */
  const editPolicy = useEditPolicy();

  const onSubmit = form.handleSubmit(
    (values) => {
      void editPolicy
        .mutateAsync({
          ...values,
          id: input.policyId,
          limit: values.limitEnabled ? values.limit : null,
        })
        .then((result) => {
          if (result) {
            input.onSuccess(result);
          }
        });
    },
    (errors) => {
      // Schema validation
      ErrorMonitorService.error({
        message: 'Error while validating policy form schema',
        severity: 'fatal',
        extras: {
          zodErrors: JSON.stringify(errors),
        },
      });
      toasts.toastFormSchemaError();
    },
  );

  /* -------------------------------- Return -------------------------------- */
  return {
    ...form,
    isSubmitting: form.formState.isSubmitting || editPolicy.isLoading,
    onSubmit,
    result: editPolicy.data,
    validSchema,
  };
}

export type UseFormReturn = ReturnType<typeof useForm>;
