import {
  DatePicker,
  Divider,
  TextField,
} from '@flash-tecnologia/hros-web-ui-v2';
import Typography from '@frontend/components/Typography';
import { Controller, UseFormReturn } from 'react-hook-form';
import { onlyNumbers, toCurrency } from '@frontend/utils/masks';
import RadioCard from '@frontend/components/inputs/RadioCard';
import { ModuleContainer } from '@frontend/components/FocusedFlow/styled';
import { FlexColumn } from './styled';
import { DateTime } from 'luxon';
import { z } from 'zod';
import {
  CreateDepositFormSchema,
  PAYMENT_METHOD,
} from '../data/useCreateDeposit';

type Props = {
  form: UseFormReturn<z.infer<typeof CreateDepositFormSchema>>;
};

const todayStartOfDay = DateTime.now()
  .setZone('America/Sao_Paulo')
  .startOf('day');

export const DepositSetup = ({ form }: Props) => {
  return (
    <ModuleContainer>
      <Controller
        name="amount"
        control={form.control}
        render={(props) => (
          <FlexColumn gap="16px">
            <Typography.Headline8 color="neutral_20">
              Quanto você quer depositar em Flash Cash?
            </Typography.Headline8>
            <div>
              <TextField
                style={{ width: '320px' }}
                label="Valor do depósito em R$"
                onChange={({ target }) => {
                  const amount = onlyNumbers(target.value.replace(',', ''));
                  const amountAsInt = parseInt(amount.replace(/\.|\,/, ''));

                  props.field.onChange(amountAsInt);
                }}
                value={
                  props.field.value !== undefined
                    ? toCurrency(props.field.value)
                    : ''
                }
                error={!!props.formState.errors.amount}
                helperText={props.formState.errors.amount?.message as any}
              />
            </div>
          </FlexColumn>
        )}
      />

      <Divider orientation="horizontal" />

      <Controller
        name="paymentMethod"
        control={form.control}
        render={(props) => (
          <FlexColumn gap="16px">
            <FlexColumn gap="4px">
              <Typography.Headline8 color="neutral_20">
                Forma de depósito
              </Typography.Headline8>
              <Typography.Body3 color="neutral_30">
                Faça um depósito por Boleto ou Pix, é totalmente gratuito
              </Typography.Body3>
            </FlexColumn>
            <RadioCard
              metadata={{
                icon: 'IconBarcode',
                title: 'Boleto bancário',
                description:
                  'Pode ser pago através de uma conta ou agência bancária e tem prazo de compensação de 1 a 3 dias úteis.',
              }}
              onClick={() => props.field.onChange(PAYMENT_METHOD.BILLET)}
              selected={props.field.value === PAYMENT_METHOD.BILLET}
            />
            <RadioCard
              metadata={{
                icon: 'Pix',
                title: 'Pix',
                description:
                  'Com o Pix você tem pagamento instantâneo que pode ser feito em poucos segundos. É rápido e seguro.',
              }}
              onClick={() => props.field.onChange(PAYMENT_METHOD.PIX)}
              selected={props.field.value === PAYMENT_METHOD.PIX}
            />
          </FlexColumn>
        )}
      />

      <Controller
        name="expirationDate"
        control={form.control}
        render={(props) => (
          <FlexColumn gap="16px">
            <FlexColumn gap="4px">
              <Typography.Headline8 color="neutral_20">
                Data Limite de pagamento
              </Typography.Headline8>
              <Typography.Body3 color="neutral_30">
                Determine a data de vencimento para o depósito dos créditos
              </Typography.Body3>
            </FlexColumn>
            <DatePicker
              style={{ width: '320px' }}
              onDateChange={(e) => {
                if (e) {
                  const isToday =
                    todayStartOfDay
                      .diff(DateTime.fromJSDate(e.toDate()).startOf('day'))
                      .as('days') === 0;
                  props.field.onChange(
                    isToday ? DateTime.now().toJSDate() : e?.toDate(),
                  );
                }
              }}
              value={props.field.value}
              label="Data de vencimento"
              error={!!props.formState.errors.expirationDate}
              helperText={props.formState.errors.expirationDate?.message as any}
              disabledDate={(date) =>
                DateTime.fromJSDate(date, {
                  zone: 'America/Sao_Paulo',
                })
                  .startOf('day')
                  .diff(
                    DateTime.now().setZone('America/Sao_Paulo').startOf('day'),
                  )
                  .as('days') <
                (form.getValues('paymentMethod') === PAYMENT_METHOD.BILLET
                  ? 3
                  : 0)
              }
            />
          </FlexColumn>
        )}
      />
    </ModuleContainer>
  );
};
