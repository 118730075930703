import errors from '@frontend/utils/commonTexts/errors';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import { z } from 'zod';

export function toastProcessing() {
  dispatchToast({
    type: 'warning',
    content: 'Iniciando download',
    description: `O report em breve será baixado automaticamente no seu computador`,
  });
}

export function toastFormSchemaError() {
  dispatchToast({
    type: 'error',
    content: 'Falha ao validar formulário de criação do relatório',
    description: errors.actions.toastDescription,
  });
}

export function toastError(message?: string) {
  const issues = z
    .array(z.object({ message: z.string() }))
    .safeParse(JSON.parse(message ?? ''));

  dispatchToast({
    type: 'error',
    content: 'Não foi possível criar o Report',
    description: issues.success
      ? issues.data[0].message
      : errors.actions.toastDescription,
  });
}
