import TableClient from '@frontend/components/TableClient';

export function FlashCashStatementTable() {
  return (
    <TableClient
      columns={[
        {
          header: 'Data',
          accessorKey: 'date',
          enableSorting: false,
          meta: { tooltip: 'Data da movimentação' },
        },
        {
          header: 'Hora',
          accessorKey: 'time',
          enableSorting: false,
          meta: { tooltip: 'Hora da movimentação' },
        },
        {
          header: 'Tipo de Lançamento',
          accessorKey: 'type',
          enableSorting: false,
          meta: { tooltip: 'Se débito ou crédito no saldo de Flash Cash' },
        },
        {
          header: 'Movimentação',
          accessorKey: 'description',
          enableSorting: false,
          meta: { tooltip: 'O tipo de movimentação realizado na conta' },
        },
        {
          header: 'Meio de pagamento',
          accessorKey: 'paymentMethod',
          enableSorting: false,
          meta: {
            tooltip:
              'Meio de pagamento utilizado, podendo ser Flash Cash, Pix ou Boleto',
          },
        },
        {
          header: 'Valor lançamento',
          accessorKey: 'amount',
          enableSorting: false,
          meta: { tooltip: 'Valor de lançamento realizado' },
        },
        {
          header: 'Identificador',
          accessorKey: 'identifier',
          enableSorting: false,
          meta: { tooltip: 'Identificador único da operação' },
        },
      ]}
      data={[
        {
          date: '01/02/2023',
          time: '09:00',
          type: 'CRÉDITO',
          description: 'RECARGA FLASH CASH',
          paymentMethod: 'BOLETO',
          amount: 'R$ 10,00',
          identifier: 'AB1234',
        },
        {
          date: '01/02/2023',
          time: '10:00',
          type: 'CRÉDITO',
          description: 'RECARGA FLASH CASH',
          paymentMethod: 'PIX',
          amount: 'R$ 20,00',
          identifier: 'AC5678',
        },
        {
          date: '01/02/2023',
          time: '12:00',
          type: 'DÉBITO',
          description: 'DEPOSITO COLABORADORES',
          paymentMethod: 'FLASH CASH',
          amount: 'R$ (13,00)',
          identifier: 'JK1234',
        },
      ]}
      emptyState={{ emptyText: '', filteredEmptyText: '' }}
      loading={false}
    />
  );
}
