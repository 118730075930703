import * as ds from '@flash-tecnologia/hros-web-ui-v2';

type Root = React.ComponentProps<typeof ds.NotificationCard.Root>;
type Icon = React.ComponentProps<typeof ds.NotificationCard.Icon>;
type Title = React.ComponentProps<typeof ds.NotificationCard.Title>;
type Subtitle = React.ComponentProps<typeof ds.NotificationCard.Subtitle>;

type Props = {
  type: Root['type'];
  title?: Title['children'];
  iconName: Icon['iconName'];
  subtitle: Subtitle['children'];
};

export default function NotificationCard(props: Props) {
  return (
    <ds.NotificationCard.Root type={props.type} variant="outlined">
      <ds.NotificationCard.Icon iconName={props.iconName} />
      <ds.NotificationCard.WrapperTexts>
        {props.title && (
          <ds.NotificationCard.Title>{props.title}</ds.NotificationCard.Title>
        )}
        <ds.NotificationCard.Subtitle>
          {props.subtitle}
        </ds.NotificationCard.Subtitle>
      </ds.NotificationCard.WrapperTexts>
    </ds.NotificationCard.Root>
  );
}
