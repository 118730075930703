export function formatCPF(input: number): string {
  const stringNumber = input.toString();

  return `${stringNumber.substring(0, 3)}.${stringNumber.substring(
    3,
    6,
  )}.${stringNumber.substring(6, 9)}-${stringNumber.substring(9, 11)}`;
}

export function cpfMask(cpf: string): string {
  let cpfOnlyNumbers = cpf.replace(/\D/g, '');
  cpfOnlyNumbers = cpfOnlyNumbers.replace(
    /^(\d{3})(\d{3})(\d{3})(\d{1,3})$/,
    '$1.$2.$3-$4',
  );
  cpfOnlyNumbers = cpfOnlyNumbers.replace(
    /^(\d{3})(\d{3})(\d{1,3})$/,
    '$1.$2.$3',
  );
  return cpfOnlyNumbers.replace(/^(\d{3})(\d{1,3})$/, '$1.$2');
}
