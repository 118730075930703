import { Route } from 'react-router-dom';
import { zod } from 'react-router-typesafe-routes/zod';
import { z } from 'zod';
import EmployeeStatement from './EmployeeStatement';
import RouterService from '@frontend/services/RouterService';
import { ROUTE_Home } from '..';

export const ROUTE_EmployeeStatement = RouterService.create(
  `${ROUTE_Home.relativePath}/:employeeId/statement`,
  {
    params: {
      /** Employee nano id */
      employeeId: zod(z.string()).defined(),
    },
  },
);

export const RouteComponent_EmployeeStatement = (
  <Route path={ROUTE_EmployeeStatement.path} element={<EmployeeStatement />} />
);
