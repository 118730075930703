// PDFs
export const REPORTS_FAQ_URL =
  'https://beneficios.flashapp.com.br/faq/reports-de-cart%C3%A3o-corporativo';
export const REPORTS_MANUAL_URL =
  'https://flashapp-public-assets.s3.sa-east-1.amazonaws.com/corporate-card/reports/Manual+-+Reports+Carta%CC%83o+Corporativo.pdf';

// GIFs
export const REPORTS_ONBOARDING_GIF_1 =
  'https://public-images-flash.s3.sa-east-1.amazonaws.com/expense/corporate-card/onboarding-reports/onboarding-reports-1.gif';
export const REPORTS_ONBOARDING_GIF_2 =
  'https://public-images-flash.s3.sa-east-1.amazonaws.com/expense/corporate-card/onboarding-reports/onboarding-reports-2.gif';
export const REPORTS_ONBOARDING_GIF_3 =
  'https://public-images-flash.s3.sa-east-1.amazonaws.com/expense/corporate-card/onboarding-reports/onboarding-reports-3.gif';

// Reports
export const REPORTS_CORPORATE_CARD_STATEMENT = 'Extrato Cartão Corporativo';
export const REPORTS_CORPORATE_CARD_USERS = 'Pessoas com Cartão Corporativo';
export const REPORTS_FLASH_BALANCE = 'Saldo Flash';
export const REPORTS_FLASH_CASH_STATEMENT = 'Extrato Flash Cash';

// Reports names
export const REPORTS_NAME_CORPORATE_CARD_STATEMENT =
  'report_extrato_colaborador';
export const REPORTS_NAME_CORPORATE_CARD_USERS =
  'report_pessoas_cartao_corporativo';
export const REPORTS_NAME_FLASH_BALANCE = 'report_visao_saldo';
export const REPORTS_NAME_FLASH_CASH_STATEMENT = 'report_extrato_flash_cash';
