import { PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import Icon from '@frontend/components/Icon';
import Typography from '@frontend/components/Typography';
import { useTheme } from 'styled-components';
import writeXlsxFile from 'write-excel-file';
import { TEMPLATE_COLUMNS } from '../../constants';

export const DownloadTemplateModule = () => {
  const theme = useTheme();

  async function downloadTemplate() {
    const columns = Object.values(TEMPLATE_COLUMNS);

    const sheet = columns.map((field) => {
      return {
        value: field,
        fontWeight: 'bold',
        backgroundColor: '#BFBFBF',
      } as const;
    });

    await writeXlsxFile([sheet], {
      columns: columns.map((field) => ({
        width: field.length + 2,
      })),
      fileName: 'modelo_de_planilha.xlsx',
    });
  }

  return (
    <div
      style={{
        padding: '32px',
        border: `1px solid ${theme.colors.neutral[90]}`,
        borderRadius: '8px',
        gap: '24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
      }}
    >
      <div>
        <Typography.Headline8 color="neutral_30">
          Base de arquivo para importação
        </Typography.Headline8>
        <Typography.Body4 color="neutral_50">
          Utilize a planilha disponibilizada pela Flash para informar as
          transações
        </Typography.Body4>
      </div>
      <div
        style={{
          display: 'flex',
          padding: '16px',
          gap: '24px',
          border: `1px solid ${theme.colors.neutral[90]}`,
          borderRadius: '10px',
          justifyContent: 'center',
        }}
      >
        <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
          <Icon color="primary" name="XLSXDownload" />
          <div>
            <Typography.Body4 color="neutral_10" weight={700}>
              Baixar modelo XLS
            </Typography.Body4>
            <Typography.Body4 color="neutral_50">
              modelo_de_planilha.xlsx
            </Typography.Body4>
          </div>
        </div>
        <PillButton
          size="small"
          variant="default"
          icon="IconDownload"
          onClick={() => void downloadTemplate()}
        />
      </div>
    </div>
  );
};
