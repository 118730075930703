import { Icons, Menu, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import Spacer from '@frontend/components/Spacer';
import { TableProps } from '@frontend/components/TableV2';
import CancelAutomaticCreditFeedbackModal from '@frontend/pages/Home/FlashCard/modals/EditAutomaticCredit/CancelAutomaticCreditFeedback';
import * as styled from '@frontend/pages/Home/FlashCard/styled';
import FailedAutomaticCreditFeedback from '@frontend/pages/Management/modals/FailedAutomaticCreditFeedback';
import CancelDepositModal from '@frontend/pages/Transactions/Deposits/CancelDepositModal/CancelDepositModal';
import ModalService from '@frontend/services/ModalService';
import { trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import { toCurrency } from '@frontend/utils/masks';
import { capitalizeWords } from '@frontend/utils/masks/capitalizeWords';
import { formatDate } from '@frontend/utils/masks/formatDate';
import { CreditInfo } from './data/useCreditInfo';

interface ITablePageSizeOption {
  label: string;
  value: number;
}

export const pageSizeOptions: ITablePageSizeOption[] = [
  { label: '5 itens', value: 5 },
  { label: '10 itens', value: 10 },
  { label: '20 itens', value: 20 },
];

type Rows = CreditInfo['credits'][number];
type Columns = TableProps<Rows>['columns'];

export const managementColumns: Columns = [
  {
    accessorKey: 'employee.cardNumber',
    header: () => <styled.Cell>Nº do cartão</styled.Cell>,
    accessorFn: (row) => row.employee.cardNumber,
    cell: ({ cell }) => {
      const value = cell.getValue<string>();
      return <styled.Cell>{value ? `––– ${value}` : ''}</styled.Cell>;
    },
  },
  {
    accessorKey: 'employee',
    header: () => (
      <styled.Cell>
        <Spacer x="xs" />
        Colaborador
      </styled.Cell>
    ),
    cell: ({ cell }) => {
      const value = cell.getValue<{ name: string; email: string }>();
      return (
        <styled.StyledBoxAdornment
          leftAdornment={<styled.StyledIcon>{value.name[0]}</styled.StyledIcon>}
          title={capitalizeWords(value.name)}
          description={
            <styled.Wrapper>
              <Icons fill="transparent" name="IconMail" size={15} />
              <Spacer x="xs5" />
              {value.email}
            </styled.Wrapper>
          }
        />
      );
    },
  },
  {
    accessorKey: 'type',
    header: () => <styled.Cell>Tipo de recarga</styled.Cell>,
    accessorFn: ({ type }) => {
      switch (type) {
        case 'normal':
          return 'Recarga Única';
        case 'fixedDate':
          return 'Recarga Automática';
        case 'fixedValue':
          return 'Recarga Automática Complementar';
        default:
          return 'Recarga Única';
      }
    },
    cell: ({ cell }) => {
      const value = cell.getValue<string>();
      return <styled.Cell>{value}</styled.Cell>;
    },
  },
  {
    accessorKey: 'date',
    header: () => <styled.Cell>Data da recarga</styled.Cell>,
    cell: ({ cell }) => {
      const value = cell.getValue<Date>();
      return formatDate(value);
    },
  },
  {
    accessorKey: 'value',
    header: () => <styled.Cell>Valor da recarga</styled.Cell>,
    cell: ({ cell }) => {
      const value = cell.getValue<number>();
      return toCurrency(value);
    },
  },
  {
    accessorKey: 'externalLegacyId',
    accessorFn: (row) => ({
      isOkay: row.isOkay,
      type: row.type,
    }),
    header: () => <styled.Cell>Observação</styled.Cell>,
    cell: ({ cell }) => {
      const value = cell.getValue<{
        isOkay: boolean;
        type: 'none' | 'normal' | 'fixedDate' | 'fixedValue';
      }>();
      let description = '';
      switch (value.type) {
        case 'none':
        case 'normal':
          description = value.isOkay ? 'Boleto pago' : 'Boleto em aberto';
          break;
        default:
          description = value.isOkay
            ? 'Saldo Flash Cash Corporativo suficiente'
            : 'Saldo Flash Cash Corporativo insuficiente';
          break;
      }
      value.type;

      return <div style={{ width: '200px' }}>{description}</div>;
    },
  },
  {
    accessorKey: 'externalLegacyId',
    accessorFn: (row) => ({
      _id: row._id,
      amount: row.value,
      date: row.date.toISOString(),
      employeeName: row.employee.name,
      employeeDepositId: row.externalLegacyId,
      type: row.type,
      isOkay: row.isOkay,
      employeeId: row.employee.externalLegacyId,
    }),
    isSticky: true,
    header: () => <styled.Cell>Ações</styled.Cell>,
    cell: ({ cell }) => {
      const options: React.ComponentProps<typeof Menu>['options'] = [];
      const props = cell.getValue<{
        _id: string;
        amount: number;
        date: string;
        employeeName: string;
        employeeDepositId: string;
        type: 'none' | 'normal' | 'fixedDate' | 'fixedValue';
        isOkay: boolean;
        employeeId: string;
      }>();
      const context = trpc.useContext();
      if (!props.isOkay) {
        const { date, amount, type } = props;
        if (type === 'fixedDate' || type === 'fixedValue') {
          const { mutate } = trpc.company.automaticCredit.retry.useMutation({
            onMutate: () => {
              dispatchToast({
                type: 'warning',
                content: 'Tentando realizar recarga automática...',
              });
            },
            onSuccess: () => {
              context.company.deposits.failed.refetch(undefined);
              dispatchToast({
                type: 'success',
                content: 'Recarga realizada com sucesso!',
              });
            },
            onError: (error) => {
              ModalService.show(FailedAutomaticCreditFeedback, {
                value: error.message ? parseInt(error.message) : undefined,
              });
            },
          });

          options.push({
            label: 'Tentar novamente',
            onClick: () => {
              mutate({
                depositIds: [props._id],
              });
            },
          });
          options.push({
            label: 'Cancelar recarga',
            onClick: () =>
              ModalService.show(CancelAutomaticCreditFeedbackModal, {
                automaticCreditMetadata: {
                  date: new Date(date),
                  amount,
                  type,
                  employeeDepositIdList: [props._id],
                },
                onCancel: () => {
                  context.company.deposits.failed.refetch(undefined);
                },
              }),
          });
        } else {
          options.push({
            label: 'Cancelar recarga',
            onClick: () =>
              ModalService.show(CancelDepositModal, {
                amount: props.amount,
                employeeName: props.employeeName,
                employeeDepositId: props.employeeDepositId,
                date: props.date,
                onCancel: () => {
                  context.company.deposits.failed.refetch(undefined);
                },
              }),
          });
        }
      }
      return options.length ? (
        <Menu type="default" options={options}>
          <PillButton size="medium" variant="default" icon="IconDotsVertical" />
        </Menu>
      ) : (
        <div>
          <Spacer y="m" />
          <Spacer y="xs3" />
        </div>
      );
    },
  },
];
