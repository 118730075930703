import React from 'react';
import debounce from 'lodash.debounce';
import SearchField from '@frontend/components/inputs/SearchField';

type Props = { disable: boolean };

export default function useSearchFilter(props: Props) {
  const [appliedFilter, setAppliedFilter] = React.useState<string | undefined>(
    undefined,
  );
  const handleDebouncedUpdate = debounce(
    (newFilter) => setAppliedFilter(newFilter ?? undefined),
    500,
    {
      maxWait: 1500,
    },
  );

  const SearchFilterComponent = (
    <SearchField
      label="Filtrar política por nome ou descrição"
      size="lg"
      disabled={props.disable}
      onChange={(e) => handleDebouncedUpdate(e.target.value)}
    />
  );

  return {
    SearchFilterComponent,
    appliedFilter,
  };
}
