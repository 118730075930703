import { trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

export default function useToggleActive() {
  const context = trpc.useContext();
  const toggleActive = trpc.company.externalCards.toggleActive.useMutation({
    async onSuccess(data, variables) {
      await context.company.externalCards.getAll.invalidate();
      await context.company.externalCards.get.invalidate({
        cardId: variables.cardId,
      });
      successToast(data.active);
    },
    onError: errorToast,
  });

  const mutate = (
    cardId: string,
    newStatus: boolean,
    callback?: () => void,
  ) => {
    startToast(newStatus);
    toggleActive.mutate({ cardId }, { onSuccess: () => callback?.() });
  };

  return {
    mutate,
    isLoading: toggleActive.isLoading,
  };
}

function startToast(newStatus: boolean) {
  dispatchToast({
    type: 'warning',
    content: newStatus ? 'Ativando cartão...' : 'Desativando cartão...',
    description: 'Isso pode demorar alguns segundos.',
  });
}

function successToast(newStatus: boolean) {
  dispatchToast({
    type: 'success',
    content: newStatus
      ? 'Cartão ativado com sucesso!'
      : 'Cartão desativado com sucesso!',
  });
}

function errorToast() {
  dispatchToast({
    type: 'error',
    content: 'Não foi possível alterar o status do cartão.',
    description:
      'Tente novamente. Se o problema persistir, entre em contato com o suporte',
  });
}
