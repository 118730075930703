import styled from 'styled-components';
import Typography from '../../../../../components/Typography';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 120vh;
  padding: 56px 112px;
`;

export const HeaderDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const HeaderDescriptionSubtitle = styled(Typography.Body3)`
  font-weight: 400;
  font-size: 16px;

  color: #83727d;
`;

type RadioProps = {
  selected: boolean;
};

export const RadioOptionText = styled(Typography.Body4)<RadioProps>`
  ${({ selected }) => selected && 'font-weight: 700;'}
`;

export const RadioOption = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const RadioOptionsContainer = styled.div`
  display: flex;
  gap: 24px;
`;
