import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import Typography from '@frontend/components/Typography';
import ModalService from '@frontend/services/ModalService';
import OnboardingFlashCashTutorialModal from './OnboardingFlashCashTutorialModal';

function OnboardingFlashCashEndModal() {
  const modalController = ModalService.useModalController();
  function backToTutorial() {
    modalController.remove();
    ModalService.show(OnboardingFlashCashTutorialModal, {});
  }

  function handleDismiss() {
    modalController.remove();
  }

  return (
    <ConfirmationModal
      isOpen={modalController.visible}
      onClose={handleDismiss}
      variant="default"
      text={{
        highlightedText: 'Tudo certo!',
        title:
          'Pronto! Agora você pode começar a utilizar o Flash Cash Corporativo!',
        body: (
          <>
            Se ainda tiver dúvidas, consulte nossa
            <br />
            <Typography.Link
              href="https://beneficios.flashapp.com.br/faq/flash-expense"
              target="_blank"
            >
              Central de Ajuda Flash.
            </Typography.Link>
          </>
        ),
      }}
      buttons={{
        cancel: {
          onClick: backToTutorial,
          text: 'Voltar',
        },
        confirm: {
          onClick: handleDismiss,
          text: 'Começar',
        },
      }}
    />
  );
}

export default ModalService.create(OnboardingFlashCashEndModal);
