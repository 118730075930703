import TagFilters from '@frontend/components/TagFilters';
import { toCurrency } from '@frontend/utils/masks';
import React from 'react';
import { z } from 'zod';
import fromCurrency from '@frontend/utils/masks/fromCurrency';
import { UseGetStatementOutput } from '../data/useGetStatement';
import { SetElements } from '@frontend/utils/types/setElements';

const FilterSchema = z
  .object({
    date: z.object({ from: z.date().optional(), to: z.date().optional() }),
    source: z.set(z.enum(['CREDIT', 'PREPAID'])),
    amount: z
      .object({ min: z.number(), max: z.number() })
      .transform((original) => [original.min, original.max] as const),
    type: z.set(z.string()),
    accountabilityStatus: z.set(
      z.enum([
        'PENDING',
        'OPEN',
        'SUBMITTED',
        'APPROVED',
        'DISAPPROVED',
        'REFUNDED',
        'PROCESSING',
        'NA',
      ]),
    ),
  })
  .partial();

type FilterSchemaInputType = z.input<typeof FilterSchema>;

type Input = {
  disableAll: boolean;
  metadata: UseGetStatementOutput['metadata'];
};

export default function useTagFilters(input: Input) {
  const [rawFilter, setRawFilter] = React.useState<FilterSchemaInputType>({});

  /* ------------------------------------- Drawer input refs ------------------------------------ */
  const ref_type =
    React.useRef<
      React.ComponentRef<
        typeof TagFilters.Checkbox<SetElements<FilterSchemaInputType['type']>>
      >
    >(null);

  const ref_accountabilityStatus =
    React.useRef<
      React.ComponentRef<
        typeof TagFilters.Checkbox<
          SetElements<FilterSchemaInputType['accountabilityStatus']>
        >
      >
    >(null);

  const ref_amount =
    React.useRef<React.ComponentRef<typeof TagFilters.Range>>(null);

  /* ----------------------------------------- Handlers ----------------------------------------- */
  /** Triggered by the `Filter` button */
  function handleApplyDrawerFilter() {
    handleUpdateFilter({
      accountabilityStatus: ref_accountabilityStatus.current?.triggerApply(),
      amount: ref_amount.current?.triggerApply(),
      type: ref_type.current?.triggerApply(),
    });
  }

  /** Triggered by the `Clear` button */
  function handleClearDrawerFilter() {
    handleUpdateFilter({
      accountabilityStatus: ref_accountabilityStatus.current?.triggerClear(),
      amount: ref_amount.current?.triggerClear(),
      type: ref_type.current?.triggerClear(),
    });
  }

  /** Triggered by closing the Drawer */
  function handleCloseDrawerFilter() {
    ref_accountabilityStatus.current?.triggerClose();
    ref_amount.current?.triggerClose();
    ref_type.current?.triggerClose();
  }

  function handleUpdateFilter(partialFilter: FilterSchemaInputType) {
    const newFilter = { ...rawFilter, ...partialFilter };
    if (FilterSchema.safeParse(newFilter).success) setRawFilter(newFilter);
  }

  const FilterComponent = (
    <>
      <TagFilters.DateRange
        label="Data"
        disabled={input.disableAll}
        onApply={(value) => {
          handleUpdateFilter({ date: value });
        }}
      />
      <TagFilters.Checkbox
        label="Tipo de cartão"
        disabled={input.disableAll}
        options={[
          { label: 'Crédito', value: 'CREDIT' },
          { label: 'Pré-pago', value: 'PREPAID' },
        ]}
        onApply={(value) => {
          handleUpdateFilter({ source: value });
        }}
      />
      <TagFilters.Drawer
        disabled={input.disableAll}
        onApply={handleApplyDrawerFilter}
        onClear={handleClearDrawerFilter}
        onClose={handleCloseDrawerFilter}
      >
        <TagFilters.Range
          asDrawerItem
          label="Saldo atual"
          disabled={input.disableAll}
          min={input.metadata?.amount.min ?? 0}
          max={input.metadata?.amount.max ?? 0}
          mask={toCurrency}
          fromMask={fromCurrency}
          ref={ref_amount}
        />
        {/* // > Adicionar quando tivermos um mapping pra esse campo
        <TagFilters.Checkbox
          asDrawerItem
          disabled={input.disableAll}
          label="Tipo"
          options={[...(input.metadata?.type ?? [])].map((option) => ({
            label: option,
            value: option,
          }))}
          ref={ref_type}
        /> */}
        <TagFilters.Checkbox
          asDrawerItem
          disabled={input.disableAll}
          label="Status"
          options={[
            {
              label: 'Em aberto',
              value: 'OPEN',
            },
            {
              label: 'Pendente',
              value: 'PENDING',
            },
            {
              label: 'Aguardando processamento',
              value: 'PROCESSING',
            },
            {
              label: 'Submetido',
              value: 'SUBMITTED',
            },
            {
              label: 'Aprovado',
              value: 'APPROVED',
            },
            {
              label: 'Reprovado',
              value: 'DISAPPROVED',
            },
            {
              label: 'Reembolsado',
              value: 'REFUNDED',
            },
            {
              label: 'N/A',
              value: 'NA',
            },
          ]}
          ref={ref_accountabilityStatus}
        />
      </TagFilters.Drawer>
    </>
  );

  const formattedFilter = Object.entries(FilterSchema.parse(rawFilter)).map(
    (entry) => ({
      id: entry[0],
      value: entry[1],
    }),
  );

  return {
    formattedFilter,
    FilterComponent,
  };
}
