import Icon from '@frontend/components/Icon';
import Typography from '@frontend/components/Typography';
import styled from 'styled-components';

type Props = {
  error?: string;
};

/** Error message displayed below an input */
export default function InputErrorMessage(props: Props) {
  if (!props.error) return null;
  return (
    <Container>
      <Icon color="error_50" name="IconInfoCircle" size={20} />
      <Typography.Body4 color="neutral_40">{props.error}</Typography.Body4>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacings.xs5};
  padding-left: ${({ theme }) => theme.spacings.xs3};
  gap: ${({ theme }) => theme.spacings.xs4};
`;
