import TableClient from '@frontend/components/TableClient';

export function CorporateCardUsersTable() {
  return (
    <TableClient
      columns={[
        {
          accessorKey: 'date',
          header: 'Data',
          enableSorting: false,
          meta: {
            tooltip:
              'Data pesquisada, referente ao saldo que será informado no report.',
          },
        },
        {
          accessorKey: 'documentNumber',
          header: 'CPF',
          enableSorting: false,
          meta: { tooltip: 'CPF da pessoa vinculada em Cartão Corporativo' },
        },
        {
          accessorKey: 'name',
          header: 'Nome',
          enableSorting: false,
          meta: { tooltip: 'Nome da pessoa vinculada em Cartão Corporativo' },
        },
        {
          accessorKey: 'status',
          header: 'Status Atual',
          enableSorting: false,
          meta: {
            tooltip:
              'Status da pessoa em cartão corporativo, podendo ser Ativo ou Desativado',
          },
        },
        {
          accessorKey: 'balance',
          header: 'Saldo na data pesquisada (R$)',
          enableSorting: false,
          meta: { tooltip: 'Saldo disponível para a pessoa' },
        },
      ]}
      data={[
        {
          date: '01/02/2024',
          documentNumber: '368.***.***-60',
          name: 'FLAVIA MINGO',
          status: 'DESATIVADO',
          balance: 'R$ -',
        },
        {
          date: '01/02/2024',
          documentNumber: '409.***.***-31',
          name: 'JUSSARA CORREA',
          status: 'ATIVO',
          balance: 'R$ 350,00',
        },
        {
          date: '01/02/2024',
          documentNumber: '424.***.***-62',
          name: 'BEATRIZ SANTOS',
          status: 'ATIVO',
          balance: 'R$ 1.520,00',
        },
      ]}
      emptyState={{ emptyText: '', filteredEmptyText: '' }}
      loading={false}
    />
  );
}
