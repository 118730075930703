import {
  Breadcrumbs,
  Icons,
  LinkButton,
  PageContainer,
  PageHeader,
  Tab,
} from '@flash-tecnologia/hros-web-ui-v2';
import BasePath from '@frontend/routes/BasePath';
import RouterService from '@frontend/services/RouterService';
import { useTabPage } from '@frontend/utils/hooks/useTabPage';
import { Outlet, useNavigate } from 'react-router-dom';

export const ROUTE_company_transactions = RouterService.create(
  `${BasePath.relativePath}/transactions`,
);
const routesMap = ['billet', 'deposits', 'flashcash'];

export default function Transactions() {
  const navigate = useNavigate();
  const { currentTab, setParams } = useTabPage(
    `/${ROUTE_company_transactions.relativePath}`,
    routesMap,
  );

  return (
    <>
      <PageHeader>
        <Breadcrumbs
          breadcrumbs={[
            <LinkButton
              onClick={() => {
                navigate('/corporateCard/management/deposits');
              }}
              variant="neutral"
            >
              Saldo corporativo
            </LinkButton>,
            <LinkButton variant="neutral" disabled>
              Histórico de movimentações
            </LinkButton>,
          ]}
          separator={<Icons name="IconChevronRight" fill="#ffffff" />}
        />
      </PageHeader>
      <PageContainer>
        <Tab
          defaultTab={currentTab}
          onTabChanged={setParams}
          tabItens={[
            {
              label: 'Fatura',
              component: <Outlet />,
            },
            {
              label: 'Depósito',
              component: <Outlet />,
            },
            {
              label: 'Flash Cash',
              component: <Outlet />,
            },
          ]}
        />
      </PageContainer>
    </>
  );
}
