import Typography from '@frontend/components/Typography';
import React from 'react';

type Props = {
  pageInfo: {
    title: React.ReactNode;
    description?: React.ReactNode;
  };
  contentInfo?: {
    title: React.ReactNode;
    description: React.ReactNode;
  };
  Modules: React.ReactNode;
  ResultModule?: React.ReactNode;
};

export const Template = (props: Props) => {
  const { pageInfo, contentInfo, Modules, ResultModule } = props;

  return (
    <div
      style={{
        height: '100%',
        display: 'column',
        gap: '16px',
        overflowY: 'auto',
      }}
    >
      <Typography.Headline6
        color={'neutral_20'}
        style={{ padding: '32px 24px' }}
      >
        {pageInfo.title}
      </Typography.Headline6>
      <div
        style={{
          display: 'grid',
          columnGap: '24px',
          gridTemplateColumns: 'repeat(12, 1fr)',
          gap: '24px',
          padding: '0 24px',
        }}
      >
        {!!contentInfo && (
          <div
            style={{
              display: 'flex',
              gap: '16px',
              flexDirection: 'column',
              gridColumnStart: 1,
              gridColumnEnd: 4,
            }}
          >
            <Typography.Headline7 color={'secondary_50'}>
              {contentInfo.title}
            </Typography.Headline7>
            <Typography.Body3 color={'neutral_50'}>
              {contentInfo.description}
            </Typography.Body3>
          </div>
        )}
        <div
          style={{
            gridColumnStart: !!contentInfo ? 4 : 1,
            gridColumnEnd: !!ResultModule ? 9 : 13,
          }}
        >
          {Modules}
        </div>
        {ResultModule && (
          <div style={{ gridColumnStart: 9, gridColumnEnd: 13 }}>
            {ResultModule}
          </div>
        )}
      </div>
    </div>
  );
};
