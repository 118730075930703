import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import ModalService from '../../../../services/ModalService';

type Props = {
  message: string;
};

function ReclassificationErrorModal(props: Props) {
  const modalController = ModalService.useModalController();
  return (
    <ConfirmationModal
      isOpen={modalController.visible}
      onClose={modalController.remove}
      variant="error"
      text={{
        highlightedText: 'Atenção!',
        title: 'Não é possível reclassificar esta compra',
        body: props.message,
      }}
      buttons={{
        confirm: {
          text: 'Continuar',
          onClick: modalController.remove,
        },
      }}
    />
  );
}

export default ModalService.create(ReclassificationErrorModal);
