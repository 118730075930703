import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { RouterInputs } from '@frontend/trpc';
import { DateTime } from 'luxon';
import { useGetBillingInfo } from './useGetBillingInfo';
import { useCreateOrder } from './useCreateOrder';

type AddBalanceInput = RouterInputs['company']['order'];

export const PAYMENT_METHOD = {
  BILLET: 'billet',
  PIX: 'pix',
};

const todayStartOfDay = DateTime.now()
  .setZone('America/Sao_Paulo')
  .startOf('day');

export const CreateDepositFormSchema = z
  .object({
    amount: z.number({
      required_error: 'Informar valor do depósito',
    }),
    paymentMethod: z.enum([PAYMENT_METHOD.BILLET, PAYMENT_METHOD.PIX]),
    expirationDate: z.date({
      required_error: 'Informar data de vencimento para o depósito',
    }),
  })
  .refine(
    ({ paymentMethod, amount }) =>
      paymentMethod === PAYMENT_METHOD.PIX ||
      (paymentMethod === PAYMENT_METHOD.BILLET && amount >= 1000),
    {
      message: 'Depósito para boleto deve ser igual ou superior a R$ 10,00',
      path: ['amount'],
    },
  )
  .refine(
    ({ paymentMethod, expirationDate }) =>
      (paymentMethod === PAYMENT_METHOD.PIX &&
        DateTime.fromJSDate(expirationDate, {
          zone: 'America/Sao_Paulo',
        })
          .startOf('day')
          .diff(todayStartOfDay)
          .as('days') >= 0) ||
      (paymentMethod === PAYMENT_METHOD.BILLET &&
        DateTime.fromJSDate(expirationDate, {
          zone: 'America/Sao_Paulo',
        })
          .startOf('day')
          .diff(todayStartOfDay)
          .as('days') >= 3),
    {
      message:
        'Data de expiração para boleto deve ser superior a 2 dias a partir de hoje',
      path: ['expirationDate'],
    },
  );

export const useCreateDeposit = (
  callbackFunction: () => void,
  amount?: number,
) => {
  const {
    adminInfo,
    createOrder,
    externalId,
    isLoading: createOrderLoading,
  } = useCreateOrder(callbackFunction);
  const { billingInfo, isLoading: getBillingInfoLoading } =
    useGetBillingInfo(externalId);

  const createDepositForm = useForm<z.infer<typeof CreateDepositFormSchema>>({
    resolver: zodResolver(CreateDepositFormSchema),
    mode: 'onSubmit',
    defaultValues: {
      paymentMethod: PAYMENT_METHOD.BILLET,
      amount,
    },
  });

  const createDepositFormSubmit = createDepositForm.handleSubmit(
    ({ amount, expirationDate, paymentMethod }) => {
      const creditDate =
        paymentMethod === PAYMENT_METHOD.PIX
          ? DateTime.fromISO(expirationDate.toISOString(), {
              zone: 'America/Sao_Paulo',
            }).toJSDate()
          : DateTime.fromISO(expirationDate.toISOString(), {
              zone: 'America/Sao_Paulo',
            })
              .plus({ day: 2 })
              .toJSDate();

      const addBalanceInput: AddBalanceInput = {
        value: amount,
        creditDate,
        paymentMethod: paymentMethod as 'billet' | 'pix',
      };

      createOrder(addBalanceInput);
    },
  );

  return {
    form: createDepositForm,
    createDepositFormSubmit,
    createOrderLoading,
    adminInfo,
    billingInfo,
    getBillingInfoLoading,
  };
};
